import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import "./Vendor.less";
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 4,
    md: 14,
  },
  wrapperCol: {
    lg: 12,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateVendor(props) {
  const [data, setData] = useState({
    vendor_code: "",
    company_name: "",
    group: "",
    train_start: "",
    address: "",
    phone: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/vendor`,
      {
        vendor_code: data.vendor_code,
        company_name: data.company_name,
        group: data.group,
        train_start: data.train_start,
        address: data.address,
        phone: data.phone,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/vendor");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      vendor_code: "",
      company_name: "",
      group: "",
      train_start: "",
      address: "",
      phone: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Vendor"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/vendor">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Vendor</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form
                {...layout}
                name="control-hooks"
                onFinish={onSaveData}
                labelAlign="left"
                style={{ marginLeft: "25%" }}
              >
                <Form.Item
                  required
                  label="Vendor Code "
                  validateStatus={error && error.vendor_code ? "error" : false}
                  help={
                    error && error.vendor_code ? error.vendor_code[0] : false
                  }
                >
                  <Input
                    value={data.vendor_code}
                    onChange={(e) =>
                      handleChange("vendor_code", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Vendor Name "
                  validateStatus={error && error.company_name ? "error" : false}
                  help={
                    error && error.company_name ? error.company_name[0] : false
                  }
                >
                  <Input
                    value={data.company_name}
                    onChange={(e) =>
                      handleChange("company_name", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Group "
                  validateStatus={error && error.group ? "error" : false}
                  help={error && error.group ? error.group[0] : false}
                >
                  <Input
                    value={data.group}
                    onChange={(e) => handleChange("group", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Train Start"
                  validateStatus={error && error.train_start ? "error" : false}
                  help={
                    error && error.train_start ? error.train_start[0] : false
                  }
                >
                  <Input
                    value={data.train_start}
                    onChange={(e) =>
                      handleChange("train_start", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Title"
                  validateStatus={error && error.title ? "error" : false}
                  help={error && error.title ? error.title[0] : false}
                >
                  <Input
                    value={data.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Name"
                  validateStatus={error && error.name ? "error" : false}
                  help={error && error.name ? error.name[0] : false}
                >
                  <Input
                    value={data.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Search Term"
                  validateStatus={error && error.search_term ? "error" : false}
                  help={
                    error && error.search_term ? error.search_term[0] : false
                  }
                >
                  <Input
                    value={data.search_term}
                    onChange={(e) =>
                      handleChange("search_term", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Address "
                  validateStatus={error && error.address ? "error" : false}
                  help={error && error.address ? error.address[0] : false}
                >
                  <Input
                    style={{ width: 120, marginRight: 6 }}
                    placeholder="Street"
                    value={data.street}
                    onChange={(e) => handleChange("street", e.target.value)}
                  />

                  <Input
                    style={{ width: 120, marginRight: 6 }}
                    placeholder="City"
                    value={data.city}
                    onChange={(e) => handleChange("city", e.target.value)}
                  />

                  <Input
                    style={{ width: 120 }}
                    placeholder="Post Code"
                    value={data.post_code}
                    onChange={(e) => handleChange("address", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Contact "
                  validateStatus={error && error.contact ? "error" : false}
                  help={error && error.contact ? error.contact[0] : false}
                >
                  <Input
                    style={{ width: 120, marginRight: 6 }}
                    placeholder="Telephone 1"
                    value={data.telephone1}
                    onChange={(e) => handleChange("telephone1", e.target.value)}
                  />

                  <Input
                    style={{ width: 120, marginRight: 6 }}
                    placeholder="Telephone 2"
                    value={data.telephone2}
                    onChange={(e) => handleChange("telephone2", e.target.value)}
                  />

                  <Input
                    style={{ width: 120 }}
                    placeholder="Fax"
                    value={data.fax}
                    onChange={(e) => handleChange("fax", e.target.value)}
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateVendor;
