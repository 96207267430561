import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditMasterApproval(props) {
  const [data, setData] = useState({
    approval_access: "",
    username: "",
    npk: "",
    dept: "",
    position: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(`${url}/approval/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          approval_access: res.data.data.approval_access,
          username: res.data.data.username,
          npk: res.data.data.npk,
          dept: res.data.data.dept,
          position: res.data.data.position,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const onSaveData = () => {
    setLoading(true);
    Axios.post(
      `${url}/approval/${props.match.params.id}`,
      {
        approval_access: data.approval_access,
        username: data.username,
        npk: data.npk,
        dept: data.dept,
        position: data.position,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-approval");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };
  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleReset = () => {
    setData({
      approval_access: "",
      username: "",
      npk: "",
      dept: "",
      position: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Approval"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-approval">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Approval</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Approval Access "
                  validateStatus={
                    error && error.approval_access ? "error" : false
                  }
                  help={
                    error && error.approval_access
                      ? error.approval_access[0]
                      : false
                  }
                >
                  <Input
                    placeholder="Surat Jalan"
                    disabled
                    value={data.approval_access}
                    onChange={(e) =>
                      handleChange("approval_access", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="username "
                  validateStatus={error && error.username ? "error" : false}
                  help={error && error.username ? error.username[0] : false}
                >
                  <Input
                    value={data.username}
                    onChange={(e) => handleChange("username", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="NPK "
                  validateStatus={error && error.npk ? "error" : false}
                  help={error && error.npk ? error.npk[0] : false}
                >
                  <Input
                    value={data.npk}
                    onChange={(e) => handleChange("npk", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Dept"
                  validateStatus={error && error.dept ? "error" : false}
                  help={error && error.dept ? error.dept[0] : false}
                >
                  <Input
                    value={data.dept}
                    onChange={(e) => handleChange("dept", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Position"
                  validateStatus={error && error.position ? "error" : false}
                  help={error && error.position ? error.position[0] : false}
                >
                  <Input
                    value={data.position}
                    onChange={(e) => handleChange("position", e.target.value)}
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditMasterApproval;
