import { DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Form, Layout, List, message, Row, Upload } from 'antd';
import Axios from 'axios';
import React from 'react'
import { Fragment } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { url } from '../../config/global';

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CompanyUpload = (props) => {
  const [data, setData] = useState({
    file: null,
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/company/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/company");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Internal Server Error");
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      file: null,
    });
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card size="small">
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/company">Company</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Upload Company</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item name="file" label="File">
                  <Fragment>
                    <Upload
                      onRemove={handleRemove}
                      beforeUpload={handleBeforeUpload}
                      fileList={[]}
                    >
                      <Button>
                        Choose File <FileExcelOutlined />
                      </Button>
                    </Upload>
                    {data.file && (
                      <List>
                        <List.Item
                          actions={[
                            <Button onClick={handleRemove} type="danger" ghost>
                              <DeleteOutlined />
                            </Button>,
                          ]}
                        >
                          <List.Item.Meta description={data.file.name} />
                        </List.Item>
                      </List>
                    )}
                  </Fragment>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Upload
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  )
}

export default CompanyUpload;