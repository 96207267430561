import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Dropdown,
  Menu,
  Col,
  Modal,
  Input,
  // Table,
  DatePicker,
  Space,
  message,
  Radio,
  Typography,
  Tooltip,
  Comment, Avatar, Badge, Button, Form, Pagination
} from "antd";
import Table from "antd/lib/table";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./HomeApproval.less";
import Search from "antd/lib/input/Search";
import _ from "underscore";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExclamationOutlined,
  EyeOutlined,
  MoreOutlined,
  ProfileOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import TextArea from "antd/lib/input/TextArea";
import "../SummaryDetail.less"

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Meta } = Card;
const { Title } = Typography;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "pending",
    title: "Total Pending",
  },
  {
    filter: "approve",
    title: "Total Approve",
  },
  {
    filter: "review",
    title: "Total Review",
  },
  {
    filter: "reject",
    title: "Total Reject",
  },
];

function HomeApproval(props) {
  const [type, setType] = useState("pending");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadReject, setLoadReject] = useState(false);
  const [keyTransaction, setKeyTransaction] = useState("");
  const [keyAddBudget, setKeyAddBudget] = useState("");
  const [keyTransBudget, setKeyTransBudget] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [dataPayments, setDataPayments] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [pageT, setPageT] = useState(1);
  const [pageAB, setPageAB] = useState(1);
  const [pageTB, setPageTB] = useState(1);
  const [pageTransaction, setPageTransaction] = useState(10);
  const [pageAddBudget, setPageAddBudget] = useState(10);
  const [pageTransferBudget, setPageTrasnferBudget] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [count, setCount] = useState({
    all: "",
    pending: "",
    approve: "",
    reject: "",
    review: "",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "item_number_gr",
    order: "descend",
  });
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [budgetArea, setBudgetArea] = useState(null);
  const [budgetDivHead, setBudgetDivHead] = useState(null);
  const [pengajuanBiayaRupaDetails, setPengajuanBiayaRupaDetails] = useState(
    []
  );
  const [pengajuanBiayaRupaDetailsTotal, setPengajuanBiayaRupaDetailsTotal] =
    useState(0);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [approvalPemindahanBudgets, setApprovalPemindahanBudgets] = useState(
    []
  );
  const [approvalPenambahanBudgets, setApprovalPenambahanBudgets] = useState(
    []
  );
  const [handleDataReject, setHandleDataReject] = useState([]);

  const [printing, setPrinting] = useState([]);
  const [commentTemp, setCommentTemp] = useState({
    tempDataComment: []
  });
  const [commentList, setCommentList] = useState({});
  const [visible, setVisible] = useState(false);

  const handleSearch = (name, value) => {
    if(name === "transaction") {
      setKeyTransaction(value);
    } else if(name === "add-budget"){
      setKeyAddBudget(value);
    } else {
      setKeyTransBudget(value);
    }
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
    getDataTransferBudget();
    getDataPenambahanBudget();
    getApprovalPengajuanBiayaRupaRupaCount();
    getSetBudget();
    getSetBudgetDivHead();

    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
    }
  }, [keyTransaction, keyAddBudget, keyTransBudget, pageT, pageAB, pageTB, sort, type, data.start_date, data.end_date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/approval-home`, {
      params: {
        keyTransaction,
        columns: [
          "pengajuan_id",
          "penyelesaian_id",
          "department",
          "nama",
          "npk",
          "grand_total",
        ],
        perpage: pageTransaction,
        page: pageT,
        sort: sort.sort,
        order: sort.order,
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataPayments(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDataTransferBudget = () => {
    setLoading(true);
    Axios.get(`${url}/approval-home-pemindahan-budget`, {
      params: {
        keyTransBudget,
        columns: [
          "ref_code",
          "reminder_name",
          "reminder_status",
          "name",
          "npk",
          "divisi",
          "department",
          "transfer_to",
          "internal_order",
          "grand_total",
        ],
        perpage: pageTransferBudget,
        page: pageTB,
        sort: sort.sort,
        order: sort.order,
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setApprovalPemindahanBudgets(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDataPenambahanBudget = () => {
    setLoading(true);
    Axios.get(`${url}/approval-home-penambahan-budget`, {
      params: {
        keyAddBudget,
        columns: [
          "ref_code",
          "reminder_name",
          "reminder_status",
          "name",
          "npk",
          "divisi",
          "department",
          "transfer_to",
          "internal_order",
          "payment_status",
          "grand_total",
        ],
        perpage: pageAddBudget,
        page: pageAB,
        sort: sort.sort,
        order: sort.order,
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setApprovalPenambahanBudgets(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApprovalPengajuanBiayaRupaRupaCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pengajuan-uang-muka-biaya-rupa/count`, {
      params: {
        // keyword,
        // columns: ["gr_number", "vendor_name", "po_number"],
        // perpage: pageSize,
        // page,
        // sort: sort.sort,
        role_name: sessionStorage.getItem("role_name"),
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableTransaction = (pagination, filters, sorter) => {
    setPageT(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
    setPageTransaction(pagination.pageSize);
  };

  const handleTableAddBudget = (pagination, filters, sorter) => {
    setPageAB(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
    setPageAddBudget(pagination.pageSize);
  };

  const handleTableTransferBudget = (pagination, filters, sorter) => {
    setPageTB(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
    setPageTrasnferBudget(pagination.pageSize);
  };

  const showModal = (data, id) => {
    setHandleDataReject({
      ...handleDataReject,
      reminder_name: data.reminder_name,
      reminder_status: data.reminder_status
    })
    setSelectedId(id);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      status: "",
      remark: "",
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeStatus = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeDetails = (data, params) => {
    const index = data[data.length - 1];
    var dataParams = params.filter((x) => x._id === index);
    console.log('DATA PARAMS FILTER', dataParams);
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      console.log ('data2', data2[0]);
      setSelectedId(data2[0]);
      getDataDetailsPaymentsTransaction(data2[0], dataParams[0]);
    }
  };

  const getDataDetailsPaymentsTransaction = (id = selectedId , param) => {
    setLoadingDetail(true);
    let url_detail = ''
    if(param.reminder_status == 'pengajuan' && param.reminder_name == 'rupa-rupa'){
      url_detail = `${url}/pengajuan-uang-muka-biaya-rupa/details/${id}`
    }else if(param.reminder_status == 'penyelesaian' && param.reminder_name == 'rupa-rupa'){
      url_detail = `${url}/penyelesaian-uang-muka-biaya-rupa/details/${id}`
    }else if(param.reminder_status == 'pengajuan' && param.reminder_name == 'entertainment'){
      url_detail = `${url}/pengajuan-uang-muka-entertainment/details/${id}`
    }else if(param.reminder_status == 'penyelesaian' && param.reminder_name == 'entertainment'){
      url_detail = `${url}/penyelesaian-uang-muka-entertainment/details/${id}`
    }else if(param.reminder_status == 'pengajuan' && param.reminder_name == 'perdin'){
      url_detail = `${url}/pengajuan-uang-muka-perjalanan-dinas/details/${id}`
    }else if(param.reminder_status == 'penyelesaian' && param.reminder_name == 'perdin' || param.reminder_name == 'perjalanan dinas'){
      url_detail = `${url}/penyelesaian-uang-muka-perjalanan-dinas/details/${id}`
    }
    Axios.get(`${url_detail}`, {
      params: {
        keyword: keywordDetails,
        columns: ["id_material", "material_description"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPengajuanBiayaRupaDetails(res.data.data);
        setPengajuanBiayaRupaDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleReject = (id) => {
    setLoading(true);
    setLoadReject(true);

    let endpoint = ""
    if(handleDataReject.reminder_status == 'pengajuan' && handleDataReject.reminder_name == 'rupa-rupa'){
      endpoint = "pengajuan-uang-muka-biaya-rupa"
    }else if(handleDataReject.reminder_status == 'pengajuan' && handleDataReject.reminder_name == 'entertainment'){
      endpoint = "pengajuan-uang-muka-entertainment"
    }else if(handleDataReject.reminder_status == 'pengajuan' && handleDataReject.reminder_name == 'perdin'){
      endpoint = "pengajuan-uang-muka-perjalanan-dinas"
    }else if(handleDataReject.reminder_status == 'penyelesaian' && handleDataReject.reminder_name == 'rupa-rupa'){
      endpoint = "penyelesaian-uang-muka-biaya-rupa"
    }else if(handleDataReject.reminder_status == 'penyelesaian' && handleDataReject.reminder_name == 'entertainment'){
      endpoint = "penyelesaian-uang-muka-entertainment"
    }else if(handleDataReject.reminder_status == 'penyelesaian' && handleDataReject.reminder_name == 'perdin'){
      endpoint = "penyelesaian-perjalanan-dinas"
    }else if(handleDataReject.reminder_status == 'pemindahan'){
      endpoint = "pemindahan-budget"
    }else if(handleDataReject.reminder_status == 'penambahan'){
      endpoint = "penambahan-budget"
    }

    Axios.post(
      data.status === 3
        ? `${url}/review-${endpoint}/${id}`
        : `${url}/reject-${endpoint}/${id}`,
      {
        status: data.status,
        remark: data.remark,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        if(handleDataReject.reminder_status == 'penambahan') {
          getDataPenambahanBudget();
        } else if(handleDataReject.reminder_status == 'pemindahan') {
          getDataTransferBudget();
        } else {
          getData();
        }
        
        closeModal();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadReject(false);
        setLoading(false);
      });
  };

  const handleApprove = (data, id) => {
    console.log('HandleApprove', data, id);
    let message = "";
    if(data.reminder_status=='pengajuan'){
      message = "Advance Payment Submission"
    } else if(data.reminder_status == 'penyelesaian'){
      message = "Advance Settlement"
    } else if(data.reminder_status == 'pemindahan'){
      message = "Transfer Budget"
    } else {
      message = "Add Budget"
    }
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>{message} will be Approved</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApprove(data, id);
      },
    });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/pengajuan-uang-muka-biaya-rupa/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmApprove = (data, id) => {
    // console.log('approve', url, id, data.status);
    setLoading(true);
    let endpoint = ""
    if(data.reminder_status=='pengajuan' && data.reminder_name=='rupa-rupa'){
      endpoint = "approval-pengajuan-uang-muka-biaya-rupa"
    }else if(data.reminder_status=='pengajuan' && data.reminder_name=='entertainment'){
      endpoint = "approval-pengajuan-uang-muka-entertainment"
    }else if(data.reminder_status=='pengajuan' && data.reminder_name=='perdin' || data.reminder_name=='perjalanan dinas'){
      endpoint = "approval-pengajuan-uang-muka-perjalanan-dinas"
    }else if(data.reminder_status=='penyelesaian' && data.reminder_name=='rupa-rupa'){
      endpoint = "approval-penyelesaian-uang-muka-biaya-rupa"
    }else if(data.reminder_status=='penyelesaian' && data.reminder_name=='entertainment'){
      endpoint = "approval-penyelesaian-uang-muka-entertainment"
    }else if(data.reminder_status=='penyelesaian' && data.reminder_name=='perdin' || data.reminder_name=='perjalanan dinas'){
      endpoint = "approval-penyelesaian-perjalanan-dinas"
    }else if(data.reminder_status=='penyelesaian' && data.reminder_name=='perdin' || data.reminder_name=='perjalanan dinas'){
      endpoint = "approval-penyelesaian-perjalanan-dinas"
    }else if(data.reminder_status=='pemindahan'){
      endpoint = "approval-pemindahan-budget"
    }else if(data.reminder_status=='penambahan'){
      endpoint = "approval-penambahan-budget"
    }
    Axios.post(
      `${url}/${endpoint}/${id}`,
      {
        status: data.status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        if(data.reminder_status === "penambahan") {
          getDataPenambahanBudget();
        } else if(data.reminder_status === "pemindahan") {
          getDataTransferBudget();
        } else {
          getData();
        }
        // getApprovalPengajuanBiayaRupaRupaCount();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getSetBudgetDivHead = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "budget_div_head",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetDivHead(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getDetailApproval = (data) => {
    if(data.reminder_status=='pengajuan' && data.reminder_name=='rupa-rupa'){
      return(getPengajuanBiayaRupaRupaDetails())
    }else if(data.reminder_status=='penyelesaian' && data.reminder_name=='rupa-rupa'){
      return(getPengajuanBiayaRupaRupaDetails())
    }else if(data.reminder_status=='pengajuan' && data.reminder_name=='entertainment'){
      return(getEntertainmentDetails())
    }else if(data.reminder_status=='penyelesaian' && data.reminder_name=='entertainment'){
      return(getEntertainmentDetails())
    }else if(data.reminder_status=='pengajuan' && data.reminder_name=='perdin'){
      return(getPerjalananDinasDetails())
    }else if(data.reminder_status=='penyelesaian' && data.reminder_name=='perdin'){
      return(getPerjalananDinasDetails())
    }
    
  }
  const getPengajuanBiayaRupaRupaDetails = () => {
    const columns = [
      {
        title: "GL CODE",
        dataIndex: "gl_code",
        key: "gl_code",
        align: "center",
        width: 150,
      },
      {
        title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
        dataIndex: "description",
        key: "description",
        align: "left",
        width: 160,
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
        render: (cost_center_number) => {
          return cost_center_number ? 
            cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        title: "QUANTITY",
        dataIndex: "quantity",
        key: "quantity",
        width: 120,
        align: "center",
        render: (quantity) => {
          return <AntNumberFormat value={quantity} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>Unit Price</div>,
        dataIndex: "harga_satuan",
        key: "harga_satuan",
        // width: 80,
        render: (harga_satuan) => {
          return <AntNumberFormat value={harga_satuan} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>AMOUNT</div>,
        dataIndex: "jumlah",
        key: "jumlah",
        render: (jumlah) => {
          return <AntNumberFormat value={jumlah} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>PPN</div>,
        dataIndex: "ppn",
        key: "ppn",
        // sorter: true,
        render: (ppn) => {
          return <AntNumberFormat value={ppn} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>PPH21</div>,
        dataIndex: "pph21",
        key: "pph21",
        align: "right",
        render: (pph21) => {
          return <AntNumberFormat value={pph21} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>PPH23</div>,
        dataIndex: "pph23",
        key: "pph23",
        align: "right",
        render: (pph23) => {
          return <AntNumberFormat value={pph23} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>PPH4(2)</div>,
        dataIndex: "pph4",
        key: "pph4",
        align: "right",
        render: (pph4) => {
          return <AntNumberFormat value={pph4} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>TOTAL</div>,
        dataIndex: "total",
        key: "total",
        // sorter: true,
        render: (total) => {
          return <AntNumberFormat value={total} type="text" />;
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              //scroll={{ x: 1000 }}
              bordered
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={pengajuanBiayaRupaDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: pengajuanBiayaRupaDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ jumlah, total }) => {
                  totalAmount += jumlah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={6} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={5} className="table-summary">
                        <div className="summary">
                          <AntNumberFormat value={totalGrandTotal} type="text" />
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const getEntertainmentDetails = () => {
    const columns = [
      {
        title: "NO",
        dataIndex: "no",
        sorter: false,
        fixed: "center",
        width: 40,
        render: (text, record, index) => index + pageDetail * 10 - 9,
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
        render: (cost_center_number) => {
          return cost_center_number ? 
            cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        title: "Purchase of entertainment and the like",
        children: [
          {
            align: "center",
            title: "DATE",
            dataIndex: "tanggal",
            render: (date) => moment(date).format("DD-MM-YYYY"),
          },
          {
            align: "center",
            title: <div style={{ textAlign: "center" }}>LOCATION</div>,
            dataIndex: "tempat",
          },
          {
            align: "center",
            title: <div style={{ textAlign: "center" }}>ADDRESS</div>,
            dataIndex: "alamat",
          },

          {
            align: "center",
            title: "Type *",
            dataIndex: "jenis",
          },
          {
            align: "right",
            title: <div style={{ textAlign: "center" }}>AMOUNT (Rp)</div>,
            dataIndex: "jumlah",
            render: (jumlah) => {
              return <AntNumberFormat value={jumlah} type="text" />;
            },
          },
        ],
      },
      {
        title: "Relase business provided entertainment and the like",
        children: [
          {
            align: "left",
            title: <div style={{ textAlign: "center" }}>EMPLOYEE NAME</div>,
            dataIndex: "nama",
          },
          {
            align: "center",
            title: "Department",
            dataIndex: "jabatan",
          },
          {
            align: "left",
            title: <div style={{ textAlign: "center" }}>COMPANY NAME</div>,
            dataIndex: "nama_perusahaan",
          },
        ],
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={pengajuanBiayaRupaDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: pengajuanBiayaRupaDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ jumlah, total }) => {
                  totalAmount += jumlah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={7} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={3} className="table-summary"></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const getPerjalananDinasDetails = () => {
    const columns = [
      {
        title: <div>GL CODE</div>,
        align: "center",
        dataIndex: "gl_code",
        key: "gl_code",
        // width: 150,
      },
      {
        title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
        align: "center",
        dataIndex: "description",
        key: "description",
        // width: 150,
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
        render: (cost_center_number) => {
          return cost_center_number ? 
            cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        title: <div>QTY</div>,
        align: "center",
        dataIndex: "qty",
        key: "qty",
        render: (jumlah) => {
          return <AntNumberFormat value={jumlah} type="text" />;
        },
        // width: 120,
      },
      {
        title: <div style={{ textAlign: "center" }}>IDR</div>,
        align: "right",
        dataIndex: "rupiah",
        key: "rupiah",
        render: (rupiah) => {
          return <AntNumberFormat value={rupiah} type="text" />;
        },
        // width: 80,
      },
      {
        title: <div style={{ textAlign: "center" }}>USD</div>,
        align: "right",
        dataIndex: "usd",
        key: "usd",
        render: (usd) => {
          return <AntNumberFormat value={usd} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL</div>,
        align: "right",
        dataIndex: "total",
        key: "total",
        sorter: true,
        render: (total) => {
          return <AntNumberFormat value={total} type="text" />;
        },
        // render: (price_po) => {
        //   return <AntNumberFormat value={price_po} type="text" />;
        // },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              scroll={{ x: 500 }}
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={pengajuanBiayaRupaDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: pengajuanBiayaRupaDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ rupiah, total }) => {
                  totalAmount += rupiah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalGrandTotal} type="text" />
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const handlePrint = (id) => {
    setPrinting(true);
    setLoading(true);
    Axios.post(
      `${url}/pengajuan-uang-muka-biaya-rupa-print`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet started");
        }
      })
      .finally(() => {
        setPrinting(false);
        setLoading(false);
      });
  };

  const closeComment = () => {
    setVisible(false);
  };

  const handleChangeComment = (name, value) => {
    setCommentTemp({
        ...commentTemp,
        [name]: value,
        last_comment: sessionStorage.getItem("npk")
    })
  }

  const submitComment = () => {
    setLoading(true);

    Axios.post(
      `${url}/send-comment`,
      {
        doc_id: commentTemp.doc_id,
        doc_number: commentTemp.doc_number,
        doc_name: commentTemp.doc_name,
        doc_type: commentTemp.doc_type,
        comment_description: commentTemp.comment_description,
        last_comment: commentTemp.last_comment
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setCommentTemp({
        ...commentTemp,
        comment_description: '',
        last_comment: ''
      })
      
      setCommentList(res.data.data);
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /*  Open Data Comment Transaksi */
  const openDataComment = async (id, data) => {
    let $docNumber = "";
    if(data.reminder_status=='pengajuan'){
      $docNumber = data.pengajuan_id;
    } else if(data.reminder_status=='penyelesaian'){
      $docNumber = data.penyelesaian_id;
    } else if(data.reminder_status=='pemindahan'){
      $docNumber = data.ref_code;
    } else if(data.reminder_status=='penambahan'){
      $docNumber = data.ref_code;
    }

    setCommentTemp({
      ...commentTemp,
      doc_number: $docNumber,
      doc_name: data.reminder_name,
      doc_type: data.reminder_status,      
      doc_id: id,
      DocNumber: $docNumber,
      CreatedDate: data.created_at
    })
    setLoading(true);

    await Axios.get(
      `${url}/show-comment`, {
        params: {
          doc_id: id,
          doc_number: $docNumber,
          doc_name: data.reminder_name,
          doc_type: data.reminder_status,
          comment_description: data.comment_description,
          last_comment: data.last_comment
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    })
    .then((res) => {
      setCommentList(res.data.data)
      if(res.data.data){
        if(res.data.data.doc_type === "pemindahan") {
          getDataTransferBudget();
        } else if(res.data.data.doc_type === "penambahan") {
          getDataPenambahanBudget();
        } else {
          getData();
        }
      }

    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
      setVisible(true);

    });
  };

  const handleClickFile = (x) => {
    window.open(x.bukti_scan_url);
  };
  
  const columns = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      sorter: true,
      width: 80,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "document_number",
      key: "document_number",
      align: "center",
      width: 100,
      sorter: false,
      render: (id, data) => {
        if(data.reminder_status=='pengajuan'){
          return (
            <span>{data.pengajuan_id}</span>
          )
        }else{
          return (
            <span>{data.penyelesaian_id}</span>
          )
        }
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "nama",
      key: "nama",
      align: "left",
      width: 250,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 200,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      sorter: false,
      width: 300,
      key: "description",
    },
    {
      title: "FILE",
      dataIndex: "bukti_scan_details",
      sorter: false,
      align: "center",
      width: 200,
      render: (bukti_scan_url) =>
        bukti_scan_url &&
        bukti_scan_url.map((x, index) => {
          return x.bukti_scan.split(".")[1] === "jpg" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "jpeg" ? (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconimage.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : x.bukti_scan.split(".")[1] === "pdf" ? (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconpdf.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconexcel.png")}
              onClick={() => handleClickFile(x)}
            />
          );
        }),
    },
    {
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "grand_total",
      key: "grand_total",
      align: "right",
      width: 120,
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total} type="text" />;
      },
    },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width: 
        sessionStorage.getItem('role_name') === "Staff" ? 80 :
        sessionStorage.getItem('role_name') === "Admin" ? 150 : 100,
      render: (id, data) => {
        return (
          <div>
            {
							sessionStorage.getItem("role_name") === "Department Head" ? (
								<>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}} >
											<img
												onClick={() =>
													data.status === 1 ? handleApprove(data, id) : true
												}
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
		
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}}>
											<img
												onClick={() =>
													data.status === 1 ? showModal(data, id) : true
												}
												alt="icon"
												width="20"
												src={
												require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
								</>
							) : sessionStorage.getItem("role_name") === "Division Head" ? (
									<>
										<Tooltip placement="top" title="Approve">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? handleApprove(data, id) : true
													}
													alt="icon"
													width="20"
													src={
													require("../../assets/img/approve.png")
													}
												/>
											</span>
										</Tooltip>
			
			
										<Tooltip placement="top" title="Reject / Review">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? showModal(data, id) : true
													}
													alt="icon"
													width="20"
													src={
														require("../../assets/img/reject.png")
													}
												/>
											</span>
										</Tooltip>
									</>								
							) : sessionStorage.getItem("role_name") === "Personalia" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 3 ? handleApprove(data, id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                        require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>
    
    
                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 3 ? showModal(data, id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>								
            ) : sessionStorage.getItem("role_name") === "Direktur" ? (
                data.reminder_name === "perdin" || data.reminder_name === "perjalanan dinas" ?
                  <>
                    <Tooltip placement="top" title="Approve">
                      <span 
                        style={{
                          marginRight: 10, 
                          cursor: data.status !== 4 ? 'not-allowed' : 'pointer'
                        }} 
                      >
                        <img
                          onClick={() =>
                            data.status === 4 ? 
                              data.dir_approval ? handleApprove(data, id) : handleApprove(data, id)
                              : true
                          } 
                          alt="icon"
                          width="20"
                          src={
                            require("../../assets/img/approve.png")
                          }
                        />
                      </span>
                    </Tooltip>

                    <Tooltip placement="top" title="Reject / Review">
                      <span 
                        style={{
                          marginRight: 10, 
                          cursor: data.status !== 4 ? 'not-allowed' : 'pointer'   
                        }} 
                      >
                        <img
                          onClick={() =>
                            data.status === 4 ? data.dir_approval ? showModal(data, id) : showModal(data, id)
                            : true
                          }
                          alt="icon"
                          width="20"
                          src={
                            require("../../assets/img/reject.png")
                          }
                        />
                      </span>
                    </Tooltip>
                  </>

                : data.grand_total < budgetArea ? 
                  (
                    <>
                      <Tooltip placement="top" title="Approve">
                        <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                          <img
                            onClick={() =>
                              data.status === 3 ? handleApprove(data, id) : true
                            }
                            alt="icon"
                            width="20"
                            src={
                              require("../../assets/img/approve.png")
                            }
                          />
                        </span>
                      </Tooltip>
      
      
                      <Tooltip placement="top" title="Reject / Review">
                        <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                          <img
                            onClick={() =>
                              data.status === 3 ? showModal(data, id) : true
                            }
                            alt="icon"
                            width="20"
                            src={
                              require("../../assets/img/reject.png")
                            }
                          />
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip placement="top" title="Approve">
                        <span 
                          style={{
                            marginRight: 10, 
                            cursor: 
                              data.status === 3 ? 
                                data.status !== 3 ? 'not-allowed' : 'pointer'
                              : data.status === 4 ?
                                data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? "not-allowed" : "pointer"
                              : "not-allowed"  
                          }} 
                        >
                          <img
                            onClick={() =>
                              data.status === 3 ? 
                                data.dir_approval ? handleApprove(data, id) : handleApprove(data, id)
                              : data.status === 4 ?
                                data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? true : handleApprove(data, id)
                              : true
                            } 
                            alt="icon"
                            width="20"
                            src={
                              require("../../assets/img/approve.png")
                            }
                          />
                        </span>
                      </Tooltip>
      
                      <Tooltip placement="top" title="Reject / Review">
                        <span 
                          style={{
                            marginRight: 10, 
                            cursor: 
                              data.status === 3 ? 
                                data.status !== 3 ? 'not-allowed' : 'pointer'
                              : data.status === 4 ?
                                data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? "not-allowed" : "pointer"
                              : "not-allowed"   
                          }} 
                        >
                          <img
                            onClick={() =>
                              data.status === 3 ? 
                                data.dir_approval ? showModal(data, id) : showModal(data, id)
                              : data.status === 4 ?
                                data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? true : showModal(data, id)
                              : true
                            }
                            alt="icon"
                            width="20"
                            src={
                              require("../../assets/img/reject.png")
                            }
                          />
                        </span>
                      </Tooltip>
                    </>
                  )
							) : sessionStorage.getItem("role_name") === "Admin" ? (
								<>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10}}
											size="small"
											type="default"
											onClick={() => handleApprove(data,id) }
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
									
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10}}
											size="small"
											type="default"
											onClick={() =>
												data.status !== 5 ? showModal(data, id) : true
											}
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
		
									<Tooltip placement="top" title="Delete">
										<span style={{marginRight: 10}}
											size="small"
											type="default"
											onClick={() => handleDelete(data, id)}
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/delete.png")
												}
											/>
										</span>
									</Tooltip>
								</>
							) : null
						}
            <Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                // onClick={openComment}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Personalia" ?
                    data.sc_pers >= 1 ? data.sc_pers : 0
                  : sessionStorage.getItem('role_name') === "Direktur" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                  // data.status_comment > 0 && data.last_comment != sessionStorage.getItem('npk') ? data.status_comment : 0 }
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>

          </div>
        );
      }
    },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      sorter: false,
      width: 250,
      key: "transfer_to",
    },
   
    {
      title: "APPROVAL",
      children: [
        {
          title: " DEPT HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalUser",
          width: 110,
          render: (status, data) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    status === 2 || status === 3 || status === 4 || status === 5
                      ? data.app_dept
                      : ""
                  }
                >
                  {/* {data.grand_total < budgetArea ? ( */}
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 1
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                  {/* ) : (
                  "-"
                )} */}
                </Tooltip>
              </>
            );
          },
        },
        {
          title: " DIV HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalDeptHead",
          width: 110,
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={
                  status === 2 || status === 3 || status === 4 || status === 5
                    ? data.app_div
                    : ""
                }
              >
                <img
                  alt="icon"
                  width="20"
                  src={
                    status === 2
                      ? require("../../assets/img/sand-clock.png")
                      : status === 2 ||
                        status === 3 ||
                        status === 4 ||
                        status === 5
                      ? require("../../assets/img/checked.png")
                      : status === -2
                      ? require("../../assets/img/warning.png")
                      : status === -1
                      ? require("../../assets/img/danger.png")
                      : require("../../assets/img/sand-clock.png")
                  }
                />
              </Tooltip>
            );
          },
        },
        {
          title: "PERSONALIA",
          dataIndex: "status",
          align: "center",
          key: "approvalPersonalia",
          width: 110,
          render: (status, data) => {
            return (
              <>
                {data.reminder_name === "perjalanan dinas" || data.reminder_name === "perdin" ?
                    <Tooltip
                      placement="top"
                      title={
                        status === 3 || status === 4 || status === 5
                          ? data.app_personalia
                          : ""
                      }
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 3
                            ? require("../../assets/img/sand-clock.png")
                            : status === 4 || status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    </Tooltip>
                  : (
                    "-"
                  )
                }
              </>
            );
          },
        },
        {
          title: " DIRECTOR 1",
          dataIndex: "status",
          align: "center",
          key: "approvalDivHead",
          width: 110,
          render: (status, data) => {
            return (
              <>
                {data.reminder_name === "rupa-rupa" ?
                  data.grand_total >= budgetDivHead ? (
                    <Tooltip
                      placement="top"
                      title={
                        status === 3 || status === 4 || status === 5
                          ? data.app_dir1
                          : ""
                      }
                    >
                      {data.grand_total >= budgetDivHead ? (
                        <img
                          alt="icon"
                          width="20"
                          src={
                            status === 3
                              ? require("../../assets/img/sand-clock.png")
                              : status === 3 || status === 4 || status === 5
                              ? require("../../assets/img/checked.png")
                              : status === -2
                              ? require("../../assets/img/warning.png")
                              : status === -1
                              ? require("../../assets/img/danger.png")
                              : require("../../assets/img/sand-clock.png")
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </Tooltip>
                  ) 
                  : (
                    "-"
                  ) 
                : data.reminder_name === "perdin" || data.reminder_name === "perjalanan dinas" ? (
                  <Tooltip
                    placement="top"
                    title={
                      status === 3 || status === 4 || status === 5
                        ? data.app_dir1
                        : ""
                    }
                  >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 4
                            ? require("../../assets/img/sand-clock.png")
                            : status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="top"
                    title={
                      status === 3 || status === 4 || status === 5
                        ? data.app_dir1
                        : ""
                    }
                  >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 3
                            ? require("../../assets/img/sand-clock.png")
                            : status === 3 || status === 4 || status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                  </Tooltip>
                )}
              </>
            );
          },
        },
        {
          title: " DIRECTOR 2",
          dataIndex: "status",
          align: "center",
          key: "approvalDirektur",
          width: 110,
          render: (status, data) => {
            return (
              <>
                {data.reminder_name === "perdin" || data.reminder_name === "perjalanan dinas" ?
                  "-"
                : (
                  <Tooltip
                    placement="top"
                    title={status === 4 || status === 5 ? data.app_dir2 : ""}
                  >
                    {data.grand_total >= budgetArea ? (
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 4
                            ? require("../../assets/img/sand-clock.png")
                            : status === 4 || status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </Tooltip>
                )}
              </>
            );
          },
        },
      ],
    },
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalPersonalia" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalPersonalia" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDivHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Personalia") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDivHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Direktur") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalPersonalia" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Admin") {
      return filter.key !== "approvalAdmin";
    } else if (
      sessionStorage.getItem("role_name") !== "Department Head" &&
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Personalia" &&
      sessionStorage.getItem("role_name") !== "Direktur"
    ) {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalPersonalia" &&
        filter.key !== "approvalStatus" &&
        filter.key !== "approvalDeptHead"
      );
    }
    return filter;
  });

  const columnsTransferBudget = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      key: "created_at",
      width: 80,
      align: "center",
      sorter: true,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "ref_code",
      key: "ref_code",
      width: 100,
      align: "center",
      sorter: false,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "name",
      sorter: false,
      width: 250,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 200,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    // {
    //   title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
    //   dataIndex: "old_available",
    //   key: "old_available",
    //   width: 150,
    //   align: "right",
    //   render: (old_available) => {
    //     return <AntNumberFormat value={old_available ? old_available : 0} type="text" />;
    //   },
    // },
    
    {
      title: <div style={{ textAlign: "center" }}>EXPENSE</div>,
      dataIndex: "grand_total",
      key: "grand_total",
      width: 150,
      align: "right",
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total ? grand_total : 0} type="text" />;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>TOTAL TRANSFER BUDGET</div>,
      dataIndex: "total_transfer_budget",
      key: "total_transfer_budget",
      width: 150,
      align: "right",
      render: (total_transfer_budget) => {
        return <AntNumberFormat value={total_transfer_budget ? total_transfer_budget : 0} type="text" />;
      },
    },
    {
      title: "SOURCE DOCUMENT",
      dataIndex: "ref_code",
      sorter: false,
      align: "center",
      width: 150,
      render: (id, data) =>
        data.status === 0 ? (
          sessionStorage.getItem("npk") === `${data.npk}` ? (
            <Link
              style={{ fontWeight: 800 }}
              className={sessionStorage.getItem("npk")}
              dataType={data.npk}
              to={`/pemindahan-budget/${data.types_reimbursment
                .replaceAll(" ", "-")
                .toLowerCase()}/${id}`}
            >
              {id}
            </Link>
          ) : (
            id
          )
        ) : (
          id
        ),
    },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width:  sessionStorage.getItem('role_name') === "Staff" ? 80 :
              sessionStorage.getItem('role_name') === "Admin" ? 150 : 100,
      render: (id, data) => {
        return (
					<div>
						{
							sessionStorage.getItem("role_name") === "Department Head" ? (
								<>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}} >
											<img
												onClick={() =>
													data.status === 1 ? handleApprove(data, id) : true
												}
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
		
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}}>
											<img
												onClick={() =>
													data.status === 1 ? showModal(data, id) : true
												}
												alt="icon"
												width="20"
												src={
												require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
								</>
							) : sessionStorage.getItem("role_name") === "Division Head" ? (
									<>
										<Tooltip placement="top" title="Approve">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? handleApprove(data, id) : true
													}
													alt="icon"
													width="20"
													src={
													require("../../assets/img/approve.png")
													}
												/>
											</span>
										</Tooltip>
			
			
										<Tooltip placement="top" title="Reject / Review">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? showModal(data, id) : true
													}
													alt="icon"
													width="20"
													src={
														require("../../assets/img/reject.png")
													}
												/>
											</span>
										</Tooltip>
									</>								
							) : sessionStorage.getItem("role_name") === "Accounting" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 3 ? handleApprove(data, id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                        require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>
    
    
                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 2 ? showModal(data, id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>								
              ) : sessionStorage.getItem("role_name") === "Admin" ? (
								<>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10}}
											size="small"
											type="default"
											onClick={() => handleApprove(data, id) }
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
									
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10}}
											size="small"
											type="default"
											onClick={() =>
												data.status !== 5 ? showModal(data, id) : true
											}
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
		
									<Tooltip placement="top" title="Delete">
										<span style={{marginRight: 10}}
											size="small"
											type="default"
											onClick={() => handleDelete(id)}
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/delete.png")
												}
											/>
										</span>
									</Tooltip>
								</>
							) : null
						}

						<Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Accounting" ?
                    data.sc_acc >= 1 ? data.sc_acc : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>
					</div>
        );
      }
    },
    // {
    //   title: "INTERNAL ORDER",
    //   children: [
    //     {
    //       title: "FROM",
    //       dataIndex: "old_internal_order",
    //       width: 150,
    //       align: "center",
    //       key: "old_internal_order",
    //       render: (old_internal_order) => {
    //         // return old_internal_order.substring(2);
    //         return old_internal_order;
    //       },
    //     },
    //     {
    //       title: "TO",
    //       dataIndex: "new_internal_order",
    //       width: 150,
    //       align: "center",
    //       key: "new_internal_order",
    //       render: (new_internal_order) => {
    //         return new_internal_order;
    //       },
    //     },
    //   ]
    // },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      key: "approvalUser",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status < 4 && status >= 1
                ? require("../../assets/img/sand-clock.png")
                : status === 4
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalDeptHead",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 1
                ? require("../../assets/img/sand-clock.png")
                : status >= 2
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalDivHead",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 2
                ? require("../../assets/img/sand-clock.png")
                : status >= 3
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalAccounting",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 3
                ? require("../../assets/img/sand-clock.png")
                : status >= 4
                ? require("../../assets/img/checked.png")
                : status === 6
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalAccounting"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDeptHead" &&
        filter.key !== "approvalAccounting"
      );
    } else if (sessionStorage.getItem("role_name") === "Accounting") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Department Heaed" &&
      sessionStorage.getItem("role_name") !== "Accounting"
    ) {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDeptHead" &&
        filter.key !== "approvalStatus" &&
        filter.key !== "approvalAccounting"
      );
    } 
    return filter;
  });

  const columnsAddBudget = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      key: "created_at",
      width: 80,
      align: "center",
      sorter: true,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "ref_code",
      width: 100,
      align: "center",
      key: "ref_code",
      sorter: false,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "name",
      sorter: false,
      width: 250,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 200,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available",
      key: "available",
      width: 150,
      align: "right",
      render: (available) => {
        return <AntNumberFormat value={available ? available : 0} type="text" />;
      },
    },
    
    {
      title: <div style={{ textAlign: "center" }}>EXPENSE</div>,
      dataIndex: "grand_total",
      key: "grand_total",
      width: 150,
      align: "right",
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total ? grand_total : 0} type="text" />;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>ADD BUDGET</div>,
      dataIndex: "total_add_budget",
      key: "total_add_budget",
      width: 150,
      align: "right",
      render: (total_add_budget, data) => {
        return <AntNumberFormat value={total_add_budget ? total_add_budget : data.total_penambahan} type="text" />;
      },
    },
    {
      title: "SOURCE DOCUMENT",
      dataIndex: "ref_code",
      sorter: false,
      align: "center",
      width: 150,
      render: (id, data) =>
        data.status === 0 ? (
          sessionStorage.getItem("npk") === `${data.npk}` ? (
            <Link
              style={{ fontWeight: 800 }}
              className={sessionStorage.getItem("npk")}
              dataType={data.npk}
              to={`/penambahan-budget/${data.types_reimbursment
                .replaceAll(" ", "-")
                .toLowerCase()}/${id}`}
            >
              {id}
            </Link>
          ) : (
            id
          )
        ) : (
          id
        ),
    },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width:  sessionStorage.getItem('role_name') === "Staff" ? 80 :
              sessionStorage.getItem('role_name') === "Admin" ? 150 : 100,
      render: (id, data) => {
        return (
					<div>
						{
							sessionStorage.getItem("role_name") === "Department Head" ? (
								<>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}} >
											<img
												onClick={() =>
													data.status === 1 ? handleApprove(data, id) : true
												}
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
		
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}}>
											<img
												onClick={() =>
													data.status === 1 ? showModal(data, id) : true
												}
												alt="icon"
												width="20"
												src={
												require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
								</>
							) : sessionStorage.getItem("role_name") === "Division Head" ? (
									<>
										<Tooltip placement="top" title="Approve">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? handleApprove(data, id) : true
													}
													alt="icon"
													width="20"
													src={
													require("../../assets/img/approve.png")
													}
												/>
											</span>
										</Tooltip>
			
			
										<Tooltip placement="top" title="Reject / Review">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? showModal(data, id) : true
													}
													alt="icon"
													width="20"
													src={
														require("../../assets/img/reject.png")
													}
												/>
											</span>
										</Tooltip>
									</>								
							) : sessionStorage.getItem("role_name") === "Direktur" ? (
                  <>
                    <Tooltip placement="top" title="Approve">
                      <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                        <img
                          onClick={() =>
                            data.status === 3 ? handleApprove(data, id) : true
                          }
                          alt="icon"
                          width="20"
                          src={
                          require("../../assets/img/approve.png")
                          }
                        />
                      </span>
                    </Tooltip>
      
      
                    <Tooltip placement="top" title="Reject / Review">
                      <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                        <img
                          onClick={() =>
                            data.status === 3 ? showModal(data, id) : true
                          }
                          alt="icon"
                          width="20"
                          src={
                            require("../../assets/img/reject.png")
                          }
                        />
                      </span>
                    </Tooltip>
                  </>								
              ) : sessionStorage.getItem("role_name") === "Accounting" ? (
                  <>
                    <Tooltip placement="top" title="Approve">
                      <span style={{marginRight: 10, cursor: data.status !== 4 ? 'not-allowed' : 'pointer'}} >
                        <img
                          onClick={() =>
                            data.status === 4 ? handleApprove(data, id) : true
                          }
                          alt="icon"
                          width="20"
                          src={
                          require("../../assets/img/approve.png")
                          }
                        />
                      </span>
                    </Tooltip>
      
      
                    <Tooltip placement="top" title="Reject / Review">
                      <span style={{marginRight: 10, cursor: data.status !== 4 ? 'not-allowed' : 'pointer'}} >
                        <img
                          onClick={() =>
                            data.status === 4 ? showModal(data, id) : true
                          }
                          alt="icon"
                          width="20"
                          src={
                            require("../../assets/img/reject.png")
                          }
                        />
                      </span>
                    </Tooltip>
                  </>								
              ) : sessionStorage.getItem("role_name") === "Admin" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: 'pointer'}}
                      size="small"
                      type="default"
                      onClick={() => handleApprove(data, id) }
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>
                  
                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: 'pointer'}}
                      size="small"
                      type="default"
                      onClick={() =>
                        data.status !== 5 ? showModal(data, id) : true
                      }
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
    
                  <Tooltip placement="top" title="Delete">
                    <span style={{marginRight: 10, cursor: 'pointer'}}
                      size="small"
                      type="default"
                      onClick={() => handleDelete(id)}
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/delete.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>
              ) : null
						}
						<Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Direktur" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : sessionStorage.getItem('role_name') === "Accounting" ?
                    data.sc_acc >= 1 ? data.sc_acc : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>
					</div>
        );
      }
    },
    // {
    //   title: "INTERNAL ORDER",
    //   dataIndex: "internal_order",
    //   width: 150,
    //   align: "center",
    //   key: "internal_order",
    //   render: (internal_order) => {
    //     return internal_order.substring(2);
    //   },
    // },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalUser",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status < 4 && status >= 1
                ? require("../../assets/img/sand-clock.png")
                : status === 3
                ? require("../../assets/img/checked.png")
                : status === 6
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : status === -2
                ? require("../../assets/img/warning.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      width: 140,
      key: "approvalDeptHead",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 1
                ? require("../../assets/img/sand-clock.png")
                : status >= 2
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      width: 140,
      key: "approvalDivHead",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 2
                ? require("../../assets/img/sand-clock.png")
                : status >= 3
                ? require("../../assets/img/checked.png")
                : status === 6
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      width: 140,
      key: "approvalDirektur",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 3
                ? require("../../assets/img/sand-clock.png")
                : status >= 4
                ? require("../../assets/img/checked.png")
                : status === 6
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalAccounting",
      width: 140,
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 4
                ? require("../../assets/img/sand-clock.png")
                : status >= 5
                ? require("../../assets/img/checked.png")
                : status === 6
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalAccounting" &&
        filter.key !== "approvalDirektur"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalAccounting" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Direktur") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalAccounting" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Accounting") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (
      sessionStorage.getItem("role_name") !== "Department Head" &&
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Direktur" &&
      sessionStorage.getItem("role_name") !== "Accounting"
    ) {
      return (
        filter.key !== "approvalDeptHead" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalAccounting" &&
        filter.key !== "approvalStatus"
      );
    }
    return filter;
  });

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Why Reject?"
        visible={isModalVisible}
        onOk={() => {
          handleReject(selectedId);
        }}
        confirmLoading={loadReject}
        onCancel={handleCancel}
      >
        <Radio.Group
          style={{ marginBottom: 20 }}
          onChange={(e) => handleChangeStatus("status", e.target.value)}
          value={data.status}
        >
          <Radio value={3}>Review</Radio>
          <Radio value={4}>Reject</Radio>
        </Radio.Group>
        <div name="control-hooks">
          <TextArea
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          ></TextArea>
        </div>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="HOME APPROVAL "
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col xs={24}>
            {/* ALL TRANSACTION */}
            {
              sessionStorage.getItem('role_name') === "Staff" |
              sessionStorage.getItem('role_name') === "Department Head" |
              sessionStorage.getItem('role_name') === "Division Head" |
              sessionStorage.getItem('role_name') === "Personalia" |
              sessionStorage.getItem('role_name') === "Direktur" |
              sessionStorage.getItem('role_name') === "Admin" ? (
                <Card className="body-data-a">
                  <Row type="flex" gutter={[10, 20]} justify="space-around">
                    <Col xs={24} className="align-right">
                      <Space>
                        <Search
                          placeholder="Search..."
                          onSearch={(value) => handleSearch("transaction", value)}
                        />
                      </Space>
                    
                    </Col>
                    <Col span={24} style={{backgroundColor: '#daffb9', padding: 10}}>
                      <span style={{fontSize: 16, fontWeight: 'bold'}}>
                        Approval Payments : You have {dataPayments.total} pending task
                      </span>
                    </Col>
                    <Col xs={24}>
                      <Table
                        bordered
                        scroll={{ x: 1500 }}
                        expandable={{
                          expandedRowRender: (data) => getDetailApproval(data),
                          onExpandedRowsChange: (data) => handleChangeDetails(data, dataPayments.data),
                          expandedRowKeys: rowDetails,
                        }}
                        columns={columns}
                        dataSource={dataPayments.data}
                        rowKey={(data) => data._id}
                        onChange={handleTableTransaction}
                        pagination={{
                          pageSize: pageTransaction,
                          current: pageT,
                          total: dataPayments.total,
                          showSizeChanger: true,
                          position: ["bottomCenter"]
                        }}
                        loading={loading}
                        size="small"
                      />
                    </Col>
                  </Row>      
                </Card>
              ) : ""
            }
            {/* END ALL TRANSACTION */}

            {/* TRANSFER BUDGET */}
            {
              sessionStorage.getItem('role_name') === "Staff" |
              sessionStorage.getItem('role_name') === "Department Head" |
              sessionStorage.getItem('role_name') === "Division Head" |
              sessionStorage.getItem('role_name') === "Accounting" |
              sessionStorage.getItem('role_name') === "Admin" ? (
                <Card className="body-data-a">
                  <Row type="flex" gutter={[10, 20]} justify="space-around">
                    {/* Transfer Budget */}
                    <Col xs={24} className="align-right">
                      <Space>
                        <Search
                          placeholder="Search..."
                          onSearch={(value) => handleSearch("transfer-budget", value)}
                        />
                      </Space>
                    
                    </Col>

                    <Col span={24} style={{backgroundColor: '#daffb9', padding: 10}}>
                      <span style={{fontSize: 16, fontWeight: 'bold'}}>
                        Approval Transfer Budget : You have {approvalPemindahanBudgets.total} pending task
                      </span>
                    </Col>
                    <Col xs={24}>
                      <Table
                        bordered
                        scroll={{ x: 1500 }}
                        columns={columnsTransferBudget}
                        dataSource={approvalPemindahanBudgets.data}
                        onChange={handleTableTransferBudget}
                        pagination={{
                          pageSize: pageTransferBudget,
                          current: pageTB,
                          total: approvalPemindahanBudgets.total,
                          showSizeChanger: true,
                          position: ["bottomCenter"]
                        }}
                        loading={loading}
                        size="small"
                      />
                    </Col>
                  </Row>
                </Card>
              ) : ""
            }
            {/* END TRANSFER BUDGET */}

            {/* ADD BUDGET */}
            {
              sessionStorage.getItem('role_name') === "Staff" |
              sessionStorage.getItem('role_name') === "Department Head" |
              sessionStorage.getItem('role_name') === "Division Head" |
              sessionStorage.getItem('role_name') === "Direktur" |
              sessionStorage.getItem('role_name') === "Accounting" |
              sessionStorage.getItem('role_name') === "Admin" ? (
                <Card className="body-data-a">
                  <Row type="flex" gutter={[10, 20]} justify="space-around">
                    {/* Additional Budget */}
                    <Col xs={24} className="align-right">
                      <Space>
                        <Search
                          placeholder="Search..."
                          onSearch={(value) => handleSearch("add-budget", value)}
                        />
                      </Space>
                    
                    </Col>

                    <Col span={24} style={{backgroundColor: '#daffb9', padding: 10}}>
                      <span style={{fontSize: 16, fontWeight: 'bold'}}>
                        Approval Additional Budget : You have {approvalPenambahanBudgets.total} pending task
                      </span>
                    </Col>
                    <Col xs={24}>
                      <Table
                        bordered
                        scroll={{ x: 1500 }}
                        columns={columnsAddBudget}
                        dataSource={approvalPenambahanBudgets.data}
                        onChange={handleTableAddBudget}
                        pagination={{
                          pageSize: pageAddBudget,
                          current: pageAB,
                          total: approvalPenambahanBudgets.total,
                          showSizeChanger: true,
                          position: ["bottomCenter"]
                        }}
                        loading={loading}
                        size="small"
                      />
                    </Col>
                  </Row>
                </Card>
              ) : ""
            }
            {/* END ADD BUDGET */}
          </Col>
        </Row>
      </Content>

        {/* MODAL COMMENT LIST */}
        <Modal
          className="modal-comment-list"
          visible={visible}
          title={`${commentTemp.DocNumber} - ${commentTemp.CreatedDate} `}
          onOk={submitComment}
          onCancel={closeComment}
          footer={[
            <Form.Item>
              <TextArea
                rows={4}
                value={commentTemp.comment_description}
                onChange={(e) => handleChangeComment("comment_description", e.target.value)}
              ></TextArea>
            </Form.Item>,
            <Button key="back" onClick={closeComment} >
              Close
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={submitComment} >
              Send
            </Button>
          ]}
        >
        { commentList ?
            commentList.comment_list && 
              commentList.comment_list.map((comment) => {
              return (
                <Comment
                  className={`${comment.username === sessionStorage.getItem('username') ? 'comment-right' : ''}`}
                  author={<span className="comment-title">{comment.full_name}</span>}
                  avatar={<Avatar className="comment-avatar" src={comment.avatar} alt={`${comment.full_name}`} />}
                  content={
                    <p>
                      {comment.comment_description}
                    </p>
                  }
                  datetime={
                    <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{comment.comment_date}</span>
                    </Tooltip>
                  }
                />
              )
            })
          : <div id="comment-new-list">{commentTemp.tempDataComment}</div>}
      </Modal>
      {/* END COMMENT LIST */}
    </Fragment>
  );
}

export default HomeApproval;
