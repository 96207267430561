import React from "react";
import { Layout } from "antd";
import "./BasicLayout.less";
const { Footer } = Layout;

export default ({ children }, props) => (
  <div className="content-basic" {...props}>
    {children}
    {/* <Footer className="footer">&copy; 2021 Dharma Polimetal</Footer> */}
  </div>
);
