import React, { useState,useEffect } from "react";
import { Input, Tabs, Button, message,Tooltip, Form, Row,Col,Typography,Spin} from "antd";
import "./SelectCompany.less";
import { CalculatorFilled, LoadingOutlined, LogoutOutlined } from '@ant-design/icons'
import { UserOutlined, LockOutlined, RedoOutlined } from "@ant-design/icons";
import QrReader from "react-qr-reader";
import Axios from "axios";
import { url } from "../../config/global";
import { Link, Redirect } from "react-router-dom";

const { Title, Paragraph, Text} = Typography;
const { TabPane } = Tabs;

function SelectCompany(props) {
  const [ptlist, setPtList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaderText, setloaderText] = useState("");
  const [loadeddata, setLoadedData] = useState(false);
  const [companies, setCompanies] = useState([]);

  const getCompanies = async (user_companies) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("ids", user_companies);

    await Axios.post(`${url}/company/gets`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res)
        if (res.data.data.length === 1) {
          const company = res.data.data[0];
          handleSelectCompany(company._id, company.code, company.name, company.logo_url ? company.logo_url : null);
        } else {
          setCompanies(res.data.data);
          getData();
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);

          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }

        setLoading(false);
      });
  };

  useEffect(() => {
    // if(!checkUsrCompany())
    // {
    //   getData();
    // }

    const user_companies =
      localStorage.getItem("user_companies") === "undefined"
        ? false
        : JSON.parse(localStorage.getItem("user_companies"));

    if (user_companies) {
      getCompanies(user_companies);
    }
   
  },[]);

  const handleSelectCompany = async (id, code, name, logo_url) => {
    setLoading(true);
    setloaderText('Redirecting...')
    var jsoncomp = JSON.parse(sessionStorage.getItem('reg_company_code'));

   
    if(jsoncomp.length <= 1)
    {
      sessionStorage.setItem("cmpid", id);
      sessionStorage.setItem("company_code", code);
      sessionStorage.setItem("company_name", name);
      sessionStorage.setItem("company_logo", logo_url === null ? require("../../assets/img/Logo-Dharma2.png") : logo_url);
  
      setTimeout(() => {
        return props.history.push("/home-approval", { from: "select-company" });
      }, 1000);
    } else{
      // props.history.push('select-company');
      getData()
    }
  };

  function setHeaderCode(code,id)  {
    setLoadedData(false)
    setloaderText('Redirectings...')
    Axios.get(`${url}/company/show/`+id, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      console.log(res)
      if(res.data.data != null)
      {
        sessionStorage.setItem("cmpid", res.data.data.id);
        sessionStorage.setItem("company_name", res.data.data.name);
        sessionStorage.setItem("company_logo", res.data.data.logo_url === null ? require("../../assets/img/Logo-Dharma2.png") : res.data.data.logo_url);
        sessionStorage.setItem("company_code", code);
        //  alert(sessionStorage.getItem("company_code"));
          Axios.defaults.headers.common['COMPANY-CODE'] = sessionStorage.getItem("company_code");
          message.success(res.data.data.name+" is selected.");
          return new Promise((resolve) =>
            setTimeout(
              () => { 
                //props.history.push('/dashboard')
                window.location.href = "/home-approval"
              },
              1000
            )
          );
      } else{
        message.error("Selected Company is invalid.");
        setLoadedData(true)
      }
      
    })
    .catch((err) => {
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
        }
      }
    })
    .finally(() => {
      setLoading(false);
    });

      
  }

  const checkUsrCompany = () => {
    var jsoncomp = JSON.parse(sessionStorage.getItem('reg_company_code'));
   
    if(jsoncomp.length <= 1)
    {
      sessionStorage.setItem("company_code", jsoncomp[0]);
      Axios.defaults.headers.common['COMPANY-CODE'] = sessionStorage.getItem("company_code");
      console.log(ptlist)
      // window.location.href = "/home-approval"
    } else{
      props.history.push('select-company');
    }
  
  }

  const getData = () => {
    setLoading(true);
    
    setloaderText('Memuat Data PT...')
    
    Axios.get(`${url}/company`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      
      var jsoncomp = JSON.parse(sessionStorage.getItem('reg_company_code'));
      var ptlist = res.data.data;
      //Get All
      // setPtList(ptlist);
      // console.log(ptlist);
      // console.log(jsoncomp);
      //FOR Registered company on user collection
      jsoncomp.map(function(rcc, index) { 
     
            ptlist.map(function(ptl, index) { 
            
              if(rcc == ptl.code){
               
                setPtList(current => [...current,{
                  code: ptl.code,
                  key:ptl.key,
                  name:ptl.name,
                  table_generated:ptl.table_generated
                }]);
              }
            
            
            } )
      } )
      setLoadedData(true)
    })
    .catch((err) => {
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
        }
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleLogout = () => {
    sessionStorage.clear()

    return props.history.push('/login') 
  }

  if (!sessionStorage.getItem('token')) {
    return <Redirect to="/login" />
  }
 
  return (
    <div>
      <Row gutter={[16,16]}>
        <Col span={24} align="right">
          <Button className='button-logout' onClick={handleLogout}>
              <LogoutOutlined />
                Logout
          </Button>
        </Col>
      </Row>
      

      <Row gutter={[16, 16]} align="middle" >
        <Col span={24}  align="middle"> 
          <div className="header-company">
              <img
                src={require("../../assets/img/Logo-Dharma2.png")}
                className="img-dharma"
                alt="Dharma Logo"
              />
          </div>
        </Col>
        <Col span={24} align="middle">
          <Title level={3}>Pilih salah satu PT untuk melanjutkan</Title>
        </Col>

        <Col span={24} align="middle">
          <div className="container">
            <Row  
              type="flex"  
              gutter={[16, 12]} 
              align="middle" 
              className={loadeddata ? 'hide':''}
            >
              <Col span="24">
                <Spin tip={loaderText}></Spin>
              </Col>
            </Row>
          
            <Row  
              type="flex"  
              gutter={[16, 12]} 
              align="middle" 
              className={loadeddata ? 'scrollable':'hide'}
            >

              {ptlist.map((ptl, index) => (  
                <Col
                  key={ptl.code} 
                  span={12} 
                  align="middle"
                >
                  { ptl.table_generated ? (
                    <Button  
                      type="primary" 
                      onClick={() =>  {setHeaderCode(ptl.code,ptl.key) }} 
                      size="large" 
                      style={{width:"100%"}}
                    >
                      { ptl.name }
                    </Button> 
                  ) : (  
                    <Button 
                      title={ptl.name+" has not generated yet"}  
                      type="primary" 
                      disabled  
                      className="dis"  
                      size="large" 
                      style={{width:"100%"}}
                    >
                      {ptl.name}
                    </Button>
                  )} 
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
    
  )
}

export default SelectCompany;
