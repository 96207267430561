import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Input,
  Table,
  DatePicker,
  Space,
  message,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./ApprovalPengajuanUangMuka.less";
import Search from "antd/lib/input/Search";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "inProgress",
    title: "Total In Progress",
  },
  {
    filter: "receive",
    title: "Total Receive",
  },
  {
    filter: "unverified",
    title: "Total Unverified",
  },
];

function ApprovalPengajuanUangMuka() {
  const [type, setType] = useState("all");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [approvalUangMukas, setApprovalUangMukas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [count, setCount] = useState({
    all: "",
    pending: "",
    approve: "",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "item_number_gr",
    order: "descend",
  });
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [pengajuanUangMukaDetails, setPengajuanUangMukaDetails] = useState([]);
  const [pengajuanUangMukaDetailsTotal, setPengajuanUangMukaDetailsTotal] =
    useState(0);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
    getApprovalUangMukaCount();
  }, [keyword, page, sort, type, data.start_date, data.end_date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/pengajuan-uang-muka`, {
      params: {
        keyword,
        //columns: ["gr_number", "vendor_name", "po_number"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setApprovalUangMukas(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApprovalUangMukaCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pengajuan-uang-muka/count`, {
      params: {
        // keyword,
        // columns: ["gr_number", "vendor_name", "po_number"],
        // perpage: pageSize,
        // page,
        // sort: sort.sort,
        // order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getBiayaPengajuanUangMukaDetails(data2[0]);
    }
  };

  const getBiayaPengajuanUangMukaDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/pengajuan-uang-muka/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["id_material", "material_description"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPengajuanUangMukaDetails(res.data.data);
        setPengajuanUangMukaDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const getPengajuanUangMukaDetails = () => {
    const columns = [
      {
        title: "Kolom A",
        dataIndex: "kolom_a",
        key: "kolom_a",
        width: 150,
      },
      {
        title: "Kolom B",
        dataIndex: "kolom_b",
        key: "kolom_b",
        width: 150,
      },
      {
        title: "Kolom C",
        dataIndex: "kolom_c",
        key: "kolom_c",
        width: 120,
      },
      {
        title: "Kolom D",
        dataIndex: "kolom_d",
        key: "kolom_d",
        width: 80,
      },
      {
        title: "Kolom E",
        dataIndex: "kolom_e",
        key: "kolom_e",
      },
      {
        title: "Kolom F",
        dataIndex: "kolom_f",
        key: "kolom_f",
        sorter: true,
        // render: (price_po) => {
        //   return <AntNumberFormat value={price_po} type="text" />;
        // },
      },
      {
        title: "Kolom G",
        dataIndex: "kolom_g",
        key: "kolom_g",
        sorter: true,
        // render: (amount_gr) => {
        //   return <AntNumberFormat value={amount_gr} type="text" />;
        // },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              //scroll={{ x: 1000 }}
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={pengajuanUangMukaDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: pengajuanUangMukaDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
            />
          </Col>
        </Row>
      </>
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "nama_karyawan",
      key: "nama_karyawan",
      sorter: true,
    },
    {
      title: "NPK",
      dataIndex: "npk",
      key: "npk",
      sorter: true,
    },
    {
      title: "Internal Order",
      dataIndex: "internal_order",
      key: "internal_order",
    },
    {
      title: "Cost Center",
      dataIndex: "cost_center",
      key: "cost_center",
    },
    {
      title: "Amount",
      dataIndex: "grand_total",
      key: "grand_total",
    },
    // {
    //     title: '',
    //     dataIndex: 'status',
    //     key: 'status',
    //     fixed: 'right',
    //     width: 100,
    //     render: (status, data) => status === 3 && (
    //         <Button size="small" loading={printing} type="default" onClick={() => handlePrint(data.invoice_number)}><PrinterOutlined />Print</Button>
    //     )
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="Approval Uang Muka"
            //extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-in-progress pointer"
              title="Pending"
              bordered={false}
              onClick={() => handleChangeType("pending")}
            >
              <div className="value-font-size ">{count.pending}</div>
              {/* <div className="value-font-size ">5</div> */}
            </Card>
          </Col>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-received pointer"
              title="Approved"
              bordered={false}
              onClick={() => handleChangeType("approve")}
            >
              <div className="value-font-size "> {count.approve}</div>
              {/* <div className="value-font-size ">3</div> */}
            </Card>
          </Col>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-all pointer"
              title="Total Document"
              bordered={false}
              onClick={() => handleChangeType("all")}
            >
              <div className="value-font-size "> {count.all}</div>
              {/* <div className="value-font-size ">8</div> */}
            </Card>
          </Col>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={10} lg={10}>
                  {types.find((x) => x.filter === type).title}
                </Col>
                <Col xs={24} md={14} lg={14} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                    {/* <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                    /> */}
                  </Space>
                  {/* <Search
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                    /> */}
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getPengajuanUangMukaDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    dataSource={approvalUangMukas.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: approvalUangMukas.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ApprovalPengajuanUangMuka;
