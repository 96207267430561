import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Select,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { CaretRightOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;
const dateFormat = "DD-MM-YYYY";

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function EditPenyelesaianUangMukaPerjalananDinas(props) {
  const [data, setData] = useState({
    pengajuan_uang_muka_perjalanan_dinas_code: "",
    pengajuan_uang_muka_perjalanan_dinas_id: "",
    nama_karyawan: "",
    npk: "",
    divisi: "",
    tanggal_berangkat: "",
    tanggal_pulang: "",
    jenis_perjalanan: "",
    kota_tujuan: "",
    kendaraan: "",
    nominal_kurs: "",
    code_name: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [codes, setCodes] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [penyelesaianUangMukaDetail, setPenyelesaianUangMukaDetail] = useState(
    []
  );

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(
      `${url}/penyelesaian-uang-muka-perjalanan-dinas/${props.match.params.id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        setData({
          ...data,
          perjalanan_dinas_code: res.data.data.deklarasi_label,
          perjalanan_dinas_id: res.data.data.deklarasi_id,
          nama_karyawan: res.data.data.nama,
          npk: res.data.data.npk,
          divisi: res.data.data.divisi,
          tanggal_berangkat: res.data.data.tanggal_berangkat
            ? moment(res.data.data.tanggal_berangkat, dateFormat)
            : null,
          tanggal_pulang: res.data.data.tanggal_pulang
            ? moment(res.data.data.tanggal_pulang, dateFormat)
            : null,
          jenis_perjalanan: res.data.data.jenis_perjalanan,
          kota_tujuan: res.data.data.tujuan,
          kendaraan: res.data.data.kendaraan,
          nominal_kurs: res.data.data.nominal_kurs,
          mata_uang: res.data.data.mata_uang,
          grand_total: res.data.data.grand_total,
          details: penyelesaianUangMukaDetail,
          internal_order_id: res.data.data.internal_order_id,
          internal_order_number: res.data.data.internal_order_number,
          internal_order_label: `${res.data.data.internal_order_number} - ${res.data.data.internal_order_description}`,
          cost_center_id: res.data.data.cost_center_id,
          cost_center_number: res.data.data.cost_center_number,
          cost_center_label: `${res.data.data.cost_center_number} - ${res.data.data.cost_center_description}`,
          available: res.data.data.available_budget,
          deklarasi_umb_id: res.data.data.deklarasi_id,
          deklarasi_umb_code: res.data.data.deklarasi_code,
          deklarasi_umb_total: res.data.data.total_umb,
        });
        setPenyelesaianUangMukaDetail(
          res.data.data.details.map((detail) => {
            return {
              id: detail._id,
              ...detail,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log(data);

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/penyelesaian-uang-muka-perjalanan-dinas/${props.match.params.id}`,
      {
        perjalanan_dinas_id: data.perjalanan_dinas_id,
        perjalanan_dinas_code: data.perjalanan_dinas_code,
        tanggal_berangkat: data.tanggal_berangkat,
        tanggal_pulang: data.tanggal_pulang,
        jenis_perjalanan: data.jenis_perjalanan,
        kota_tujuan: data.kota_tujuan,
        kendaraan: data.kendaraan,
        nominal_kurs: data.nominal_kurs,
        details: penyelesaianUangMukaDetail,
        types_reimbursment: "Penyelesaian Perjalanan Dinas",
        internal_order_id: data.internal_order_id,
        internal_order_number: data.internal_order_number,
        cost_center_id: data.cost_center_id,
        cost_center_number: data.cost_center_number,
        cost_center_label: data.cost_center_label,
        mata_uang: data.mata_uang,
        available: data.available,
        grand_total: data.grand_total,
        deklarasi_id: data.perjalanan_dinas_id,
        deklarasi_code: data.deklarasi_umb_code,
        deklarasi_label: data.perjalanan_dinas_code,
        total_umb: data.deklarasi_umb_total,
        calSum: data.kurang_lebih,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/penyelesaian-uang-muka-perjalanan-dinas");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      code_name: "",
      nama_karyawan: "",
      npk: "",
      divisi: "",
      tanggal_berangkat: "",
      tanggal_pulang: "",
      jenis_perjalanan: "",
      kota_tujuan: "",
      kendaraan: "",
      nominal_kurs: "",
    });
  };

  const getDetails = (id) => {
    setLoading(true);
    Axios.get(`${url}/perjalanan-dinas/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPenyelesaianUangMukaDetail(res.data.data.details);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSelectCode = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          nama_karyawan: findControlItem.nama_karyawan,
          npk: findControlItem.npk,
          divisi: findControlItem.divisi,
          jenis_perjalanan: findControlItem.jenis_perjalanan,
          tanggal_pulang: moment(findControlItem.tanggal_pulang),
          tanggal_berangkat: moment(findControlItem.tanggal_berangkat),
          kota_tujuan: findControlItem.kota_tujuan,
          kendaraan: findControlItem.kendaraan,
          nominal_kurs: findControlItem.nominal_kurs,
          grand_total: findControlItem.grand_total,
          dirupiahkan: findControlItem.dirupiahkan,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const getCode = async () => {
    await Axios.get(`${url}/perjalanan-dinas`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const columns = [
    {
      align: "center",
      title: "Code",
      dataIndex: "gl_code",
    },
    {
      title: <div style={{ textAlign: "center" }}>Description</div>,
      dataIndex: "description",
      align: "left",
    },
    {
      align: "center",
      title: "Qty",
      dataIndex: "qty",
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>IDR</div>,
      dataIndex: "rupiah",
      render: (rupiah) => {
        return <AntNumberFormat value={rupiah} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>USD</div>,
      dataIndex: "usd",
      render: (usd) => {
        return <AntNumberFormat value={usd} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Total</div>,
      dataIndex: "total",
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/penyelesaian-uang-muka-perjalanan-dinas">
                    Back
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Advance settlement Update</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.perjalanan_dinas_code
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.perjalanan_dinas_code
                                  ? error.perjalanan_dinas_code[0]
                                  : false
                              }
                              label="Ref Doc Number"
                            >
                              <Select
                                labelInValue
                                disabled
                                onChange={(value) =>
                                  handleChangeSelectCode(
                                    "perjalanan_dinas",
                                    value
                                  )
                                }
                                onFocus={() => getCode("")}
                                showSearch
                                onSearch={(value) => getCode(value)}
                                filterOption={false}
                                value={{
                                  value: data.perjalanan_dinas_id,
                                  label: data.perjalanan_dinas_code,
                                }}
                              >
                                {codes &&
                                  codes.map((code) => {
                                    return (
                                      <Option value={code._id} key={code._id}>
                                        {code.perjalanan_dinas_code}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Employee Name"
                            >
                              <Input
                                disabled
                                value={data.nama_karyawan}
                                onChange={(e) => {
                                  handleChange("nama_karyawan", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={data.npk}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Division"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("divisi")}
                                onChange={(e) => {
                                  handleChange("divisi", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.tanggal_berangkat
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.tanggal_berangkat
                                  ? error.tanggal_berangkat[0]
                                  : false
                              }
                              label="Departure "
                            >
                              <DatePicker
                                disabled
                                style={{ width: 280 }}
                                format="DD MMMM YYYY"
                                value={data.tanggal_berangkat}
                                onChange={(value) =>
                                  handleChange("tanggal_berangkat", value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.tanggal_pulang ? "error" : false
                              }
                              help={
                                error && error.tanggal_pulang
                                  ? error.tanggal_pulang[0]
                                  : false
                              }
                              label="Return "
                            >
                              <DatePicker
                                disabled
                                style={{ width: 280 }}
                                format="DD MMMM YYYY"
                                value={data.tanggal_pulang}
                                onChange={(value) =>
                                  handleChange("tanggal_pulang", value)
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.cost_center ? "error" : false
                              }
                              help={
                                error && error.cost_center
                                  ? error.cost_center[0]
                                  : false
                              }
                              label="Cost Center"
                            >
                              <Select
                                disabled
                                labelInValue
                                showSearch
                                filterOption={false}
                                value={{
                                  key: data.cost_center_id,
                                  label: data.cost_center_label,
                                }}
                              >
                                {data.cost_centers &&
                                  data.cost_centers.map((cost_center) => {
                                    return (
                                      <Option
                                        value={cost_center._id}
                                        key={cost_center._id}
                                      >
                                        {`${cost_center.cost_center_number} - ${cost_center.description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Internal Order">
                              <Select
                                loading={
                                  data.available === 0 ? loadingBudget : ""
                                }
                                disabled
                                labelInValue
                                showSearch
                                filterOption={false}
                                value={{
                                  key: data.internal_order_number,
                                  label: data.internal_order_label,
                                }}
                              >
                                {budgets &&
                                  budgets.map((internal_order_budget) => {
                                    return (
                                      <Option
                                        value={
                                          internal_order_budget.internal_order_number
                                        }
                                        key={internal_order_budget._id}
                                      >
                                        {`${internal_order_budget.internal_order_number} - ${internal_order_budget.description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.jenis_perjalanan
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.jenis_perjalanan
                                  ? error.jenis_perjalanan[0]
                                  : false
                              }
                              label="Type of Travel"
                            >
                              <Select
                                disabled
                                onChange={(value) =>
                                  handleChange("jenis_perjalanan", value)
                                }
                                value={data.jenis_perjalanan}
                              >
                                <Option value="Domestik">Domestik</Option>
                                <Option value="Mancanegara">Mancanegara</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.kota_tujuan ? "error" : false
                              }
                              help={
                                error && error.kota_tujuan
                                  ? error.kota_tujuan[0]
                                  : false
                              }
                              label="Destination"
                            >
                              <Input
                                disabled
                                value={data.kota_tujuan}
                                onChange={(e) => {
                                  handleChange("kota_tujuan", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.kendaraan ? "error" : false
                              }
                              help={
                                error && error.kendaraan
                                  ? error.kendaraan[0]
                                  : false
                              }
                              label="Vehicle"
                            >
                              <Input
                                disabled
                                value={data.kendaraan}
                                onChange={(e) => {
                                  handleChange("kendaraan", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nominal_kurs ? "error" : false
                              }
                              help={
                                error && error.nominal_kurs
                                  ? error.nominal_kurs[0]
                                  : false
                              }
                              label="Currency Reference"
                            >
                              <Input
                                disabled
                                decimalSeparator="."
                                thousandSeparator=","
                                type="text"
                                id="nominal_kurs"
                                className={`form-control text-right ant-input ${
                                  error &&
                                  error.data.errors.nominal_kurs &&
                                  "is-invalid"
                                }`}
                                value={data.nominal_kurs}
                                onValueChange={(value) =>
                                  handleChange("nominal_kurs", value.floatValue)
                                }
                                placeholder="0 "
                              />
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Table
                      columns={columns}
                      size="small"
                      dataSource={penyelesaianUangMukaDetail}
                      rowKey={(data) => data._id}
                      onChange={handleTableChange}
                      // pagination={{
                      //   pageSize: 10,
                      //   current: page,
                      //   total: biayaRupas.total,
                      //   showSizeChanger: false,
                      // }}
                      loading={loading}
                    />
                    <Form
                      style={{ marginTop: "30px" }}
                      labelAlign="left"
                      {...layout}
                      name="control-hooks"
                    >
                      <Row>
                        <Col xs={24} md={12} lg={12}>
                          <Form.Item label="Total">
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="grand_total"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.grand_total &&
                                "is-invalid"
                              }`}
                              // value={data.grand_total}
                              value={penyelesaianUangMukaDetail.reduce(
                                (prevValue, currentValue) => {
                                  const total =
                                    currentValue.category === "Mancanegara"
                                      ? currentValue.total
                                      : currentValue.total;
                                  const grandTotal = total;

                                  return grandTotal + prevValue;
                                },
                                0
                              )}
                              onValueChange={(value) =>
                                handleChange("jumlah", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.uang_muka ? "error" : false
                            }
                            help={
                              error && error.uang_muka
                                ? error.uang_muka[0]
                                : false
                            }
                            label="IDR"
                          >
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="uang_muka"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.uang_muka &&
                                "is-invalid"
                              }`}
                              // value={data.uang_muka}
                              value={penyelesaianUangMukaDetail.reduce(
                                (prevValue, currentValue) => {
                                  const total =
                                    currentValue.category === "Mancanegara"
                                      ? currentValue.rupiah
                                      : 0;
                                  const grandTotal = total;

                                  return grandTotal + prevValue;
                                },
                                0
                              )}
                              onValueChange={(value) =>
                                handleChange("uang_muka", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.uang_muka ? "error" : false
                            }
                            help={
                              error && error.uang_muka
                                ? error.uang_muka[0]
                                : false
                            }
                            label="Down Payment Filing"
                          >
                            <NumberFormat
                              //defaultValue="0"
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="uang_muka"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.uang_muka &&
                                "is-invalid"
                              }`}
                              value={
                                data.deklarasi_umb_total
                                  ? data.deklarasi_umb_total
                                  : 0
                              }
                              onValueChange={(value) =>
                                handleChange("uang_muka", value.floatValue)
                              }
                              placeholder="0"
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.kurang_lebih ? "error" : false
                            }
                            help={
                              error && error.kurang_lebih
                                ? error.kurang_lebih[0]
                                : false
                            }
                            label="+/-"
                          >
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="kurang_lebih"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.kurang_lebih &&
                                "is-invalid"
                              }`}
                              value={
                                data.deklarasi_umb_total
                                  ? data.deklarasi_umb_total - data.grand_total
                                  : 0
                              }
                              onValueChange={(value) =>
                                handleChange("kurang_lebih", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                          <Form.Item label="Grand Total">
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="amount"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.amount &&
                                "is-invalid"
                              }`}
                              value={penyelesaianUangMukaDetail.reduce(
                                (prevValue, currentValue) => {
                                  const total =
                                    currentValue.category === "Mancanegara"
                                      ? currentValue.rupiah
                                      : currentValue.total;
                                  const grandTotal = total;

                                  return grandTotal + prevValue;
                                },
                                0
                              )}
                              onValueChange={(value) =>
                                handleChange("grand_total", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditPenyelesaianUangMukaPerjalananDinas;
