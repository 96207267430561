import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Input,
  Table,
  DatePicker,
  Space,
  message,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
// import "./VerifyDocumentReceive.less";
import Search from "antd/lib/input/Search";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "inProgress",
    title: "Total In Progress",
  },
  {
    filter: "receive",
    title: "Total Receive",
  },
  {
    filter: "unverified",
    title: "Total Unverified",
  },
];

function VerifyDocumentReceive() {
  const [type, setType] = useState("all");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [keyword, setKeyword] = useState("");
  const [VerifyDocumentReceives, setVerifyDocumentReceives] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({
    //sort: "po_number",
    order: "ascend",
  });
  const [count, setCount] = useState({
    all: "",
    in_progress: "",
    canceled: "",
    received: "",
    unverified: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
    getVerifyDocumentReceiveCount();
  }, [keyword, page, sort, type, data.start_date, data.end_date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pemindahan-budget`, {
      params: {
        keyword,
        //columns: ["gr_number", "vendor_name", "po_number"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setVerifyDocumentReceives(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getVerifyDocumentReceiveCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pemindahan-budget/count`, {
      params: {
        // keyword,
        // columns: ["gr_number", "vendor_name", "po_number"],
        // perpage: pageSize,
        // page,
        // sort: sort.sort,
        // order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
      width: 150,
      sorter: true,
    },
    {
      title: "Nama",
      dataIndex: <div style={{ textAlign: "center" }}>Name</div>,
      align: "left",
      key: "nama",
      sorter: true,
    },
    {
      title: "NPK",
      dataIndex: "npk",
      key: "npk",
      align: "center",
      sorter: true,
    },
    {
      title: "Division",
      dataIndex: "divisi",
      key: "divisi",
      align: "center",
      sorter: true,
    },
    {
      title: "Internal Order",
      dataIndex: "internal_order",
      key: "internal_order",
      align: "center",
    },
    {
      title: "Internal Order Transfer",
      dataIndex: "internal_order_peralihan",
      key: "internal_order_peralihan",
      align: "center",
    },
    {
      title: "Cost Center",
      dataIndex: "cost_center",
      key: "cost_center",
      align: "center",
    },
    {
      title: <div style={{ textAlign: "center" }}>Account Name</div>,
      dataIndex: "transfer_to",
      key: "transfer_to",
      align: "left",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
    },
    {
      title: "Approval Status",
      dataIndex: "approval_status",
      key: "approval_status",
      align: "center",
      sorter: true,
    },
    // {
    //     title: '',
    //     dataIndex: 'status',
    //     key: 'status',
    //     fixed: 'right',
    //     width: 100,
    //     render: (status, data) => status === 3 && (
    //         <Button size="small" loading={printing} type="default" onClick={() => handlePrint(data.invoice_number)}><PrinterOutlined />Print</Button>
    //     )
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="Approval Transfer Budget"
            //extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-in-progress pointer"
              title="Pending"
              bordered={false}
              //onClick={() => handleChangeType("inProgress")}
            >
              {/* <div className="value-font-size ">{count.in_progress}</div> */}
              <div className="value-font-size ">5</div>
            </Card>
          </Col>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-received pointer"
              title="Approved"
              bordered={false}
              //onClick={() => handleChangeType("receive")}
            >
              {/* <div className="value-font-size "> {count.received}</div> */}
              <div className="value-font-size ">3</div>
            </Card>
          </Col>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={10} lg={10}>
                  {types.find((x) => x.filter === type).title}
                </Col>
                <Col xs={24} md={14} lg={14} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                    {/* <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                    /> */}
                  </Space>
                  {/* <Search
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                    /> */}
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={VerifyDocumentReceives.data}
                    //rowKey={(data) => data.purchase_order}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: VerifyDocumentReceives.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default VerifyDocumentReceive;
