import React, { Fragment, useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  List,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";

import Axios from "axios";
import { url } from "../../config/global";

const { Title } = Typography;

const { Content } = Layout;

function ViewMasterApproval(props) {
  const [data, setData] = useState({
    approval_access: "",
    username: "",
    npk: "",
    dept: "",
    position: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(`${url}/approval/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          approval_access: res.data.data.approval_access,
          username: res.data.data.username,
          npk: res.data.data.npk,
          dept: res.data.data.dept,
          position: res.data.data.position,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const dataview = [
    {
      title: "Approval Access",
      description: data && data.approval_access,
    },
    {
      title: "Username ",
      description: data && data.username,
    },
    {
      title: "NPK",
      description: data && data.npk,
    },
  ];
  const dataview2 = [
    {
      title: "Dept",
      description: data && data.dept,
    },
    {
      title: "Position",
      description: data && data.position,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Title level={4}>View Approval </Title>
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-approval">Approval </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View Approval </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={40} className="pr-25 pl-25 mb-25">
                <Col sm={24} md={12}>
                  <List
                    itemLayout="horizontal"
                    dataSource={dataview}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<strong>{item.title}</strong>}
                          description={
                            item.description ? item.description : "-"
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col sm={24} md={12}>
                  <List
                    itemLayout="horizontal"
                    dataSource={dataview2}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<strong>{item.title}</strong>}
                          description={
                            item.description ? item.description : "-"
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ViewMasterApproval;
