import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Tag,
  Tooltip,
  Badge,
  Avatar,
  Comment,
  Form
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import "../SummaryDetail.less"
import TextArea from "antd/lib/input/TextArea";

const { Search } = Input;
const { Content } = Layout;

function PenyelesaianBiayaRupaRupa() {
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [printing, setPrinting] = useState([]);
  const [penyelesaianBiayaRupas, setPenyelesaianBiayaRupas] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [budgetArea, setBudgetArea] = useState(null);
  const [sort, setSort] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [penyelesaianBiayaRupaDetails, setPenyelesaianBiayaRupaDetails] =
    useState([]);
  const [pengajuanBiayaRupaDetailsTotal, setPengajuanBiayaRupaDetailsTotal] =
    useState(0);
  const [budgetDivHead, setBudgetDivHead] = useState(null);
  const [visible, setVisible] = useState(false);
  const [commentTemp, setCommentTemp] = useState({
    tempDataComment: []
  });
  const [commentList, setCommentList] = useState({});

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getPenyelesaianBiayaRupaDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
    getSetBudget();
    getSetBudgetDivHead();
  }, [keyword, page, sort]);

  useEffect(() => {
    if (selectedId) {
      getPenyelesaianBiayaRupaDetails();
    }

    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getSetBudgetDivHead = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "budget_div_head",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetDivHead(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/penyelesaian-uang-muka-biaya-rupa`, {
      params: {
        keyword,
        columns: [
          "penyelesaian_biaya_rupa_code",
          "nama",
          "npk",
          "internal_order_number",
          "cost_center_number",
          "grand_total",
          "transfer_to",
          "department"
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPenyelesaianBiayaRupas(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getPenyelesaianBiayaRupaDetails(data2[0]);
    }
  };

  const getPenyelesaianBiayaRupaDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/penyelesaian-uang-muka-biaya-rupa/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: [
          "gl_code",
          "description",
          "harga_satuan",
          "quantity",
          "jumlah",
          "ppn",
          "pph21",
          "pph23",
          "pph4",
          "total",
        ],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPenyelesaianBiayaRupaDetails(res.data.data);
        setPengajuanBiayaRupaDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handlePrint = (id) => {
    setPrinting(true);
    setLoading(true);
    Axios.post(
      `${url}/penyelesaian-uang-muka-biaya-rupa-print`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet started");
        }
      })
      .finally(() => {
        setPrinting(false);
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/penyelesaian-uang-muka-biaya-rupa/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleClickFile = (x) => {
    console.log(x.bukti_scan_url, "click");
    window.open(x.bukti_scan_url);
  };

  const getPengajuanBiayaRupaRupaDetails = () => {
    const columns = [
      {
        title: "GL CODE",
        dataIndex: "gl_code",
        key: "code",
        align: "center",
      },
      {
        title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
        dataIndex: "description",
        align: "left",
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
        render: (cost_center_number) => {
          return cost_center_number ? 
            cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        title: "QTY",
        dataIndex: "quantity",
        // key: "quantity",
        align: "center",
      },
      {
        title: <div style={{ textAlign: "center" }}>UNIT PRICE</div>,
        dataIndex: "harga_satuan",
        key: "harga_satuan",
        align: "right",
        render: (harga_satuan) => {
          return <AntNumberFormat value={harga_satuan} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL</div>,
        dataIndex: "jumlah",
        key: "jumlah",
        align: "right",
        render: (jumlah) => {
          return <AntNumberFormat value={jumlah} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>PPN</div>,
        dataIndex: "ppn",
        key: "ppn",
        align: "right",
        render: (ppn) => {
          return <AntNumberFormat value={ppn} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>PPH21</div>,
        dataIndex: "pph21",
        key: "pph21",
        align: "right",
        render: (pph21) => {
          return <AntNumberFormat value={pph21} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>PPH23</div>,
        dataIndex: "pph23",
        key: "pph23",
        align: "right",
        render: (pph23) => {
          return <AntNumberFormat value={pph23} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>PPH4(2)</div>,
        dataIndex: "pph4",
        key: "pph4",
        align: "right",
        render: (pph4) => {
          return <AntNumberFormat value={pph4} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>GRAND TOTAL</div>,
        dataIndex: "total",
        key: "total",
        align: "right",
        render: (total) => {
          return <AntNumberFormat value={total} type="text" />;
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              scroll={{ x: 1500 }}
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={penyelesaianBiayaRupaDetails}
              size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: pengajuanBiayaRupaDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ jumlah, total }) => {
                  totalAmount += jumlah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={7} className="table-summary">
                        <div className="summary">
                          <AntNumberFormat value={totalGrandTotal} type="text" />
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const closeComment = () => {
    setVisible(false);
  };

  const handleChangeComment = (name, value) => {
    setCommentTemp({
        ...commentTemp,
        [name]: value,
        last_comment: sessionStorage.getItem("npk")
    })
  }

  const submitComment = () => {
    setLoading(true);

    Axios.post(
      `${url}/send-comment`,
      {
        doc_id: commentTemp.doc_id,
        doc_number: commentTemp.doc_number,
        doc_name: commentTemp.doc_name,
        doc_type: commentTemp.doc_type,
        comment_description: commentTemp.comment_description,
        last_comment: commentTemp.last_comment
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setCommentTemp({
        ...commentTemp,
        comment_description: '',
        last_comment: ''
      })
      
      setCommentList(res.data.data);
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /*  Open Data Comment Transaksi */
  const openDataComment = async (id, data) => {
    setCommentTemp({
      ...commentTemp,
      doc_number: data.penyelesaian_id,
      doc_name: data.reminder_name,
      doc_type: data.reminder_status,      
      doc_id: id,
      DocNumber: data.penyelesaian_id,
      CreatedDate: data.created_at
    })
    setLoading(true);

    await Axios.get(
      `${url}/show-comment`, {
        params: {
          doc_id: id,
          doc_number: data.penyelesaian_id,
          doc_name: data.reminder_name,
          doc_type: data.reminder_status,
          comment_description: data.comment_description,
          last_comment: data.last_comment
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    })
    .then((res) => {
      setCommentList(res.data.data)
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
      setVisible(true);

    });
  };

  const columns = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      align: "center",
      render: (created_at) => moment(created_at).format("DD-MM-YYYY"),
      width: 120,
    },
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "penyelesaian_biaya_rupa_code",
      width: 120,
      align: "center",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "nama",
      width: 150,
      align: "left",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 150,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      sorter: false,
      width: 200,
      key: "description",
    },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      sorter: false,
      width: 150,
      key: "transfer_to",
    },
    {
      title: <div style={{ textAlign: "center" }}>GRAND TOTAL</div>,
      dataIndex: "grand_total",
      sorter: false,
      align: "right",
      width: 130,
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total} type="text" />;
      },
    },
    // {
    //   title: "TOTAL",
    //   dataIndex: "total",
    //   sorter: false,
    //   children: [
    //     {
    //       title: <div style={{ textAlign: "center" }}>Down Payment</div>,
    //       dataIndex: "total_umb",
    //       width: 150,
    //       align: "right",
    //       sorter: false,
    //       render: (total_umb) => {
    //         return <AntNumberFormat value={total_umb} type="text" />;
    //       },
    //     },
    //     {
    //       title: <div style={{ textAlign: "center" }}>More</div>,
    //       dataIndex: "total_selisih",
    //       width: 100,
    //       sorter: false,
    //       align: "right",
    //       render: (total_selisih) => {
    //         return (
    //           <AntNumberFormat
    //             value={Math.sign(total_selisih) === 1 ? total_selisih : 0}
    //             type="text"
    //           />
    //         );
    //       },
    //     },
    //     {
    //       align: "right",
    //       title: <div style={{ textAlign: "center" }}>Less</div>,
    //       dataIndex: "total_selisih",
    //       width: 100,
    //       sorter: false,
    //       render: (total_selisih) => {
    //         return (
    //           <AntNumberFormat
    //             value={Math.sign(total_selisih) === -1 ? total_selisih : 0}
    //             type="text"
    //           />
    //         );
    //       },
    //     },
    //   ],
    // },
    {
      title: "FILE",
      dataIndex: "bukti_scan_details",
      sorter: false,
      align: "center",
      width: 200,
      render: (bukti_scan_url, data) =>
        bukti_scan_url &&
        bukti_scan_url.map((x, index) => {
          return x.bukti_scan.split(".")[1] === "jpg" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "jpeg" ? (
            <img
              key={Math.random()}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconimage.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : x.bukti_scan.split(".")[1] === "pdf" ? (
            <img
              key={Math.random()}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconpdf.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : (
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconexcel.png")}
              onClick={() => handleClickFile(x)}
            />
          );
        }),
    },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width:  sessionStorage.getItem('role_name') === "Staff" ? 100 :
              sessionStorage.getItem('role_name') === "Admin" ? 150 : 100,
      render: (id, data) => {
        return (
          <div>
            {
              buttonAccess &&
              buttonAccess.find(
                (x) => x.url === "penyelesaian-uang-muka-biaya-rupa/print"
              ) && (
                <Tooltip placement="top" title="Print">

                  <span style={{marginRight: 10}}
                    size="small"
                    type="default"
                    onClick={
                      data.status >= 3 ? () => handlePrint(id) : ""
                    }
                  >
                    <img
                      alt="icon"
                      width="20"
                      src={
                        require("../../assets/img/printer.png")
                      }
                      style={{
                        cursor: data.status >= 3 ? 'pointer' : 'not-allowed'
                      }}
                    />
                  </span>
                </Tooltip>              
              )
            }

            <Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                // onClick={openComment}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Direktur" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                  // data.status_comment > 0 && data.last_comment != sessionStorage.getItem('npk') ? data.status_comment : 0 }
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>

          </div>
        );
      }
    },
    {
      title: "STATUS RECEIVE",
      dataIndex: "status_received",
      align: "center",
      key: "status_received",
      width: 80,
      render: (status_received) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status_received === 1
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: "PAYMENT DATE",
      dataIndex: "payment_date",
      key: "payment_date",
      width: 150,
      align: "center",
      render: (payment_date) => {
        return {
          children: payment_date
            ? moment(payment_date).format("DD MMMM YYYY ")
            : "",
        };
      },
    },
    {
      title: "APPROVAL",
      children: [
        {
          title: " DEPT HEAD",
          dataIndex: "status",
          width: 100,
          align: "center",
          key: "approvalUser",
          render: (status, data) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    status === 2 || status === 3 || status === 4 || status === 5
                      ? data.app_dept
                      : ""
                  }
                >
                  {/* {data.grand_total < budgetArea ? ( */}
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 1
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                  {/* ) : (
            "-"
          )} */}
                </Tooltip>
              </>
            );
          },
        },
        {
          title: " DIV HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalDeptHead",
          width: 100,
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={
                  status === 2 || status === 3 || status === 4 || status === 5
                    ? data.app_div
                    : ""
                }
              >
                {data.app_div !== "-" ? (
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 2
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                ) : (
                  "-"
                )}
               
              </Tooltip>
            );
          },
        },
        {
          title: " DIRECTOR 1",
          dataIndex: "status",
          align: "center",
          key: "approvalDivHead",
          width: 100,
          render: (status, data) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    status === 3 || status === 4 || status === 5
                      ? data.app_dir1
                      : ""
                  }
                >
                  {data.grand_total >= budgetDivHead ? (
                    <img
                      alt="icon"
                      width="20"
                      src={
                        status === 3
                          ? require("../../assets/img/sand-clock.png")
                          : status === 3 || status === 4 || status === 5
                          ? require("../../assets/img/checked.png")
                          : status === -2
                          ? require("../../assets/img/warning.png")
                          : status === -1
                          ? require("../../assets/img/danger.png")
                          : require("../../assets/img/sand-clock.png")
                      }
                    />
                  ) : (
                    "-"
                  )}
                </Tooltip>
              </>
            );
          },
        },
        {
          title: " DIRECTOR 2",
          dataIndex: "status",
          align: "center",
          key: "approvalDirektur",
          width: 100,
          render: (status, data) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={status === 4 || status === 5 ? data.app_dir2 : ""}
                >
                  {data.grand_total >= budgetArea ? (
                    <img
                      alt="icon"
                      width="20"
                      src={
                        status === 4
                          ? require("../../assets/img/sand-clock.png")
                          : status === 4 || status === 5
                          ? require("../../assets/img/checked.png")
                          : status === -2
                          ? require("../../assets/img/warning.png")
                          : status === -1
                          ? require("../../assets/img/danger.png")
                          : require("../../assets/img/sand-clock.png")
                      }
                    />
                  ) : (
                    "-"
                  )}
                </Tooltip>
              </>
            );
          },
        },
      ],
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   // fixed: "right",
    //   width: 50,
    //   render: (id, data) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               {buttonAccess &&
    //                 buttonAccess.find(
    //                   (x) => x.url === "penyelesaian-uang-muka-biaya-rupa/print"
    //                 ) && (
    //                   <Menu.Item key="1" disabled={data.status < 3}>
    //                     <span
    //                       size="small"
    //                       type="default"
    //                       onClick={
    //                         data.status >= 3 ? () => handlePrint(id) : ""
    //                       }
    //                     >
    //                       <PrinterOutlined />
    //                       Print
    //                     </span>
    //                   </Menu.Item>
    //                 )}

    //               {/* {buttonAccess &&
    //                 buttonAccess.find(
    //                   (x) => x.url === "penyelesaian-uang-muka-biaya-rupa/edit"
    //                 ) && (
    //                   <Menu.Item key="2" disabled={data.status !== 1}>
    //                     <span>
    //                       <Link
    //                         disabled={data.status !== 1}
    //                         to={`/penyelesaian-uang-muka-biaya-rupa/edit/${id}`}
    //                       >
    //                         <EditOutlined /> Edit
    //                       </Link>
    //                     </span>
    //                   </Menu.Item>
    //                 )} */}

    //               {/* {buttonAccess &&
    //                 buttonAccess.find((x) => x.url === "penyelesaian-uang-muka-biaya-rupa-rupa/delete") && (
    //                 <Menu.Item key="3">
    //                   <span onClick={() => handleDelete(id)}>
    //                     <DeleteOutlined />
    //                     Delete
    //                   </span>
    //                 </Menu.Item>
    //               )}

    //               <Menu.Item key="1">
    //                 <span
    //                   size="small"
    //                   type="default"
    //                   onClick={() => handlePrint(id)}
    //                 >
    //                   <PrinterOutlined />
    //                   Print
    //                 </span>
    //               </Menu.Item>
    //               <Menu.Item disabled={data.status !== 3} key="2">
    //                 <span>
    //                   <Link
    //                     disabled={data.status !== 3}
    //                     to={`/penyelesaian-uang-muka-biaya-rupa-rupa/edit/${id}`}
    //                   >
    //                     <EditOutlined /> Edit
    //                   </Link>
    //                 </span>
    //               </Menu.Item> */}
    //               {/* <Menu.Item key="3">
    //                 <span onClick={() => handleDelete(id)}>
    //                   <DeleteOutlined />
    //                   Delete
    //                 </span>
    //               </Menu.Item> */}
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/penyelesaian-uang-muka-biaya-rupa-rupa/create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    {/* <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button> */}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    size="small"
                    scroll={{ x: 2000 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getPengajuanBiayaRupaRupaDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    dataSource={penyelesaianBiayaRupas.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: penyelesaianBiayaRupas.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* MODAL COMMENT LIST */}
      <Modal
        className="modal-comment-list"
        visible={visible}
        title={`${commentTemp.DocNumber} - ${commentTemp.CreatedDate} `}
        onOk={submitComment}
        onCancel={closeComment}
        footer={[
          <Form.Item>
            <TextArea
              rows={4}
              value={commentTemp.comment_description}
              onChange={(e) => handleChangeComment("comment_description", e.target.value)}
            ></TextArea>
          </Form.Item>,
          <Button key="back" onClick={closeComment} >
            Close
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={submitComment} >
            Send
          </Button>
        ]}
      >
        { commentList ?
            commentList.comment_list && 
              commentList.comment_list.map((comment) => {
              return (
                <Comment
                  // actions={actions}
                  className={`${comment.username === sessionStorage.getItem('username') ? 'comment-right' : ''}`}
                  author={<span className="comment-title">{comment.full_name}</span>}
                  avatar={<Avatar className="comment-avatar" src={comment.avatar} alt={`${comment.full_name}`} />}
                  content={
                    <p>
                      {comment.comment_description}
                    </p>
                  }
                  datetime={
                    <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{comment.comment_date}</span>
                    </Tooltip>
                  }
                />
              )
            })
          : <div id="comment-new-list">{commentTemp.tempDataComment}</div>}
      </Modal>
      {/* END COMMENT LIST */}

    </Fragment>
  );
}

export default PenyelesaianBiayaRupaRupa;
