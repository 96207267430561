import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Upload,
  List,
  message,
  Tag,
  Checkbox,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import CreateEntertainment from "../Entertainment/CreateEntertainment";
const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;


const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateUser(props) {
  // console.log(roleUser)
  const roleUser = sessionStorage.getItem("role_name");

  const [data, setData] = useState({
    name: "",
    divisi: "",
    username: "",
    full_name: "",
    npk: "",
    email: "",
    department: "",
    io_department: "",
    role_id: "",
    role_name: "",
    company_code: [],
    company_name: "",
    refer_id: "",
    refer_name: "",
    photo: null,
    vendor_id: "",
    position: "",
    vendor_code: "",
    vendor_name: "",
    vendor: "",
    user_type: "",
    permission_company: "",
    values: [],
    roles: [],
    companies: [],
    vendors: [],
    is_active: false,
  });
  const [loadCompany, setLoadCompany] = useState(false);
  const [dept, setDept] = useState([]);
  const [div, setDiv] = useState([]);
  const [doNum, setDoNum] = useState([]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRemove = () => {
    setData({
      ...data,
      photo: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      photo: files,
    });

    return false;
  };

  const getVendor = async (keyword) => {
    await Axios.get(`${url}/vendor/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          vendors: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getRole = async (keyword) => {
    await Axios.get(`${url}/role/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          roles: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getCompany = async (keyword) => {
    await Axios.get(`${url}/company/options`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          companies: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    if (value === "Internal" || value === "Eksternal") {
      console.log(value);
      setData({
        ...data,
        [name]: value,
        vendor_id: "",
        vendor_code: "",
        vendor_name: "",
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleAddValue = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      console.log(data);
      setData({
        ...data,
        values: [
          ...data.values,
          {
            id: Math.random(),
            name: data.divisi,
          },
        ],
        divisi: "",
      });
    }
    console.log(data.divisi, data.values);
  };
  const handleChangeSelectCompany = (name, value) => {
    console.log(name)
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleDeleteValue = (id) => {
    const { values } = data;
    const filterValues = values.filter((divisi) => divisi.id !== id);

    setData({
      ...data,
      values: filterValues,
    });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const handleResetVendor = (name, value) => {
    setData({
      vendor_id: "",
      vendor_code: "",
      vendor_name: "",
      values: [],
    });
  };

  const handleChangeSelectDept = (name, value) => {
    if (value) {
      const findControlItem = dept.filter((departement) =>
        value.includes(departement.department)
      );

      setData({
        ...data,
        [`${name}`]: value,
      });
    } else {
      setData({
        ...data,
        [`${name}`]: null,
      });
    }
  };

  const getDepartment = async (keyword) => {
    await Axios.get(`${url}/internal-order/list/io`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDept(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getDivision = async (keyword) => {
    await Axios.get(`${url}/internal-order/list-div`, {
      // await Axios.get(`${url}/internal-order/list-div`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDiv(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectDiv = (name, value) => {
    if (value) {
      setData({
        ...data,
        values: value.map((x) => {
          return {
            id: Math.random(),
            name: x,
          };
        }),
      });
    } else {
      setData({
        ...data,
        [`${name}`]: null,
      });
    }

    console.log(data.divisi);
  };

  // function userTypeChange(name, value) {
  //   if (data && data.user_type === "Internal" || data && data.user_type === "Eksternal") {
  //     handleResetVendor();
  //   } else {
  //     setData({
  //       ...data,
  //       user_type: value,
  //     });
  //   }

  //   setData({
  //     ...data,
  //     user_type: value,
  //   });
  // };

  const onSaveData = () => {
    setLoading(true);
    // console.log(data)
    let fd = new FormData();

    fd.set("username", data.username);
    fd.set("full_name", data.full_name);
    fd.set("department", data.department);
    fd.set("io_department", data.io_department);
    fd.set("divisi", JSON.stringify(data.values));
    fd.set("position", data.position);
    fd.set("npk", data.npk);
    fd.set("email", data.email);
    fd.set("user_type", data.user_type);
    fd.set("vendor_id", data.vendor_id);
    fd.set("vendor_code", data.vendor_code);
    fd.set("companies", data.company);
    fd.set("permission_company", data.permission_company);
    fd.set("vendor_name", data.vendor_name);
    fd.set("role_id", data.role_id);
    fd.set("role_name", data.role_name);
    fd.append("photo", data.photo);
    fd.set("is_active", data.is_active);

    Axios.post(`${url}/user`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/user");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      username: "",
      full_name: "",
      department: "",
      io_department: "",
      divisi: "",
      position: "",
      vendor_id: "",
      vendor_code: "",
      vendor_name: "",
      user_type: "",
      npk: "",
      email: "",
      role_id: "",
      vendor_id: "",
      companies: "",
      permission_company: "",
      photo: null,
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="User"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/user">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add User</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form
                {...layout}
                name="control-hooks"
                onFinish={onSaveData}
                labelAlign="left"
              >
                <Row>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Username"
                        validateStatus={
                          error && error.username ? "error" : false
                        }
                        help={
                          error && error.username ? error.username[0] : false
                        }
                      >
                        <Input
                          value={data.username}
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="NPK"
                        validateStatus={error && error.npk ? "error" : false}
                        help={error && error.npk ? error.npk[0] : false}
                      >
                        <Input
                          value={data.npk}
                          onChange={(e) => handleChange("npk", e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Full Name"
                        validateStatus={
                          error && error.full_name ? "error" : false
                        }
                        help={
                          error && error.full_name ? error.full_name[0] : false
                        }
                      >
                        <Input
                          value={data.full_name}
                          onChange={(e) =>
                            handleChange("full_name", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Email"
                        validateStatus={error && error.email ? "error" : false}
                        help={error && error.email ? error.email[0] : false}
                      >
                        <Input
                          value={data.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                        />
                      </Form.Item>

                      {/* <Form.Item
                        required
                        label="Division"
                        validateStatus={error && error.divisi ? "error" : false}
                        help={error && error.divisi ? error.divisi[0] : false}
                      >
                        <Input
                          value={data.divisi}
                          onKeyPress={handleAddValue}
                          onChange={(e) =>
                            handleChange("divisi", e.target.value)
                          }
                          placeholder="Press enter to add"
                        />
                      </Form.Item> */}

                      <Form.Item label="Division" required>
                        <Select
                          mode="multiple"
                          allowClear
                          onChange={(value) =>
                            handleChangeSelectDiv("divisi", value, data._id)
                          }
                          onFocus={() => getDivision("")}
                          showSearch
                          onSearch={(value) => getDivision(value)}
                          filterOption={false}
                        >
                          {/* {div &&
                            div.map((divisi) => {
                              return (
                                <Option
                                  value={divisi.division}
                                  key={divisi._id}
                                >
                                  {divisi.division}
                                </Option>
                              );
                            })} */}
                          {div &&
                            div.map((divisi, index) => {
                              return divisi.division !== null ? (
                                <Option value={divisi.division} key={index}>
                                  {divisi.division}
                                </Option>
                              ) : ( "" );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item label="Department" required>
                        <Select
                          // disabled={data.pengajuan_um !== "Belum"}
                          // labelInValue
                          mode={data.department !== "Staff" ? "multiple" : ""}
                          allowClear
                          // loading={ldDoNum}
                          onChange={(value) =>
                            handleChangeSelectDept(
                              "department",
                              value,
                              data._id
                            )
                          }
                          // onChange={(value) =>
                          //   handleChangeSelectDept("department", value)
                          // }
                          onFocus={() => getDepartment("")}
                          showSearch
                          onSearch={(value) => getDepartment(value)}
                          filterOption={false}
                        >
                          {dept &&
                            dept.map((department) => {
                              return (
                                <Option
                                  value={department.department}
                                  key={department._id}
                                >
                                  {department.department}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item label="IO Department" required>
                        <Select
                          // disabled={data.pengajuan_um !== "Belum"}
                          // labelInValue
                          mode={data.io_department !== "Staff" ? "multiple" : ""}
                          allowClear
                          // loading={ldDoNum}
                          onChange={(value) =>
                            handleChangeSelectDept(
                              "io_department",
                              value,
                              data._id
                            )
                          }
                          // onChange={(value) =>
                          //   handleChangeSelectDept("department", value)
                          // }
                          onFocus={() => getDepartment("")}
                          showSearch
                          onSearch={(value) => getDepartment(value)}
                          filterOption={false}
                        >
                          {dept &&
                            dept.map((io_department) => {
                              return (
                                <Option
                                  value={io_department.department}
                                  key={io_department._id}
                                >
                                  {io_department.department}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      {/* <Form.Item {...tailLayout}>
                        {data.values &&
                          data.values.map((divisi) => {
                            return (
                              <Tag
                                key={divisi.id}
                                closable
                                onClose={() => handleDeleteValue(divisi.id)}
                              >
                                {divisi.name}
                              </Tag>
                            );
                          })}
                      </Form.Item> */}
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Position"
                        validateStatus={
                          error && error.position ? "error" : false
                        }
                        help={
                          error && error.position ? error.position[0] : false
                        }
                      >
                        <Input
                          value={data.position}
                          onChange={(e) =>
                            handleChange("position", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label="User Type"
                        required
                        validateStatus={
                          error && error.user_type ? "error" : false
                        }
                        help={
                          error && error.user_type ? error.user_type[0] : false
                        }
                      >
                        <Select
                          value={data.user_type}
                          placeholder="Select Type"
                          optionFilterProp="children"
                          onChange={(value) => handleChange("user_type", value)}
                          style={{ width: "100%" }}
                          required
                          //onFocus={onFocus}
                          //onSearch={onSearch}
                        >
                          <Option value="Internal">Internal</Option>
                          <Option value="Eksternal">Eksternal</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Vendor"
                        required
                        validateStatus={error && error.vendor ? "error" : false}
                        help={error && error.vendor ? error.vendor[0] : false}
                      >
                        <Input
                          value={data.vendor}
                          onChange={(e) =>
                            handleChange("vendor", e.target.value)
                          }
                        />

                        {/* <Select
                          disabled={
                            data && data.user_type === "Internal"
                          }
                          labelInValue
                          onChange={(value) => handleChangeSelect("vendor", value)}
                          onFocus={() => getVendor("")}
                          showSearch
                          onSearch={(value) => getVendor(value)}
                          filterOption={false}
                          value={{ key: data.vendor_id, label: data.vendor_name }}
                        >
                          {data.vendors &&
                            data.vendors.map((vendor) => {
                              return (
                                <Option value={vendor._id} key={vendor._id}>
                                  {`${vendor.vendor_code}-${vendor.vendor_name}`}
                                </Option>
                              );
                            })}
                        </Select> */}
                      </Form.Item>

                      <Form.Item
                        label="Role"
                        required
                        validateStatus={error && error.role ? "error" : false}
                        help={error && error.role ? error.role[0] : false}
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("role", value)
                          }
                          onFocus={() => getRole("")}
                          showSearch
                          onSearch={(value) => getRole(value)}
                          filterOption={false}
                          value={{ key: data.role_id, label: data.role_name }}
                        >
                          {data.roles &&
                            data.roles.map((role) => {
                              return (
                                <Option value={role._id} key={role._id}>
                                  {role.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Company Code"
                        required
                        validateStatus={error && error.role ? "error" : false}
                        help={error && error.role ? error.role[0] : false}
                      >
                         <Select
                          mode="multiple"
                          disabled={roleUser !== "Admin"}
                          allowClear
                          loading={loadCompany}
                          onChange={(value) =>
                            handleChangeSelectCompany(
                              "company",
                              value,
                              data.code
                            )
                          }
                          onFocus={() => getCompany("")}
                          showSearch
                          onSearch={(value) => getCompany(value)}
                          filterOption={false}
                          value={
                            // key: data.department_id,
                            data.code
                          }
                        >
                          {data.companies &&
                            data.companies.map((company) => {
                              return (
                                <Option
                                  value={company.code}
                                  key={Math.random()}
                                >
                                    ({company.code}) {company.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Permission Company"
                        required
                        validateStatus={error && error.role ? "error" : false}
                        help={error && error.role ? error.role[0] : false}
                      >
                         <Select
                          // mode="multiple"
                          disabled={roleUser !== "Admin"}
                          allowClear
                          loading={loadCompany}
                          onChange={(value) =>
                            handleChangeSelectCompany(
                              "permission_company",
                              value,
                              data.code
                            )
                          }
                          onFocus={() => getCompany("")}
                          showSearch
                          onSearch={(value) => getCompany(value)}
                          filterOption={false}
                          value={
                            // key: data.department_id,
                            data.code
                          }
                        >
                          {data.companies &&
                            data.companies.map((company) => {
                              return (
                                <Option
                                  value={company.code}
                                  key={Math.random()}
                                >
                                    ({company.code}) {company.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="photo"
                        label="Photo"
                        // required
                        validateStatus={error && error.photo ? "error" : false}
                        help={error && error.photo ? error.photo[0] : false}
                      >
                        <Fragment>
                          <Upload
                            onRemove={handleRemove}
                            beforeUpload={handleBeforeUpload}
                            fileList={[]}
                          >
                            <Button>
                              Upload <UploadOutlined />
                            </Button>
                          </Upload>
                          {data.photo && (
                            <List>
                              <List.Item
                                actions={[
                                  <Button
                                    onClick={handleRemove}
                                    type="danger"
                                    ghost
                                  >
                                    <DeleteOutlined />
                                  </Button>,
                                ]}
                              >
                                <List.Item.Meta description={data.photo.name} />
                              </List.Item>
                            </List>
                          )}
                        </Fragment>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          className="mr-button"
                          htmlType="reset"
                          onClick={handleResetVendor}
                        >
                          Reset
                        </Button>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateUser;
