import { message } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { url, url_sso } from '../../config/global';
import PulseLoader from "react-spinners/PulseLoader";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function Sso(props) {
  let [loading, setLoading] = useState(true);
  const [urlSso, setUrlSso] = useState("");

  let query = useQuery();
  useEffect(() => {
    getDataApiSSO()
  }, [])

  const getDataApiSSO = async () => {
    await Axios.get(`${url}/settings-sso/find`, {
      params: {
        variable: "api_sso",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res)
        if (res.data.type === "error") {
          message.error(res.data.msg);
        } else {
          setUrlSso(res.data.data[0].name);

          getUser(res.data.data[0].name)
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getUser = async (apiUrl) => {
    const user = await Axios.get(`${apiUrl}/check-token`, {
      params: {
        token: query.get('token')
      }
    }).then(res => {
      loginSSO(res.data.user)
      // return res.data.user
    }).catch((err) => {
      props.history.push('login');
      if (err.response) {
        console.log(err.response)
        message.error(err.response.data.message);
      }
    })
  }

  const loginSSO = (user) => {
    Axios.post(`${url}/login-sso`, {
      username: user.username,
      password: user.password,
      email: user.email,
      full_name: user.full_name,
      role_user_destination_id: user.role_user_destination_id,
      role_user_destination: user.role_user_destination,
      role_id: user.role_id,
      role_name: user.role_name,
      npk: user.npk,
      user_type: user.user_type
    })
      .then((res) => {
        const ObjDiv = res.data.data.divisi;
        const arrDiv = [];
        if (ObjDiv) {
          for (let i = 0; i < ObjDiv.length; i++) {
            const el = ObjDiv[i].name;
            arrDiv.push(el)
          }
        } else {
          arrDiv.push('');
        }
        console.log(res)

        if (res.data.data.companies) {

          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("username", res.data.data.username);
          sessionStorage.setItem("fullname", res.data.data.full_name);
          sessionStorage.setItem("npk", res.data.data.npk);
          sessionStorage.setItem("divisi", arrDiv);
          sessionStorage.setItem("department", JSON.stringify(res.data.data.department));
          sessionStorage.setItem("io_department", JSON.stringify(res.data.data.io_department));
          sessionStorage.setItem("reg_company_code", JSON.stringify(res.data.data.companies));
          sessionStorage.setItem(
            "avatar",
            res.data.data.photo ? res.data.data.photo : ""
          );
          sessionStorage.setItem(
            "permissions",
            JSON.stringify(res.data.permissions)
          );
          sessionStorage.setItem("redirect", res.data.redirect);
          sessionStorage.setItem("role_name", res.data.data.role_name);
          localStorage.setItem("user_companies", JSON.stringify(res.data.data.companies));

          checkUsrCompany(res.data.message)
        } else {
          props.history.push('login');
          message.warning("Company permissions on your account have not been set, please contact the IT team.");
        }


        // message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        props.history.push('login');
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  }

  const checkUsrCompany = (msg) => {
    var jsoncomp = JSON.parse(sessionStorage.getItem('reg_company_code'));
    // console.log(jsoncomp.length)
    // return false;
    if (jsoncomp == null || jsoncomp == '' || jsoncomp == 'undefined') {
      sessionStorage.clear()
      setLoading(false);
      props.history.push('login');
      message.warning("Company permissions on your account have not been set, please contact the IT team.");
    }

    if (jsoncomp.length <= 1) {
      message.success(msg);
      sessionStorage.setItem("company_code", jsoncomp[0]);
      Axios.defaults.headers.common['COMPANY-CODE'] = sessionStorage.getItem("company_code");
      setLoading(false);
      props.history.push('select-company');
      // window.location.href = "/home-approval"
    } else {
      message.success(msg);
      setLoading(false);
      props.history.push('select-company');
    }

  }

  return (
    <div style={stylewrap}>
      <div className="sweet-loading" style={loader}>
        <PulseLoader loading={loading} size={20} color={"#03A000"} />
      </div>
    </div>
  )

}
const stylewrap = {
  textAlign: "center",
  position: "fixed",
  top: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "#ffffff",
  zIndex: 1000,
  opacity: "0.5"
}
const loader = {
  position: "relative",
  top: "15rem"
}

export default Sso
