import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Input,
  Table,
  DatePicker,
  Modal,
  Space,
  message,
  Typography,
  Radio,
  Tooltip,
  Badge,
  Avatar, Button, Form, Comment
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./ApprovalPemindahanBudget.less";
import _ from "underscore";
import Search from "antd/lib/input/Search";
import AntNumberFormat from "../../components/AntNumberFormat";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  ProfileOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "pending",
    title: "Total In Pending",
  },
  {
    filter: "approve",
    title: "Total Approve",
  },
];

function ApprovalPemindahanBudget() {
  const [error, setError] = useState(null);
  const [type, setType] = useState("all");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [keyword, setKeyword] = useState("");
  const [approvalPemindahanBudgets, setApprovalPemindahanBudgets] = useState(
    []
  );
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [listBudget, setListBudget] = useState([]);
  const [newListBudget, setNewListBudget] = useState([]);
  const [loadReject, setLoadReject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [count, setCount] = useState({
    all: "",
    approve: "",
    pending: "",
    reject: "",
  });

  const [commentTemp, setCommentTemp] = useState({
    tempDataComment: []
  });
  const [commentList, setCommentList] = useState({});
  const [visible, setVisible] = useState(false);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  const showModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
  };

  useEffect(() => {
    getData();
    getApprovalPemindahanBudgetCount();
  }, [keyword, page, sort, type, data.start_date, data.end_date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pemindahan-budget`, {
      params: {
        keyword,
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setApprovalPemindahanBudgets(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApprovalPemindahanBudgetCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pemindahan-budget/count`, {
      params: {
        // keyword,
        // columns: ["gr_number", "vendor_name", "po_number"],
        // perpage: pageSize,
        // page,
        // sort: sort.sort,
        // order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApprove = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Add Budget will be Approved</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApprove(id);
      },
    });
  };

  const confirmApprove = (id) => {
    setLoading(true);

    Axios.post(
      `${url}/approval-pemindahan-budget/${id}`,
      {
        status: data.status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData(keyword, page, sort, type, data.start_date, data.end_date);
        getApprovalPemindahanBudgetCount();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeStatus = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const closeModal = () => {
    setData({
      ...data,
      status: "",
      remark: "",
    });
    setIsModalVisible(false);
  };

  const handleReject = (id) => {
    setLoading(true);
    setLoadReject(true);

    Axios.post(
      data.status === 2
        ? `${url}/review-pemindahan-budget/${id}`
        : `${url}/reject-pemindahan-budget/${id}`,
      {
        status: data.status,
        remark: data.remark,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
      getData();
      getApprovalPemindahanBudgetCount();
      closeModal();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      }
    })
    .finally(() => {
      setLoading(false);
      setLoadReject(false);

    });
  };

  const closeComment = () => {
    setVisible(false);
  };

  const handleChangeComment = (name, value) => {
    setCommentTemp({
        ...commentTemp,
        [name]: value,
        last_comment: sessionStorage.getItem("npk")
    })
  }

  const submitComment = () => {
    setLoading(true);

    Axios.post(
      `${url}/send-comment`,
      {
        doc_id: commentTemp.doc_id,
        doc_number: commentTemp.doc_number,
        doc_name: commentTemp.doc_name,
        doc_type: commentTemp.doc_type,
        comment_description: commentTemp.comment_description,
        last_comment: commentTemp.last_comment
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setCommentTemp({
        ...commentTemp,
        comment_description: '',
        last_comment: ''
      })
      
      setCommentList(res.data.data);
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /*  Open Data Comment Transaksi */
  const openDataComment = async (id, data) => {

    setCommentTemp({
      ...commentTemp,
      doc_number: data.ref_code,
      doc_name: data.reminder_name,
      doc_type: data.reminder_status,      
      doc_id: id,
      DocNumber: data.ref_code,
      CreatedDate: data.created_at
    })
    setLoading(true);

    await Axios.get(
      `${url}/show-comment`, {
        params: {
          doc_id: id,
          doc_number: data.ref_code,
          doc_name: data.reminder_name,
          doc_type: data.reminder_status,
          comment_description: data.comment_description,
          last_comment: data.last_comment
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    })
    .then((res) => {
      setCommentList(res.data.data)
      getData();

    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
      setVisible(true);

    });
  };

  const handleChangeListBudgetDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getDataListBudget(data2[0]);
    }
  };

  const getDataListBudget = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/pemindahan-budget/details/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setNewListBudget(
        res.data.data.new_list_budget_details ?
          res.data.data.new_list_budget_details :
          res.data.data.list_budget_details.filter((x) => x.statusBudget === 'X'));
    })
    .catch((err) => {
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
        }
      }
    })
    .finally(() => {
      setLoadingDetail(false);
    });
  };

  const getDetails = () => {
    const columns = [
      {
        title: "INTERNAL ORDER",
        children: [
          {
            title: "FROM",
            dataIndex: "internal_order_number_get",
            align: "center",
            key: "internal_order_number_get",
            render: (internal_order_number_get) => {
              return internal_order_number_get ? 
                internal_order_number_get.length == 12 ? internal_order_number_get.substring(2) : internal_order_number_get
                : '';
            },
          },
          {
            title: "TO",
            dataIndex: "internal_order_number",
            // width: 100,
            align: "center",
            key: "internal_order_number",
            render: (internal_order_number) => {
              return internal_order_number ? 
                internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
                : '';
            },
          },
        ]
      },
      {
        title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
        dataIndex: "available_budget",
        align: "right",
        render: (available_budget) => {
          return <AntNumberFormat value={available_budget} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL AMOUNT</div>,
        dataIndex: "total_amount",
        align: "right",
        render: (total_amount) => {
          return <AntNumberFormat value={total_amount} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL TRANSFER</div>,
        dataIndex: "total_transfer",
        align: "right",
        render: (total_transfer) => {
          return <AntNumberFormat value={total_transfer} type="text" />;
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              columns={columns}
              loading={loadingDetail}
              dataSource={newListBudget}
              size="small"
              // pagination={{
              //   pageSize: perPageDetail,
              //   current: pageDetail,
              //   total: pengajuanBiayaRupaDetailsTotal,
              //   showSizeChanger: true,
              //   style: {
              //     marginTop: "2rem",
              //     marginBottom: 0,
              //   },
              // }}
              // onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      key: "created_at",
      width: 80,
      align: "center",
      sorter: true,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "ref_code",
      key: "ref_code",
      width: 100,
      align: "center",
      sorter: false,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "name",
      sorter: false,
      width: 250,
    },
    {
      title: "DIVISION",
      dataIndex: "divisi",
      key: "divisi",
      sorter: false,
      align: "center",
      width: 250,
      render: (divisi) => {
        return _.pluck(divisi, "name").join(", ");
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      key: "transfer_to",
      width: 250,
      align: "left",
    },
    {
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "total_amount",
      key: "total_amount",
      width: 150,
      align: "right",
      render: (total_amount) => {
        return <AntNumberFormat value={total_amount ? total_amount : 0} type="text" />;
      },
    },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width:  sessionStorage.getItem('role_name') === "Staff" ? 80 :
              sessionStorage.getItem('role_name') === "Admin" ? 150 : 100,
      render: (id, data) => {
        return (
					<div>
						{
							sessionStorage.getItem("role_name") === "Department Head" ? (
								<>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}} >
											<img
												onClick={() =>
													data.status === 1 ? handleApprove(id) : true
												}
												alt="icon"
												width="20"
												src={
													data.status === 1 ?
                          require("../../assets/img/approve.png") :
                          require("../../assets/img/approve-disable.png")
												}
											/>
										</span>
									</Tooltip>
		
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}}>
											<img
												onClick={() =>
													data.status === 1 ? showModal(id) : true
												}
												alt="icon"
												width="20"
												src={
													data.status === 1 ?
												  require("../../assets/img/reject.png") :
												  require("../../assets/img/reject-disable.png")
												}
											/>
										</span>
									</Tooltip>
								</>
							) : sessionStorage.getItem("role_name") === "Division Head" ? (
									<>
										<Tooltip placement="top" title="Approve">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? handleApprove(id) : true
													}
													alt="icon"
													width="20"
													src={
													  data.status === 2 ?
                            require("../../assets/img/approve.png") :
													  require("../../assets/img/approve-disable.png")
													}
												/>
											</span>
										</Tooltip>
			
			
										<Tooltip placement="top" title="Reject / Review">
											<span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 2 ? showModal(id) : true
													}
													alt="icon"
													width="20"
													src={
													  data.status === 2 ?
														require("../../assets/img/reject.png") :
														require("../../assets/img/reject-disable.png")
													}
												/>
											</span>
										</Tooltip>
									</>								
							) : sessionStorage.getItem("role_name") === "Accounting" ? (
                  <>
                    <Tooltip placement="top" title="Approve">
                      <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                        <img
                          onClick={() =>
                            data.status === 3 ? handleApprove(id) : true
                          }
                          alt="icon"
                          width="20"
                          src={
                            data.status === 3 ?
                            require("../../assets/img/approve.png") :
                            require("../../assets/img/approve-disable.png")  
                          }
                        />
                      </span>
                    </Tooltip>
      
      
                    <Tooltip placement="top" title="Reject / Review">
                      <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                        <img
                          onClick={() =>
                            data.status === 3 ? showModal(id) : true
                          }
                          alt="icon"
                          width="20"
                          src={
                            data.status === 3 ?
                            require("../../assets/img/reject.png") :
                            require("../../assets/img/reject-disable.png")
                          }
                        />
                      </span>
                    </Tooltip>
                  </>								
              ) : sessionStorage.getItem("role_name") === "Admin" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 4 ? 'pointer' : 'not-allowed'}}
                      size="small"
                      type="default"
                      onClick={() => data.status !== 4 ? handleApprove(id) : true }
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          data.status !== 4 ?
                          require("../../assets/img/approve.png") : 
                          require("../../assets/img/approve-disable.png") 
                        }
                      />
                    </span>
                  </Tooltip>
                  
                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 4 ? 'pointer' : 'not-allowed'}}
                      size="small"
                      type="default"
                      onClick={() =>
                        data.status !== 4 ? showModal(id) : true 
                      }
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          data.status !== 4 ?
                          require("../../assets/img/reject.png") :
                          require("../../assets/img/reject-disable.png")
                        }
                      />
                    </span>
                  </Tooltip>
    
                  {/* <Tooltip placement="top" title="Delete">
                    <span style={{marginRight: 10, cursor: 'pointer'}}
                      size="small"
                      type="default"
                      onClick={() => handleDelete(id)}
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/delete.png")
                        }
                      />
                    </span>
                  </Tooltip> */}
                </>
              ) : null
						}
						<Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Accounting" ?
                    data.sc_acc >= 1 ? data.sc_acc : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>
					</div>
        );
      }
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      key: "approvalUser",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status < 4 && status >= 1
                ? require("../../assets/img/sand-clock.png")
                : status === 4
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalDeptHead",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 1
                ? require("../../assets/img/sand-clock.png")
                : status >= 2
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalDivHead",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 2
                ? require("../../assets/img/sand-clock.png")
                : status >= 3
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      key: "approvalAccounting",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 3
                ? require("../../assets/img/sand-clock.png")
                : status >= 4
                ? require("../../assets/img/checked.png")
                : status === 6
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : require("../../assets/img/sand-clock.png")
            }
          />
        );
      },
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   // fixed: "right",
    //   width: 50,
    //   render: (id, data) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             sessionStorage.getItem("role_name") === "Department Head" ? (
    //               data.department.filter((x) =>
    //                 JSON.parse(sessionStorage.getItem("department").includes(x))
    //               ).length > 0 ? (
    //                 <Menu>
    //                   <Menu.Item
    //                     disabled={data.status !== 1}
    //                     key="handleApproveDeptHead"
    //                   >
    //                     <span
    //                       onClick={() =>
    //                         data.status === 1 ? handleApprove(id) : true
    //                       }
    //                     >
    //                       <SafetyOutlined />
    //                       Approve
    //                     </span>
    //                   </Menu.Item>

    //                   <Menu.Item
    //                     disabled={data.status !== 1}
    //                     key="handleApproveDeptHead"
    //                   >
    //                     <span
    //                       onClick={() =>
    //                         data.status === 1 ? showModal(id) : true
    //                       }
    //                     >
    //                       <CloseCircleOutlined />
    //                       Reject
    //                     </span>
    //                   </Menu.Item>
    //                 </Menu>
    //               ) : (
    //                 <Menu>
    //                   <Menu.Item disabled="true" key="handleApproveDeptHead">
    //                     <span>
    //                       <SafetyOutlined />
    //                       Approve
    //                     </span>
    //                   </Menu.Item>

    //                   <Menu.Item disabled="true" key="handleApproveDeptHead">
    //                     <span>
    //                       <CloseCircleOutlined />
    //                       Reject
    //                     </span>
    //                   </Menu.Item>
    //                 </Menu>
    //               )
    //             ) : sessionStorage.getItem("role_name") === "Division Head" ? (
    //               <Menu>
    //                 <Menu.Item
    //                   disabled={data.status !== 2}
    //                   key="handleApproveDivHead"
    //                 >
    //                   <span
    //                     onClick={() =>
    //                       data.status === 2 ? handleApprove(id) : true
    //                     }
    //                   >
    //                     <SafetyOutlined />
    //                     Approve
    //                   </span>
    //                 </Menu.Item>

    //                 <Menu.Item
    //                   disabled={data.status !== 2}
    //                   key="handleApproveDivHead"
    //                 >
    //                   <span
    //                     onClick={() =>
    //                       data.status === 2 ? showModal(id) : true
    //                     }
    //                   >
    //                     <CloseCircleOutlined />
    //                     Reject
    //                   </span>
    //                 </Menu.Item>
    //               </Menu>
    //             ) : sessionStorage.getItem("role_name") === "Accounting" ? (
    //               <Menu>
    //                 <Menu.Item
    //                   disabled={data.status !== 3}
    //                   key="handleApproveAcc"
    //                 >
    //                   <span
    //                     onClick={() =>
    //                       data.status === 3 ? handleApprove(id) : true
    //                     }
    //                   >
    //                     <SafetyOutlined />
    //                     Approve
    //                   </span>
    //                 </Menu.Item>

    //                 <Menu.Item disabled>
    //                   <span
    //                     onClick={() =>
    //                       data.status === 3 ? showModal(id) : true
    //                     }
    //                   >
    //                     <CloseCircleOutlined />
    //                     Reject
    //                   </span>
    //                 </Menu.Item>
    //               </Menu>
    //             ) : (
    //               <Menu>
    //                 <Menu.Item disabled>
    //                   <span>
    //                     <SafetyOutlined />
    //                     Approve
    //                   </span>
    //                 </Menu.Item>

    //                 <Menu.Item disabled>
    //                   <span>
    //                     <CloseCircleOutlined />
    //                     Reject
    //                   </span>
    //                 </Menu.Item>
    //               </Menu>
    //             )
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalAccounting"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDeptHead" &&
        filter.key !== "approvalAccounting"
      );
    } else if (sessionStorage.getItem("role_name") === "Accounting") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Department Heaed" &&
      sessionStorage.getItem("role_name") !== "Accounting"
    ) {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDeptHead" &&
        filter.key !== "approvalStatus" &&
        filter.key !== "approvalAccounting"
      );
    }
    return filter;
  });

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Why Reject?"
        visible={isModalVisible}
        confirmLoading={loadReject}
        onOk={() => {
          handleReject(selectedId);
        }}
        onCancel={handleCancel}
      >
        <Radio.Group
          style={{ marginBottom: 20 }}
          onChange={(e) => handleChangeStatus("status", e.target.value)}
          value={data.status}
        >
          <Radio value={2}>Review</Radio>
          <Radio value={1}>Reject</Radio>
        </Radio.Group>
        <div name="control-hooks">
          <Input
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          />
        </div>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="Approval Transfer Budget"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col xs={24} md={8} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("pending")}>
              {/* <Meta
                avatar={
                 
                }
              /> */}
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ExclamationCircleOutlined
                  style={{ fontSize: "20px", color: "#FFFF00" }}
                />
                <row></row> Pending
              </Title>
              <div className="value-font-size "> {count.pending}</div>
            </Card>
          </Col>
          <Col xs={24} md={8} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("approve")}>
              {/* <Meta
                avatar={
                 
                }
              /> */}
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <CheckCircleOutlined
                  style={{ fontSize: "20px", color: "#008000" }}
                />
                <row></row> Approved
              </Title>
              <div className="value-font-size "> {count.approve}</div>
            </Card>
          </Col>
          <Col xs={24} md={8} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("all")}>
              {/* <Meta
                avatar={
                 
                }
              /> */}
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ProfileOutlined
                  style={{ fontSize: "20px", color: "#0000FF" }}
                />
                <row></row> Total Document
              </Title>
              <div className="value-font-size "> {count.all}</div>
            </Card>
          </Col>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={10} lg={10}>
                  {types.find((x) => x.filter === type).title}
                </Col>
                <Col xs={24} md={14} lg={14} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                  </Space>
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    scroll={{ x: 1500 }}
                    columns={columns}
                    dataSource={approvalPemindahanBudgets.data}
                    //rowKey={(data) => data.purchase_order}
                    rowKey={(data) => data._id}
                    expandable={{
                      expandedRowRender: getDetails,
                      onExpandedRowsChange: (data) => handleChangeListBudgetDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: approvalPemindahanBudgets.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* MODAL COMMENT LIST */}
      <Modal
        className="modal-comment-list"
        visible={visible}
        title={`${commentTemp.DocNumber} - ${commentTemp.CreatedDate} `}
        onOk={submitComment}
        onCancel={closeComment}
        footer={[
          <Form.Item>
            <TextArea
              rows={4}
              value={commentTemp.comment_description}
              onChange={(e) => handleChangeComment("comment_description", e.target.value)}
            ></TextArea>
          </Form.Item>,
          <Button key="back" onClick={closeComment} >
            Close
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={submitComment} >
            Send
          </Button>
        ]}
      >
        { commentList ?
            commentList.comment_list && 
              commentList.comment_list.map((comment) => {
              return (
                <Comment
                  className={`${comment.username === sessionStorage.getItem('username') ? 'comment-right' : ''}`}
                  author={<span className="comment-title">{comment.full_name}</span>}
                  avatar={<Avatar className="comment-avatar" src={comment.avatar} alt={`${comment.full_name}`} />}
                  content={
                    <p>
                      {comment.comment_description}
                    </p>
                  }
                  datetime={
                    <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{comment.comment_date}</span>
                    </Tooltip>
                  }
                />
              )
            })
          : <div id="comment-new-list">{commentTemp.tempDataComment}</div>}
      </Modal>
      {/* END COMMENT LIST */}

    </Fragment>
  );
}

export default ApprovalPemindahanBudget;
