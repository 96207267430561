import React, { Fragment, useState, useEffect } from "react";
import {
    Form,
    Input,
    Button,
    Layout,
    Row,
    Col,
    Card,
    Breadcrumb,
    message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import "./InternalOrder";
const { Content } = Layout;

const layout = {
    labelCol: {
        lg: 4,
        md: 14,
    },
    wrapperCol: {
        lg: 12,
        md: 14,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

function EditInternalOrder(props) {
    const [data, setData] = useState({
        departement: "",
        pic: "",
        io_number: "",
        description: "",
        chart_of_account: "",
    });

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getInternalOrder();
      }, []);
    
      const getInternalOrder = () => {
        Axios.get(`${url}/internal-order/${props.match.params.id}`, {
          params: {
            //user_type: "supplier",
            //status: "2",
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            setData({
              ...data,
              departement: res.data.data.departement,
              pic: res.data.data.pic,
              io_number: res.data.data.io_number,
              description: res.data.data.description,
              chart_of_account: res.data.data.chart_of_account,
            });
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data.message);
              if (err.response.status === 401) {
                sessionStorage.removeItem("token");
              }
            } else {
              message.error("Backend not yet Started");
            }
          });
      };

    const handleChange = (name, value) => {
        setError(null);
        setData({
            ...data,
            [name]: value,
        });
    };

    const onSaveData = () => {
        setLoading(true);

        Axios.post(
            `${url}/internal-order`,
            {
                data:[{
                    departement: data.departement,
                    pic: data.pic,
                    io_number: data.io_number,
                    description: data.description,
                    chart_of_account: data.chart_of_account,
                }]
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            }
        )
            .then((res) => {
                message.success(res.data.message);
                props.history.push("/internal-order");
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 422) {
                        setError(err.response.data.errors);
                    }
                    if (err.response.status === 401) {
                        sessionStorage.removeItem("token");
                    }
                }
                setLoading(false);
            });
    };

    const handleReset = () => {
        setData({
            departement: "",
            pic: "",
            io_number: "",
            description: "",
            chart_of_account: "",
        });
    };

    if (!sessionStorage.getItem("token")) {
        return <Redirect to="/login" />;
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card
                        type="inner"
                        title="Internal Order"
                        extra={moment(new Date()).format("DD MMMM YYYY")}
                    >
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/internal-order">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit Internal Order</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form {...layout} name="control-hooks" onFinish={onSaveData} labelAlign="left" style={{marginLeft: '25%'}}>
                                <Form.Item
                                    required
                                    label="Departement"
                                    validateStatus={error && error.code ? "error" : false}
                                    help={
                                        error && error.departement ? error.departement[0] : false
                                    }
                                >
                                    <Input
                                        value={data.departement}
                                        onChange={(e) =>
                                            handleChange("departement", e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="PIC"
                                    validateStatus={error && error.pic ? "error" : false}
                                    help={
                                        error && error.pic ? error.pic[0] : false
                                    }
                                >
                                    <Input
                                        value={data.pic}
                                        onChange={(e) =>
                                            handleChange("pic", e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="IO Number "
                                    validateStatus={error && error.io_number ? "error" : false}
                                    help={error && error.io_number ? error.io_number[0] : false}
                                >
                                    <Input
                                        value={data.io_number}
                                        onChange={(e) => handleChange("io_number", e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Description"
                                    validateStatus={error && error.description ? "error" : false}
                                    help={
                                        error && error.description ? error.description[0] : false
                                    }
                                >
                                    <Input
                                        value={data.description}
                                        onChange={(e) =>
                                            handleChange("description", e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Chart Of Account"
                                    validateStatus={error && error.chart_of_account ? "error" : false}
                                    help={
                                        error && error.chart_of_account ? error.chart_of_account[0] : false
                                    }
                                >
                                    <Input
                                        value={data.chart_of_account}
                                        onChange={(e) =>
                                            handleChange("chart_of_account", e.target.value)
                                        }
                                    />
                                </Form.Item>

                                <Form.Item {...tailLayout}>
                                    <Button
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                        className="mr-button"
                                    >
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                        Reset
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}

export default EditInternalOrder;