import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Typography,
  Select,
  Modal,
  DatePicker,
  Upload,
  Radio,
  Divider,
  Spin
} from "antd";
import CreatableSelect from 'react-select/creatable';
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import NumberFormat from "react-number-format";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditEntertainment(props, history) {
  const [entertainment, setEntertainment] = useState([]);
  const [data, setData] = useState({
    ref_doc_pengajuan: "",
    internal_order: "",
    cost_center: "",
    transfer_to: "",
    nama_bank: "",
    no_rekening: "",
    description_header: "",
    gl_code: "",
    _id: null,
    description: "",
    tanggal: "",
    tempat: "",
    alamat: "",
    jumlah: "",
    nama: "",
    jenis: "",
    jabatan: "",
    nama_perusahaan: "",
    total: 0,
    internal_order_id: "",
    internal_order_number: "",
    cost_center_id: "",
    cost_center_number: "",
    bukti: [],
  });
  const [entertainmentDetail, setEntertainmentDetail] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [bankName, setBankName] = useState([]);
  const [accountNumber, setAccountNumber] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [codes, setCodes] = useState([]);
  const [codes_account, setCodesAccount] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [isModalVisibleOpsi, setIsModalVisibleOpsi] = useState(false);
  const [waktuBerangkat, setWaktuBerangkat] = useState(null);
  const [waktuPulang, setWaktuPulang] = useState(null);
  const refAccountName = useRef(null);
  const refBtnUpload = useRef(null);
  const [listBudget, setListBudget] = useState([]);
  const arrayTes = ["delete", "edit"];
  const [selectedId, setSelectedId] = useState("");

  /* START - createby "abdoelhariez-kelola" 04-09-2022 */
  const refInputDescription = useRef(null)
  const refActCostCenter = useRef(null)
  const refActInternalOrder = useRef(null)
  const refActGlCode = useRef(null)
  const refActLocation = useRef(null)
  const refActAddress = useRef(null)
  const refActType = useRef(null)
  const refActAmount = useRef(null)
  const refActName = useRef(null)
  const refActPosition = useRef(null)
  const refActCompany = useRef(null)
  /* END - createby "abdoelhariez-kelola" 04-09-2022 */

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/entertainment/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const bukti =
          res.data.data.bukti_scan_details.length > 0
            ? res.data.data.bukti_scan_details.map((x, i) => {
              return {
                uid: i,
                name: x.bukti_scan,
                status: "done",
                url: x.bukti_scan_url,
                fileid: x._id,
              };
            })
            : [];

        const lenDiv = res.data.data.divisi.length;
        let elDiv = [];
        for (let i = 0; i < lenDiv; i++) {
          elDiv.push(res.data.data.divisi[i].name);
        }

        setData({
          ...data,
          nama: res.data.data.nama,
          npk: res.data.data.npk,
          divisi: elDiv,
          user_id: res.data.data.user_id,
          pengajuan_uang_muka_entertainment_code:
            res.data.data.pengajuan_umb_code,
          pengajuan_uang_muka_entertainment_id: res.data.data.pengajuan_umb_id,
          transfer_to: res.data.data.transfer_to,
          entertainment_code: res.data.data.entertainment_code,
          nama_bank: res.data.data.nama_bank,
          no_rekening: res.data.data.no_rekening,
          description_header: res.data.data.description,
          grand_total: res.data.data.grand_total,
          details: entertainmentDetail,
          listBudget: listBudget,
          pengajuan_umb_label: res.data.data.pengajuan_umb_label,
          vendor_code: res.data.data.vendor_code,
          vendor_name: res.data.data.vendor_name,
          vendor_label: `${res.data.data.vendor_code} - ${res.data.data.vendor_name} `,
          bukti,
        });
        setEntertainmentDetail(
          res.data.data.details.map((detail) => {
            return {
              id: detail._id,
              ...detail,
            };
          })
        );
        setListBudget(
          res.data.data.list_budget_details.map((detail) => {
            return {
              id: detail._id,
              ...detail,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelectCode = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          nama: findControlItem.nama,
          npk: findControlItem.npk,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          internal_order: findControlItem.internal_order,
          cost_center: findControlItem.cost_center,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const handleChangeSelectCodeAccount = (name, value) => {
    if (value) {
      const findControlItem = codes_account.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_label`]: value.label,
          [`${name}_code`]: findControlItem.code,
          [`_id`]: value.value,
          description: findControlItem.description,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`_id`]: null,
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      ref_doc_pengajuan: "",
      internal_order: "",
      internal_order_number: "",
      cost_center_number: "",
      transfer_to: "",
      nama_bank: "",
      no_rekening: "",
      gl_code: "",
      _id: null,
      description: "",
      tanggal: "",
      tempat: "",
      alamat: "",
      jumlah: "",
      nama: "",
      jenis: "",
      jabatan: "",
      nama_perusahaan: "",
      total: 0,
    });
  };

  const handleResetModal = () => {
    setData({
      cost_center_number: "",
      cost_center_label: "",
      internal_order_number: "",
      internal_order_label: "",
      gl_code: "",
      _id: null,
      description: "",
      tanggal: "",
      tempat: "",
      alamat: "",
      jumlah: "",
      nama: "",
      jenis: "",
      jabatan: "",
      nama_perusahaan: "",
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
      description: "",
      gl_code: "",
      cost_center_id: "",
      cost_center_label: "",
      cost_center_number: "",
      internal_order_id: "",
      internal_order_number: "",
      internal_order_label: "",
      available: "",
      _id: "",
      tanggal: "",
      tempat: "",
      alamat: "",
      jenis: "",
      jumlah: "",
      nama: "",
      jabatan: "",
      nama_perusahaan: "",
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisible(false);
    setIsModalVisibleOpsi(false);
  };

  const onSaveTemporaryData = () => {
    /* START - createby "abdoelhariez-kelola" 04-09-2022 */
    if (data.gl_code !== '' && data.cost_center_number !== '' && data.internal_order_number !== '' && data.tempat !== '' && data.alamat !== '' && data.jenis !== '' && data.nama !== '' && data.jabatan !== '' && data.nama_perusahaan !== '' && data.jumlah !== 0) {
      if (selectedId) {
        const modifyEntertainmentDetail = [...entertainmentDetail];
        const findEntertainmentDetail = modifyEntertainmentDetail.find(
          (x) => x._id === selectedId
        );

        const budgetIO = data.available;
        const amountBudget = data.jumlah;
        const beforeAmount = findEntertainmentDetail.jumlah;
        const modifyListBudget = [...listBudget];
        const listIoDuplicate = modifyListBudget.find(
          (x) => x.internal_order_number === findEntertainmentDetail.internal_order_number
        );

        // Setting Budget on details items
        findEntertainmentDetail.cost_center_id = data.cost_center_id;
        findEntertainmentDetail.cost_center_number = data.cost_center_number;
        findEntertainmentDetail.cost_center_label = data.cost_center_label;
        // findEntertainmentDetail.internal_order_id = data.internal_order_id;
        findEntertainmentDetail.internal_order_number = data.internal_order_number;
        findEntertainmentDetail.internal_order_label = data.internal_order_label;
        findEntertainmentDetail.available_budget = budgetIO;
        // end setting budget

        findEntertainmentDetail.gl_code = data.gl_code;

        findEntertainmentDetail.description = data.description;
        findEntertainmentDetail.tanggal = moment(data.tanggal).format(
          "DD-MM-YYYY"
        );
        findEntertainmentDetail.tempat = data.tempat;
        findEntertainmentDetail.alamat = data.alamat;

        findEntertainmentDetail.jenis = data.jenis;
        findEntertainmentDetail.jumlah = data.jumlah;
        findEntertainmentDetail.nama = data.nama;
        findEntertainmentDetail.jabatan = data.jabatan;
        findEntertainmentDetail.nama_perusahaan = data.nama_perusahaan;

        setEntertainmentDetail(modifyEntertainmentDetail);

        if (listIoDuplicate) {
          const totalAmountBefore = listIoDuplicate.total_amount - beforeAmount + amountBudget;

          listIoDuplicate.cost_center_id = data.cost_center_id;
          listIoDuplicate.cost_center_number = data.cost_center_number;
          listIoDuplicate.cost_center_label = data.cost_center_label;
          listIoDuplicate.internal_order_number = data.internal_order_number;
          listIoDuplicate.internal_order_label = data.internal_order_label;
          listIoDuplicate.available_budget = parseFloat(budgetIO);
          listIoDuplicate.remaining_budget = parseFloat(budgetIO - totalAmountBefore);
          listIoDuplicate.total_amount = listIoDuplicate.total_amount - beforeAmount + amountBudget;
          listIoDuplicate.statusBudget = parseFloat(budgetIO) >= totalAmountBefore ? 'V' : 'X'

          setListBudget(modifyListBudget);
        } else {
          setListBudget([
            ...listBudget,
            {
              id: Math.random(),
              cost_center_id: data.cost_center_id,
              cost_center_number: data.cost_center_number,
              cost_center_label: data.cost_center_label,
              internal_order_number: data.internal_order_number,
              internal_order_label: data.internal_order_label,
              available_budget: parseFloat(budgetIO),
              remaining_budget: parseFloat(budgetIO - amountBudget),
              total_amount: amountBudget,
              statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
            }
          ]);
        }

      } else {
        const budgetIO = data.available;
        const amountBudget = data.jumlah;

        setEntertainmentDetail([
          ...entertainmentDetail,
          {
            description: data.description,
            gl_code: data.gl_code,
            _id: data._id,
            cost_center_id: data.cost_center_id,
            cost_center_number: data.cost_center_number,
            cost_center_label: data.cost_center_label,
            internal_order_number: data.internal_order_number,
            internal_order_label: data.internal_order_label,
            available_budget: budgetIO,
            tanggal: moment(data.tanggal).format("YYYY-MM-DD"),
            tempat: data.tempat,
            alamat: data.alamat,
            jenis: data.jenis,
            jumlah: data.jumlah,
            nama: data.nama,
            jabatan: data.jabatan,
            nama_perusahaan: data.nama_perusahaan,
          },
        ]);

        if (listBudget.length >= 1) {
          const modifyListBudget = [...listBudget];
          const listIoDuplicate = modifyListBudget.find(
            (x) => x.internal_order_number === data.internal_order_number
          );

          if (listIoDuplicate) {
            const totalAmountBefore = listIoDuplicate.total_amount + amountBudget;
            listIoDuplicate.cost_center_id = data.cost_center_id;
            listIoDuplicate.cost_center_number = data.cost_center_number;
            listIoDuplicate.cost_center_label = data.cost_center_label;
            listIoDuplicate.internal_order_number = data.internal_order_number;
            listIoDuplicate.internal_order_label = data.internal_order_label;
            listIoDuplicate.available_budget = parseFloat(budgetIO);
            listIoDuplicate.remaining_budget = parseFloat(budgetIO - totalAmountBefore);
            listIoDuplicate.total_amount = listIoDuplicate.total_amount + amountBudget;
            listIoDuplicate.statusBudget = parseFloat(budgetIO) >= totalAmountBefore ? 'V' : 'X'

            setListBudget(modifyListBudget);
          } else {
            setListBudget([
              ...listBudget,
              {
                id: Math.random(),
                cost_center_id: data.cost_center_id,
                cost_center_number: data.cost_center_number,
                cost_center_label: data.cost_center_label,
                internal_order_number: data.internal_order_number,
                internal_order_label: data.internal_order_label,
                available_budget: parseFloat(budgetIO),
                remaining_budget: parseFloat(budgetIO - amountBudget),
                total_amount: amountBudget,
                statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
              }
            ]);
          }
        } else {
          setListBudget([
            ...listBudget,
            {
              id: Math.random(),
              cost_center_id: data.cost_center_id,
              cost_center_number: data.cost_center_number,
              cost_center_label: data.cost_center_label,
              internal_order_number: data.internal_order_number,
              internal_order_label: data.internal_order_label,
              available_budget: parseFloat(budgetIO),
              remaining_budget: parseFloat(budgetIO - amountBudget),
              total_amount: amountBudget,
              statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
            }
          ]);
        }
      }

      setSelectedId("");

      setData({
        ...data,
        description: "",
        gl_code: "",
        gl_label: "",
        cost_center_id: "",
        cost_center_label: "",
        cost_center_number: "",
        internal_order_id: "",
        internal_order_number: "",
        internal_order_label: "",
        available: "",
        _id: "",
        tanggal: "",
        tempat: "",
        alamat: "",
        jenis: "",
        jumlah: "",
        nama: "",
        jabatan: "",
        nama_perusahaan: "",
      });

      setIsModalVisible(false);
    } else if (data.cost_center_number === '') {
      message.error("Please input your Cost Center Number, cannot be empty");
      refActCostCenter.current.focus()
    } else if (data.internal_order_number === '') {
      message.error("Please input your Internal Order Number, cannot be empty");
      refActInternalOrder.current.focus()
    } else if (data.gl_code === '') {
      message.error("Please input your Activity Code, cannot be empty");
      refActGlCode.current.focus()
    } else if (data.tempat === '') {
      message.error("Please input your Location, cannot be empty");
      refActLocation.current.focus()
    } else if (data.alamat === '') {
      message.error("Please input your Address, cannot be empty");
      refActAddress.current.focus()
    } else if (data.jenis === '') {
      message.error("Please input your Type, cannot be empty");
      refActType.current.focus()
    } else if (data.jumlah === 0) {
      message.error("Please input your Amount, cannot be empty");
      refActAmount.current.focus()
    } else if (data.nama === '') {
      message.error("Please input your Relase Name, cannot be empty");
      refActName.current.focus()
    } else if (data.jabatan === '') {
      message.error("Please input your Position, cannot be empty");
      refActPosition.current.focus()
    } else if (data.nama_perusahaan === '') {
      message.error("Please input your Company Name, cannot be empty");
      refActCompany.current.focus()
    }
    /* END - createby "abdoelhariez-kelola" 04-09-2022 */
  };

  const onSaveData = () => {
    const total_amount = Math.floor(
      entertainmentDetail.reduce((prevValue, currentValue) => {
        const sumTotal = currentValue.jumlah;
        const grandTotal = sumTotal;
        return grandTotal + prevValue;
      }, 0) ||
      entertainmentDetail.reduce((prevValue, currentValue) => {
        const sumTotal = currentValue.jumlah;
        const grandTotal = sumTotal;
        return grandTotal + prevValue;
      }, 0)
    );
    const totals = data.available;
    const datapengajuan = data.pengajuan_um === "Belum";

    const countDataBudgetX = listBudget.filter((x) => x.statusBudget === 'X');
    setData({
      ...data,
      countStatusBudgetX: countDataBudgetX,
    })

    if (countDataBudgetX.length == 0) {
      setLoading(true);
      let fd = new FormData();

      fd.set("status_proses", "budget_ok");
      fd.set("status_pengajuan", data.pengajuan_um);
      fd.set("types_reimbursment", "Biaya Entertainment");
      fd.set("details", JSON.stringify(entertainmentDetail));
      fd.set("list_budget_details", JSON.stringify(listBudget));
      fd.set(
        "pengajuan_uang_muka_entertainment_code",
        datapengajuan ? "-" : data.pengajuan_uang_muka_entertainment_code
      );
      fd.set(
        "pengajuan_uang_muka_entertainment_id",
        datapengajuan ? "-" : data.pengajuan_uang_muka_entertainment_id
      );
      fd.set("vendor_code", data.vendor_code);
      fd.set("vendor_name", data.vendor_name);
      fd.set("transfer_to", data.transfer_to);
      fd.set("nama_bank", data.nama_bank);
      fd.set("no_rekening", data.no_rekening);
      fd.set("description", data.description_header);
      fd.set("available", totals);
      fd.set("total_amount", total_amount);
      fd.set("grand_total", data.grand_total);
      fd.set(
        "pengajuan_umb_code",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_code
      );
      fd.set(
        "pengajuan_umb_id",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_id
      );
      fd.set(
        "pengajuan_umb_total",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_total
      );

      if (data.bukti) {
        if (data.bukti.length > 0) {
          data.bukti.length > 0 && data.bukti.map((bukti, i) => {
            if (bukti.originFileObj) {
              fd.append(`bukti_scanner[${i}]`, bukti.originFileObj);
            } else {
              fd.set(`bukti_scanner[${i}]`, bukti.name);
            }
          });
        } else {
          fd.append(`bukti_scanner`, "-");
        }
      } else {
        fd.append(`bukti_scanner`, "-");
      }

      if (fd.get('bukti_scanner') !== '-' && fd.get('description') !== '' && fd.get('transfer_to') && entertainmentDetail.length != 0 && listBudget.length != 0) {
        Axios.post(props.match.params.id
          ? `${url}/entertainment/${props.match.params.id}`
          : `${url}/entertainment`,
          fd, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            message.success(res.data.message);
            props.history.push("/entertainment");
          })
          .catch((err) => {
            console.log(err)
            if (err.response) {
              message.error(err.response.data.message);
              if (err.response.status === 422) {
                setError(err.response.data.errors);
              }
              if (err.response.status === 401) {
                localStorage.removeItem("token");
              }
            } else {
              message.error("Backend not yet Started");
            }
            setLoading(false);
          });
      } else {
        if (fd.get('transfer_to') === '') {
          message.error("Please input your Account Name, cannot be empty");
          refAccountName.current.focus()
        } else if (fd.get('description') === '') {
          message.error("Please input your description, cannot be empty");
          refInputDescription.current.focus()
        } else if (fd.get('bukti_scanner') === '-') {
          message.error("Please upload file or image, cannot be empty");
          refBtnUpload.current.focus()
        } else if (entertainmentDetail.length == 0) {
          message.error("Please input activity details transaction, cannot be empty");
        } else if (listBudget.length == 0) {
          message.error("Please complete your transaction list budget Internal Order & Cost Center");
        }
        setLoading(false);
      }
    } else {
      showModalOpsi();
    }
  };

  const handleModalSelect = async () => {
    setLoading(true);
    const totals = data.available;
    const datapengajuan = data.pengajuan_um === "Belum";
    let fd = new FormData();

    fd.set("full_name", data.full_name);
    fd.set("npk", data.npk);
    fd.set("divisi", data.divisi);
    fd.set("user_id", data.user_id);
    fd.set("details", JSON.stringify(entertainmentDetail));
    fd.set(
      "pengajuan_uang_muka_entertainment_code",
      data.pengajuan_uang_muka_entertainment_code
    );
    fd.set(
      "pengajuan_uang_muka_entertainment_id",
      data.pengajuan_uang_muka_entertainment_id
    );
    fd.set("internal_order_id", data.internal_order_id);
    fd.set("internal_order_number", data.internal_order_number);
    fd.set("cost_center_id", data.cost_center_id);
    fd.set("cost_center_number", data.cost_center_number);
    fd.set("cost_center_label", data.cost_center_label);
    fd.set("vendor_code", data.vendor_code);
    fd.set("vendor_name", data.vendor_name);
    fd.set("transfer_to", data.transfer_to);
    fd.set("nama_bank", data.nama_bank);
    fd.set("no_rekening", data.no_rekening);
    fd.set("description", data.description_header);
    fd.set("grand_total", data.grand_total);
    fd.set("available", data.available);
    fd.set("remark", "");

    data.bukti &&
      data.bukti.length > 0 &&
      data.bukti.map((bukti, i) => {
        if (bukti.originFileObj) {
          fd.append(`bukti_scanner[${i}]`, bukti.originFileObj);
        } else {
          fd.set(`bukti_scanner[${i}]`, bukti.name);
        }
      });

    if (fd.get('bukti_scanner') !== '-' && fd.get('transfer_to') && entertainmentDetail.length != 0 && listBudget.length != 0) {
      await Axios.post(
        props.match.params.id
          ? `${url}/entertainment/${props.match.params.id}`
          : `${url}/entertainment`,
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then(async () => {
          await Axios.post(
            data.status === "penambahan"
              ? `${url}/penambahan-budget`
              : `${url}/pemindahan-budget`,
            {
              status_proses: data.status,
              types_reimbursment: "Biaya Entertainment",
              pengajuan_uang_muka_entertainment_code:
                data.pengajuan_uang_muka_entertainment_code,
              pengajuan_uang_muka_entertainment_id:
                data.pengajuan_uang_muka_entertainment_id,
              details: entertainmentDetail,
              internal_order_id: data.internal_order_id,
              internal_order_number: data.internal_order_number,
              cost_center_id: data.cost_center_id,
              cost_center_number: data.cost_center_number,
              vendor_code: data.vendor_code,
              vendor_name: data.vendor_name,
              transfer_to: data.transfer_to,
              nama_bank: data.nama_bank,
              no_rekening: data.no_rekening,
              description: data.description_header,
              available: totals,
              total_amount: data.grand_total,
              grand_total: data.grand_total,
            },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              message.success(res.data.message);
              if (data.status === "penambahan") {
                props.history.push("/penambahan-budget");
              } else {
                props.history.push("/pemindahan-budget");
              }
              closeModal();
            })
            .catch((err) => {
              console.log(err.response);
              if (err.response) {
                message.error(err.response.data.message);
                if (err.response.status === 401) {
                  sessionStorage.removeItem("token");
                }
              }
            })
            .finally(() => {
              setLoading(false);
            });
          // message.success(res.data.message);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 422) {
              setError(err.response.data.errors);
            }
            if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            }
          } else {
            message.error("Backend not yet Started");
          }
          setLoading(false);
        });
    } else {
      if (fd.get('transfer_to') === '') {
        message.error("Please input your Account Name, cannot be empty");
        refAccountName.current.focus()
      } else if (fd.get('bukti_scanner') === '-') {
        message.error("Please upload file or image, cannot be empty");
        refBtnUpload.current.focus()
      } else if (entertainmentDetail.length == 0) {
        message.error("Please input activity details transaction, cannot be empty");
      } else if (listBudget.length == 0) {
        message.error("Please complete your transaction list budget Internal Order & Cost Center");
      }
      setLoading(false);
    }
  };

  const showModalOpsi = () => {
    setIsModalVisibleOpsi(true);
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const handleRemove = (files) => {
    if (files.fileid) {
      deleteFileList(files.fileid);
    }
    setData({
      ...data,
      bukti: null,
    });
  };

  const deleteFileList = (id) => {
    Axios.delete(`${url}/file-deklarasi-entertainment-image/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBeforeUpload = () => {
    return false;
  };

  const handlePreview = async (file) => {
    const file64 = await getBase64(file.originFileObj);
    const pdf = URL.createObjectURL(file.originFileObj);
    Modal.info({
      title: "File Preview",
      content: <img style={{ width: 250, height: 400 }} src={file64} alt="" />,
    });

    console.log(data.bukti, "BUK");
  };

  const handleChangeFile = (files) => {
    if (files.fileList.length > 10000) {
      message.error("Maximum files !");
    } else {
      let fileList = [...files.fileList];
      fileList = fileList.map((file) => {
        file.url = URL.createObjectURL(file.originFileObj);
        return file;
      });
      setData({
        ...data,
        bukti: fileList,
        // bukti: files,
      });
    }
  };

  const handleRemoveDetails = (id) => {
    const cekDetailIO = entertainmentDetail.find(
      (x) => x._id === id
    );

    const details = entertainmentDetail.filter(
      (x) => x._id !== id
    );

    const modifyListBudget = [...listBudget];
    const listBudgetDetails = modifyListBudget.find(
      (x) => x.internal_order_number === cekDetailIO.internal_order_number
    );

    const sumAmount = listBudgetDetails.total_amount - cekDetailIO.jumlah;

    if (sumAmount <= 0) {
      const listBudgetDetails = listBudget.filter(
        (x) => x.internal_order_number !== cekDetailIO.internal_order_number
      );

      setListBudget(listBudgetDetails);
    } else {
      listBudgetDetails.total_amount = sumAmount;
      listBudgetDetails.remaining_budget = listBudgetDetails.available_budget - sumAmount;
      listBudgetDetails.statusBudget = listBudgetDetails.available_budget >= sumAmount ? 'V' : 'X'
      setListBudget(modifyListBudget);
    }

    setEntertainmentDetail(details);
  };

  const handleEditDetails = (id) => {
    const details = entertainmentDetail.find(
      (entertainmentDetail) => entertainmentDetail._id === id
    );

    setSelectedId(id);
    setData({
      ...data,
      cost_center_id: details.cost_center_id,
      cost_center_label: details.cost_center_label,
      cost_center_number: details.cost_center_number,
      internal_order_number: details.internal_order_number,
      internal_order_label: details.internal_order_label,
      available: details.available_budget,
      _id: details._id,
      gl_code: details.gl_code,
      gl_label: `${details.gl_code} - ${details.description}`,
      description: details.description,
      tanggal: moment(details.tanggal, "DD-MM-YYYY"),
      tempat: details.tempat,
      alamat: details.alamat,
      jenis: details.jenis,
      jumlah: details.jumlah,
      nama: details.nama,
      jabatan: details.jabatan,
      nama_perusahaan: details.nama_perusahaan,
    });
    setIsModalVisible(true);
  };

  const getDetails = (id) => {
    setLoadingDetail(true);
    Axios.get(`${url}/pengajuan-uang-muka-entertainment/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setEntertainmentDetail(res.data.data.details);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChangeSelectIo = (name, value) => {
    setLoadingBudget(true);
    setData({
      ...data,
      available: 0,
    });

    if (value) {
      const findControlItem = budgets.find((x) => x.aufnr === value.value);
      if (findControlItem) {
        setData({
          ...data,
          available: findControlItem.available,
          [`${name}_number`]: value.value,
          [`${name}_label`]: value.label,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_number`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const getInternalOrder = async (keyword) => {
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        department: JSON.parse(sessionStorage.getItem("io_department")),
        keyword,
        columns: ["internal_order_number", "description"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          internal_orders: res.data.data,
        });
        setBudgets(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleGetBudget = (name, value) => {
    setLoadingBudget(true);
    setData({
      ...data,
      [`${name}_number`]: value.value,
      [`${name}_label`]: value.label,
      available: 0,
      availLoad: 0
    });

    Axios.get(`${url}/internal-order/sap/${value.value}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.data.length <= 0) {
          message.error("Budget not available");
          setData({
            ...data,
            availLoad: 1
          });
        } else {
          setData({
            ...data,
            [`${name}_number`]: value.value,
            [`${name}_label`]: value.label,
            available:
              res.data.data.length > 0
                ? res.data.data[0].available
                  ? res.data.data[0].available.replaceAll(".", "")
                  : 0
                : 0,
            availLoad: 1
          });
        }
        setLoadingBudget(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoadingBudget(false);
      });
  };

  const getVendor = async (keyword) => {
    setLoadingVendor(true);
    await Axios.get(`${url}/vendor/list`, {
      params: {
        keyword,
        columns: ["vendor_name", "vendor_code"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setVendor(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoadingVendor(false);
  };

  const handleCreate = (value) => {
    console.log('create new', value, bankName.length);
    let arrBank = [];
    let key = bankName.length;
    arrBank.push({
      key: key,
      label: value,
      value: value
    })
    setBankName(bankName.concat(arrBank));
    let name = "nama_bank";
    setData({
      ...data,
      [`${name}`]: value,
      [`${name}_id`]: key,
      no_rekening: accountNumber[key],
    });
  }

  const handleChangeSelectVendor = (name, value) => {
    if (name === "vendor") {
      const findControlItem = vendor.find((x) => x.vendor_code === value.value);
      if (findControlItem) {
        if (findControlItem.bank_name1) {
          const arrBank = [];
          const arrAccBank = [];
          const elBN = findControlItem.bank_name1;
          const elAB = findControlItem.account_number1;

          arrBank.push({
            key: 0,
            label: elBN,
            value: elBN
          })

          arrAccBank.push(elAB);

          if (findControlItem.bank_name2) {
            const elBN = findControlItem.bank_name2;
            const elAB = findControlItem.account_number2;

            arrBank.push({
              key: 1,
              label: elBN,
              value: elBN
            })

            arrAccBank.push(elAB);
          } else {
            message.success("Bank Name & Account Number available 1 data");
          }

          setBankName(arrBank);
          setAccountNumber(arrAccBank);

          setData({
            ...data,
            [`${name}_code`]: value.value,
            [`${name}_name`]: findControlItem.vendor_name,
            [`${name}_label`]: value.label,
            transfer_to: findControlItem.vendor_name,
          });
        } else {
          message.error("Bank Name or Account Number not found");
          setData({
            ...data,
            [`${name}_code`]: value.value,
            [`${name}_name`]: findControlItem.vendor_name,
            [`${name}_label`]: value.label,
            transfer_to: findControlItem.vendor_name,
            nama_bank: "",
            no_rekening: "",
          });
        }
      }
    } else if (name === "nama_bank") {
      setData({
        ...data,
        [`${name}`]: value.label,
        [`${name}_id`]: value.key,
        no_rekening: accountNumber[value.key],
      });
    } else {
      setData({
        ...data,
        [`${name}_code`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
        department: JSON.parse(sessionStorage.getItem("io_department")),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getCodeAccount = async () => {
    await Axios.get(`${url}/gl-account`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodesAccount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    if (name === "cost_center") {
      const findControlItem = data.cost_centers.find(
        (x) => x._id === value.value
      );
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: findControlItem.cost_center_number,
        [`${name}_label`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: value.label,
      });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChangeStatus = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const columnsListBudget = [
    {
      title: "INTERNAL ORDER",
      dataIndex: "internal_order_label",
      align: "center",
    },
    {
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      align: "right",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>REMAINING BUDGET</div>,
      dataIndex: "remaining_budget",
      align: "right",
      render: (remaining_budget) => {
        return <AntNumberFormat value={remaining_budget} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
  ];

  const columns = [
    {
      align: "right",
      title: "NO",
      dataIndex: "no",
      sorter: false,
      fixed: "center",
      width: 40,
      render: (text, record, index) => index + page * 10 - 9,
    },
    {
      align: "center",
      title: "ACTIVITY CODE",
      dataIndex: "gl_code",
      sorter: false,
    },
    {
      align: "center",
      title: "DESCRIPTION",
      dataIndex: "description",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
      dataIndex: "cost_center_number",
      align: "center",
      render: (cost_center_number) => {
        return cost_center_number ?
          cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
          : '';
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
      align: "center",
      render: (internal_order_number) => {
        return internal_order_number ?
          internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
          : '';
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" />;
      },
    },
    {
      title: "Purchase of entertainment and the like",
      // title: "Purchase entertainment and the like",
      children: [
        {
          align: "center",
          title: "DATE",
          dataIndex: "tanggal",
          render: (date) => moment(date).format("DD-MM-YYYY"),
        },
        {
          align: "center",
          title: "LOCATION",
          dataIndex: "tempat",
        },
        {
          align: "center",
          title: "ADDRESS",
          dataIndex: "alamat",
        },

        {
          align: "center",
          title: "TYPE *",
          dataIndex: "jenis",
        },
        {
          align: "center",
          title: "AMOUNT (Rp)",
          dataIndex: "jumlah",
          render: (jumlah) => {
            return <AntNumberFormat value={jumlah} type="text" />;
          },
        },
      ],
    },
    {
      title: "Business Relations Provided by Entertainment & The Like",
      children: [
        {
          align: "center",
          title: "NAME",
          dataIndex: "nama",
        },
        {
          align: "center",
          title: "POSITION",
          dataIndex: "jabatan",
        },
        {
          align: "center",
          title: "COMPANY NAME",
          dataIndex: "nama_perusahaan",
        },
      ],
    },
    {
      title: "ACTION",
      align: 'center',
      dataIndex: "_id",
      width: 200,
      render: (id) =>
        arrayTes &&
        arrayTes.map((x, index) => {
          return x == "delete" ? (
            <Button
              onClick={() => handleRemoveDetails(id)}
              size="small"
              type="danger"
            >
              <DeleteOutlined />
              Delete
            </Button>
          ) : (
            <Button
              style={{ marginLeft: 5 }}
              onClick={() => handleEditDetails(id)}
              size="small"
              type="primary"
            >
              <EditOutlined />
              Edit
            </Button>
          );
        }),
    },
  ];

  return (
    <Fragment>
      <Modal
        title="Select Optional"
        visible={isModalVisibleOpsi}
        onOk={handleModalSelect}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Radio.Group
          style={{ marginBottom: 20 }}
          onChange={(e) => handleChangeStatus("status", e.target.value)}
          value={data.status}
        >
          <Radio value="penambahan">Add Budget</Radio>
          <Radio value="pemindahan">Transfer Budget</Radio>
        </Radio.Group>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Entertainment Expense "
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/entertainment">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Entertainment Expense Update</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Ref Doc Number">
                              <Input
                                disabled
                                value={
                                  data.pengajuan_umb_label
                                    ? data.pengajuan_umb_label
                                    : "-"
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Employee Name"
                            >
                              <Input
                                disabled
                                value={data.nama}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={data.npk}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Division"
                            >
                              <Input
                                disabled
                                value={data.divisi}
                                onChange={(e) => {
                                  handleChange("divisi", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item label="Note">
                              <TextArea
                                value={data.description_header}
                                ref={refInputDescription}
                                onChange={(e) => {
                                  handleChange(
                                    "description_header",
                                    e.target.value
                                  );
                                }}
                              ></TextArea>
                            </Form.Item>

                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Select
                                labelInValue
                                loading={loadingVendor}
                                onChange={(value) =>
                                  handleChangeSelectVendor("vendor", value)
                                }
                                onFocus={() => getVendor("")}
                                showSearch
                                onSearch={(value) => getVendor(value)}
                                filterOption={false}
                                value={{
                                  key: data.vendor_code,
                                  label: data.vendor_label,
                                }}
                              >
                                {vendor &&
                                  vendor.map((account) => {
                                    return (
                                      <Option
                                        value={account.vendor_code}
                                        key={account.vendor_code}
                                      >
                                        {`${account.vendor_code} - ${account.vendor_name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama_bank ? "error" : false
                              }
                              help={
                                error && error.nama_bank
                                  ? error.nama_bank[0]
                                  : false
                              }
                              label=" Bank Name"
                            >

                              <CreatableSelect
                                theme={(theme) => ({
                                  ...theme,
                                  borderWidth: 0.1,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#f5f5f5',
                                    primary: '#089244',
                                  },
                                })}
                                // isClearable
                                onCreateOption={(value) => handleCreate(value)}
                                onChange={(value) =>
                                  handleChangeSelectVendor("nama_bank", value)
                                }
                                options={bankName}
                                value={{
                                  key: data.nama_bank_id,
                                  label: data.nama_bank,
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.no_rekening ? "error" : false
                              }
                              help={
                                error && error.no_rekening
                                  ? error.no_rekening[0]
                                  : false
                              }
                              label="Account Number"
                            >
                              <Input
                                value={data.no_rekening}
                                onChange={(e) => {
                                  handleChange("no_rekening", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              name="bukti"
                              label="Upload File"
                              // required
                              validateStatus={
                                error && error.bukti ? "error" : false
                              }
                              help={
                                error && error.bukti ? error.bukti[0] : false
                              }
                            >
                              <Fragment>
                                <Upload
                                  // listType="picture-card"
                                  multiple={true}
                                  onRemove={handleRemove}
                                  onChange={handleChangeFile}
                                  beforeUpload={handleBeforeUpload}
                                  // onPreview={handlePreview}
                                  // defaultfileList={[data.bukti]}
                                  action={data.bukti}
                                  fileList={data.bukti}
                                >
                                  <Button ref={refBtnUpload}>
                                    Upload
                                    <UploadOutlined />
                                  </Button>
                                </Upload>
                              </Fragment>
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space direction="vertical">
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add
                        </Button>

                        <Modal
                          title="Add Details"
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1200}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  labelAlign="left"
                                  {...layout}
                                  onFinish={onSaveData}
                                  name="control-hooks"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        {/* COST CENTER */}
                                        <Form.Item
                                          validateStatus={
                                            error && error.cost_center ? "error" : false
                                          }
                                          help={
                                            error && error.cost_center
                                              ? error.cost_center[0]
                                              : false
                                          }
                                          label="Cost Center"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelect("cost_center", value)
                                            }
                                            onFocus={() => getCostCenter("")}
                                            ref={refActCostCenter}
                                            showSearch
                                            onSearch={(value) => getCostCenter(value)}
                                            filterOption={false}
                                            value={{
                                              key: data.cost_center_id,
                                              label: data.cost_center_label,
                                            }}
                                          >
                                            {data.cost_centers &&
                                              data.cost_centers.map((cost_center) => {
                                                return (
                                                  <Option
                                                    value={cost_center._id}
                                                    key={cost_center._id}
                                                  >
                                                    {`${cost_center.cost_center_number} - ${cost_center.description}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        {/* END COST CENTER */}

                                        {/* INTERNAL ORDER */}
                                        <Form.Item label="Internal Order">
                                          <Select
                                            labelInValue
                                            loading={
                                              data.available === 0 ? loadingBudget : ""
                                            }
                                            onChange={(value) =>
                                              handleChangeSelectIo("internal_order", value)
                                            }
                                            ref={refActInternalOrder}
                                            onFocus={() => getInternalOrder("")}
                                            showSearch
                                            onSearch={(value) => getInternalOrder(value)}
                                            onSelect={(value) =>
                                              handleGetBudget("internal_order", value)
                                            }
                                            filterOption={false}
                                            value={{
                                              key: data.internal_order_number,
                                              label: data.internal_order_label,
                                            }}
                                          >
                                            {budgets &&
                                              budgets.map((internal_order_budget) => {
                                                return (
                                                  <Option
                                                    value={
                                                      internal_order_budget.internal_order_number
                                                    }
                                                    key={internal_order_budget._id}
                                                  >
                                                    {`${internal_order_budget.internal_order_number} - ${internal_order_budget.description}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        {/* END INTERNAL ORDER */}

                                        <Form.Item
                                          label={
                                            data.available !== "" ? "Budget Tersedia" : ""
                                          }
                                        >
                                          {/* START - createby "abdoelhariez-kelola" 04-09-2022 */}
                                          {data.availLoad === 0 ?
                                            (
                                              <Spin tip="Loading get data budget . . ."></Spin>
                                            ) : (
                                              <NumberFormat
                                                disabled
                                                type="input"
                                                thousandSeparator={true}
                                                prefix={"Rp. "}
                                                style={{ fontWeight: 600, fontSize: 20 }}
                                                value={data.available}
                                                displayType={"text"}
                                                onChange={(value) => {
                                                  handleChange("available", value);
                                                }}
                                              />
                                            )
                                          }
                                        </Form.Item>

                                        <Divider />
                                        <Form.Item
                                          validateStatus={
                                            error && error.gl_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.gl_code
                                              ? error.gl_code[0]
                                              : false
                                          }
                                          label="Activity Code"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectCodeAccount(
                                                "gl",
                                                value
                                              )
                                            }
                                            onFocus={() => getCodeAccount("")}
                                            ref={refActGlCode}
                                            showSearch
                                            onSearch={(value) =>
                                              getCodeAccount(value)
                                            }
                                            filterOption={false}
                                            value={{
                                              value: data._id,
                                              label: data.gl_code,
                                            }}
                                          >
                                            {codes_account &&
                                              codes_account.map(
                                                (code_account) => {
                                                  return (
                                                    <Option
                                                      value={code_account._id}
                                                      key={code_account._id}
                                                    >
                                                      {`${code_account.code} - ${code_account.description}`}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.description
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.description
                                              ? error.description[0]
                                              : false
                                          }
                                          label="Description"
                                        >
                                          <Input
                                            disabled
                                            value={data.description}
                                            onChange={(e) => {
                                              handleChange(
                                                "description",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <Title level={4}>
                                          Purchase of entertainment and the like
                                        </Title>
                                        <Form.Item
                                          validateStatus={
                                            error && error.tanggal
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.tanggal
                                              ? error.tanggal[0]
                                              : false
                                          }
                                          label="Date"
                                        >
                                          <DatePicker
                                            style={{ width: 645 }}
                                            format="DD MMMM YYYY"
                                            value={data.tanggal}
                                            onChange={(value) =>
                                              handleChange("tanggal", value)
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.tempat
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.tempat
                                              ? error.tempat[0]
                                              : false
                                          }
                                          label="Location"
                                        >
                                          <Input
                                            value={data.tempat}
                                            ref={refActLocation}
                                            onChange={(e) => {
                                              handleChange(
                                                "tempat",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.alamat
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.alamat
                                              ? error.alamat[0]
                                              : false
                                          }
                                          label="Address"
                                        >
                                          <Input
                                            value={data.alamat}
                                            ref={refActAddress}
                                            onChange={(e) => {
                                              handleChange(
                                                "alamat",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.jenis
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.jenis
                                              ? error.jenis[0]
                                              : false
                                          }
                                          label="Type"
                                        >
                                          <Input
                                            value={data.jenis}
                                            ref={refActType}
                                            onChange={(e) => {
                                              handleChange(
                                                "jenis",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.jumlah
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.jumlah
                                              ? error.jumlah[0]
                                              : false
                                          }
                                          label="Amount (Rp)"
                                        >
                                          <NumberFormat
                                            decimalSeparator="."
                                            thousandSeparator=","
                                            getInputRef={refActAmount}
                                            type="text"
                                            id="jumlah"
                                            className={`form-control text-right ant-input ${error &&
                                              error.data.errors.jumlah &&
                                              "is-invalid"
                                              }`}
                                            value={data.jumlah}
                                            onValueChange={(value) =>
                                              handleChange(
                                                "jumlah",
                                                value.floatValue
                                              )
                                            }
                                            placeholder="0 "
                                          />
                                        </Form.Item>
                                        <Title level={4}>
                                          Relase business provided entertainment
                                          and the like
                                        </Title>

                                        <Form.Item
                                          validateStatus={
                                            error && error.nama
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.nama
                                              ? error.nama[0]
                                              : false
                                          }
                                          label="Name"
                                        >
                                          <Input
                                            value={data.nama}
                                            ref={refActName}
                                            onChange={(e) => {
                                              handleChange(
                                                "nama",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.jabatan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.jabatan
                                              ? error.jabatan[0]
                                              : false
                                          }
                                          label="Position"
                                        >
                                          <Input
                                            value={data.jabatan}
                                            ref={refActPosition}
                                            onChange={(e) => {
                                              handleChange(
                                                "jabatan",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.nama_perusahaan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.nama_perusahaan
                                              ? error.nama_perusahaan[0]
                                              : false
                                          }
                                          label="Company Name"
                                        >
                                          <Input
                                            value={data.nama_perusahaan}
                                            ref={refActCompany}
                                            onChange={(e) => {
                                              handleChange(
                                                "nama_perusahaan",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button
                                  htmlType="reset"
                                  onClick={handleResetModal}
                                >
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>

                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        size="small"
                        columns={columnsListBudget}
                        dataSource={listBudget}
                        rowKey={(data) => data.id}
                        onChange={handleTableChange}
                        // scroll={{ x: 2000 }}
                        loading={loading}
                      />
                    </Col>

                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        size="small"
                        columns={columns}
                        dataSource={entertainmentDetail}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        scroll={{ x: 2500 }}
                        loading={loading}
                      />
                      <Form
                        style={{ marginTop: "30px" }}
                        labelAlign="left"
                        {...layout}
                        name="control-hooks"
                      >
                        <Row>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Total">
                              <NumberFormat
                                disabled
                                decimalSeparator="."
                                thousandSeparator=","
                                type="text"
                                id="total"
                                className={`form-control text-right ant-input ${error &&
                                  error.data.errors.total &&
                                  "is-invalid"
                                  }`}
                                value={entertainmentDetail.reduce(
                                  (prevValue, currentValue) => {
                                    return prevValue + currentValue.jumlah;
                                  },
                                  0
                                )}
                                onValueChange={(value) =>
                                  handleChange("grand_total", value.floatValue)
                                }
                                placeholder="0 "
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Row justify="start">
                      <Col xs={24}>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            disabled={loading}
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditEntertainment;
