import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Checkbox,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Content } = Layout;
const { TextArea } = Input;

const layout = {
  labelCol: {
    lg: 9,
    md: 12,
  },
  wrapperCol: {
    lg: 12,
    md: 14,
  },
};

function EditVendor(props) {
  const [data, setData] = useState({
    vendor_code: "",
    vendor_name: "",
    company_code: "",
    search_term: "",
    telephone: "",
    subject_to_with_tax: "",
    withholding_tax_type: "",
    withholding_tax_code: "",
    desc_with_tax_code: "",
    vendor_type: "",
    tax_registration_number: "",
    adress: "",
    email: "",
    term_of_payment: "",
    //pph23: false,
    pph23: "",
    gl_account: "",
    journal: "",
    pst_key_sap: "",
    special_gl: "",
    description: "",
    recount_account: "",
    recount_account_description: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVendor();
  }, []);

  const getVendor = () => {
    Axios.get(`${url}/vendor/${props.match.params.vendor_code}`, {
      params: {
        //user_type: "supplier",
        //status: "2",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          vendor_code: res.data.data.vendor_code,
          vendor_name: res.data.data.vendor_name,
          company_code: res.data.data.company_code,
          search_term: res.data.data.search_term,
          telephone: res.data.data.telephone,
          subject_to_with_tax: res.data.data.subject_to_with_tax,
          withholding_tax_type: res.data.data.withholding_tax_type,
          withholding_tax_code: res.data.data.withholding_tax_code,
          desc_with_tax_code: res.data.data.desc_with_tax_code,
          vendor_type: res.data.data.vendor_type,
          tax_registration_number: res.data.data.tax_registration_number,
          address: res.data.data.address,
          email: res.data.data.email,
          term_of_payment: res.data.data.term_of_payment,
          pph23: res.data.data.pph23,
          gl_account: res.data.data.gl_account,
          journal: res.data.data.journal,
          pst_key_sap: res.data.data.pst_key_sap,
          special_gl: res.data.data.special_gl,
          description: res.data.data.description,
          recount_account: res.data.data.recount_account,
          recount_account_description:
            res.data.data.recount_account_description,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const updateData = () => {
    setLoading(true);

    Axios.post(
      `${url}/update-vendor/${props.match.params.vendor_code}`,
      {
        vendor_code: data.vendor_code,
        vendor_name: data.vendor_name,
        company_code: data.company_code,
        search_term: data.search_term,
        telephone: data.telephone,
        subject_to_with_tax: data.subject_to_with_tax,
        withholding_tax_type: data.withholding_tax_type,
        withholding_tax_code: data.withholding_tax_code,
        desc_with_tax_code: data.desc_with_tax_code,
        vendor_type: data.vendor_type,
        tax_registration_number: data.tax_registration_number,
        address: data.address,
        email: data.email,
        term_of_payment: data.term_of_payment,
        pph23: data.pph23,
        gl_account: data.gl_account,
        journal: data.journal,
        pst_key_sap: data.pst_key_sap,
        special_gl: data.special_gl,
        description: data.description,
        recount_account: data.recount_account,
        recount_account_description: data.recount_account_description,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/vendor");
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      vendor_code: "",
      vendor_name: "",
      company_code: "",
      search_term: "",
      telephone: "",
      subject_to_with_tax: "",
      withholding_tax_type: "",
      withholding_tax_code: "",
      desc_with_tax_code: "",
      vendor_type: "",
      tax_registration_number: "",
      address: "",
      email: "",
      term_of_payment: "",
      pph23: "",
      gl_account: "",
      journal: "",
      pst_key_sap: "",
      special_gl: "",
      description: "",
      recount_account: "",
      recount_account_description: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            size="small"
            //type="inner"
            //title="Edit Vendor"
            //extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/vendor">Vendor</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Vendor</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={updateData} labelAlign="left">
                <Row>
                  <Fragment>
                    <Col xs={24} md={12}>
                      <Form.Item
                        required
                        label="Company Code"
                        validateStatus={
                          error && error.company_code ? "error" : false
                        }
                        help={
                          error && error.company_code
                            ? error.company_code[0]
                            : false
                        }
                      >
                        <Input
                          value={data.company_code}
                          onChange={(e) =>
                            handleChange("company_code", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Vendor Code"
                        validateStatus={
                          error && error.vendor_code ? "error" : false
                        }
                        help={
                          error && error.vendor_code
                            ? error.vendor_code[0]
                            : false
                        }
                      >
                        <Input
                          disabled
                          value={data.vendor_code}
                          onChange={(e) =>
                            handleChange("vendor_code", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Vendor Name"
                        validateStatus={
                          error && error.vendor_name ? "error" : false
                        }
                        help={
                          error && error.vendor_name
                            ? error.vendor_name[0]
                            : false
                        }
                      >
                        <Input
                          value={data.vendor_name}
                          onChange={(e) =>
                            handleChange("vendor_name", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Search Term"
                        validateStatus={
                          error && error.search_term ? "error" : false
                        }
                        help={
                          error && error.search_term
                            ? error.search_term[0]
                            : false
                        }
                      >
                        <Input
                          value={data.search_term}
                          onChange={(e) =>
                            handleChange("search_term", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Email"
                        validateStatus={error && error.email ? "error" : false}
                        help={error && error.email ? error.email[0] : false}
                      >
                        <Input
                          value={data.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="NPWP"
                        validateStatus={
                          error && error.tax_registration_number
                            ? "error"
                            : false
                        }
                        help={
                          error && error.tax_registration_number
                            ? error.tax_registration_number[0]
                            : false
                        }
                      >
                        <Input
                          value={data.tax_registration_number}
                          onChange={(e) =>
                            handleChange(
                              "tax_registration_number",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Address"
                        validateStatus={
                          error && error.address ? "error" : false
                        }
                        help={error && error.address ? error.address[0] : false}
                      >
                        <TextArea
                          value={data.address}
                          onChange={(e) =>
                            handleChange("address", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Telephone"
                        validateStatus={
                          error && error.telephone ? "error" : false
                        }
                        help={
                          error && error.telephone ? error.telephone[0] : false
                        }
                      >
                        <Input
                          value={data.telephone}
                          onChange={(e) =>
                            handleChange("telephone", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Term of Payment"
                        validateStatus={
                          error && error.term_of_payment ? "error" : false
                        }
                        help={
                          error && error.term_of_payment
                            ? error.term_of_payment[0]
                            : false
                        }
                      >
                        <Input
                          value={data.term_of_payment}
                          onChange={(e) =>
                            handleChange("term_of_payment", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Subject to With Tax"
                        validateStatus={
                          error && error.subject_to_with_tax ? "error" : false
                        }
                        help={
                          error && error.subject_to_with_tax
                            ? error.subject_to_with_tax[0]
                            : false
                        }
                      >
                        <Input
                          value={data.subject_to_with_tax}
                          onChange={(e) =>
                            handleChange("subject_to_with_tax", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Withholding Tax Type"
                        validateStatus={
                          error && error.withholding_tax_type ? "error" : false
                        }
                        help={
                          error && error.withholding_tax_type
                            ? error.withholding_tax_type[0]
                            : false
                        }
                      >
                        <Input
                          value={data.withholding_tax_type}
                          onChange={(e) =>
                            handleChange("withholding_tax_type", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Withholding Tax Code"
                        validateStatus={
                          error && error.withholding_tax_code ? "error" : false
                        }
                        help={
                          error && error.withholding_tax_code
                            ? error.withholding_tax_code[0]
                            : false
                        }
                      >
                        <Input
                          value={data.withholding_tax_code}
                          onChange={(e) =>
                            handleChange("withholding_tax_code", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Desc With Tax Code"
                        validateStatus={
                          error && error.desc_with_tax_code ? "error" : false
                        }
                        help={
                          error && error.desc_with_tax_code
                            ? error.desc_with_tax_code[0]
                            : false
                        }
                      >
                        <Input
                          value={data.desc_with_tax_code}
                          onChange={(e) =>
                            handleChange("desc_with_tax_code", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Vendor Type"
                        validateStatus={
                          error && error.vendor_type ? "error" : false
                        }
                        help={
                          error && error.vendor_type
                            ? error.vendor_type[0]
                            : false
                        }
                      >
                        <Input
                          value={data.vendor_type}
                          onChange={(e) =>
                            handleChange("vendor_type", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="PPh23 (%)"
                        validateStatus={error && error.pph23 ? "error" : false}
                        help={error && error.pph23 ? error.pph23[0] : false}
                      >
                        {/* <Checkbox
                          checked={data.pph23}
                          onChange={(e) =>
                            handleChange("pph23", e.target.checked)
                          }
                        >
                          Yes
                        </Checkbox> */}
                        <Input
                          value={data.pph23}
                          onChange={(e) =>
                            handleChange("pph23", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="GL Account"
                        validateStatus={
                          error && error.gl_account ? "error" : false
                        }
                        help={
                          error && error.gl_account
                            ? error.gl_account[0]
                            : false
                        }
                      >
                        <Input
                          value={data.gl_account}
                          onChange={(e) =>
                            handleChange("gl_account", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="PST Key Sap"
                        validateStatus={
                          error && error.pst_key_sap ? "error" : false
                        }
                        help={
                          error && error.pst_key_sap
                            ? error.pst_key_sap[0]
                            : false
                        }
                      >
                        <Input
                          disabled
                          value={data.pst_key_sap}
                          onChange={(e) =>
                            handleChange("pst_key_sap", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Journal"
                        validateStatus={
                          error && error.journal ? "error" : false
                        }
                        help={error && error.journal ? error.journal[0] : false}
                      >
                        <Input
                          disabled
                          value={data.journal}
                          onChange={(e) =>
                            handleChange("journal", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Special GL"
                        validateStatus={
                          error && error.special_gl ? "error" : false
                        }
                        help={
                          error && error.special_gl
                            ? error.special_gl[0]
                            : false
                        }
                      >
                        <Input
                          value={data.special_gl}
                          onChange={(e) =>
                            handleChange("special_gl", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Description"
                        validateStatus={
                          error && error.description ? "error" : false
                        }
                        help={
                          error && error.description
                            ? error.description[0]
                            : false
                        }
                      >
                        <Input
                          value={data.description}
                          onChange={(e) =>
                            handleChange("description", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Recount Account"
                        validateStatus={
                          error && error.recount_account ? "error" : false
                        }
                        help={
                          error && error.recount_account
                            ? error.recount_account[0]
                            : false
                        }
                      >
                        <Input
                          disabled
                          value={data.recount_account}
                          onChange={(e) =>
                            handleChange("recount_account", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Recount Account Description"
                        validateStatus={
                          error && error.recount_account_description
                            ? "error"
                            : false
                        }
                        help={
                          error && error.recount_account_description
                            ? error.recount_account_description[0]
                            : false
                        }
                      >
                        <Input
                          disabled
                          value={data.recount_account_description}
                          onChange={(e) =>
                            handleChange(
                              "recount_account_description",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Row type="flex" gutter={[10, 20]} justify="space-around">
                      <Col>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          className="mr-button"
                        >
                          Update
                        </Button>
                        <Button htmlType="reset" onClick={handleReset}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Fragment>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditVendor;
