import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Tag,
  Typography,
  DatePicker, Tooltip, Comment, Badge, Form, Avatar
} from "antd";
import _ from "underscore";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import fileDownload from "js-file-download";
import TextArea from "antd/lib/input/TextArea";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;

function PenambahanBudget() {
  const [type, setType] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [penambahanBudgets, setPenambahanBudgets] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const [count, setCount] = useState({
    all: "",
    pending: "",
    approve: "",
  });

  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [penambahanBudgetDetails, setPenambahanBudgetDetails] = useState([]);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [penambahanBudgetDetailsTotal, setPenambahanBudgetDetailsTotal] =
    useState(0);
  const [listBudget, setListBudget] = useState([]);
  const [newListBudget, setNewListBudget] = useState([]);
  const [commentTemp, setCommentTemp] = useState({
    tempDataComment: []
  });
  const [visible, setVisible] = useState(false);
  const [commentList, setCommentList] = useState({});

  const dateFormat = "DD MMM YYYY";
  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });
  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();

    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, [keyword, page, sort, date]);

  const handleChangeDate = (value) => {
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/penambahan-budget`, {
      params: {
        keyword,
        columns: [
          "ref_code",
          "reminder_name",
          "name",
          "npk",
          "internal_order",
          "grand_total",
          "total_penambahan",
          "payment_status",
        ],
        start_date: date.from ? date.from.format("YYYY-MM-DD") : "",
        end_date: date.to ? date.to.format("YYYY-MM-DD") : "",
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPenambahanBudgets(res.data);
        setListBudget(
          res.data.data.list_budget_details.filter((x) => {
            return {
              id: x._id,
              ...x,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log(listBudget);

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getBiayaPenambahanBudgetDetails(data2[0]);
    }
  };

  const getBiayaPenambahanBudgetDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/penambahan-budget/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["id_material", "material_description"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPenambahanBudgetDetails(res.data.data);
        setPenambahanBudgetDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/penambahan-budget/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const exportData = () => {
    Axios.get(`${url}/penambahan-budget/download`, {
      params: {
        keyword,
        columns: [
          "ref_code",
          "reminder_name",
          "name",
          "npk",
          "internal_order",
          "grand_total",
          "total_penambahan",
          "payment_status",
        ],
        start_date: date.from ? date.from.format("YYYY-MM-DD") : "",
        end_date: date.to ? date.to.format("YYYY-MM-DD") : "",
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      console.log(res);
      fileDownload(res.data, "Export-List-Add-Budget" + ".xlsx");
    });
  };

  const closeComment = () => {
    setVisible(false);
  };

  const handleChangeComment = (name, value) => {
    setCommentTemp({
        ...commentTemp,
        [name]: value,
        last_comment: sessionStorage.getItem("npk")
    })
  }

  const submitComment = () => {
    setLoading(true);

    Axios.post(
      `${url}/send-comment`,
      {
        doc_id: commentTemp.doc_id,
        doc_number: commentTemp.doc_number,
        doc_name: commentTemp.doc_name,
        doc_type: commentTemp.doc_type,
        comment_description: commentTemp.comment_description,
        last_comment: commentTemp.last_comment
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setCommentTemp({
        ...commentTemp,
        comment_description: '',
        last_comment: ''
      })
      
      setCommentList(res.data.data);
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /*  Open Data Comment Transaksi */
  const openDataComment = async (id, data) => {
    setCommentTemp({
      ...commentTemp,
      doc_number: data.ref_code,
      doc_name: data.reminder_name,
      doc_type: data.reminder_status,      
      doc_id: id,
      DocNumber: data.ref_code,
      CreatedDate: data.created_at
    })
    setLoading(true);

    await Axios.get(
      `${url}/show-comment`, {
        params: {
          doc_id: id,
          doc_number: data.ref_code,
          doc_name: data.reminder_name,
          doc_type: data.reminder_status,
          comment_description: data.comment_description,
          last_comment: data.last_comment
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    })
    .then((res) => {
      setCommentList(res.data.data)
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
      setVisible(true);

    });
  };

  const columns = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      width: 80,
      align: "center",
      render: (created_at) => moment(created_at).format("DD-MM-YYYY"),
    },
    {
      title: "REF CODE NUMBER",
      dataIndex: "ref_code",
      sorter: false,
      width: 100,
      align: "center",
      render: (id, data) =>
        data.status === 0 ? (
          sessionStorage.getItem("npk") === `${data.npk}` ? (
            <Link style={{ fontWeight: 600 }} to={`/penambahan-budget/${id}`}>
              {id}
            </Link>
          ) : (
            id
          )
        ) : (
          id
        ),
    },
    {
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "name",
      width: 250,
      align: "left",
      sorter: false,
    },
    {
      title: "DIVISION",
      dataIndex: "divisi",
      width: 200,
      align: "center",
      sorter: false,
      render: (divisi) => {
        return _.pluck(divisi, "name").join(", ");
      },
    },
    // {
    //   title: "Internal Order",
    //   dataIndex: "internal_order",
    //   key: "internal_order",
    //   width: 130,
    //   align: "center",
    //   sorter: false,
    //   render: (internal_order) => {
    //     return internal_order.substring(2);
    //   },
    // },
    // {
    //   title: "Cost Center",
    //   dataIndex: "cost_center",
    //   width: 130,
    //   align: "center",
    //   sorter: false,
    // },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      width: 250,
      align: "left",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "grand_total",
      width: 130,
      align: "right",
      sorter: false,
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total ? grand_total : 0} type="text" />;
      },
    },
    // {
    //   title: <div style={{ textAlign: "center" }}>Total Additions</div>,
    //   dataIndex: "total_penambahan",
    //   width: 130,
    //   align: "right",
    //   sorter: false,
    //   render: (total_penambahan) => {
    //     return <AntNumberFormat value={total_penambahan} type="text" />;
    //   },
    // },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width:  sessionStorage.getItem('role_name') === "Staff" ? 100 :
              sessionStorage.getItem('role_name') === "Admin" ? 120 : 100,
      render: (id, data) => {
        return (
          <div>
            <Tooltip placement="top" title="Edit">
              <span style={{marginRight: 10, cursor: data.status === 0 ? 'not-allowed' : 'pointer'}} >
                <Link
                  style={{ fontWeight: 600, cursor: data.status === 0 ? 'pointer' : 'not-allowed' }}
                  className={sessionStorage.getItem("npk")}
                  dataType={data.npk}
                  to={
                    data.status === 0 ? `/penambahan-budget/${data.ref_code}` : true}
                >
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/writing.png")
                    }
                    style={{
                      cursor: data.status === 0 ? 'pointer' : 'not-allowed'
                    }}
                  />
                </Link>
              </span>
            </Tooltip>
            
            {/* <Tooltip placement="top" title="Delete Document">
              <span style={{marginRight: 10}}
                size="small"
                type="default"
                onClick={() =>
                  data.status === 0 ? handleDelete(id) : true
                }
              >
                <img
                  alt="icon"
                  width="20"
                  src={
                    require("../../assets/img/danger.png")
                  }
                  style={{
                    cursor: data.status === 0 ? 'pointer' : 'not-allowed'
                  }}
                />
              </span>
            </Tooltip> */}
            
            <Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Direktur" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>
            

          </div>
        );
      }
    },
    {
      title: "Approval Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      key: "status",
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
                status === 1 || status === 2 || status === 3 || status === 4 
                ? require("../../assets/img/sand-clock.png")
                : status >= 5
                ? require("../../assets/img/checked.png")
                : status === 0
                ? require("../../assets/img/warning.png")
                : status === -1
                ? require("../../assets/img/danger.png")
                : status === -2
                ? require("../../assets/img/warning.png")
                : require("../../assets/img/sand-clock.png")
              
            }
          />
        );
      },
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   // fixed: "right",
    //   width: 50,
    //   render: (id, data) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             sessionStorage.getItem("npk") === data.npk ? (
    //               <Menu>
    //                 {buttonAccess &&
    //                   buttonAccess.find(
    //                     (x) => x.url === "penambahan-budget/edit"
    //                   ) && (
    //                     <Menu.Item key="2">
    //                       <span>
    //                         <Link
    //                           disabled={data.status !== 0}
    //                           to={`/penambahan-budget/${data.ref_code}`}
    //                         >
    //                           <EditOutlined /> Edit
    //                         </Link>
    //                       </span>
    //                     </Menu.Item>
    //                   )}
    //                 {buttonAccess &&
    //                   buttonAccess.find(
    //                     (x) => x.url === "penambahan-budget/delete"
    //                   ) && (
    //                     <Menu.Item key="3">
    //                       <span onClick={() => handleDelete(id)}>
    //                         <DeleteOutlined />
    //                         Delete
    //                       </span>
    //                     </Menu.Item>
    //                   )}
    //               </Menu>
    //             ) : (
    //               <Menu>
    //                 <Menu.Item key="2" disabled="true">
    //                   <EditOutlined /> Edit
    //                 </Menu.Item>
    //                 <Menu.Item key="3" disabled="true">
    //                   <span>
    //                     <DeleteOutlined />
    //                     Delete
    //                   </span>
    //                 </Menu.Item>
    //               </Menu>
    //             )
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const handleChangeListBudgetDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getDataListBudget(data2[0]);
    }
  };
  
  const getDataListBudget = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/penambahan-budget/details/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setNewListBudget(
        res.data.data.new_list_budget_details ?
          res.data.data.new_list_budget_details :
          res.data.data.list_budget_details.filter((x) => x.statusBudget === 'X'));
    })
    .catch((err) => {
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
        }
      }
    })
    .finally(() => {
      setLoadingDetail(false);
    });
  };

  const getDetails = () => {
    const columns = [
      {
        title: "INTERNAL ORDER",
        dataIndex: "internal_order_number",
        align: "center",
        key: "internal_order_number",
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
        dataIndex: "available_budget",
        align: "right",
        render: (available_budget) => {
          return <AntNumberFormat value={available_budget} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL AMOUNT</div>,
        dataIndex: "total_amount",
        align: "right",
        render: (total_amount) => {
          return <AntNumberFormat value={total_amount} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL ADD BUDGET</div>,
        dataIndex: "total_add",
        align: "right",
        render: (total_add) => {
          return <AntNumberFormat value={total_add} type="text" />;
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              columns={columns}
              loading={loadingDetail}
              dataSource={newListBudget}
              size="small"
              // pagination={{
              //   pageSize: perPageDetail,
              //   current: pageDetail,
              //   total: pengajuanBiayaRupaDetailsTotal,
              //   showSizeChanger: true,
              //   style: {
              //     marginTop: "2rem",
              //     marginBottom: 0,
              //   },
              // }}
              // onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Add Budget"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={6} lg={6}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "penambahan-budget/export"
                      ) && (
                        <Button type="primary" onClick={exportData}>
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={12} className="align-right">
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      onChange={handleChangeDate}
                      value={[date.from, date.to]}
                      format="DD MMM YYYY"
                      picker="date"
                    />
                  </Space>
                </Col>
                <Col xs={24} md={8} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    size="small"
                    scroll={{ x: 1500 }}
                    columns={columns}
                    dataSource={penambahanBudgets.data}
                    expandable={{
                      expandedRowRender: getDetails,
                      onExpandedRowsChange: (data) => handleChangeListBudgetDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: penambahanBudgets.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* MODAL COMMENT LIST */}
      <Modal
        className="modal-comment-list"
        visible={visible}
        title={`${commentTemp.DocNumber} - ${commentTemp.CreatedDate} `}
        onOk={submitComment}
        onCancel={closeComment}
        footer={[
          <Form.Item>
            <TextArea
              rows={4}
              value={commentTemp.comment_description}
              onChange={(e) => handleChangeComment("comment_description", e.target.value)}
            ></TextArea>
          </Form.Item>,
          <Button key="back" onClick={closeComment} >
            Close
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={submitComment} >
            Send
          </Button>
        ]}
      >
        { commentList ?
            commentList.comment_list && 
              commentList.comment_list.map((comment) => {
              return (
                <Comment
                  className={`${comment.username === sessionStorage.getItem('username') ? 'comment-right' : ''}`}
                  author={<span className="comment-title">{comment.full_name}</span>}
                  avatar={<Avatar className="comment-avatar" src={comment.avatar} alt={`${comment.full_name}`} />}
                  content={
                    <p>
                      {comment.comment_description}
                    </p>
                  }
                  datetime={
                    <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{comment.comment_date}</span>
                    </Tooltip>
                  }
                />
              )
            })
          : <div id="comment-new-list">{commentTemp.tempDataComment}</div>}
      </Modal>
      {/* END COMMENT LIST */}

    </Fragment>
  );
}

export default PenambahanBudget;
