import React, { Fragment, useState, useEffect } from "react";
import { Layout, Row, Col, Card, Breadcrumb, List, message } from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";

const { Content } = Layout;

function ViewVendor(props) {
  const [data, setData] = useState({
    email: "",
    vendor_name: "",
    tax_registration_number: "",
    address: "",
  });

  useEffect(() => {
    getVendor();
  }, []);

  const getVendor = () => {
    Axios.get(`${url}/vendor/${props.match.params.vendor_code}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          email: res.data.data.email,
          vendor_code: res.data.data.vendor_code,
          vendor_name: res.data.data.vendor_name,
          vendor_type: res.data.data.vendor_type,
          telephone: res.data.data.telephone,
          search_term: res.data.data.search_term,
          company_code: res.data.data.company_code,
          tax_registration_number: res.data.data.tax_registration_number,
          address: res.data.data.address,
          pph23: res.data.data.pph23,
          withholding_tax_code: res.data.data.withholding_tax_code,
          withholding_tax_type: res.data.data.withholding_tax_type,
          desc_with_tax_code: res.data.data.desc_with_tax_code,
          gl_account: res.data.data.gl_account,
          journal: res.data.data.journal,
          pst_key_sap: res.data.data.pst_key_sap,
          special_gl: res.data.data.special_gl,
          description: res.data.data.description,
          recount_account: res.data.data.recount_account,
          recount_account_description:
            res.data.data.recount_account_description,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const dataview = [
    {
      title: "Email",
      description: data && data.email,
    },
    {
      title: "Vendor Code",
      description: data && data.vendor_code,
    },
    {
      title: "Vendor Name",
      description: data && data.vendor_name,
    },
    {
      title: "Vendor Type",
      description: data && data.vendor_type,
    },
    {
      title: "Company Code",
      description: data && data.company_code,
    },
    {
      title: "Search Term",
      description: data && data.search_term,
    },
    {
      title: "Tax Registration Number",
      description: data && data.tax_registration_number,
    },
    {
      title: "Telephone",
      description: data && data.telephone,
    },
    {
      title: "PPh23 (%)",
      description: data && data.pph23,
    },
    {
      title: "Address",
      description: data && data.address,
    },
  ];

  const dataview2 = [
    {
      title: "Withholding Tax Code",
      description: data && data.withholding_tax_code,
    },
    {
      title: "Withholding Tax Type",
      description: data && data.withholding_tax_type,
    },
    {
      title: "Desc With Tax Code",
      description: data && data.desc_with_tax_code,
    },
    {
      title: "GL Account",
      description: data && data.gl_account,
    },
    {
      title: "Journal",
      description: data && data.journal,
    },
    {
      title: "PST Key SAP",
      description: data && data.pst_key_sap,
    },
    {
      title: "Special GL",
      description: data && data.special_gl,
    },
    {
      title: "Description",
      description: data && data.description,
    },
    {
      title: "Recount Account",
      description: data && data.recount_account,
    },
    {
      title: "Recount Account Desc",
      description: data && data.recount_account_description,
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            size="small"
            //type="inner"
            //title="View Vendor"
            //extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/vendor">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{data.vendor_name}</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={40} className="pr-25 pl-25 mb-25">
                <Col sm={24} md={12}>
                  <List
                    itemLayout="horizontal"
                    dataSource={dataview}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<strong>{item.title}</strong>}
                          description={
                            item.description ? item.description : "-"
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <Col sm={24} md={12}>
                  <List
                    itemLayout="horizontal"
                    dataSource={dataview2}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<strong>{item.title}</strong>}
                          description={
                            item.description ? item.description : "-"
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ViewVendor;
