import React, { useState } from "react";
import { Input, Tabs, Button, message, Form, Space } from "antd";
import { useLocation } from "react-router-dom";
import {
  LockOutlined,
  MailOutlined,
  HourglassOutlined,
  UserOutlined,
  BankOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { url } from "../../config/global";

const { TabPane } = Tabs;

function Register(props) {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const email = query.get("email");
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    email,
    token,
    password: "",
    password_confirmation: "",
  });

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/reset-password`,
      {
        email: data.email,
        token: data.token,
        password: data.password,
        password_confirmation: data.password_confirmation,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/login");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTab = (key) => {
    setTab(key);
  };

  return (
    <div className="main">
      <div>
        <img
          src={require("../../assets/img/logo-dharma-polimetal.png")}
          className="img-logo"
          alt="DEM"
        />
        <Tabs activeKey={tab} onChange={handleTab}>
          <TabPane tab="Register" key="1">
            <Form onFinish={onSaveData}>
              <Form.Item
                validateStatus={error && error.name ? "error" : false}
                help={error && error.name ? error.name[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<UserOutlined />}
                  placeholder="Name"
                  onChange={(e) => handleChange("name", e.target.value)}
                  value={data.name}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.npk ? "error" : false}
                help={error && error.npk ? error.npk[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<UserOutlined />}
                  placeholder="NPK"
                  onChange={(e) => handleChange("npk", e.target.value)}
                  value={data.npk}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.division ? "error" : false}
                help={error && error.division ? error.division[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<BankOutlined />}
                  placeholder="Division"
                  onChange={(e) => handleChange("division", e.target.value)}
                  value={data.division}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.dept ? "error" : false}
                help={error && error.dept ? error.dept[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<GroupOutlined />}
                  placeholder="Department"
                  onChange={(e) => handleChange("dept", e.target.value)}
                  value={data.dept}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.password ? "error" : false}
                help={error && error.password ? error.password[0] : false}
              >
                <Input.Password
                  className="login-input"
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  onChange={(e) => handleChange("password", e.target.value)}
                  value={data.password}
                />
              </Form.Item>
              <Form.Item
                validateStatus={
                  error && error.password_confirmation ? "error" : false
                }
                help={
                  error && error.password_confirmation
                    ? error.password_confirmation[0]
                    : false
                }
              >
                <Input.Password
                  className="login-input"
                  prefix={<LockOutlined />}
                  placeholder="Retype Password"
                  onChange={(e) =>
                    handleChange("password_confirmation", e.target.value)
                  }
                  value={data.password_confirmation}
                />
              </Form.Item>

              <div>
                <Space>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    type="primary"
                    className="button-login"
                  >
                    Register
                  </Button>
                </Space>
              </div>
            </Form>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Register;
