import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Modal,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { CaretRightOutlined, EditOutlined } from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function EditPenyelesaianUangMukaEntertainment(props) {
  const [data, setData] = useState({
    pengajuan_uang_muka_entertainment_code: "",
    nama: "",
    npk: "",
    transfer_to: "",
    nama_bank: "",
    no_rekening: "",
    internal_order: "",
    cost_center: "",
  });
  const [keywordDetails, setKeywordDetails] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [sortDetail, setSortDetail] = useState({
    sort: "item_number_gr",
    order: "ascend",
  });

  const [codes, setCodes] = useState([]);
  const [codes_account, setCodesAccount] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [penyelesaianUangMukaDetails, setPenyelesaianUangMukaDetails] =
    useState([]);

  const [
    penyelesaianUangMukaDetailsTotal,
    setPenyelesaianUangMukaDetailsTotal,
  ] = useState(0);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(
      `${url}/penyelesaian-uang-muka-entertainment/${props.match.params.id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        setData({
          ...data,
          entertainment_id: res.data.data.entertainment_id,
          entertainment_code: res.data.data.entertainment_code,
          penyelesaian_uang_muka_entertainment_code:
            res.data.data.penyelesaian_uang_muka_entertainment_code,
          user_id: res.data.data.user_id,
          nama: res.data.data.nama,
          npk: res.data.data.npk,
          internal_order_id: res.data.data.internal_order_id,
          internal_order_number: res.data.data.internal_order_number,
          internal_order_description: res.data.data.internal_order_description,
          cost_center_id: res.data.data.cost_center_id,
          cost_center_number: res.data.data.cost_center_number,
          transfer_to: res.data.data.transfer_to,
          nama_bank: res.data.data.nama_bank,
          no_rekening: res.data.data.no_rekening,
          text: res.data.data.text,
          quantity: res.data.data.quantity,
          grand_total: res.data.data.grand_total,
          uang_muka: res.data.data.uang_muka,
          kurang_lebih: res.data.data.kurang_lebih,
        });
        setPenyelesaianUangMukaDetails(
          res.data.data.details.map((detail) => {
            return {
              id: detail._id,
              ...detail,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelectCodeAccount = (name, value) => {
    if (value) {
      const findControlItem = codes_account.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`_id`]: value.value,
          description: findControlItem.description,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`_id`]: null,
      });
    }
  };

  const getCode = async () => {
    // Axios.get(match.params.id
    //     ? `${url}/penyelesaian-uang-muka-perjalanan-dinas`
    //     :  `${url}/penyelesaian-uang-muka-perjalanan-dinas/${match.params.id} `, {
    await Axios.get(`${url}/entertainment/list-approve`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getDetails = (id) => {
    setLoadingDetail(true);
    Axios.get(`${url}/entertainment/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPenyelesaianUangMukaDetails(res.data.data.details);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChangeSelectCode = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          nama: findControlItem.nama,
          npk: findControlItem.npk,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          internal_order: findControlItem.internal_order,
          cost_center: findControlItem.cost_center,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      props.match.params.id
        ? `${url}/penyelesaian-uang-muka-entertainment/${props.match.params.id}`
        : `${url}/penyelesaian-uang-muka-entertainment`,
      {
        user_id: data.user_id,
        nama: data.nama,
        npk: data.npk,
        entertainment_id: data.entertainment_id,
        entertainment_code: data.entertainment_code,
        internal_order_id: data.internal_order_id,
        internal_order_number: data.internal_order_number,
        internal_order_description: data.internal_order_description,
        cost_center_id: data.cost_center_id,
        cost_center_number: data.cost_center_number,
        transfer_to: data.transfer_to,
        nama_bank: data.nama_bank,
        no_rekening: data.no_rekening,
        quantity: data.quantity,
        grand_total: data.grand_total,
        uang_muka: data.uang_muka,
        kurang_lebih: data.kurang_lebih,
        // nominal_kurs: data.nominal_kurs,
        //vendor_code: data.vendor_code,
        details: penyelesaianUangMukaDetails,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/penyelesaian-uang-muka-entertainment");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifyPenyelesaianEntertainment = [...penyelesaianUangMukaDetails];
      const findPenyelesaianEntertainmentDetails =
        modifyPenyelesaianEntertainment.find((x) => x._id === selectedId);
      findPenyelesaianEntertainmentDetails.gl_code = data.gl_code;

      findPenyelesaianEntertainmentDetails.description = data.description;
      findPenyelesaianEntertainmentDetails.quantity = data.quantity;
      findPenyelesaianEntertainmentDetails.harga_satuan = data.harga_satuan;
      findPenyelesaianEntertainmentDetails.jumlah =
        data.quantity * data.harga_satuan;
      findPenyelesaianEntertainmentDetails.ppn_persen = data.ppn_persen;
      findPenyelesaianEntertainmentDetails.ppn =
        data.quantity * data.harga_satuan * (data.ppn_persen / 100);
      findPenyelesaianEntertainmentDetails.pph21 = "0";
      findPenyelesaianEntertainmentDetails.total =
        data.quantity * data.harga_satuan +
        data.quantity * data.harga_satuan * (data.ppn_persen / 100);

      setPenyelesaianUangMukaDetails(modifyPenyelesaianEntertainment);
    } else {
      setPenyelesaianUangMukaDetails([
        ...penyelesaianUangMukaDetails,
        {
          //description: data.description,
          //tanggal: moment(data.tanggal).format("YYYY-MM-DD"),
          _id: data._id,
          gl_code: data.gl_code,
          description: data.description,
          quantity: data.quantity,
          harga_satuan: data.harga_satuan,
          jumlah: data.quantity * data.harga_satuan,
          ppn_persen: data.ppn_persen,
          ppn: data.quantity * data.harga_satuan * (data.ppn_persen / 100),
          // pph21: data.pph21,
          pph21: "0",
          total:
            data.quantity * data.harga_satuan +
            data.quantity * data.harga_satuan * (data.ppn_persen / 100),
        },
      ]);
    }

    setSelectedId("");

    setData({
      ...data,
      gl_code: null,
      _id: null,
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: "",
      ppn: "",
      pph21: "",
      total: "",
    });

    setIsModalVisible(false);
    setIsModalVisibleEdit(false);
  };

  const handleEditDetails = (id) => {
    const details = penyelesaianUangMukaDetails.find(
      (penyelesaianUangMukaDetails) => penyelesaianUangMukaDetails._id === id
    );

    setSelectedId(id);

    setData({
      ...data,
      gl_code: details.gl_code,
      description: details.description,
      quantity: details.quantity,
      jumlah: details.jumlah,
      ppn: details.ppn,
      pph21: details.pph,
      total: details.total,
    });

    setIsModalVisible(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      gl_code: null,
      _id: null,
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: "",
      ppn: "",
      pph21: "",
      total: "",
    });
    setSelectedId("");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const resetModal = () => {
    setData({
      ...data,
      gl_code: null,
      _id: null,
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: "",
      ppn: "",
      pph21: "",
      total: "",
    });
  };

  const handleReset = () => {
    setData({
      vendor_code: "",
    });
  };

  const handleRemoveDetails = (id) => {
    const details = penyelesaianUangMukaDetails.filter(
      (penyelesaianUangMukaDetails) => penyelesaianUangMukaDetails._id !== id
    );
    setPenyelesaianUangMukaDetails(details);
  };

  const getInternalOrder = async (keyword) => {
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          internal_orders: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getCodeAccount = async () => {
    await Axios.get(`${url}/gl-account`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodesAccount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_number`]: value.label,
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const columns = [
    {
      title: "Code",
      dataIndex: "gl_code",
      align: "center",
    },
    {
      title: <div style={{ textAlign: "center" }}>Description</div>,
      dataIndex: "description",
      align: "left",
    },
    {
      title: <div style={{ textAlign: "center" }}>Amount (Rp)</div>,
      dataIndex: "jumlah",
      render: (jumlah) => {
        return <AntNumberFormat value={jumlah} type="text" />;
      },
      align: "right",
    },

    {
      title: <div style={{ textAlign: "center" }}>Total</div>,
      dataIndex: "jumlah",
      align: "right",
      align: "center",
    },
    {
      // title: "Option",
      dataIndex: "option",
      render: (option, id) => (
        <Button
          onClick={() => handleRemoveDetails(id)}
          size="small"
          type="danger"
        >
          {/* <DeleteOutlined /> */}
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/penyelesaian-uang-muka-entertainment">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Advance settlement Update</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.ref_doc_no ? "error" : false
                              }
                              help={
                                error && error.ref_doc_no
                                  ? error.ref_doc_no[0]
                                  : false
                              }
                              label="Ref Doc Number"
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectCode("entertainment", value)
                                }
                                onFocus={() => getCode("")}
                                showSearch
                                onSearch={(value) => getCode(value)}
                                filterOption={false}
                                value={{
                                  value: data.entertainment_id,
                                  label: data.entertainment_code,
                                }}
                              >
                                {codes &&
                                  codes.map((code) => {
                                    return (
                                      <Option value={code._id} key={code._id}>
                                        {`${code.entertainment_code} - ${code.internal_order_description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Employee Name"
                            >
                              <Input
                                disabled
                                value={data.nama}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={data.npk}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Input
                                disabled
                                value={data.transfer_to}
                                onChange={(e) => {
                                  handleChange("transfer_to", e.target.value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.nama_bank ? "error" : false
                              }
                              help={
                                error && error.nama_bank
                                  ? error.nama_bank[0]
                                  : false
                              }
                              label="Bank Name"
                            >
                              <Input
                                disabled
                                // style={{ width: 280 }}
                                // format="DD MMMM YYYY"
                                value={data.nama_bank}
                                onChange={(value) =>
                                  handleChange("nama_bank", value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.no_rekening ? "error" : false
                              }
                              help={
                                error && error.no_rekening
                                  ? error.no_rekening[0]
                                  : false
                              }
                              label=" Account Number"
                            >
                              <Input
                                disabled
                                // style={{ width: 280 }}
                                value={data.no_rekening}
                                onChange={(value) =>
                                  handleChange("no_rekening", value)
                                }
                              />
                            </Form.Item>

                            <Form.Item label="Internal Order">
                              <Select
                                disabled
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("internal_order", value)
                                }
                                onFocus={() => getInternalOrder("")}
                                showSearch
                                onSearch={(value) => getInternalOrder(value)}
                                filterOption={false}
                                value={{
                                  key: data.internal_order_id,
                                  label: data.internal_order_number,
                                }}
                              >
                                {data.internal_orders &&
                                  data.internal_orders.map((internal_order) => {
                                    return (
                                      <Option
                                        value={internal_order._id}
                                        key={internal_order._id}
                                      >
                                        {`${internal_order.internal_order_number}-${internal_order.description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.cost_center ? "error" : false
                              }
                              help={
                                error && error.cost_center
                                  ? error.cost_center[0]
                                  : false
                              }
                              label="Cost Center"
                            >
                              <Select
                                disabled
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("cost_center", value)
                                }
                                onFocus={() => getCostCenter("")}
                                showSearch
                                onSearch={(value) => getCostCenter(value)}
                                filterOption={false}
                                value={{
                                  key: data.cost_center_id,
                                  label: data.cost_center_number,
                                }}
                              >
                                {data.cost_centers &&
                                  data.cost_centers.map((cost_center) => {
                                    return (
                                      <Option
                                        value={cost_center._id}
                                        key={cost_center._id}
                                      >
                                        {`${cost_center.cost_center_number}-${cost_center.description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Modal
                      title={selectedId ? "Edit Details" : "Add Details"}
                      style={{ top: 20 }}
                      visible={isModalVisible}
                      width={1000}
                      footer={null}
                      onCancel={closeModal}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Form {...layout} name="control-hooks">
                              <Row>
                                <Fragment>
                                  <Col xs={24}>
                                    <Form.Item
                                      validateStatus={
                                        error && error.gl_code ? "error" : false
                                      }
                                      help={
                                        error && error.gl_code
                                          ? error.gl_code[0]
                                          : false
                                      }
                                      label="Activity Code"
                                    >
                                      <Select
                                        labelInValue
                                        onChange={(value) =>
                                          handleChangeSelectCodeAccount(
                                            "gl",
                                            value
                                          )
                                        }
                                        onFocus={() => getCodeAccount("")}
                                        showSearch
                                        onSearch={(value) =>
                                          getCodeAccount(value)
                                        }
                                        filterOption={false}
                                        value={{
                                          value: data._id,
                                          label: data.gl_code,
                                        }}
                                      >
                                        {codes_account &&
                                          codes_account.map((code_account) => {
                                            return (
                                              <Option
                                                value={code_account._id}
                                                key={code_account._id}
                                              >
                                                {code_account.code}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>

                                    <Form.Item
                                      validateStatus={
                                        error && error.description
                                          ? "error"
                                          : false
                                      }
                                      help={
                                        error && error.description
                                          ? error.description[0]
                                          : false
                                      }
                                      label="Description"
                                    >
                                      <Input
                                        //maxLength={16}
                                        disabled
                                        value={data.description}
                                        // onKeyPress={(e) => {
                                        //   checkReceipt(e.target.value);
                                        // }}
                                        onChange={(e) => {
                                          handleChange(
                                            "description",
                                            e.target.value
                                          );
                                          //checkReceipt(e.target.value);
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      validateStatus={
                                        error && error.jumlah ? "error" : false
                                      }
                                      help={
                                        error && error.jumlah
                                          ? error.jumlah[0]
                                          : false
                                      }
                                      label="Amount"
                                    >
                                      <AntNumberFormat
                                        //defaultValue="0"
                                        value={data.jumlah}
                                        type="input"
                                        onChange={(e) => {
                                          handleChange("jumlah", e);
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      validateStatus={
                                        error && error.ppn_persen
                                          ? "error"
                                          : false
                                      }
                                      help={
                                        error && error.ppn_persen
                                          ? error.ppn_persen[0]
                                          : false
                                      }
                                      label="PPn (%)"
                                    >
                                      <AntNumberFormat
                                        //defaultValue="0"
                                        value={data.ppn_persen}
                                        type="input"
                                        onChange={(e) => {
                                          handleChange("ppn_persen", e);
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      validateStatus={
                                        error && error.ppn ? "error" : false
                                      }
                                      help={
                                        error && error.ppn
                                          ? error.ppn[0]
                                          : false
                                      }
                                      label="PPn"
                                    >
                                      <AntNumberFormat
                                        //defaultValue="0"
                                        disabled
                                        value={
                                          data.quantity *
                                          data.harga_satuan *
                                          (data.ppn_persen / 100)
                                        }
                                        type="input"
                                        onChange={(e) => {
                                          handleChange("ppn", e);
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      validateStatus={
                                        error && error.total ? "error" : false
                                      }
                                      help={
                                        error && error.total
                                          ? error.total[0]
                                          : false
                                      }
                                      label="Total"
                                    >
                                      <AntNumberFormat
                                        //defaultValue="0"
                                        disabled
                                        value={
                                          data.quantity * data.harga_satuan +
                                          data.quantity *
                                            data.harga_satuan *
                                            0.1
                                        }
                                        type="input"
                                        onChange={(e) => {
                                          handleChange("total", e);
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Fragment>
                              </Row>
                            </Form>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSaveTemporaryData}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                              }}
                            >
                              Submit
                            </Button>
                            <Button htmlType="reset" onClick={resetModal}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>
                    <Table
                      columns={columns}
                      size="small"
                      rowKey={(data) => data._id}
                      // onChange={handleTableChange}
                      dataSource={penyelesaianUangMukaDetails}
                      // pagination={{
                      //     pageSize: perPageDetail,
                      //     current: pageDetail,
                      //     total: penyelesaianUangMukaDetailsTotal,
                      //     showSizeChanger: true,
                      //     style: {
                      //         marginTop: "2rem",
                      //         marginBottom: 0,
                      //     },
                      // }}
                      loading={loading}
                    />
                    <Form
                      style={{ marginTop: "30px" }}
                      labelAlign="left"
                      {...layout}
                      name="control-hooks"
                    >
                      <Row>
                        <Col xs={24} md={12} lg={12}>
                          <Form.Item label="Total">
                            <Input
                              value={data.grand_total}
                              onChange={(e) => {
                                handleChange("grand_total", e.target.value);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.uang_muka ? "error" : false
                            }
                            help={
                              error && error.uang_muka
                                ? error.uang_muka[0]
                                : false
                            }
                            label="Down Payment"
                          >
                            <Input
                              value={data.uang_muka}
                              onChange={(e) => {
                                handleChange("uang_muka", e.target.value);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.kurang_lebih ? "error" : false
                            }
                            help={
                              error && error.kurang_lebih
                                ? error.kurang_lebih[0]
                                : false
                            }
                            label="+/-"
                          >
                            <Input
                              //maxLength={16}
                              value={data.kurang_lebih}
                              // onKeyPress={(e) => {
                              //   checkReceipt(e.target.value);
                              // }}
                              onChange={(e) => {
                                handleChange("kurang_lebih", e.target.value);
                                //checkReceipt(e.target.value);
                              }}
                            />
                          </Form.Item>
                          {/* <Form.Item
                                                        validateStatus={
                                                            error && error.dirupiahkan ? "error" : false
                                                        }
                                                        help={
                                                            error && error.dirupiahkan ? error.dirupiahkan[0] : false
                                                        }
                                                        
                                                        label="Dirupiahkan"
                                                    >
                                                        <Input
                                                            //maxLength={16}
                                                            value={data.dirupiahkan}
                                                            // onKeyPress={(e) => {
                                                            //   checkReceipt(e.target.value);
                                                            // }}
                                                            onChange={(e) => {
                                                                handleChange("dirupiahkan", e.target.value);
                                                                //checkReceipt(e.target.value);
                                                            }}
                                                        />
                                                    </Form.Item> */}
                        </Col>
                      </Row>
                    </Form>
                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditPenyelesaianUangMukaEntertainment;
