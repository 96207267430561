import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  DatePicker,
  Radio,
  Space,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditPenyelesaianBiayaRupaRupa(props, history) {
  const [data, setData] = useState({
    gl_code: "",
    gl_label: "",
    description: "",
    quantity: "",
    harga_satuan: "",
    internal_order: "",
    internal_order_number: "",
    cost_center_id: "",
    cost_center_number: "",
    transfer_to: "",
    nama_bank: "",
    no_rekening: "",
    jumlah: "",
    ppn: "",
    total: "",
    pph21: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [codes, setCodes] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [biayaRupas, setBiayaRupas] = useState({});
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [biayaRupaDetail, setBiayaRupaDetail] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [codes_account, setCodesAccount] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(
      `${url}/penyelesaian-uang-muka-biaya-rupa/${props.match.params.id}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        setData({
          ...data,
          nama: res.data.data.nama,
          npk: res.data.data.npk,
          divisi: res.data.data.divisi,
          penyelesaian_uang_muka_biaya_rupa_code:
            res.data.data.penyelesaian_uang_muka_biaya_rupa_code,
          internal_order_id: res.data.data.internal_order_id,
          internal_order_number: res.data.data.internal_order_number,
          cost_center_id: res.data.data.cost_center_id,
          cost_center_number: res.data.data.cost_center_number,
          transfer_to: res.data.data.transfer_to,
          nama_bank: res.data.data.nama_bank,
          no_rekening: res.data.data.no_rekening,
          text: res.data.data.text,
        });
        setBiayaRupaDetail(
          res.data.data.details.map((detail) => {
            return {
              id: detail._id,
              ...detail,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeRow = (name, value, id) => {
    const index = biayaRupaDetail.findIndex((x) => x._id === id);
    const newItem = [...biayaRupaDetail];
    newItem[index][name] = value;
    setBiayaRupaDetail(newItem);
  };

  const handleChangeSelectCodeAccount = (name, value) => {
    if (value) {
      const findControlItem = codes_account.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_label`]: value.label,
          [`${name}_code`]: findControlItem.code,
          [`_id`]: value.value,
          description: findControlItem.description,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`_id`]: null,
      });
    }
  };

  const getDeklarasi = async () => {
    await Axios.get(`${url}/biaya-rupa-rupa`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectDeklarasi = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          nama: findControlItem.nama,
          npk: findControlItem.npk,
          divisi: findControlItem.divisi,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          internal_order_id: findControlItem.internal_order_id,
          internal_order_number: findControlItem.internal_order_number,
          cost_center_id: findControlItem.cost_center_id,
          cost_center_number: findControlItem.cost_center_number,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const getDetails = (id) => {
    setLoadingDetail(true);
    Axios.get(`${url}/biaya-rupa-rupa/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBiayaRupaDetail(res.data.data.details);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifyBiayaRupaDetail = [...biayaRupaDetail];
      const findBiayaRupaDetail = modifyBiayaRupaDetail.find(
        (x) => x._id === selectedId
      );
      findBiayaRupaDetail.gl_code = data.gl_code;
      findBiayaRupaDetail.gl_label = data.gl_label;

      findBiayaRupaDetail.description = data.description;
      findBiayaRupaDetail.quantity = data.quantity;
      findBiayaRupaDetail.harga_satuan = data.harga_satuan;
      findBiayaRupaDetail.jumlah = data.quantity * data.harga_satuan;
      findBiayaRupaDetail.ppn = data.quantity * data.harga_satuan * 0.1;
      findBiayaRupaDetail.pph21 = "0";
      findBiayaRupaDetail.total =
        data.quantity * data.harga_satuan +
        data.quantity * data.harga_satuan * 0.1;

      setBiayaRupaDetail(modifyBiayaRupaDetail);
    } else {
      setBiayaRupaDetail([
        ...biayaRupaDetail,
        {
          //description: data.description,
          //tanggal: moment(data.tanggal).format("YYYY-MM-DD"),
          _id: data._id,
          gl_code: data.gl_code,
          gl_label: data.gl_label,
          description: data.description,
          quantity: data.quantity,
          harga_satuan: data.harga_satuan,
          jumlah: data.quantity * data.harga_satuan,
          ppn: data.quantity * data.harga_satuan * 0.1,
          // pph21: data.pph21,
          pph21: "0",
          total:
            data.quantity * data.harga_satuan +
            data.quantity * data.harga_satuan * 0.1,
        },
      ]);
    }

    setSelectedId("");

    setData({
      ...data,
      gl_code: null,
      gl_label: null,
      _id: null,
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: "",
      ppn: "",
      pph21: "",
      total: "",
    });

    setIsModalVisible(false);
  };

  const handleEditDetails = (id) => {
    const details = biayaRupaDetail.find(
      (biayaRupaDetail) => biayaRupaDetail._id === id
    );

    setSelectedId(id);

    setData({
      ...data,
      gl_code: details.gl_code,
      gl_label: details.gl_label,
      description: details.description,
      quantity: details.quantity,
      harga_satuan: details.harga_satuan,
      jumlah: details.jumlah,
      ppn: details.ppn,
      pph21: details.pph,
      total: details.total,
    });

    setIsModalVisible(true);
  };

  const handleRemoveDetails = (id) => {
    const details = biayaRupaDetail.filter(
      (biayaRupaDetail) => biayaRupaDetail._id !== id
    );
    setBiayaRupaDetail(details);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const resetModal = () => {
    setData({
      ...data,
      gl_code: null,
      gl_label: null,
      _id: null,
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: "",
      ppn: "",
      pph21: "",
      total: "",
    });
  };

  const handleReset = () => {
    setData({
      gl_code: "",
      gl_label: "",
      description: "",
      quantity: "",
      harga_satuan: "",
      internal_order_number: "",
      cost_center_number: "",
      transfer_to: "",
      nama_bank: "",
      no_rekening: "",
      jumlah: "",
      ppn: "",
      total: "",
      pph21: "",
    });

    setBiayaRupaDetail([]);
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_number`]: value.label,
    });
  };

  const onSaveData = () => {
    setLoading(true);
    // let fd = new FormData();
    Axios.post(
      props.match.params.id
        ? `${url}/penyelesaian-uang-muka-biaya-rupa/${props.match.params.id}`
        : `${url}/penyelesaian-uang-muka-biaya-rupa`,
      {
        details: biayaRupaDetail,
        nama: data.nama,
        npk: data.npk,
        divisi: data.divisi,
        penyelesaian_uang_muka_biaya_rupa_id:
          data.penyelesaian_uang_muka_biaya_rupa_id,
        penyelesaian_uang_muka_biaya_rupa_code:
          data.penyelesaian_uang_muka_biaya_rupa_code,
        internal_order_id: data.internal_order_id,
        internal_order_number: data.internal_order_number,
        cost_center_id: data.cost_center_id,
        cost_center_number: data.cost_center_number,
        transfer_to: data.transfer_to,
        nama_bank: data.nama_bank,
        no_rekening: data.no_rekening,
        grand_total: data.grand_total,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/penyelesaian-uang-muka-biaya-rupa-rupa");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getInternalOrder = async (keyword) => {
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          internal_orders: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCodeAccount = async () => {
    await Axios.get(`${url}/gl-account`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodesAccount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const columns = [
    {
      align: "left",
      title: "Activity Code",
      dataIndex: "gl_code",
    },
    {
      align: "left",
      title: "Description",
      dataIndex: "description",
    },
    {
      align: "left",
      title: "Quantity",
      dataIndex: "quantity",
      render: (quantity) => {
        return <AntNumberFormat value={quantity} type="text" />;
      },
    },
    {
      align: "left",
      title: "Unit Price",
      dataIndex: "harga_satuan",
      render: (harga_satuan) => {
        return <AntNumberFormat value={harga_satuan} type="text" />;
      },
    },
    {
      align: "left",
      title: "Total",
      dataIndex: "jumlah",
      render: (jumlah) => {
        return <AntNumberFormat value={jumlah} type="text" />;
      },
    },
    {
      align: "left",
      title: "PPn",
      dataIndex: "ppn",
      render: (ppn) => {
        return <AntNumberFormat value={ppn} type="text" />;
      },
      // render: (ppn, item) => (
      //   <Input
      //     // style={{ width: 100 }}
      //     allowClear="true"
      //     value={ppn}
      //     // type="input"
      //     onChange={(e) => handleChangeRow("ppn", e.target.value, item._id)}
      //   />
      // ),
    },
    {
      align: "left",
      title: "PPH21",
      dataIndex: "pph21",
      render: (pph21) => {
        return <AntNumberFormat value={pph21} type="text" />;
      },
      // render: (pph21, item) => (
      //   <Input
      //     // style={{ width: 100 }}
      //     allowClear="true"
      //     value={pph21}
      //     // type="input"
      //     onChange={(e) => handleChangeRow("pph21", e.target.value, item._id)}
      //   />
      // ),
    },
    {
      align: "left",
      title: "PPH23",
      dataIndex: "pph23",
      render: (pph23) => {
        return <AntNumberFormat value={pph23} type="text" />;
      },
      // render: (pph21, item) => (
      //   <Input
      //     // style={{ width: 100 }}
      //     allowClear="true"
      //     value={pph21}
      //     // type="input"
      //     onChange={(e) => handleChangeRow("pph21", e.target.value, item._id)}
      //   />
      // ),
    },
    {
      align: "left",
      title: "Grand Total",
      dataIndex: "total",
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
    // {
    //   // title: "Option",
    //   dataIndex: "_id",
    //   children: [
    //     {
    //       // title: "Option",
    //       width: 50,
    //       dataIndex: "_id",
    //       render: (id) => (
    //         <Button
    //           onClick={() => handleRemoveDetails(id)}
    //           size="small"
    //           type="danger"
    //         >
    //           <DeleteOutlined />
    //           Delete

    //         </Button>
    //       ),
    //     },
    //     {
    //       // title: "Option",
    //       dataIndex: "_id",
    //       render: (id) => (
    //         <Button
    //           onClick={() => handleEditDetails(id)}
    //           size="small"
    //           type="primary"
    //         >
    //           <EditOutlined />
    //           Edit
    //         </Button>
    //       ),
    //     }
    //   ],
    // },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/penyelesaian-uang-muka-biaya-rupa-rupa">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Advance settlement Update</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Ref Doc Biaya Rupa-Rupa">
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectDeklarasi(
                                    "penyelesaian_uang_muka_biaya_rupa",
                                    value
                                  )
                                }
                                onFocus={() => getDeklarasi("")}
                                showSearch
                                onSearch={(value) => getDeklarasi(value)}
                                filterOption={false}
                                value={{
                                  value:
                                    data.penyelesaian_uang_muka_biaya_rupa_id,
                                  label:
                                    data.penyelesaian_uang_muka_biaya_rupa_code,
                                }}
                              >
                                {codes &&
                                  codes.map((code) => {
                                    return (
                                      <Option value={code._id} key={code._id}>
                                        {`${code.biaya_rupa_code} - ${code.internal_order_description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Name"
                            >
                              <Input
                                //maxLength={16}
                                disabled
                                value={data.nama}
                                // value={sessionStorage.getItem("fullname")}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                //maxLength={16}
                                disabled
                                value={data.npk}
                                // value={sessionStorage.getItem("npk")}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Division"
                            >
                              <Input
                                //maxLength={16}
                                disabled
                                value={data.divisi}
                                // value={sessionStorage.getItem("divisi")}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("divisi", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item label="Internal Order">
                              <Select
                                disabled
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("internal_order", value)
                                }
                                onFocus={() => getInternalOrder("")}
                                showSearch
                                onSearch={(value) => getInternalOrder(value)}
                                filterOption={false}
                                value={{
                                  key: data.internal_order_id,
                                  label: data.internal_order_number,
                                }}
                              >
                                {data.internal_orders &&
                                  data.internal_orders.map((internal_order) => {
                                    return (
                                      <Option
                                        value={internal_order._id}
                                        key={internal_order._id}
                                      >
                                        {`${internal_order.internal_order_number}-${internal_order.description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.cost_center ? "error" : false
                              }
                              help={
                                error && error.cost_center
                                  ? error.cost_center[0]
                                  : false
                              }
                              label="Cost Center"
                            >
                              <Select
                                disabled
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("cost_center", value)
                                }
                                onFocus={() => getCostCenter("")}
                                showSearch
                                onSearch={(value) => getCostCenter(value)}
                                filterOption={false}
                                value={{
                                  key: data.cost_center_id,
                                  label: data.cost_center_number,
                                }}
                              >
                                {data.cost_centers &&
                                  data.cost_centers.map((cost_center) => {
                                    return (
                                      <Option
                                        value={cost_center._id}
                                        key={cost_center._id}
                                      >
                                        {`${cost_center.cost_center_number}-${cost_center.description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Input
                                disabled
                                maxLength={16}
                                value={data.transfer_to}
                                //value={receiptNumberUppercase}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("transfer_to", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama_bank ? "error" : false
                              }
                              help={
                                error && error.nama_bank
                                  ? error.nama_bank[0]
                                  : false
                              }
                              label="Bank Name"
                            >
                              <Input
                                disabled={data.pengajuan_um !== "Belum"}
                                //placeholder="Nama Bank"
                                //maxLength={16}
                                value={data.nama_bank}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("nama_bank", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.no_rekening ? "error" : false
                              }
                              help={
                                error && error.no_rekening
                                  ? error.no_rekening[0]
                                  : false
                              }
                              label="Account Number"
                            >
                              <Input
                                disabled={data.pengajuan_um !== "Belum"}
                                //placeholder="0"
                                //maxLength={16}
                                value={data.no_rekening}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("no_rekening", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space>
                        {/* <Button type="primary"> */}
                        {/* <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add
                        </Button> */}

                        <Modal
                          title={selectedId ? "Edit Details" : "Add Details"}
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1000}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form {...layout} name="control-hooks">
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        <Form.Item
                                          validateStatus={
                                            error && error.gl_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.gl_code
                                              ? error.gl_code[0]
                                              : false
                                          }
                                          label="Activity Code"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectCodeAccount(
                                                "gl",
                                                value
                                              )
                                            }
                                            onFocus={() => getCodeAccount("")}
                                            showSearch
                                            onSearch={(value) =>
                                              getCodeAccount(value)
                                            }
                                            filterOption={false}
                                            value={{
                                              value: data._id,
                                              label: data.gl_label,
                                            }}
                                          >
                                            {codes_account &&
                                              codes_account.map(
                                                (code_account) => {
                                                  return (
                                                    <Option
                                                      value={code_account._id}
                                                      key={code_account._id}
                                                    >
                                                      {`${code_account.code} - ${code_account.description}`}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.description
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.description
                                              ? error.description[0]
                                              : false
                                          }
                                          label="Description"
                                        >
                                          <Input
                                            //maxLength={16}
                                            disabled={
                                              data.pengajuan_um !== "Belum"
                                            }
                                            value={data.description}
                                            // onKeyPress={(e) => {
                                            //   checkReceipt(e.target.value);
                                            // }}
                                            onChange={(e) => {
                                              handleChange(
                                                "description",
                                                e.target.value
                                              );
                                              //checkReceipt(e.target.value);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.quantity
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.quantity
                                              ? error.quantity[0]
                                              : false
                                          }
                                          label="Quantity"
                                        >
                                          {/* <Input
                                            //maxLength={16}
                                            value={data.quantity}
                                            // onKeyPress={(e) => {
                                            //   checkReceipt(e.target.value);
                                            // }}
                                            onChange={(e) => {
                                              handleChange(
                                                "quantity",
                                                e.target.value
                                              );
                                              //checkReceipt(e.target.value);
                                            }}
                                          /> */}
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            value={data.quantity}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("quantity", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.harga_satuan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.harga_satuan
                                              ? error.harga_satuan[0]
                                              : false
                                          }
                                          label="Unit Price"
                                        >
                                          {/* <Input
                                            //maxLength={16}
                                            value={data.harga_satuan}
                                            // onKeyPress={(e) => {
                                            //   checkReceipt(e.target.value);
                                            // }}
                                            onChange={(e) => {
                                              handleChange(
                                                "harga_satuan",
                                                e.target.value
                                              );
                                              //checkReceipt(e.target.value);
                                            }}
                                          /> */}
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            value={data.harga_satuan}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("harga_satuan", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.jumlah
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.jumlah
                                              ? error.jumlah[0]
                                              : false
                                          }
                                          label="Total"
                                        >
                                          {/* <Input
                                            //maxLength={16}
                                            disabled
                                            value={data.jumlah}
                                            // onKeyPress={(e) => {
                                            //   checkReceipt(e.target.value);
                                            // }}
                                            onChange={(e) => {
                                              handleChange(
                                                "jumlah",
                                                e.target.value
                                              );
                                              //checkReceipt(e.target.value);
                                            }}
                                          /> */}
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            disabled
                                            value={
                                              data.quantity * data.harga_satuan
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("jumlah", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.ppn ? "error" : false
                                          }
                                          help={
                                            error && error.ppn
                                              ? error.ppn[0]
                                              : false
                                          }
                                          label="PPn"
                                        >
                                          {/* <Input
                                            //maxLength={16}
                                            disabled
                                            value={data.ppn}
                                            // onKeyPress={(e) => {
                                            //   checkReceipt(e.target.value);
                                            // }}
                                            onChange={(e) => {
                                              handleChange(
                                                "ppn",
                                                e.target.value
                                              );
                                              //checkReceipt(e.target.value);
                                            }}
                                          /> */}
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            disabled
                                            value={
                                              data.quantity *
                                              data.harga_satuan *
                                              0.1
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("ppn", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.total
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.total
                                              ? error.total[0]
                                              : false
                                          }
                                          label=" Grand Total"
                                        >
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            disabled
                                            value={
                                              data.quantity *
                                                data.harga_satuan +
                                              data.quantity *
                                                data.harga_satuan *
                                                0.1
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("total", e);
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={resetModal}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>

                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        size="small"
                        columns={columns}
                        dataSource={biayaRupaDetail}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        scroll={{ x: 1000 }}
                        // pagination={{
                        //   pageSize: 10,
                        //   current: page,
                        //   total: biayaRupas.total,
                        //   showSizeChanger: false,
                        // }}
                        loading={loading}
                      />
                      <Form
                        style={{ marginTop: "30px" }}
                        labelAlign="left"
                        {...layout}
                        name="control-hooks"
                      >
                        <Row>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item required label="Total">
                              <AntNumberFormat
                                //maxLength={16}
                                type="input"
                                disabled
                                value={biayaRupaDetail.reduce(
                                  (prevValue, currentValue) => {
                                    const total =
                                      currentValue.quantity *
                                      currentValue.harga_satuan;
                                    const ppn = total * 0.1;
                                    const grandTotal = total + ppn;

                                    return grandTotal + prevValue;
                                  },
                                  0
                                )}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                // onChange={(e) => true}
                                onChange={(e) => {
                                  handleChange("grand_total", e);
                                }}
                              />
                            </Form.Item>
                            {/* <Form.Item label="PPn">
                              <Input
                                addonAfter="%"
                                // disabled
                                value={data.ppn}
                                type="input"
                                onChange={(e) =>
                                  handleChange("ppn", e.target.value)
                                }
                              />
                            </Form.Item> */}

                            <Form.Item
                              validateStatus={
                                error && error.kelebihan ? "error" : false
                              }
                              help={
                                error && error.kelebihan
                                  ? error.kelebihan[0]
                                  : false
                              }
                              label="Remaining Down Paymen"
                            >
                              <AntNumberFormat
                                //defaultValue="0"
                                disabled
                                value={
                                  data.quantity * data.harga_satuan +
                                  data.quantity * data.harga_satuan * 0.1
                                }
                                type="input"
                                onChange={(e) => {
                                  handleChange("kelebihan", e);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.kekurangan ? "error" : false
                              }
                              help={
                                error && error.kekurangan
                                  ? error.kekurangan[0]
                                  : false
                              }
                              label="Insufficient Payment"
                            >
                              <AntNumberFormat
                                //defaultValue="0"
                                disabled
                                value={
                                  data.quantity * data.harga_satuan +
                                  data.quantity * data.harga_satuan * 0.1
                                }
                                type="input"
                                onChange={(e) => {
                                  handleChange("kekurangan", e);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>

                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditPenyelesaianBiayaRupaRupa;
