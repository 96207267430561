import React, { useState, useEffect, Fragment } from "react";
import { Layout, Grid, Col, Card, Breadcrumb, Row, Typography, message } from "antd";
import { CopyrightCircleOutlined } from "@ant-design/icons";
import "./FullLayout.less";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Axios from "axios";
import { url } from "../config/global";
const { Header, Footer, Sider } = Layout;
const { useBreakpoint } = Grid;
const { Title, Paragraph } = Typography;

function FullLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [running, setRunning] = useState(null);
  const screens = useBreakpoint();

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    // props.screens.xs && setCollapsed(false)
    getRunningText();
    const width = window.innerWidth;
    width <= 500 && setCollapsed(false);
  }, []);

  const getRunningText = () => {
    Axios.get(`${url}/runnings`, {
      params: {
        variable: 'running_text',
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if(res.data.data.value.length > 0 ){
          let data = res.data.data.value;
          let arrRun = [];
          let temp = data.reduce((s,v) => {
            console.log(s,v);
            arrRun.push(v.name);
          },[])
          setRunning(arrRun.join(' || '));
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
    
      });
  };

  const refresh = () => {
    const path = props.history.location.pathname;
    window.location = path;
  };

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
        className={`sidebar gradasi-drma ${
          !screens.xs
            ? collapsed
              ? "sidebar-collapsed"
              : "sidebar-uncollapsed"
            : !collapsed
            ? "sidebar-none"
            : "sidebar-collapsed"
        }`}
        trigger={null}
        collapsed={collapsed}
      >
        <Sidebar {...props} collapsed={collapsed} />
      </Sider>
      <Layout>
        <Header className="header">
          <Navbar
            {...props}
            refresh={refresh}
            collapsed={collapsed}
            screens={screens}
            onToggleCollapsed={handleCollapsed}
            runningText={running}
          />
        </Header>
        <Fragment>{props.children}</Fragment>
        <Footer>
          <CopyrightCircleOutlined /> 2020 Dharma Polimetal
        </Footer>
      </Layout>
    </Layout>
  );
}

export default FullLayout;
