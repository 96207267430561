import React, { useState, useEffect, Fragment } from "react";
import {Menu,Dropdown,Card,Layout,Row,Col,Input,Table,DatePicker,Space,message,Tag,Typography,Modal,Radio,Tooltip,Badge,Comment,Avatar,Button,Form
} from "antd";
import { Redirect, Link } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
// import "./ApprovalUMPerjalananDinas.less";
import Search from "antd/lib/input/Search";
import { CloseOutlined, CloseCircleOutlined, CheckCircleOutlined, EyeOutlined, ProfileOutlined, ExclamationCircleOutlined, DeleteOutlined, EditOutlined, MoreOutlined, PrinterOutlined, SafetyOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import NumberFormat from "react-number-format";
import TextArea from "antd/lib/input/TextArea";
import "../SummaryDetail.less"

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "pending",
    title: "Total Pending",
  },
  {
    filter: "approve",
    title: "Total Approve",
  },
  {
    filter: "review",
    title: "Total Review",
  },
  {
    filter: "reject",
    title: "Total Reject",
  },
];

function ApprovalUMPerjalananDinas() {
  const [type, setType] = useState("all");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [approvalUangMukas, setApprovalUangMukas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [count, setCount] = useState({
    all: "",
    pending: "",
    approve: "",
    reject: "",
    review: "",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "item_number_gr",
    order: "descend",
  });
  const [error, setError] = useState(null);
  const [rowDetails, setRowDetails] = useState([]);
  const [loadReject, setLoadReject] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [budgetArea, setBudgetArea] = useState(null);
  const [pengajuanUangMukaDetails, setPengajuanUangMukaDetails] = useState([]);
  const [pengajuanUangMukaDetailsTotal, setPengajuanUangMukaDetailsTotal] =
    useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  /* Comment */
  const [visible, setVisible] = useState(false);
  const [commentTemp, setCommentTemp] = useState({
    tempDataComment: []
  });
  const [commentList, setCommentList] = useState({});
  /* End Comment */

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
    getSetBudget();
    getApprovalUangMukaCount();
  }, [keyword, page, sort, type, data.start_date, data.end_date]);

  const showModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
  };

  const handleApprove = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Advance Payment Submission will be Approved</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApprove(id);
      },
    });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/pengajuan-uang-muka-perjalanan-dinas/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmApprove = (id) => {
    setLoading(true);

    Axios.post(
      `${url}/approval-pengajuan-uang-muka-perjalanan-dinas/${id}`,
      {
        status: data.status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData(keyword, page, sort, type, data.start_date, data.end_date);
        getApprovalUangMukaCount();
        closeModal();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pengajuan-uang-muka-perjalanan-dinas`, {
      params: {
        keyword,
        columns: [
          "pengajuan_perdin_code",
          "types_reimbursment",
          "available_budget",
          "grand_total",
          "transfer_to",
          "department"
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setApprovalUangMukas(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApprovalUangMukaCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pengajuan-uang-muka-perjalanan-dinas/count`, {
      params: {
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getBiayaPengajuanUangMukaDetails(data2[0]);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getApprovalPengajuanPerdinCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-pengajuan-uang-muka-perjalanan-dinas/count`, {
      params: {
        role_name: sessionStorage.getItem("role_name"),
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReject = (id) => {
    setLoading(true);
    setLoadReject(true);

    Axios.post(
      data.status === 3
        ? `${url}/review-pengajuan-uang-muka-perjalanan-dinas/${id}`
        : `${url}/reject-pengajuan-uang-muka-perjalanan-dinas/${id}`,
      {
        status: data.status,
        remark: data.remark,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
        getApprovalPengajuanPerdinCount();
        closeModal();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadReject(false);
        setLoading(false);
      });
  };

  const handleChangeStatus = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const closeModal = () => {
    setData({
      ...data,
      status: "",
      remark: "",
    });
    setIsModalVisible(false);
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getBiayaPengajuanUangMukaDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/pengajuan-uang-muka-perjalanan-dinas/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["id_material", "material_description"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPengajuanUangMukaDetails(res.data.data);
        setPengajuanUangMukaDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const getPengajuanUangMukaDetails = () => {
    const columns = [
      {
        align: "center",
        title: "GL CODE",
        dataIndex: "gl_code",
      },
      {
        align: "left",
        title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
        dataIndex: "description",
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
        width: 150,
        render: (cost_center_number) => {
          return cost_center_number ? 
            cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
        width: 150,
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        align: "center",
        title: "Qty",
        dataIndex: "qty",
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>IDR</div>,
        dataIndex: "rupiah",
        render: (rupiah) => {
          return <AntNumberFormat value={rupiah} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>USD</div>,
        dataIndex: "usd",
        render: (usd) => {
          return <AntNumberFormat value={usd} type="text" />;
        },
      },
      {
        align: "right",
        title: <div style={{ textAlign: "center" }}>TOTAL</div>,
        dataIndex: "total",
        render: (total) => {
          return <AntNumberFormat value={total} type="text" />;
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")} */}
            {/* /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              //scroll={{ x: 1000 }}
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={pengajuanUangMukaDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: pengajuanUangMukaDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ rupiah, total }) => {
                  totalAmount += rupiah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalGrandTotal} type="text" />
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleClickFile = (x) => {
    console.log(x.bukti_scan_url, "click");
    window.open(x.bukti_scan_url);
  };

  /* Comment */
  const closeComment = () => {
    setVisible(false);
  };

  const handleChangeComment = (name, value) => {
    setCommentTemp({
        ...commentTemp,
        [name]: value,
        last_comment: sessionStorage.getItem("npk")
    })
  }

  const submitComment = () => {
    setLoading(true);

    Axios.post(
      `${url}/send-comment`,
      {
        doc_id: commentTemp.doc_id,
        doc_number: commentTemp.doc_number,
        doc_name: commentTemp.doc_name,
        doc_type: commentTemp.doc_type,
        comment_description: commentTemp.comment_description,
        last_comment: commentTemp.last_comment
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setCommentTemp({
        ...commentTemp,
        comment_description: '',
        last_comment: ''
      })
      
      setCommentList(res.data.data);
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /*  Open Data Comment Transaksi */
  const openDataComment = async (id, data) => {
    setCommentTemp({
      ...commentTemp,
      doc_number: data.pengajuan_id,
      doc_name: data.reminder_name,
      doc_type: data.reminder_status,      
      doc_id: id,
      DocNumber: data.pengajuan_id,
      CreatedDate: data.created_at
    })
    setLoading(true);

    await Axios.get(
      `${url}/show-comment`, {
        params: {
          doc_id: id,
          doc_number: data.pengajuan_id,
          doc_name: data.reminder_name,
          doc_type: data.reminder_status,
          comment_description: data.comment_description,
          last_comment: data.last_comment
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    })
    .then((res) => {
      setCommentList(res.data.data)
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
      setVisible(true);

    });
  };
  /* End Comment  */

  const columns = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      width: 120,
      align: "center",
      sorter: false,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "pengajuan_perdin_code",
      width: 120,
      align: "center",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "nama",
      width: 130,
      align: "left",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 150,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      sorter: false,
      width: 200,
      key: "description",
    },
    {
      title: "FILE",
      dataIndex: "bukti_scan_details",
      sorter: false,
      align: "center",
      width: 200,
      render: (bukti_scan_url) =>
        bukti_scan_url &&
        bukti_scan_url.map((x, index) => {
          return x.bukti_scan.split(".")[1] === "jpg" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "jpeg" ? (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconimage.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : x.bukti_scan.split(".")[1] === "pdf" ? (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconpdf.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconexcel.png")}
              onClick={() => handleClickFile(x)}
            />
          );
        }),
    },
    {
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "grand_total",
      key: "grand_total",
      width: 120,
      align: "right",
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total} type="text" />;
      },
    },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width: 150,
      render: (id, data) => {
        return (
          <div>
						{
              sessionStorage.getItem("role_name") === "Department Head" ? (
                <>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}} >
											<img
												onClick={() =>
													data.status === 1 ? handleApprove(id) : true
												}
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
		
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}}>
											<img
												onClick={() =>
													data.status === 1 ? showModal(id) : true
												}
												alt="icon"
												width="20"
												src={
												require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
								</>

              ) : sessionStorage.getItem("role_name") === "Division Head" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 2 ? handleApprove(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                        require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>
    
    
                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 2 ? showModal(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>	

              ) : sessionStorage.getItem("role_name") === "Personalia" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 3 ? handleApprove(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                        require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>
    
    
                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 3 ? showModal(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>	

              ) : sessionStorage.getItem("role_name") === "Direktur" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 4 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 4 ? handleApprove(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>
  
  
                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 4 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 4 ? showModal(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>
            
              ) : sessionStorage.getItem("role_name") === "Admin" ? (
                <>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10, 
                      cursor: data.grand_total < budgetArea ?
                          data.status < 4 ? 'pointer' : 'not-allowed'
                        : data.status < 5 ? 'pointer' : 'not-allowed'
                    }}
											size="small"
											type="default"
											onClick={() => 
                        data.grand_total < budgetArea ?
                          data.status < 4 ? handleApprove(id) : true
                        : data.status < 5 ? handleApprove(id) : true
                      }
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
									
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10,
                      cursor: data.grand_total < budgetArea ?
                        data.status < 4 ? 'pointer' : 'not-allowed'
                      : data.status < 5 ? 'pointer' : 'not-allowed'
                    }}
											size="small"
											type="default"
                      onClick={() => 
                        data.grand_total < budgetArea ?
                          data.status < 4 ? showModal(id) : true
                        : data.status < 5 ? showModal(id) : true
                      }
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
		
									{/* <Tooltip placement="top" title="Delete">
										<span style={{marginRight: 10}}
											size="small"
											type="default"
											onClick={() => handleDelete(id)}
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/delete.png")
												}
											/>
										</span>
									</Tooltip> */}
								</>

              ) : null
            }

            <Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Personalia" ?
                    data.sc_pers >= 1 ? data.sc_pers : 0
                  : sessionStorage.getItem('role_name') === "Direktur" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>

          </div>
        );
      }
    },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      sorter: false,
      width: 130,
      key: "transfer_to",
    },
    {
      title: "APPROVAL",
      children: [
        {
          // title:
          //   sessionStorage.getItem("role_name") === "Department Head"
          //     ? "Approval Status"
          //     : "Dept Head",
          title: " DEPT HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalUser",
          width: 110,
          render: (status, data) => {
            console.log(data.grand_total);
            console.log(budgetArea);
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    status !== 1 ? data.app_dept : data.department.join(", ")
                  }
                >
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 1
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                </Tooltip>
              </>
            );
          },
        },
        {
          title: " DIV HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalDeptHead",
          width: 110,
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={status !== 1 ? data.app_div : data.department.join(", ")}
              >
                {data.app_div !== "-" ? (
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 2
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                ) : (
                  "-"
                )}
              </Tooltip>
            );
          },
        },
        {
          title: "PERSONALIA",
          dataIndex: "status",
          align: "center",
          key: "approvalDivHead",
          width: 110,
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={status !== 1 ? data.app_personalia : data.department.join(", ")}
              >
                <img
                  alt="icon"
                  width="20"
                  src={
                    status === 3
                      ? require("../../assets/img/sand-clock.png")
                      : status === 4 ||
                        status === 5
                      ? require("../../assets/img/checked.png")
                      : status === -2
                      ? require("../../assets/img/warning.png")
                      : status === -1
                      ? require("../../assets/img/danger.png")
                      : require("../../assets/img/sand-clock.png")
                  }
                />
              </Tooltip>
            );
          },
        },
        {
          title: " DIRECTOR",
          dataIndex: "status",
          align: "center",
          key: "approvalPersonalia",
          width: 110,
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={
                  status === 4 ? data.app_dir1 : data.department.join(", ")
                }
              >
                <img
                  alt="icon"
                  width="20"
                  src={
                    status === 4
                      ? require("../../assets/img/sand-clock.png")
                      : status === 4 || status === 5
                      ? require("../../assets/img/checked.png")
                      : status === -2
                      ? require("../../assets/img/warning.png")
                      : status === -1
                      ? require("../../assets/img/danger.png")
                      : require("../../assets/img/sand-clock.png")
                  }
                />
              </Tooltip>
            );
          },
        },
        // {
        //   title: " DIRECTOR 2",
        //   dataIndex: "status",
        //   align: "center",
        //   key: "approvalDirektur",
        //   width: 110,
        //   render: (status, data) => {
        //     return (
        //       <>
        //         {data.grand_total >= budgetArea ? (
        //           <Tooltip
        //             placement="top"
        //             title={
        //               status === 4 || status === 5
        //                 ? data.app_dir2
        //                 : data.department.join(", ")
        //             }
        //           >
        //             {data.grand_total >= budgetArea ? (
        //               <img
        //                 alt="icon"
        //                 width="20"
        //                 src={
        //                   status === 4
        //                     ? require("../../assets/img/sand-clock.png")
        //                     : status === 4 || status === 5
        //                     ? require("../../assets/img/checked.png")
        //                     : status === -2
        //                     ? require("../../assets/img/warning.png")
        //                     : status === -1
        //                     ? require("../../assets/img/danger.png")
        //                     : require("../../assets/img/sand-clock.png")
        //                 }
        //               />
        //             ) : (
        //               "-"
        //             )}
        //           </Tooltip>
        //         ) : (
        //           "-"
        //         )}
        //       </>
        //     );
        //   },
        // },
      ],
    },
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalPersonalia" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalPersonalia" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDivHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Personalia") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDivHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Direktur") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalPersonalia" &&
        // filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Admin") {
      return filter.key !== "approvalAdmin";
    } else if (
      sessionStorage.getItem("role_name") !== "Department Head" &&
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Personalia" &&
      sessionStorage.getItem("role_name") !== "Direktur"
    ) {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalPersonalia" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalStatus" &&
        filter.key !== "approvalDeptHead"
      );
    }
    return filter;
  });

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Why Reject?"
        visible={isModalVisible}
        onOk={() => {
          handleReject(selectedId);
        }}
        confirmLoading={loadReject}
        onCancel={handleCancel}
      >
        <Radio.Group
          style={{ marginBottom: 20 }}
          onChange={(e) => handleChangeStatus("status", e.target.value)}
          value={data.status}
        >
          <Radio value={3}>Review</Radio>
          <Radio value={4}>Reject</Radio>
        </Radio.Group>
        <div name="control-hooks">
          <TextArea
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          ></TextArea>
          {/* <Input
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          /> */}
        </div>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="Approval Advance Payment Submission"
            //extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          {/* <Col xs={24}> */}
          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("approve")}>
              {/* <Meta
                  avatar={
                  
                  }
                /> */}
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <CheckCircleOutlined
                  style={{ fontSize: "20px", color: "#008000" }}
                />
                <row></row> Approved
              </Title>
              <div className="value-font-size "> {count.approve}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("pending")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ExclamationCircleOutlined
                  style={{ fontSize: "20px", color: "#FFFF00" }}
                />
                <row></row> Pending
              </Title>
              <div className="value-font-size "> {count.pending}</div>
            </Card>
          </Col>

          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("review")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <EyeOutlined style={{ fontSize: "20px", color: "#FF8C00" }} />{" "}
                <row></row> Review
              </Title>
              <div className="value-font-size "> {count.review}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={24} md={4} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("reject")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <CloseCircleOutlined
                  style={{ fontSize: "20px", color: "#FF0000" }}
                />
                <row></row> Reject
              </Title>
              <div className="value-font-size "> {count.reject}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={24} md={8} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("all")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ProfileOutlined
                  style={{ fontSize: "20px", color: "#0000FF" }}
                />
                <row></row> Total Document
              </Title>
              <div className="value-font-size "> {count.all}</div>
            </Card>
          </Col>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={10} lg={10}>
                  {types.find((x) => x.filter === type).title}
                </Col>
                <Col xs={24} md={14} lg={14} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                    {/* <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                    /> */}
                  </Space>
                  {/* <Search
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                    /> */}
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    scroll={{ x: 1500 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getPengajuanUangMukaDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    dataSource={approvalUangMukas.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: approvalUangMukas.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* MODAL COMMENT LIST */}
      <Modal
        className="modal-comment-list"
        visible={visible}
        title={`${commentTemp.DocNumber} - ${commentTemp.CreatedDate} `}
        onOk={submitComment}
        onCancel={closeComment}
        footer={[
          <Form.Item>
            <TextArea
              rows={4}
              value={commentTemp.comment_description}
              onChange={(e) => handleChangeComment("comment_description", e.target.value)}
            ></TextArea>
          </Form.Item>,
          <Button key="back" onClick={closeComment} >
            Close
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={submitComment} >
            Send
          </Button>
        ]}
      >
        { commentList ?
            commentList.comment_list && 
              commentList.comment_list.map((comment) => {
              return (
                <Comment
                  // actions={actions}
                  className={`${comment.username === sessionStorage.getItem('username') ? 'comment-right' : ''}`}
                  author={<span className="comment-title">{comment.full_name}</span>}
                  avatar={<Avatar className="comment-avatar" src={comment.avatar} alt={`${comment.full_name}`} />}
                  content={
                    <p>
                      {comment.comment_description}
                    </p>
                  }
                  datetime={
                    <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{comment.comment_date}</span>
                    </Tooltip>
                  }
                />
              )
            })
          : <div id="comment-new-list">{commentTemp.tempDataComment}</div>}
      </Modal>
      {/* END COMMENT LIST */}
      
    </Fragment>
  );
}

export default ApprovalUMPerjalananDinas;
