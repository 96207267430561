import React, { useState } from "react";
import { Input, Tabs, Button, message, Form, Space } from "antd";
import "./ForgotPassword.less";
import { MailOutlined, ProfileOutlined } from "@ant-design/icons";
import Axios from "axios";
import { url } from "../../config/global";

const { TabPane } = Tabs;

function ForgotPassword(props) {
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    email: "",
    username: "",
  });

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/forgot-password`,
      {
        email: data.email,
        username: data.username,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/login");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTab = (key) => {
    setTab(key);
  };

  return (
    <div className="main">
      <div>
        <img
          src={require("../../assets/img/logo-dharma-polimetal.png")}
          className="img-logo"
          alt="DEM"
        />
        <Tabs activeKey={tab} onChange={handleTab}>
          <TabPane tab="Forgot Password" key="1">
            <Form onFinish={onSaveData}>
              <Form.Item
                validateStatus={error && error.email ? "error" : false}
                help={error && error.email ? error.email[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<MailOutlined />}
                  placeholder="Email Active"
                  onChange={(e) => handleChange("email", e.target.value)}
                  value={data.email}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.username ? "error" : false}
                help={error && error.username ? error.username[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<ProfileOutlined />}
                  placeholder="Username/NPK"
                  onChange={(e) => handleChange("username", e.target.value)}
                  value={data.username}
                />
              </Form.Item>

              <div>
                <Space>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    // onClick={handleLogin}
                    type="primary"
                    className="button-login"
                  >
                    Send Token
                  </Button>
                </Space>
              </div>
            </Form>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default ForgotPassword;
