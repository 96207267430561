import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Modal,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  PicCenterOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function CreatePenyelesaianUangMukaEntertainment(props) {
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [data, setData] = useState({
    pengajuan_uang_muka_entertainment_id: "",
    pengajuan_uang_muka_entertainment_code: "",
    nama: "",
    npk: "",
    transfer_to: "",
    nama_bank: "",
    no_rekening: "",
    description_header: "",
    jumlah: "",
    grand_total: "",
    description_head: "",
  });
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [codes, setCodes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadDocNo, setLoadDocNo] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [codes_account, setCodesAccount] = useState([]);
  const [penyelesaianUangMukaDetails, setPenyelesaianUangMukaDetails] =
    useState([]);

  const [
    penyelesaianUangMukaDetailsTotal,
    setPenyelesaianUangMukaDetailsTotal,
  ] = useState(0);

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [budgetArea, setBudgetArea] = useState(null);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [listBudget, setListBudget] = useState([]);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    getSetBudget();
  }, []);

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_number`]: value.label,
    });
  };

  const getCode = async () => {
    setLoadDocNo(true);
    await Axios.get(`${url}/entertainment/list-approve`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoadDocNo(false);
  };

  const getDetails = (id) => {
    setLoadingDetail(true);
    setLoading(true);
    Axios.get(`${url}/entertainment/${id}`, {
      params: {
        perpage: perpage,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if(res.data.data.list_budget_details){
          setPenyelesaianUangMukaDetails(res.data.data.details);
          setListBudget(res.data.data.list_budget_details);
        } else {
          message.error("Please complete your transaction list budget Internal Order & Cost Center");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
        setLoading(false);
      });
  };

  const getInternalOrder = async (keyword) => {
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          internal_orders: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectCode = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          pengajuan_umb_code: findControlItem.pengajuan_umb_code
            ? findControlItem.pengajuan_umb_code
            : 0,
          deklarasi_code: findControlItem.deklarasi_entertainment_code,
          nama: findControlItem.nama,
          npk: findControlItem.npk,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          description_header: findControlItem.description,
          entertainment_code: `${findControlItem.deklarasi_entertainment_code} - Rp. ${findControlItem.grand_total.toLocaleString()} `,
          total_pengajuan_before: findControlItem.pengajuan_umb_total,
          available_budget: findControlItem.available_budget,
          calSum: findControlItem.pengajuan_umb_total === "-"
              ? 0
              : findControlItem.pengajuan_umb_total - findControlItem.grand_total,
          vendor_code: findControlItem.vendor_code,
          vendor_name: findControlItem.vendor_name,
          vendor_label: `${findControlItem.vendor_code} - ${findControlItem.vendor_name} `,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const onSaveData = () => {
    setLoading(true);
    
    if(listBudget.length != 0){
      Axios.post(
        `${url}/penyelesaian-uang-muka-entertainment`,
        {
          pengajuan_umb_code: data.pengajuan_umb_code,
          deklarasi_id: data.entertainment_id,
          deklarasi_code: data.deklarasi_code,
          deklarasi_label: data.entertainment_code,
          transfer_to: data.transfer_to,
          nama_bank: data.nama_bank,
          no_rekening: data.no_rekening,
          description: data.description_header,
          grand_total: data.grand_total,
          total_umb: data.total_pengajuan_before,
          details: penyelesaianUangMukaDetails,
          list_budget_details: listBudget,
          calSum: data.calSum,
          available: data.available_budget,
          vendor_code: data.vendor_code,
          vendor_name: data.vendor_name,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/penyelesaian-uang-muka-entertainment");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    } else {
      if(listBudget.length == 0){
        message.error("Please complete your transaction list budget Internal Order & Cost Center");
      }
      setLoading(false);
    }
  };

  const handleRemoveDetails = (id) => {
    const details = penyelesaianUangMukaDetails.filter(
      (penyelesaianUangMukaDetails) => penyelesaianUangMukaDetails._id !== id
    );
    setPenyelesaianUangMukaDetails(details);
  };

  const handleReset = () => {
    setData({
      vendor_code: "",
    });
  };

  const resetModal = () => {
    setData({
      ...data,
      gl_code: null,
      _id: null,
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: "",
      ppn: "",
      pph21: "",
      total: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const columns = [
    {
      align: "center",
      title: "ACTIVITY CODE",
      dataIndex: "gl_code",
      width: 120,
      sorter: false,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      width: 120,
      sorter: false,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
      dataIndex: "cost_center_label",
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_label",
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" />;
      },
    },
    {
      title: "Purchase of Entertainment And The Like",
      children: [
        {
          align: "center",
          title: "DATE",
          dataIndex: "tanggal",
          width: 150,
          render: (date) => moment(date).format("DD-MM-YYYY"),
        },
        {
          align: "center",
          title: "LOCATION",
          width: 120,
          dataIndex: "tempat",
        },
        {
          align: "center",
          title: "ADDRESS",
          width: 120,
          dataIndex: "alamat",
        },

        {
          align: "center",
          title: "TYPE",
          width: 120,
          dataIndex: "jenis",
        },
        {
          title: <div style={{ textAlign: "center" }}>Amount (Rp)</div>,
          dataIndex: "jumlah",
          align: "right",
          width: 120,
          render: (jumlah) => {
            return <AntNumberFormat value={jumlah} type="text" />;
          },
        },
      ],
    },
    {
      title: "Relase Business Provided Entertainment And The Like",
      children: [
        {
          align: "left",
          title: <div style={{ textAlign: "center" }}>NAME</div>,
          dataIndex: "nama",
          width: 120,
        },
        {
          align: "center",
          title: "POSITION",
          width: 120,
          dataIndex: "jabatan",
        },
        {
          align: "left",
          title: <div style={{ textAlign: "center" }}>COMPANY NAME</div>,
          dataIndex: "nama_perusahaan",
          width: 120,
        },
      ],
    },
    {
      // dataIndex: "_id",
      // children: [
      //   {
      title: "Action",
      width: 110,
      align: "center",
      dataIndex: "_id",
      render: (id) => (
        <Button
          onClick={() => handleRemoveDetails(id)}
          size="small"
          type="danger"
          disabled
        >
          <DeleteOutlined />
          Delete
        </Button>
      ),
      //   },
      // ],
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/penyelesaian-uang-muka-entertainment">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Advance settlement Create</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.ref_doc_no ? "error" : false
                              }
                              help={
                                error && error.ref_doc_no
                                  ? error.ref_doc_no[0]
                                  : false
                              }
                              label="Ref Doc Number"
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectCode("entertainment", value)
                                }
                                onFocus={() => getCode("")}
                                showSearch
                                loading={loadDocNo}
                                onSearch={(value) => getCode(value)}
                                filterOption={false}
                                value={{
                                  value: data.entertainment_id,
                                  label: data.entertainment_code,
                                }}
                              >
                                {codes &&
                                  codes.map((code) => {
                                    return code.grand_total < budgetArea &&
                                      code.status === 4 &&
                                      code.status_proses !== "penyelesaian" ? (
                                      <Option value={code._id} key={code._id}>
                                        {`${code.deklarasi_entertainment_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                      </Option>
                                    ) : code.grand_total >= budgetArea &&
                                      code.status === 5 &&
                                      code.status_proses !== "penyelesaian" ? (
                                      <Option value={code._id} key={code._id}>
                                        {`${code.deklarasi_entertainment_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                      </Option>
                                    ) : (
                                      ""
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Employee Name"
                            >
                              <Input
                                disabled
                                value={data.nama}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={data.npk}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item label="Note">
                              <TextArea
                                disabled
                                value={data.description_header}
                                onChange={(e) => {
                                  handleChange(
                                    "description_header",
                                    e.target.value
                                  );
                                }}
                              ></TextArea>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.nama_bank ? "error" : false
                              }
                              help={
                                error && error.nama_bank
                                  ? error.nama_bank[0]
                                  : false
                              }
                              label=" Bank Name"
                            >
                              <Input
                                placeholder="e.g BCA"
                                disabled
                                // format="DD MMMM YYYY"
                                value={data.nama_bank}
                                onChange={(value) =>
                                  handleChange("nama_bank", value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.no_rekening ? "error" : false
                              }
                              help={
                                error && error.no_rekening
                                  ? error.no_rekening[0]
                                  : false
                              }
                              label="Account Number"
                            >
                              <Input
                                placeholder="e.g 012345xxx"
                                disabled
                                value={data.no_rekening}
                                onChange={(value) =>
                                  handleChange("no_rekening", value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Input
                                placeholder="e.g John Doe"
                                disabled
                                value={data.transfer_to}
                                onChange={(e) => {
                                  handleChange("transfer_to", e.target.value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Table
                        bordered
                        columns={columns}
                        size="small"
                        scroll={{ x: 2000 }}
                        rowKey={(data) => data._id}
                        dataSource={penyelesaianUangMukaDetails}
                        loading={loading}
                      />
                      <Form
                        style={{ marginTop: "30px" }}
                        labelAlign="left"
                        {...layout}
                        name="control-hooks"
                      >
                        <Row>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Total">
                              <AntNumberFormat
                                disabled
                                value={penyelesaianUangMukaDetails.reduce(
                                  (prevValue, currentValue) => {
                                    return prevValue + currentValue.jumlah;
                                  },
                                  0
                                )}
                                type="input"
                                onChange={(e) => {
                                  handleChange("grand_total", e);
                                }}
                              />
                            </Form.Item>
                            <Form.Item label="Down Payment Total">
                              <AntNumberFormat
                                disabled
                                value={
                                  data.total_pengajuan_before
                                    ? data.total_pengajuan_before
                                    : 0
                                }
                                type="input"
                                onChange={(e) => {
                                  handleChange("total_um", e);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.kelebihan ? "error" : false
                              }
                              help={
                                error && error.kelebihan
                                  ? error.kelebihan[0]
                                  : false
                              }
                              label="Remaining Down Paymen"
                            >
                              <AntNumberFormat
                                disabled
                                value={
                                  Math.sign(data.calSum) === 1 ? data.calSum : 0
                                }
                                type="input"
                                onChange={(e) => {
                                  handleChange("kelebihan", e);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.kekurangan ? "error" : false
                              }
                              help={
                                error && error.kekurangan
                                  ? error.kekurangan[0]
                                  : false
                              }
                              label="Insufficient Payment"
                            >
                              <AntNumberFormat
                                disabled
                                value={
                                  Math.sign(data.calSum) === -1
                                    ? data.calSum
                                    : 0
                                }
                                type="input"
                                onChange={(e) => {
                                  handleChange("kekurangan", e);
                                }}
                              />
                            </Form.Item>
                            {/* <Form.Item label="Total">
                              <Input
                                disabled
                                value={penyelesaianUangMukaDetails.reduce(
                                  (prevValue, currentValue) => {
                                    return prevValue + currentValue.jumlah;
                                  },
                                  0
                                )}
                                onChange={(e) => {
                                  handleChange("grand_total", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.uang_muka ? "error" : false
                              }
                              help={
                                error && error.uang_muka
                                  ? error.uang_muka[0]
                                  : false
                              }
                              label="Down Payment"
                            >
                              <Input
                                value={data.uang_muka}
                                onChange={(e) => {
                                  handleChange("uang_muka", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.kurang_lebih ? "error" : false
                              }
                              help={
                                error && error.kurang_lebih
                                  ? error.kurang_lebih[0]
                                  : false
                              }
                              label="+/-"
                            >
                              <Input
                                value={
                                  data.uang_muka -
                                  penyelesaianUangMukaDetails.reduce(
                                    (prevValue, currentValue) => {
                                      return prevValue + currentValue.jumlah;
                                    },
                                    0
                                  )
                                }
                                onChange={(e) => {
                                  handleChange("kurang_lebih", e.target.value);
                                }}
                              />
                            </Form.Item> */}
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreatePenyelesaianUangMukaEntertainment;
