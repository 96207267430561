import React, { useState, useEffect, Fragment } from "react";
import { Card, Layout, Row, Dropdown, Menu, Col, Modal, Button, Form, Table, DatePicker, Space, message, Radio, Tag, Typography, Tooltip, Comment, Avatar, Badge
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./ApprovalBiayaRupaRupa.less";
import Search from "antd/lib/input/Search";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, MoreOutlined, ProfileOutlined, SafetyOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import { isNull } from "underscore";
import TextArea from "antd/lib/input/TextArea";
import "../SummaryDetail.less"

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Meta } = Card;
const { Title } = Typography;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "pending",
    title: "Total Pending",
  },
  {
    filter: "approve",
    title: "Total Approve",
  },
  {
    filter: "review",
    title: "Total Review",
  },
  {
    filter: "reject",
    title: "Total Reject",
  },
];

function ApprovalPenyelesaianBiayaRupaRupa(props) {
  const [type, setType] = useState("all");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [
    approvalPenyelesaianBiayaRupaRupas,
    setApprovalPenyelesaianBiayaRupaRupas,
  ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [error, setError] = useState(null);
  const [count, setCount] = useState({
    all: "",
    pending: "",
    approve: "",
    reject: "",
    review: "",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "item_number_gr",
    order: "descend",
  });
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [budgetArea, setBudgetArea] = useState(null);
  const [penyelesaianBiayaRupaDetails, setPenyelesaianBiayaRupaDetails] =
    useState([]);
  const [
    penyelesaianBiayaRupaDetailsTotal,
    setPenyelesaianBiayaRupaDetailsTotal,
  ] = useState(0);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [budgetDivHead, setBudgetDivHead] = useState(null);
  /* Comment */
  const [visible, setVisible] = useState(false);
  const [commentTemp, setCommentTemp] = useState({
    tempDataComment: []
  });
  const [commentList, setCommentList] = useState({});
  /* End Comment */

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getApprovalPenyelesaianBiayaRupaDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
    getApprovalPenyelesaianBiayaRupaRupaCount();
    getSetBudget();
    getSetBudgetDivHead();
  }, [keyword, page, sort, type, data.start_date, data.end_date]);

  useEffect(() => {
    if (selectedId) {
      getApprovalPenyelesaianBiayaRupaDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/approval-penyelesaian-uang-muka-biaya-rupa`, {
      params: {
        keyword,
        columns: ["penyelesaian_biaya_rupa_code", "nama", "npk", "grand_total"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        setApprovalPenyelesaianBiayaRupaRupas(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApprovalPenyelesaianBiayaRupaRupaCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-penyelesaian-uang-muka-biaya-rupa/count`, {
      params: {
        keyword,
        columns: [
          "penyelesaian_uang_muka_biaya_rupa_code",
          "internal_order_description",
          "grand_total",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      status: "",
      remark: "",
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeStatus = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getApprovalPenyelesaianBiayaRupaDetails(data2[0]);
    }
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getSetBudgetDivHead = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "budget_div_head",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetDivHead(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/penyelesaian-uang-muka-biaya-rupa/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData(keyword, page, sort, type, data.start_date, data.end_date);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApprovalPenyelesaianBiayaRupaDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/penyelesaian-uang-muka-biaya-rupa/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: [
          "gl_code",
          "description",
          "harga_satuan",
          "quantity",
          "jumlah",
          "ppn",
          "pph21",
          "pph23",
          "pph24",
          "total",
        ],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPenyelesaianBiayaRupaDetails(res.data.data);
        setPenyelesaianBiayaRupaDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleApprove = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Advance Settlement will be Approved</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApprove(id);
      },
    });
  };

  const confirmApprove = (id) => {
    setLoading(true);
    console.log(data.status);
    Axios.post(
      `${url}/approval-penyelesaian-uang-muka-biaya-rupa/${id}`,
      {
        status: data.status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
        getData(keyword, page, sort, type, data.start_date, data.end_date);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReject = (id) => {
    setLoading(true);
    Axios.post(
      data.status === 3
        ? `${url}/review-penyelesaian-uang-muka-biaya-rupa/${id}`
        : `${url}/reject-penyelesaian-uang-muka-biaya-rupa/${id}`,
      {
        status: data.status,
        remark: data.remark,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData(keyword, page, sort, type, data.start_date, data.end_date);
        getApprovalPenyelesaianBiayaRupaRupaCount();
        closeModal();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickFile = (x) => {
    console.log(x.bukti_scan_url, "click");
    window.open(x.bukti_scan_url);
  };

  const getPenyelesaianBiayaRupaRupaDetails = () => {
    const columns = [
      {
        title: "GL CODE",
        dataIndex: "gl_code",
        key: "gl_code",
        align: "center",
        width: 150,
      },
      {
        title: <div style={{ textAlign: "center" }}>DESCRIPTION </div>,
        dataIndex: "description",
        key: "description",
        align: "left",
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
        width: 120,
        render: (cost_center_number) => {
          return cost_center_number ? 
            cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
        width: 120,
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        title: "QUANTITY",
        dataIndex: "quantity",
        key: "quantity",
        width: 120,
        align: "center",
        render: (quantity) => {
          return <AntNumberFormat value={quantity} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>UNIT PRICE</div>,
        dataIndex: "harga_satuan",
        key: "harga_satuan",
        align: "right",
        render: (harga_satuan) => {
          return <AntNumberFormat value={harga_satuan} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>AMOUNT</div>,
        dataIndex: "jumlah",
        key: "jumlah",
        align: "right",
        render: (jumlah) => {
          return <AntNumberFormat value={jumlah} type="text" />;
        },
      },
      // {
      //   title: <div style={{ textAlign: "center" }}>PPn</div>,
      //   dataIndex: "ppn",
      //   key: "ppn",
      //   align: "rigth",
      //   sorter: false,
      //   width: 110,
      //   render: (ppn) => {
      //     return <AntNumberFormat value={ppn} type="text" />;
      //   },
      // },
      {
        title: <div style={{ textAlign: "center" }}>PPN</div>,
        dataIndex: "ppn",
        key: "ppn",
        align: "right",
        sorter: false,
        render: (ppn) => {
          return <AntNumberFormat value={ppn} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>PPH21</div>,
        dataIndex: "pph21",
        key: "pph21",
        align: "right",
        sorter: false,
        render: (pph21) => {
          return <AntNumberFormat value={pph21} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>PPH23</div>,
        dataIndex: "pph23",
        key: "pph23",
        sorter: false,
        align: "right",
        render: (pph23) => {
          return <AntNumberFormat value={pph23} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>PPH24(2)</div>,
        dataIndex: "pph24",
        key: "pph24",
        align: "right",
        sorter: false,
        render: (pph24) => {
          return <AntNumberFormat value={pph24} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL</div>,
        dataIndex: "total",
        key: "total",
        align: "right",
        sorter: false,
        render: (total) => {
          return <AntNumberFormat value={total} type="text" />;
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              scroll={{ x: 1000 }}
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={penyelesaianBiayaRupaDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: penyelesaianBiayaRupaDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ jumlah, total }) => {
                  totalAmount += jumlah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={6} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={6} className="table-summary">
                        <div className="summary">
                          <AntNumberFormat value={totalGrandTotal} type="text" />
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  /* COMMENT  */
  const closeComment = () => {
    setVisible(false);
  };

  const handleChangeComment = (name, value) => {
    setCommentTemp({
        ...commentTemp,
        [name]: value,
        last_comment: sessionStorage.getItem("npk")
    })
  }

  const submitComment = () => {
    setLoading(true);

    Axios.post(
      `${url}/send-comment`,
      {
        doc_id: commentTemp.doc_id,
        doc_number: commentTemp.doc_number,
        doc_name: commentTemp.doc_name,
        doc_type: commentTemp.doc_type,
        comment_description: commentTemp.comment_description,
        last_comment: commentTemp.last_comment
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setCommentTemp({
        ...commentTemp,
        comment_description: '',
        last_comment: ''
      })
      
      setCommentList(res.data.data);
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /*  Open Data Comment Transaksi */
  const openDataComment = async (id, data) => {
    setCommentTemp({
      ...commentTemp,
      doc_number: data.penyelesaian_id,
      doc_name: data.reminder_name,
      doc_type: data.reminder_status,      
      doc_id: id,
      DocNumber: data.penyelesaian_id,
      CreatedDate: data.created_at
    })
    setLoading(true);

    await Axios.get(
      `${url}/show-comment`, {
        params: {
          doc_id: id,
          doc_number: data.penyelesaian_id,
          doc_name: data.reminder_name,
          doc_type: data.reminder_status,
          comment_description: data.comment_description,
          last_comment: data.last_comment
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    })
    .then((res) => {
      setCommentList(res.data.data)
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
      setVisible(true);

    });
  };

  const columns = [
    {
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      align: "center",
      render: (created_at) => moment(created_at).format("DD-MM-YYYY"),
      width: 150,
    },
    {
      title: "DOCUMENT NUMBER ",
      dataIndex: "penyelesaian_biaya_rupa_code",
      width: 120,
      align: "center",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "nama",
      width: 150,
      align: "left",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 150,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      sorter: false,
      width: 200,
      key: "description",
    },
    {
      title: "FILE",
      dataIndex: "bukti_scan_details",
      sorter: false,
      align: "center",
      width: 200,
      render: (bukti_scan_url) =>
        bukti_scan_url &&
        bukti_scan_url.map((x, index) => {
          return x.bukti_scan.split(".")[1] === "jpg" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "jpeg" ? (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconimage.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : x.bukti_scan.split(".")[1] === "pdf" ? (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconpdf.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : (
            <img
              key={index}
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconexcel.png")}
              onClick={() => handleClickFile(x)}
            />
          );
        }),
    },
    {
      title: <div style={{ textAlign: "center" }}>GRAND TOTAL</div>,
      dataIndex: "grand_total",
      sorter: false,
      align: "right",
      width: 150,
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total} type="text" />;
      },
    },
    // {
    //   title: "TOTAL",
    //   dataIndex: "total",
    //   sorter: false,
    //   children: [
    //     {
    //       title: <div style={{ textAlign: "center" }}>Down Payment</div>,
    //       dataIndex: "total_umb",
    //       width: 150,
    //       align: "right",
    //       sorter: false,
    //       render: (total_umb) => {
    //         return <AntNumberFormat value={total_umb} type="text" />;
    //       },
    //     },
    //     {
    //       title: <div style={{ textAlign: "center" }}>More</div>,
    //       dataIndex: "total_selisih",
    //       sorter: false,
    //       align: "right",
    //       width: 100,
    //       render: (total_selisih) => {
    //         return (
    //           <AntNumberFormat
    //             value={Math.sign(total_selisih) === 1 ? total_selisih : 0}
    //             type="text"
    //           />
    //         );
    //       },
    //     },
    //     {
    //       align: "right",
    //       title: <div style={{ textAlign: "center" }}>Less</div>,
    //       dataIndex: "total_selisih",
    //       sorter: false,
    //       width: 100,
    //       render: (total_selisih) => {
    //         return (
    //           <AntNumberFormat
    //             value={Math.sign(total_selisih) === -1 ? total_selisih : 0}
    //             type="text"
    //           />
    //         );
    //       },
    //     },
    //   ],
    // },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width: 150,
      render: (id, data) => {
        return (
          <div>
            {
              sessionStorage.getItem("role_name") === "Department Head" ? (
                <>
									<Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 1 ? handleApprove(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>

									<Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}}>
                      <img
                        onClick={() =>
                          data.status === 1 ? showModal(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>

              ) : sessionStorage.getItem("role_name") === "Division Head" ? (
                <>
                  <Tooltip placement="top" title="Approve">
                    <span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 2 ? handleApprove(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/approve.png")
                        }
                      />
                    </span>
                  </Tooltip>

                  <Tooltip placement="top" title="Reject / Review">
                    <span style={{marginRight: 10, cursor: data.status !== 2 ? 'not-allowed' : 'pointer'}} >
                      <img
                        onClick={() =>
                          data.status === 2 ? showModal(id) : true
                        }
                        alt="icon"
                        width="20"
                        src={
                          require("../../assets/img/reject.png")
                        }
                      />
                    </span>
                  </Tooltip>
                </>
              ) : sessionStorage.getItem("role_name") === "Direktur" ? (
                data.grand_total < budgetArea ? 
                (
                  <>
										<Tooltip placement="top" title="Approve">
											<span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 3 ? handleApprove(id) : true
													}
													alt="icon"
													width="20"
													src={
														require("../../assets/img/approve.png")
													}
												/>
											</span>
										</Tooltip>
		
		
										<Tooltip placement="top" title="Reject / Review">
											<span style={{marginRight: 10, cursor: data.status !== 3 ? 'not-allowed' : 'pointer'}} >
												<img
													onClick={() =>
														data.status === 3 ? showModal(id) : true
													}
													alt="icon"
													width="20"
													src={
														require("../../assets/img/reject.png")
													}
												/>
											</span>
										</Tooltip>
									</>
                ) : (
                  <>
										<Tooltip placement="top" title="Approve">
											<span 
                        style={{
                          marginRight: 10, 
                          cursor: 
                            data.status === 3 ? 
                              data.status !== 3 ? 'not-allowed' : 'pointer'
                            : data.status === 4 ?
                              data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? "not-allowed" : "pointer"
                            : "not-allowed"  
                        }} 
                      >
												<img
													onClick={() =>
														data.status === 3 ? 
                              data.dir_approval ? handleApprove(id) : handleApprove(id)
                            : data.status === 4 ?
                              data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? true : handleApprove(id)
                            : true
													} 
													alt="icon"
													width="20"
													src={
														require("../../assets/img/approve.png")
													}
												/>
											</span>
										</Tooltip>
		
										<Tooltip placement="top" title="Reject / Review">
											<span 
                        style={{
                          marginRight: 10, 
                          cursor: 
                            data.status === 3 ? 
                              data.status !== 3 ? 'not-allowed' : 'pointer'
                            : data.status === 4 ?
                              data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? "not-allowed" : "pointer"
                            : "not-allowed"   
                        }} 
                      >
												<img
													onClick={() =>
                            data.status === 3 ? 
                              data.dir_approval ? showModal(id) : showModal(id)
                            : data.status === 4 ?
                              data.dir_approval.direktur1 === sessionStorage.getItem("fullname") ? true : showModal(id)
                            : true
													}
													alt="icon"
													width="20"
													src={
														require("../../assets/img/reject.png")
													}
												/>
											</span>
										</Tooltip>
									</>
                )
            
              ) : sessionStorage.getItem("role_name") === "Admin" ? (
                <>
									<Tooltip placement="top" title="Approve">
										<span style={{marginRight: 10,
                        cursor: data.grand_total < budgetArea ?
                          data.status < 4 ? 'pointer' : 'not-allowed'
                        : data.status < 5 ? 'pointer' : 'not-allowed'
                      }}
											size="small"
											type="default"
											onClick={() => 
                        data.grand_total < budgetArea ?
                          data.status < 4 ? handleApprove(id) : true
                        : data.status < 5 ? handleApprove(id) : true
                      }
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/approve.png")
												}
											/>
										</span>
									</Tooltip>
									
									<Tooltip placement="top" title="Reject / Review">
										<span style={{marginRight: 10,
                        cursor: data.grand_total < budgetArea ?
                          data.status < 4 ? 'pointer' : 'not-allowed'
                        : data.status < 5 ? 'pointer' : 'not-allowed'
                      }}
											size="small"
											type="default"
											onClick={() =>
                        data.grand_total < budgetArea ?
                          data.status < 4 ? showModal(id) : true
                        : data.status < 5 ? showModal(id) : true
											}
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/reject.png")
												}
											/>
										</span>
									</Tooltip>
		
									{/* <Tooltip placement="top" title="Delete">
										<span style={{marginRight: 10,
                        cursor: data.grand_total < budgetArea ?
                          data.status < 4 ? 'pointer' : 'not-allowed'
                        : data.status < 5 ? 'pointer' : 'not-allowed'
                      }}
											size="small"
											type="default"
											onClick={() => 
                        data.grand_total < budgetArea ?
                          data.status < 4 ? handleDelete(id) : true
                        : data.status < 5 ? handleDelete(id) : true
                      }
										>
											<img
												alt="icon"
												width="20"
												src={
													require("../../assets/img/delete.png")
												}
											/>
										</span>
									</Tooltip> */}
								</>
              ) : null
            }

            <Tooltip placement="top" title="Comment">
              <span 
                style={{ cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Direktur" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                  // data.status_comment > 0 && data.last_comment != sessionStorage.getItem('npk') ? data.status_comment : 0 }
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      sorter: false,
      width: 130,
      key: "transfer_to",
    },
    {
      title: "APPROVAL",
      children: [
        {
          // title:
          //   sessionStorage.getItem("role_name") === "Department Head"
          //     ? "Approval Status"
          //     : "Dept Head",
          title: " DEPT HEAD",
          dataIndex: "status",
          width: 110,
          align: "center",
          key: "approvalUser",
          render: (status, data) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    status !== 1 && data.app_dept
                      ? data.app_dept
                      : data.department.join(", ")
                  }
                >
                  {/* {data.grand_total < budgetArea ? ( */}
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 1
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                  {/* ) : (
                  "-"
                )} */}
                </Tooltip>
              </>
            );
          },
        },
        {
          // title:
          //   sessionStorage.getItem("role_name") === "Division Head"
          //     ? "Approval Status"
          //     : "Div Head",
          title: " DIV HEAD",
          dataIndex: "status",
          width: 110,
          align: "center",
          key: "approvalDivHead",
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={
                  status !== 1 && data.app_div
                    ? data.app_div
                    : data.department.join(", ")
                }
              >
                {data.app_div !== "-" ? (
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 2
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                ) : (
                  "-"
                )}
              </Tooltip>
            );
          },
        },
        {
          title: " DIRECTOR 1",
          dataIndex: "status",
          align: "center",
          key: "approvalDivHead",
          width: 110,
          render: (status, data) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    status !== 1 && data.app_dir1
                      ? data.app_dir1
                      : data.department.join(", ")
                  }
                >
                  {data.grand_total >= budgetDivHead ? (
                    <img
                      alt="icon"
                      width="20"
                      src={
                        status === 3
                          ? require("../../assets/img/sand-clock.png")
                          : status === 3 || status === 4 || status === 5
                          ? require("../../assets/img/checked.png")
                          : status === -2
                          ? require("../../assets/img/warning.png")
                          : status === -1
                          ? require("../../assets/img/danger.png")
                          : require("../../assets/img/sand-clock.png")
                      }
                    />
                  ) : (
                    "-"
                  )}
                </Tooltip>
              </>
            );
          },
        },
        {
          title: " DIRECTOR 2",
          dataIndex: "status",
          align: "center",
          width: 110,
          key: "approvalDirektur",
          render: (status, data) => {
            return (
              <>
                {data.grand_total >= budgetArea ? (
                  <Tooltip
                    placement="top"
                    title={
                      status !== 1 && data.app_dir2
                        ? data.app_dir2
                        : data.department.join(", ")
                    }
                  >
                    {data.grand_total >= budgetArea ? (
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 4
                            ? require("../../assets/img/sand-clock.png")
                            : status === 4 || status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </Tooltip>
                ) : (
                  "-"
                )}
              </>
            );
          },
        },
      ],
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   // fixed: "right",
    //   width: 50,
    //   key: "approvalStatus",
    //   render: (id, data) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             sessionStorage.getItem("role_name") === "Department Head" ? (
    //               <Menu>
    //                 <Menu.Item disabled={data.status !== 1} key="1">
    //                   <span
    //                     onClick={() =>
    //                       data.status === 1 ? handleApprove(id) : true
    //                     }
    //                   >
    //                     <SafetyOutlined />
    //                     Approve
    //                   </span>
    //                 </Menu.Item>
    //                 <Menu.Item disabled={data.status !== 1} key="2">
    //                   <span
    //                     onClick={() =>
    //                       data.status === 1 ? showModal(id) : true
    //                     }
    //                   >
    //                     <CloseCircleOutlined />
    //                     Reject
    //                   </span>
    //                 </Menu.Item>
    //               </Menu>
    //             ) : sessionStorage.getItem("role_name") === "Division Head" ? (
    //               <Menu>
    //                 <Menu.Item disabled={data.status !== 2} key="1">
    //                   <span
    //                     onClick={() =>
    //                       data.status === 2 ? handleApprove(id) : true
    //                     }
    //                   >
    //                     <SafetyOutlined />
    //                     Approve
    //                   </span>
    //                 </Menu.Item>

    //                 <Menu.Item disabled={data.status !== 2} key="2">
    //                   <span
    //                     onClick={() =>
    //                       data.status === 2 ? showModal(id) : true
    //                     }
    //                     // onClick={() => showModal(id)}
    //                   >
    //                     <CloseCircleOutlined />
    //                     Reject
    //                   </span>
    //                 </Menu.Item>
    //               </Menu>
    //             ) : sessionStorage.getItem("role_name") === "Direktur" ? (
    //               data.grand_total < budgetArea ? (
    //                 <Menu>
    //                   {/* {data.status === 3 ?  */}
    //                   <Menu.Item disabled={data.status !== 3} key="1">
    //                     <span
    //                       onClick={() =>
    //                         data.status === 3 ? handleApprove(id) : true
    //                       }
    //                     >
    //                       <SafetyOutlined />
    //                       Approve
    //                     </span>
    //                   </Menu.Item>
    //                   {/* : "" } */}

    //                   {/* {data.status === 3 ?  */}
    //                   <Menu.Item disabled={data.status !== 3} key="2">
    //                     <span
    //                       onClick={() =>
    //                         data.status === 3 ? showModal(id) : true
    //                       }
    //                       // onClick={() => showModal(id)}
    //                     >
    //                       <CloseCircleOutlined />
    //                       Reject
    //                     </span>
    //                   </Menu.Item>
    //                   {/* : "" } */}
    //                 </Menu>
    //               ) : (
    //                 <Menu>
    //                   {data.status === 3 ? (
    //                     <Menu.Item disabled={data.status !== 3} key="1">
    //                       <span
    //                         onClick={() =>
    //                           data.status === 3 ? handleApprove(id) : true
    //                         }
    //                       >
    //                         <SafetyOutlined />
    //                         Approve
    //                       </span>
    //                     </Menu.Item>
    //                   ) : (
    //                     ""
    //                   )}

    //                   {data.status === 3 ? (
    //                     <Menu.Item disabled={data.status !== 3} key="2">
    //                       <span
    //                         onClick={() =>
    //                           data.status === 3 ? showModal(id) : true
    //                         }
    //                         // onClick={() => showModal(id)}
    //                       >
    //                         <CloseCircleOutlined />
    //                         Reject
    //                       </span>
    //                     </Menu.Item>
    //                   ) : (
    //                     ""
    //                   )}

    //                   {data.status === 4 ? (
    //                     <Menu.Item
    //                       disabled={
    //                         data.dir_approval.direktur1 ===
    //                         sessionStorage.getItem("fullname")
    //                       }
    //                       key="1"
    //                     >
    //                       <span
    //                         onClick={() =>
    //                           data.status === 4 ? handleApprove(id) : true
    //                         }
    //                       >
    //                         <SafetyOutlined />
    //                         Approve
    //                       </span>
    //                     </Menu.Item>
    //                   ) : (
    //                     ""
    //                   )}

    //                   {data.status === 4 ? (
    //                     <Menu.Item
    //                       disabled={
    //                         data.dir_approval.direktur1 ===
    //                         sessionStorage.getItem("fullname")
    //                       }
    //                       key="2"
    //                     >
    //                       <span
    //                         onClick={() =>
    //                           data.status === 4 ? showModal(id) : true
    //                         }
    //                       >
    //                         <CloseCircleOutlined />
    //                         Reject
    //                       </span>
    //                     </Menu.Item>
    //                   ) : (
    //                     ""
    //                   )}

    //                   {data.status === 5 ? (
    //                     <Menu.Item disabled="true" key="1">
    //                       <span>
    //                         <SafetyOutlined />
    //                         Approve
    //                       </span>
    //                     </Menu.Item>
    //                   ) : (
    //                     ""
    //                   )}

    //                   {data.status === 5 ? (
    //                     <Menu.Item disabled="true" key="2">
    //                       <span>
    //                         <CloseCircleOutlined />
    //                         Reject
    //                       </span>
    //                     </Menu.Item>
    //                   ) : (
    //                     ""
    //                   )}
    //                 </Menu>
    //               )
    //             ) : sessionStorage.getItem("role_name") === "Admin" ? (
    //               <Menu>
    //                 <Menu.Item
    //                   disabled={
    //                     data.grand_total < budgetArea
    //                       ? data.status === 4
    //                       : data.status === 5
    //                   }
    //                   key="1"
    //                 >
    //                   <span
    //                     onClick={() =>
    //                       data.status !== 5 ? handleApprove(id) : true
    //                     }
    //                   >
    //                     <SafetyOutlined />
    //                     Approve
    //                   </span>
    //                 </Menu.Item>

    //                 <Menu.Item
    //                   disabled={
    //                     data.grand_total < budgetArea
    //                       ? data.status === 4
    //                       : data.status === 5
    //                   }
    //                   key="2"
    //                 >
    //                   <span
    //                     onClick={() =>
    //                       data.status !== 5 ? showModal(id) : true
    //                     }
    //                     // onClick={() => showModal(id)}
    //                   >
    //                     <CloseCircleOutlined />
    //                     Reject
    //                   </span>
    //                 </Menu.Item>
    //               </Menu>
    //             ) : (
    //               ""
    //             )
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDivHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Direktur") {
      return (
        filter.key !== "approvalUser" &&
        // filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Admin") {
      return filter.key !== "approvalAdmin";
    } else if (
      sessionStorage.getItem("role_name") !== "Department Head" &&
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Direktur"
    ) {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalStatus" &&
        filter.key !== "approvalDeptHead"
      );
    }
    return filter;
  });

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Why Reject?"
        visible={isModalVisible}
        onOk={() => {
          handleReject(selectedId);
        }}
        confirmLoading={loading}
        onCancel={handleCancel}
      >
        <Radio.Group
          style={{ marginBottom: 20 }}
          onChange={(e) => handleChangeStatus("status", e.target.value)}
          value={data.status}
        >
          <Radio value={3}>Review</Radio>
          <Radio value={4}>Reject</Radio>
        </Radio.Group>
        <div name="control-hooks">
          <TextArea
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          ></TextArea>
        </div>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="Approval Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("approve")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <CheckCircleOutlined
                  style={{ fontSize: "20px", color: "#008000" }}
                />
                Approved
              </Title>
              <div className="value-font-size "> {count.approve}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("pending")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ExclamationCircleOutlined
                  style={{ fontSize: "20px", color: "#FFFF00" }}
                />
                Pending
              </Title>
              <div className="value-font-size "> {count.pending}</div>
            </Card>
          </Col>

          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("review")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <EyeOutlined style={{ fontSize: "20px", color: "#FF8C00" }} />{" "}
                Review
              </Title>
              <div className="value-font-size "> {count.review}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={24} md={4} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("reject")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <CloseCircleOutlined
                  style={{ fontSize: "20px", color: "#FF0000" }}
                />
                <row></row> Reject
              </Title>
              <div className="value-font-size "> {count.reject}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={24} md={8} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("all")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ProfileOutlined
                  style={{ fontSize: "20px", color: "#0000FF" }}
                />
                Total Document
              </Title>
              <div className="value-font-size "> {count.all}</div>
            </Card>
          </Col>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={10} lg={10}>
                  {types.find((x) => x.filter === type).title}
                </Col>
                <Col xs={24} md={14} lg={14} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                  </Space>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1550 }}
                    expandable={{
                      expandedRowRender: getPenyelesaianBiayaRupaRupaDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    columns={columns}
                    dataSource={approvalPenyelesaianBiayaRupaRupas.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: approvalPenyelesaianBiayaRupaRupas.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* MODAL COMMENT LIST */}
      <Modal
        className="modal-comment-list"
        visible={visible}
        title={`${commentTemp.DocNumber} - ${commentTemp.CreatedDate} `}
        onOk={submitComment}
        onCancel={closeComment}
        footer={[
          <Form.Item>
            <TextArea
              rows={4}
              value={commentTemp.comment_description}
              onChange={(e) => handleChangeComment("comment_description", e.target.value)}
            ></TextArea>
          </Form.Item>,
          <Button key="back" onClick={closeComment} >
            Close
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={submitComment} >
            Send
          </Button>
        ]}
      >
        { commentList ?
            commentList.comment_list && 
              commentList.comment_list.map((comment) => {
              return (
                <Comment
                  // actions={actions}
                  className={`${comment.username === sessionStorage.getItem('username') ? 'comment-right' : ''}`}
                  author={<span className="comment-title">{comment.full_name}</span>}
                  avatar={<Avatar className="comment-avatar" src={comment.avatar} alt={`${comment.full_name}`} />}
                  content={
                    <p>
                      {comment.comment_description}
                    </p>
                  }
                  datetime={
                    <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{comment.comment_date}</span>
                    </Tooltip>
                  }
                />
              )
            })
          : <div id="comment-new-list">{commentTemp.tempDataComment}</div>}
      </Modal>
      {/* END COMMENT LIST */}

    </Fragment>
  );
}

export default ApprovalPenyelesaianBiayaRupaRupa;
