import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Modal,
  Dropdown,
  Menu,
  Input,
  Table,
  DatePicker,
  Space,
  Typography,
  message,
  Radio,
  Tooltip,
} from "antd";
import AntNumberFormat from "../../components/AntNumberFormat";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./ApprovalBiayaPerjalananDinas.less";
import Search from "antd/lib/input/Search";
import {
  MoreOutlined,
  SafetyOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import "../SummaryDetail.less"

const { Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "pending",
    title: "Total Pending",
  },
  {
    filter: "approve",
    title: "Total Approve",
  },
  {
    filter: "review",
    title: "Total Review",
  },
  {
    filter: "reject",
    title: "Total Reject",
  },
];

function ApprovalBiayaPerjalananDinas() {
  const [type, setType] = useState("all");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keywordDetails, setKeywordDetails] = useState("");
  const [approvalPerdin, setApprovalPerdin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadReject, setLoadReject] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [count, setCount] = useState({
    all: "",
    pending: "",
    approve: "",
    reject: "",
    review: "",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "item_number_gr",
    order: "descend",
  });
  const [error, setError] = useState(null);
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [budgetArea, setBudgetArea] = useState(null);
  const [perjalananDinasDetails, setPerjalananDinasDetails] = useState([]);
  const [perjalananDinasDetailsTotal, setPerjalananDinasDetailsTotal] =
    useState(0);
  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
    getApprovalBiayaPerdinCount();
    getSetBudget();
  }, [keyword, page, sort, type, data.start_date, data.end_date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/approval-perjalanan-dinas`, {
      params: {
        keyword,
        columns: ["nama", "tujuan", "jenis_perjalanan", "deklarasi_perdin_code", "grand_total"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setApprovalPerdin(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const showModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      status: "",
      remark: "",
    });
    setIsModalVisible(false);
  };

  const getApprovalBiayaPerdinCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-perjalanan-dinas/count`, {
      params: {
        role_name: sessionStorage.getItem("role_name"),
        type,
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getBiayaPerjalananDinasDetails(data2[0]);
    }
  };

  const getBiayaPerjalananDinasDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/perjalanan-dinas/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["id_material", "material_description"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPerjalananDinasDetails(res.data.data);
        setPerjalananDinasDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/biaya-rupa-rupa/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApprove = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Perjalanan Dinas will be Approved</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApprove(id);
      },
    });
  };

  const confirmApprove = (id) => {
    setLoading(true);

    Axios.post(
      `${url}/approval-perjalanan-dinas/${id}`,
      {
        status: data.status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
        getApprovalBiayaPerdinCount();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeStatus = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleReject = (id) => {
    setLoading(true);
    setLoadReject(true);

    Axios.post(
      data.status === 3
        ? `${url}/review-perjalanan-dinas/${id}`
        : `${url}/reject-perjalanan-dinas/${id}`,
      {
        status: data.status,
        remark: data.remark,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
        getApprovalBiayaPerdinCount();
        closeModal();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadReject(false);
        setLoading(false);
      });
  };

  const handleClickFile = (x) => {
    console.log(x.bukti_scan_url, "click");
    window.open(x.bukti_scan_url);
  };

  const getPerjalananDinasDetails = () => {
    const columns = [
      {
        title: <div>GL CODE</div>,
        align: "center",
        dataIndex: "gl_code",
        key: "gl_code",
        // width: 150,
      },
      {
        title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
        align: "left",
        dataIndex: "description",
        key: "description",
        // width: 150,
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
      },
      {
        title: <div>QTY</div>,
        align: "center",
        dataIndex: "qty",
        key: "qty",
        render: (jumlah) => {
          return <AntNumberFormat value={jumlah} type="text" />;
        },
        // width: 120,
      },
      {
        title: <div style={{ textAlign: "center" }}>IDR</div>,
        align: "right",
        dataIndex: "rupiah",
        key: "rupiah",
        render: (rupiah) => {
          return <AntNumberFormat value={rupiah} type="text" />;
        },
        // width: 80,
      },
      {
        title: <div style={{ textAlign: "center" }}>USD</div>,
        align: "right",
        dataIndex: "usd",
        key: "usd",
        render: (usd) => {
          return <AntNumberFormat value={usd} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>TOTAL</div>,
        align: "right",
        dataIndex: "total",
        key: "total",
        sorter: true,
        render: (total) => {
          return <AntNumberFormat value={total} type="text" />;
        },
        // render: (price_po) => {
        //   return <AntNumberFormat value={price_po} type="text" />;
        // },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              scroll={{ x: 500 }}
              columns={columns}
              rowKey={(data, index) => index}
              loading={loadingDetail}
              dataSource={perjalananDinasDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: perjalananDinasDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ rupiah, total }) => {
                  totalAmount += rupiah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={2} className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalGrandTotal} type="text" />
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>DOCUMENT DATE</div>,
      dataIndex: "created_at",
      key: "created_at",
      width: 120,
      sorter: false,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "deklarasi_perdin_code",
      width: 120,
      align: "center",
      sorter: false,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "nama",
      key: "nama",
      width: 130,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 150,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      sorter: false,
      width: 200,
      key: "description",
    },
    {
      title: "FILE",
      dataIndex: "bukti_scan_details",
      sorter: false,
      align: "center",
      width: 200,
      render: (bukti_scan_url) =>
        bukti_scan_url &&
        bukti_scan_url.map((x, index) => {
          return x.bukti_scan.split(".")[1] === "jpg" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "jpeg" ? (
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconimage.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : x.bukti_scan.split(".")[1] === "pdf" ? (
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconpdf.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : (
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconexcel.png")}
              onClick={() => handleClickFile(x)}
            />
          );
        }),
    },
    {
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "grand_total",
      key: "grand_total",
      width: 120,
      align: "right",
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total} type="text" />;
      },
    },
    // {
    //   align: "center",
    //   title: <div style={{ textAlign: "center" }}>Type Of Travel</div>,
    //   dataIndex: "jenis_perjalanan",
    //   key: "jenis_perjalanan",
    //   width: 120,
    // },
    // {
    //   align: "center",
    //   title: <div style={{ textAlign: "center" }}>Destination</div>,
    //   dataIndex: "tujuan",
    //   key: "tujuan",
    //   width: 100,
    // },
    // {
    //   align: "center",
    //   title: <div style={{ textAlign: "center" }}>Departure </div>,
    //   dataIndex: "tanggal_berangkat",
    //   width: 150,
    //   key: "tanggal_berangkat",
    //   render: (date) => moment(date).format("DD-MM-YYYY"),
    // },
    // {
    //   align: "center",
    //   title: <div style={{ textAlign: "center" }}>Return </div>,
    //   dataIndex: "tanggal_pulang",
    //   width: 150,
    //   key: "tanggal_pulang",
    //   render: (date) => moment(date).format("DD-MM-YYYY"),
    // },
    // {
    //   align: "center",
    //   title: <div style={{ textAlign: "center" }}>Vehicle</div>,
    //   dataIndex: "kendaraan",
    //   key: "kendaraan",
    //   width: 100,
    // },
    // {
    //   title: <div style={{ textAlign: "center" }}>Description</div>,
    //   dataIndex: "description",
    //   width: 130,
    //   align: "left",
    //   sorter: false,
    // },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width: 200,
      render: (id, data) => {
        return (
          sessionStorage.getItem("role_name") === "Department Head" ? (
            <div>
            
            <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                            onClick={() =>
                              data.status === 1 ? handleApprove(id) : true
                            }
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/approve.png")
                              }
                            />
              </span>
              <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                            onClick={() =>
                              data.status === 1 ? showModal(id) : true
                            }
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/reject.png")
                              }
                            />
              </span>
              <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/chat.png")
                              }
                            />
              </span>

            </div>

          ) : sessionStorage.getItem("role_name") === "Division Head" ? (
            <div>
            
            <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                            onClick={() =>
                              data.status === 2 ? handleApprove(id) : true
                            }
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/approve.png")
                              }
                            />
              </span>

              <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                            onClick={() =>
                              data.status === 2 ? showModal(id) : true
                            }
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/reject.png")
                              }
                            />
              </span>

              <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/chat.png")
                              }
                            />
              </span>

            </div>

          ) : sessionStorage.getItem("role_name") === "Direktur" ? (
            data.grand_total < budgetArea ? 
            (
              <div>
                <span style={{marginRight: 10}}
                                size="small"
                                type="default"
                                onClick={() =>
                                  data.status === 3 ? handleApprove(id) : true
                                }
                              >
                                <img
                                  alt="icon"
                                  width="20"
                                  src={
                                    require("../../assets/img/approve.png")
                                  }
                                />
                  </span>
                  <span style={{marginRight: 10}}
                                size="small"
                                type="default"
                                onClick={() =>
                                data.status === 3 ? showModal(id) : true
                              }
                              >
                                <img
                                  alt="icon"
                                  width="20"
                                  src={
                                    require("../../assets/img/reject.png")
                                  }
                                />
                  </span>
                  <span style={{marginRight: 10}}
                                size="small"
                                type="default"
                              >
                                <img
                                  alt="icon"
                                  width="20"
                                  src={
                                    require("../../assets/img/chat.png")
                                  }
                                />
                  </span>
              </div> 
            ) : ""
        
          ) : sessionStorage.getItem("role_name") === "Admin" ? (
            <div>
            
            <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                            onClick={() =>
                              data.status !== 5 ? handleApprove(id) : true
                            }
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/approve.png")
                              }
                            />
              </span>
              <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                            onClick={() =>
                              data.status !== 5 ? showModal(id) : true
                            }
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/reject.png")
                              }
                            />
              </span>
              <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                            // onClick={() =>
                            //   data.status !== 5 ? showModal(id) : true
                            // }
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/delete.png")
                              }
                            />
              </span>
              <span style={{marginRight: 10}}
                            size="small"
                            type="default"
                          >
                            <img
                              alt="icon"
                              width="20"
                              src={
                                require("../../assets/img/chat.png")
                              }
                            />
              </span>

            </div>

          ) : null
        );
      }
    },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      sorter: false,
      width: 130,
      key: "transfer_to",
    },
    {
      title: "APPROVAL",
      children: [
        {
          // title:
          //   sessionStorage.getItem("role_name") === "Department Head"
          //     ? "Approval Status"
          //     : "Dept Head",
          title: " DEPT HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalUser",
          width: 110,
          render: (status, data) => {
            console.log(data.grand_total);
            console.log(budgetArea);
            return (
              <>
                {data.grand_total < budgetArea ? (
                  <Tooltip
                    placement="top"
                    title={
                      status !== 1 && data.pengajuan_um === "Sudah"
                        ? data.app_dept
                        : data.department.join(", ")
                    }
                  >
                    {data.grand_total < budgetArea ? (
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 1
                            ? require("../../assets/img/sand-clock.png")
                            : status === 2 ||
                              status === 3 ||
                              status === 4 ||
                              status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </Tooltip>
                ) : (
                  "-"
                )}
              </>
            );
          },
        },
        {
          // title:
          //   sessionStorage.getItem("role_name") === "Division Head"
          //     ? "Approval Status"
          //     : "Div Head",
          title: " DIV HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalDeptHead",
          width: 110,
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={
                  status !== 1 && data.pengajuan_um === "Sudah"
                    ? data.app_div
                    : data.department.join(", ")
                }
              >
                <img
                  alt="icon"
                  width="20"
                  src={
                    status === 2
                      ? require("../../assets/img/sand-clock.png")
                      : status === 2 ||
                        status === 3 ||
                        status === 4 ||
                        status === 5
                      ? require("../../assets/img/checked.png")
                      : status === -2
                      ? require("../../assets/img/warning.png")
                      : status === -1
                      ? require("../../assets/img/danger.png")
                      : require("../../assets/img/sand-clock.png")
                  }
                />
              </Tooltip>
            );
          },
        },
        {
          title: " DIRECTOR 1",
          dataIndex: "status",
          align: "center",
          key: "approvalDivHead",
          width: 110,
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={
                  status === 3 ||
                  status === 4 ||
                  (status === 5 && data.pengajuan_um === "Sudah")
                    ? data.app_dir1
                    : data.department.join(", ")
                }
              >
                <img
                  alt="icon"
                  width="20"
                  src={
                    status === 3
                      ? require("../../assets/img/sand-clock.png")
                      : status === 3 || status === 4 || status === 5
                      ? require("../../assets/img/checked.png")
                      : status === -2
                      ? require("../../assets/img/warning.png")
                      : status === -1
                      ? require("../../assets/img/danger.png")
                      : require("../../assets/img/sand-clock.png")
                  }
                />
              </Tooltip>
            );
          },
        },
        {
          title: " DIRECTOR 2",
          dataIndex: "status",
          align: "center",
          key: "approvalDirektur",
          width: 110,
          render: (status, data) => {
            return (
              <>
                {data.grand_total >= budgetArea ? (
                  <Tooltip
                    placement="top"
                    title={
                      status === 4 ||
                      (status === 5 && data.pengajuan_um === "Sudah")
                        ? data.app_dir2
                        : data.department.join(", ")
                    }
                  >
                    {data.grand_total >= budgetArea ? (
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 4
                            ? require("../../assets/img/sand-clock.png")
                            : status === 4 || status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </Tooltip>
                ) : (
                  "-"
                )}
              </>
            );
          },
        },
      ],
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      // fixed: "right",
      width: 50,
      key: "approvalStatus",
      render: (id, data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                sessionStorage.getItem("role_name") === "Department Head" ? (
                  data.grand_total < budgetArea ? (
                    <Menu>
                      <Menu.Item disabled={data.status !== 1} key="1">
                        <span
                          onClick={() =>
                            data.status === 1 ? handleApprove(id) : true
                          }
                        >
                          <SafetyOutlined />
                          Approve
                        </span>
                      </Menu.Item>
                      <Menu.Item
                        disabled={data.status !== 1}
                        key="handleAppr2oveDeptHead"
                      >
                        <span
                          onClick={() =>
                            data.status === 1 ? showModal(id) : true
                          }
                        >
                          <CloseCircleOutlined />
                          Reject
                        </span>
                      </Menu.Item>
                    </Menu>
                  ) : (
                    <Menu>
                      <Menu.Item disabled="true" key="1">
                        <span
                          onClick={() =>
                            data.status === 1 ? handleApprove(id) : true
                          }
                        >
                          <SafetyOutlined />
                          Approve
                        </span>
                      </Menu.Item>
                      <Menu.Item disabled="true" key="handleApproveDeptHead">
                        <span
                          onClick={() =>
                            data.status === 1 ? showModal(id) : true
                          }
                        >
                          <CloseCircleOutlined />
                          Reject
                        </span>
                      </Menu.Item>
                    </Menu>
                  )
                ) : sessionStorage.getItem("role_name") === "Division Head" ? (
                  <Menu>
                    <Menu.Item disabled={data.status !== 2} key="1">
                      <span
                        onClick={() =>
                          data.status === 2 ? handleApprove(id) : true
                        }
                      >
                        <SafetyOutlined />
                        Approve
                      </span>
                    </Menu.Item>

                    <Menu.Item disabled={data.status !== 2} key="2">
                      <span
                        onClick={() =>
                          data.status === 2 ? showModal(id) : true
                        }
                        // onClick={() => showModal(id)}
                      >
                        <CloseCircleOutlined />
                        Reject
                      </span>
                    </Menu.Item>
                  </Menu>
                ) : sessionStorage.getItem("role_name") === "Direktur" ? (
                  data.grand_total < budgetArea ? (
                    <Menu>
                      {/* {data.status === 3 ?  */}
                      <Menu.Item disabled={data.status !== 3} key="1">
                        <span
                          onClick={() =>
                            data.status === 3 ? handleApprove(id) : true
                          }
                        >
                          <SafetyOutlined />
                          Approve
                        </span>
                      </Menu.Item>
                      {/* : "" } */}

                      {/* {data.status === 3 ?  */}
                      <Menu.Item disabled={data.status !== 3} key="2">
                        <span
                          onClick={() =>
                            data.status === 3 ? showModal(id) : true
                          }
                          // onClick={() => showModal(id)}
                        >
                          <CloseCircleOutlined />
                          Reject
                        </span>
                      </Menu.Item>
                      {/* : "" } */}
                    </Menu>
                  ) : (
                    <Menu>
                      {data.status === 3 ? (
                        <Menu.Item disabled={data.status !== 3} key="1">
                          <span
                            onClick={() =>
                              data.status === 3 ? handleApprove(id) : true
                            }
                          >
                            <SafetyOutlined />
                            Approve
                          </span>
                        </Menu.Item>
                      ) : (
                        ""
                      )}

                      {data.status === 3 ? (
                        <Menu.Item disabled={data.status !== 3} key="2">
                          <span
                            onClick={() =>
                              data.status === 3 ? showModal(id) : true
                            }
                            // onClick={() => showModal(id)}
                          >
                            <CloseCircleOutlined />
                            Reject
                          </span>
                        </Menu.Item>
                      ) : (
                        ""
                      )}

                      {data.status === 4 ? (
                        <Menu.Item
                          disabled={
                            data.dir_approval.direktur1 ===
                            sessionStorage.getItem("fullname")
                          }
                          key="1"
                        >
                          <span
                            onClick={() =>
                              data.status === 4 ? handleApprove(id) : true
                            }
                          >
                            <SafetyOutlined />
                            Approve
                          </span>
                        </Menu.Item>
                      ) : (
                        ""
                      )}

                      {data.status === 4 ? (
                        <Menu.Item
                          disabled={
                            data.dir_approval.direktur1 ===
                            sessionStorage.getItem("fullname")
                          }
                          key="2"
                        >
                          <span
                            onClick={() =>
                              data.status === 4 ? showModal(id) : true
                            }
                          >
                            <CloseCircleOutlined />
                            Reject
                          </span>
                        </Menu.Item>
                      ) : (
                        ""
                      )}

                      {data.status === 5 ? (
                        <Menu.Item disabled="true" key="1">
                          <span>
                            <SafetyOutlined />
                            Approve
                          </span>
                        </Menu.Item>
                      ) : (
                        ""
                      )}

                      {data.status === 5 ? (
                        <Menu.Item disabled="true" key="2">
                          <span>
                            <CloseCircleOutlined />
                            Reject
                          </span>
                        </Menu.Item>
                      ) : (
                        ""
                      )}
                    </Menu>
                  )
                ) : sessionStorage.getItem("role_name") === "Admin" ? (
                  <Menu>
                    <Menu.Item disabled={data.status !== 3} key="1">
                      <span
                        onClick={() =>
                          data.status === 3 ? handleApprove(id) : true
                        }
                      >
                        <SafetyOutlined />
                        Approve
                      </span>
                    </Menu.Item>

                    <Menu.Item disabled={data.status !== 3} key="2">
                      <span
                        onClick={() =>
                          data.status === 3 ? showModal(id) : true
                        }
                        // onClick={() => showModal(id)}
                      >
                        <CloseCircleOutlined />
                        Reject
                      </span>
                    </Menu.Item>

                    <Menu.Item key="3">
                      <span onClick={() => handleDelete(id)}>
                        <DeleteOutlined />
                        Delete
                      </span>
                    </Menu.Item>
                  </Menu>
                ) : (
                  ""
                )
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDivHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Direktur") {
      return (
        filter.key !== "approvalUser" &&
        // filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Admin") {
      return filter.key !== "approvalAdmin";
    } else if (
      sessionStorage.getItem("role_name") !== "Department Head" &&
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Direktur"
    ) {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalStatus" &&
        filter.key !== "approvalDeptHead"
      );
    }
    return filter;
  });

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Why Reject?"
        visible={isModalVisible}
        onOk={() => {
          handleReject(selectedId);
        }}
        confirmLoading={loadReject}
        onCancel={handleCancel}
      >
        <Radio.Group
          style={{ marginBottom: 20 }}
          onChange={(e) => handleChangeStatus("status", e.target.value)}
          value={data.status}
        >
          <Radio value={3}>Review Again</Radio>
          <Radio value={4}>Reject</Radio>
        </Radio.Group>
        <div name="control-hooks">
          <Input
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          />
        </div>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="Approval Travel Expense"
            //extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          {/* <Col xs={24}> */}
          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("approve")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <CheckCircleOutlined
                  style={{ fontSize: "20px", color: "#008000" }}
                />
                <row></row> Approved
              </Title>
              <div className="value-font-size "> {count.approve}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("pending")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ExclamationCircleOutlined
                  style={{ fontSize: "20px", color: "#FFFF00" }}
                />
                <row></row> Pending
              </Title>
              <div className="value-font-size "> {count.pending}</div>
            </Card>
          </Col>

          <Col className="calc-width-card-5" xs={12} md={4} span={2}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("review")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <EyeOutlined style={{ fontSize: "20px", color: "#FF8C00" }} />{" "}
                <row></row> Review
              </Title>
              <div className="value-font-size "> {count.review}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={24} md={4} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("reject")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <CloseCircleOutlined
                  style={{ fontSize: "20px", color: "#FF0000" }}
                />
                <row></row> Reject
              </Title>
              <div className="value-font-size "> {count.reject}</div>
            </Card>
          </Col>
          <Col className="calc-width-card-5" xs={24} md={8} span={8}>
            <Card className="border-card-counting-summary" onClick={() => handleChangeType("all")}>
              <Title style={{ fontSize: "15px", alignItems: "left" }}>
                <ProfileOutlined
                  style={{ fontSize: "20px", color: "#0000FF" }}
                />
                <row></row> Total Document
              </Title>
              <div className="value-font-size "> {count.all}</div>
            </Card>
          </Col>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={10} lg={10}>
                  {types.find((x) => x.filter === type).title}
                </Col>
                <Col xs={24} md={14} lg={14} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                    {/* <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                    /> */}
                  </Space>
                  {/* <Search
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                    /> */}
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    scroll={{ x: 1500 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getPerjalananDinasDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    dataSource={approvalPerdin.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: approvalPerdin.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ApprovalBiayaPerjalananDinas;
