import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import { BorderlessTableOutlined, ControlOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, MoreOutlined, PicLeftOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Layout, Menu, message, Modal, Row, Table, Input,Tag } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import fileDownload from "js-file-download";

const { Content } = Layout;

const Company = (props) => {
  const [company, setCompany] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingDwn, setLoadingDwn] = useState(false)
  const [generateLoading, setGenerateLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [keyword, setKeyword] = useState("");
  const { Search } = Input;
  const initData = () => {
    setLoading(true)

    Axios.get(`${url}/company`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }).then(res => {
      // console.log(res.data.data);
      setCompany(res.data.data)
      
    }).catch(err => {
      if (err.response) {
        message.error(err.response.data.message);
        
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
          props.history.push("/login");
        }
      }
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    initData()
  }, [])

  const handleDelete = (id) => {
    setLoading(true)

    Axios.delete(`${url}/company/delete/${id}`, {}, {},)
    .then(res => {
      message.success(res.data.message)

      initData()
    }).catch(err => {
      if (err.response) {
        message.error(err.response.data.message);
        
        if (err.response.status === 401) {
          // sessionStorage.removeItem("token");
        }
      }
    }).finally(() => setLoading(false))
  }

  const confirmToDelete = (company) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Delete company <b>{company.name}</b></p>
        </div>
      ),
      okText: "Yes",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        handleDelete(company.code);
      },
    });
  }
  
  const handleSearch = (value) => {
    setKeyword(value);
  };

  const downloadCompany = () => {
    setLoadingDwn(true)
    Axios.get(`${url}/company/export`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      setLoadingDwn(false)
      fileDownload(res.data, moment(new Date()).format("YYYY-MM-DD")+"-data-company.xlsx");
    });
  }
    
  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    
    {
      title: 'Status',
      dataIndex: 'table_generated',
      key: 'table_generated',
      align: 'center',
      render: (item) => {
        return (
          <Fragment>
            {item ? (<Tag color="success" icon={<CheckCircleOutlined />}>Generated</Tag>):(<Tag  icon={<CloseCircleOutlined />} color="error">Not-Generated</Tag>)}
          </Fragment>
        )
      }
    },
    {
      title: 'Abbre.CC',
      dataIndex: 'abbre_cc',
      key: 'abbre_cc',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'NPWP',
      dataIndex: 'npwp',
      key: 'npwp',
    },
    {
      title: 'No.Telp',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '',
      key: 'operation',
      width: '150',
      align: 'right',
      render: (item) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/company/edit/${item.key}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => confirmToDelete(item)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        )
      }
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.table_generated,
      name: record.name,
    }),
  };

  const generateTable = () => {
    setGenerateLoading(true)
    Axios.post(`${url}/company/generate-tables`, {
      codes: selectedRowKeys
    }, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }
    }).then(res => {
      setGenerateLoading(false)
      message.success("Table generate success.")
      setSelectedRowKeys([]);
      initData()
    }).catch(function (error) {
      setGenerateLoading(false)
      message.error(error.message)
      setSelectedRowKeys([]);
      initData()
    })
  }
  
  return (
    <Fragment>
  
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Company"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data-b">
              <Button   loading={loading} type="primary" style={{marginBottom: '15px'}} onClick={generateTable} loading={generateLoading} disabled={selectedRowKeys.length === 0}>
                <ControlOutlined />
                Generate
              </Button>
              <Link to="/company/create">
                          <Button style={{"margin-left": "10px"}} type="primary">
                            <PlusOutlined />
                            Create
                          </Button>
              </Link>
              <Link  to="/company/upload">
                          <Button style={{"margin-left": "10px"}}  type="primary">
                            <UploadOutlined />
                            Upload
                          </Button>
              </Link> 
              <a href="javascript:void()" onClick={downloadCompany}>
              <Button style={{"margin-left": "10px"}}  loading={loadingDwn}  type="primary">
                           <DownloadOutlined /> Download Company
                          </Button>
                      
              </a>
              <br/>
              {/* <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col> */}
              {(
                <Table 
                size="small"
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  rowKey={(company) => company.code}
                  loading={loading} 
                  dataSource={company} 
                  columns={columns} bordered/>
                  // <Table
                  //   size="small"
                  //   columns={columns}
                  //   dataSource={users.data}
                  //   rowKey={(data) => data._id}
                  //   onChange={handleTableChange}
                  //   pagination={{
                  //     pageSize: 10,
                  //     current: page,
                  //     total: users.total,
                  //     showSizeChanger: false,
                  //   }}
                  //   loading={loading}
                  //   bordered
                  // />
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  )
}

export default Company;