import React, { useState } from "react";
import { Input, Tabs, Button, message, Form } from "antd";
import "./Login.less";
import { UserOutlined, LockOutlined, RedoOutlined } from "@ant-design/icons";
import QrReader from "react-qr-reader";
import Axios from "axios";
import { url } from "../../config/global";
import { Link, Redirect } from "react-router-dom";

const { TabPane } = Tabs;

function Login(props) {
  const [tab, setTab] = useState("1");
  const [success, setSuccess] = useState(true);
  const [login, setLogin] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //   const handleScan = async (value) => {
  //     if (value !== null) {
  //       await Axios.post(`${url}/login-qr`, {
  //         username: value,
  //       })
  //         .then((res) => {
  //           sessionStorage.setItem("token", res.data.token);
  //           sessionStorage.setItem("fullname", res.data.data.full_name);
  //           sessionStorage.setItem(
  //             "avatar",
  //             res.data.data.photo ? res.data.data.photo_url : ""
  //           );
  //           sessionStorage.setItem(
  //             "permissions",
  //             JSON.stringify(res.data.permissions)
  //           );
  //           sessionStorage.setItem("redirect", res.data.redirect);

  //           message.success(res.data.message);
  //           setLoading(false);
  //           props.history.push(res.data.redirect);
  //         })
  //         .catch((err) => {
  //           message.error(err.response.data.message);
  //           setSuccess(false);
  //         });
  //     }
  //   };

  const handleChange = (name, value) => {
    setError(null);
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const handleError = (err) => {
    console.log(err);
  };

  const handleTab = (key) => {
    setTab(key);
  };

  const handleRetry = () => {
    setSuccess(true);
  };

  const handleLogin = async () => {
    setLoading(true);
    await Axios.post(`${url}/login`, {
      username: login.username,
      password: login.password,
    })
      .then((res) => {
        const ObjDiv = res.data.data.divisi;
        const arrDiv = [];
        if (ObjDiv) {
          for (let i = 0; i < ObjDiv.length; i++) {
            const el = ObjDiv[i].name;
            arrDiv.push(el)
          }
        }else{
          arrDiv.push('');
        }
        console.log(res)
        
        if(res.data.data.companies) {

          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("username", res.data.data.username);
          sessionStorage.setItem("fullname", res.data.data.full_name);
          sessionStorage.setItem("npk", res.data.data.npk);
          sessionStorage.setItem("divisi", arrDiv);
          sessionStorage.setItem("department", JSON.stringify(res.data.data.department));
          sessionStorage.setItem("io_department", JSON.stringify(res.data.data.io_department));
          sessionStorage.setItem("reg_company_code", JSON.stringify(res.data.data.companies));
          sessionStorage.setItem(
            "avatar",
            res.data.data.photo ? res.data.data.photo : ""
          );
          sessionStorage.setItem(
            "permissions",
            JSON.stringify(res.data.permissions)
          );
          sessionStorage.setItem("redirect", res.data.redirect);
          sessionStorage.setItem("role_name", res.data.data.role_name);
          localStorage.setItem("user_companies", JSON.stringify(res.data.data.companies));
          
          checkUsrCompany(res.data.message)
        } else {
          return message.warning("Company permissions on your account have not been set, please contact the IT team.");
        }

        
        // message.success(res.data.message);
        setLoading(false);
        
      })
      .catch((err) => {
        console.log(err)
        if(err.response == null)
        {
          setLoading(false);
          return message.warning("Unable to reach server, please check your network connection.");
        }
        // console.log(err.response.data)
        message.error(err.response.data.message);
        setLoading(false);
        if (err.response.status === 422) {
          setError(err.response.data.errors);
        }
      });
  };
  const checkUsrCompany = (msg) => {
    var jsoncomp = JSON.parse(sessionStorage.getItem('reg_company_code'));
    // console.log(jsoncomp.length)
    // return false;
    if(jsoncomp==null || jsoncomp == '' || jsoncomp == 'undefined')
    {
      sessionStorage.clear()
      setLoading(false);
      return message.warning("Company permissions on your account have not been set, please contact the IT team.");
    }

    if(jsoncomp.length <= 1)
    {
      message.success(msg);
      sessionStorage.setItem("company_code", jsoncomp[0]);
      Axios.defaults.headers.common['COMPANY-CODE'] = sessionStorage.getItem("company_code");
      setLoading(false);
      props.history.push('select-company');
      // window.location.href = "/home-approval"
    } else {
      message.success(msg);
      setLoading(false);
      props.history.push('select-company');
    }
  
  }
  // return (
  //   <div className="main">
  //     <div>
  //       <img
  //         src={require("../../assets/img/logo-dharma-polimetal.png")}
  //         className="img-logo"
  //         alt="DEM"
  //       />
  //       <Tabs activeKey={tab} onChange={handleTab}>
  //         <TabPane tab="Sign In" key="1">
  //           <Form onFinish={handleLogin}>
  //             <Form.Item
  //               validateStatus={error && error.username ? "error" : false}
  //               help={error && error.username ? error.username[0] : false}
  //             >
  //               <Input
  //                 className="login-input"
  //                 prefix={<UserOutlined />}
  //                 placeholder="Username / NPK"
  //                 onChange={(e) => handleChange("username", e.target.value)}
  //                 value={login.username}
  //               />
  //             </Form.Item>
  //             <Form.Item
  //               validateStatus={error && error.password ? "error" : false}
  //               help={error && error.password ? error.password[0] : false}
  //             >
  //               <Input
  //                 className="login-input"
  //                 prefix={<LockOutlined />}
  //                 type="password"
  //                 placeholder="Password"
  //                 onChange={(e) => handleChange("password", e.target.value)}
  //                 value={login.password}
  //               />
  //             </Form.Item>
  //             <div>
  //               <Button
  //                 htmlType="submit"
  //                 loading={loading}
  //                 type="primary"
  //                 className="button-login"
  //               >
  //                 Login
  //               </Button>
  //               <Link className="button-forgot" to="/forgot-password">
  //                 Forgot Password
  //               </Link>
  //             </div>
  //             {/* <div
  //               style={{
  //                 fontWeight: "bold",
  //                 fontSize: "14px",
  //                 textAlign: "center",
  //                 marginTop: 30,
  //                 marginBottom: 30,
  //               }}
  //             >
  //               Don't have an account yet? Register below
  //             </div>

  //             <Link to="/register">
  //               <Button
  //                 style={{
  //                   display: "flex",
  //                   margin: "auto",
  //                 }}
  //                 // htmlType="submit"
  //                 // loading={loading}
  //                 type="primary"
  //                 // className="Register"
  //               >
  //                 Register
  //               </Button>
  //             </Link> */}
  //           </Form>
  //         </TabPane>
  //         {/* {
  //           <TabPane tab="Sign in with Barcode" key="3">
  //             {tab === "3" && success ? (
  //               <QrReader
  //                 delay={100}
  //                 onError={handleError}
  //                 onScan={handleScan}
  //                 className="barcode"
  //                 showViewFinder={false}
  //               />
  //             ) : (
  //               <div className="barcode-fail">
  //                 <p>Login Failed</p>
  //                 <p>Retry</p>
  //                 <Button type="link" size="large" onClick={handleRetry}>
  //                   <RedoOutlined />
  //                 </Button>
  //               </div>
  //             )}
  //           </TabPane>
  //         } */}
  //       </Tabs>
  //     </div>
  //   </div>
  // );
  return (
    <div className="main-login">
        <div className="box-login">
          <div className="box-submit">
            <img
              src={require("../../assets/img/Logo-Dharma2.png")}
              className="img-logo"
              alt="DEM"
            />
          </div>
          <div className="content-login">

            <div className="box-submit">
              <span className="label-login sign">SIGN IN</span>
            </div>
            <Form onFinish={handleLogin} className="formLogin">
              <div style={{marginLeft: 30, marginRight: 30}}>
                <Form.Item
                  validateStatus={error && error.username ? "error" : false}
                  help={error && error.username ? error.username[0] : false}
                >
                  <Input
                    className="login-input"
                    prefix={<UserOutlined />}
                    placeholder="Username / NPK"
                    onChange={(e) => handleChange("username", e.target.value)}
                    value={login.username}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={error && error.password ? "error" : false}
                  help={error && error.password ? error.password[0] : false}
                >
                  <Input
                    className="login-input"
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => handleChange("password", e.target.value)}
                    value={login.password}
                  />
                </Form.Item>
              </div>
                <div className="box-submit">
                  <Button
                    htmlType="submit"
                    loading={loading}
                    type="primary"
                    className="button-login"
                  >
                    Login
                  </Button>
                </div>
                <div className="box-submit forgot-btn">
                  <Link className="button-forgot" to="/forgot-password">
                    <span className="label-login">
                      Forgot Password
                    </span>
                  </Link>
                </div>
            </Form>
          </div>
              {/* <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: 30,
                  marginBottom: 30,
                }}
              >
                Don't have an account yet? Register below
              </div>

              <Link to="/register">
                <Button
                  style={{
                    display: "flex",
                    margin: "auto",
                  }}
                  // htmlType="submit"
                  // loading={loading}
                  type="primary"
                  // className="Register"
                >
                  Register
                </Button>
              </Link> */}
            {/* </Form> */}
            <div className="box-submit">
              <div className="footer-login">
                  <span className="footer-text">
                    © 2022 PT. Dharma Polimetal Tbk
                  </span>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Login;
