import React, {useState} from 'react'
import { Menu, Badge, Avatar, Space, Dropdown, Tabs, List, Button, Input, Typography } from 'antd'
import { MenuFoldOutlined, UserOutlined, LogoutOutlined, MenuUnfoldOutlined, EditOutlined,ContainerOutlined } from '@ant-design/icons'
import './Navbar.less'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { url } from "../config/global";

const { TabPane } = Tabs
const { Title } = Typography

const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    }
]

function Navbar(props) {
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState(false)
    const [npk, setNpk] = useState(sessionStorage.getItem("npk"))

    const urlAvatarLogin = `${url.split('api')[0]}storage/images/${sessionStorage.getItem("avatar")}`;
    const handleNotification = () => {
        setVisible(!visible)
    }

    const handleSeeMore = () => {
        setVisible(false)
    }

    const handleLogout = () => {
        console.log('terclick')
        sessionStorage.clear()

        return props.history.push('/login') 
    }

    const handleProfile = () => {
        return props.history.push(`/user/edit/${npk}`) 
    }
    const handleSelectCompany = () => {
        return props.history.push(`/select-company`) 
    }

    const handleToggleSearch = (value = false) => {
        console.log(value)
        setSearch(value)
    }

    return (
        <div className="navbar">
            <div>
                { props.collapsed ? <MenuUnfoldOutlined onClick={props.onToggleCollapsed} /> : <MenuFoldOutlined onClick={props.onToggleCollapsed} /> }
            </div>
            <div className='running-text'>
                <marquee>
                   {props.runningText}
                </marquee>                               
            </div>
            <div>
                <Menu mode="horizontal" className="menu-header gradasi-drma">
                    <Menu.Item key="profile">
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="3">
                                    <span onClick={handleLogout}> 
                                        <LogoutOutlined />Logout
                                    </span>
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <span onClick={handleProfile}> 
                                        <EditOutlined />Profile
                                    </span>
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <span onClick={handleSelectCompany}> 
                                    <ContainerOutlined />Select Company
                                    </span>
                                </Menu.Item>
                            </Menu>
                            
                        } trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <Space>
                                    {
                                        sessionStorage.getItem('avatar') ? (
                                            <Avatar size="small" src={urlAvatarLogin} />
                                        ) : (
                                            <Avatar size="small" icon={<UserOutlined />} />
                                        )
                                    }
                                    { !props.screens.xs && <span> { sessionStorage.getItem('fullname') } </span> }
                                </Space>
                            </a>
                        </Dropdown>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    )
}

export default Navbar
