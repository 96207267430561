import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Dropdown,
  Menu,
  Col,
  Modal,
  Input,
  Table,
  DatePicker,
  Space,
  message,
  Radio,
  Typography,
  Tooltip,
  Comment, Avatar, Badge, Button, Form
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./LeadTime.less";
import Search from "antd/lib/input/Search";
import _ from "underscore";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExclamationOutlined,
  EyeOutlined,
  MoreOutlined,
  ProfileOutlined,
  SafetyOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import TextArea from "antd/lib/input/TextArea";
import "../SummaryDetail.less"
import fileDownload from "js-file-download";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Meta } = Card;
const { Title } = Typography;

function LeadTime(props) {
  const [error, setError] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [leadTimeReport, setLeadTimeReport] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  
  const [sortDetail, setSortDetail] = useState({
    sort: "item_number_gr",
    order: "descend",
  });
  
  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const [buttonAccess, setButtonAccess] = useState(null);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, date]);

  const exportLead = () => {
    setLoading(true);
    Axios.get(`${url}/lead-time-report/download`, {
      params: {
        keyword,
        start_date: date.from ? date.from.format("YYYY-MM-DD") : "",
        end_date: date.to ? date.to.format("YYYY-MM-DD") : "",
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Lead Time" + ".xlsx");
      setLoading(false);
    });
  };
  
  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
      // if (findPermission && findPermission.children) {
      //   const findButtonAccess = findPermission.children.filter(
      //     (x) => x.permission_type === "button"
      //   );
      //   if (findButtonAccess) {
      //     setButtonAccess(findButtonAccess);
      //   }
      // }
    }
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/lead-time-report`, {
      params: {
        keyword,
        columns: [
          "document_number",
        ],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        start_date: date.from ? date.from.format("YYYY-MM-DD") : "",
        end_date: date.to ? date.to.format("YYYY-MM-DD") : "",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setLeadTimeReport(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const calculateTimes = (fromDate, toDate) => {
    var from = new Date(fromDate);
    var to = new Date(toDate);
    
    var diffMs = (to - from); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    //hitung selisih
    var duration = (diffDays * 24 * 60) + (diffHrs * 60) + (diffMins)
    return duration;
  }

  const calculateTotalTime = (fromDate, toDate) => {
    var from = new Date(fromDate);
    var to = new Date(toDate);
   
    var diffMs = (to - from); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var diffMss = Math.round((((diffMs % 86400000) % 3600000) % 60000) / 1000); // seconds
   
    var hour = (diffDays * 24) + (diffHrs)
    var duration = (hour.toString().length == 1 ? '0' + hour : hour) + ':' + (diffMins.toString().length == 1 ? '0' + diffMins : diffMins ) + ':' + (diffMss.toString().length == 1 ? '0' + diffMss : diffMss)
    return duration;
  }

  const handleChangeDate = (value) => {
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };


  const columns = [
    {
      title: "DOCUMENT NUMBER",
      dataIndex: "document_number",
      key: "document_number",
      align: "center",
      width: 180,
      sorter: false,
      render: (id, data) => {
        if(data.reminder_status=='pengajuan'){
          return (
            <span>{data.pengajuan_id}</span>
          )
        }else{
          return (
            <span>{data.penyelesaian_id}</span>
          )
        }
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "nama",
      key: "nama",
      align: "left",
      width: 130,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 100,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: "CREATED ON",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      sorter: true,
      width: 150,
      render: (date) => moment(date).format("DD-MMMM-YYYY HH:mm:ss"),
    },
    {
      title: "APPROVAL",
      children: [
        {
          title: " DEPT HEAD",
          dataIndex: "appdate_dept",
          align: "center",
          key: "appdate_dept",
          width: 110,
          render: (appdate_dept) => {
            if(appdate_dept){
              return(
                moment(appdate_dept).format("DD-MMMM-YYYY HH:mm:ss")
              )
            }else{
              return ('-')
            }
          } 
        },
        {
          title: " DIV HEAD",
          dataIndex: "appdate_div",
          align: "center",
          key: "appdate_div",
          width: 110,
          render: (appdate_div) => {
            if(appdate_div){
              return(
                moment(appdate_div).format("DD-MMMM-YYYY HH:mm:ss")
              )
            }else{
              return ('-')
            }
          } 
        },
        {
          title: " DIRECTOR 1",
          dataIndex: "appdate_dir1",
          align: "center",
          key: "appdate_dir1",
          width: 110,
          render: (appdate_dir1) => {
            if(appdate_dir1){
              return(
                moment(appdate_dir1).format("DD-MMMM-YYYY HH:mm:ss")
              )
            }else{
              return ('-')
            }
          } 
            
        },
        {
          title: " DIRECTOR 2",
          dataIndex: "appdate_dir2",
          align: "center",
          key: "appdate_dir2",
          width: 110,
          render: (appdate_dir2) => {
            if(appdate_dir2){
              return(
                moment(appdate_dir2).format("DD-MMMM-YYYY HH:mm:ss")
              )
            }else{
              return ('-')
            }
          } 
        },
      ],
    },
    {
      title: "RECEIVED",
      children: [
        {
          title: " ACCOUNTING",
          dataIndex: "appdate_acc",
          align: "center",
          key: "appdate_acc",
          width: 110,
          render: (appdate_acc) => {
            if(appdate_acc){
              return(
                moment(appdate_acc).format("DD-MMMM-YYYY HH:mm:ss")
              )
            }else{
              return ('-')
            }
          } 
        },
        {
          title: " FINANCE",
          dataIndex: "appdate_fin",
          align: "center",
          key: "appdate_fin",
          width: 110,
          render: (appdate_fin) => {
            if(appdate_fin){
              return(
                moment(appdate_fin).format("DD-MMMM-YYYY HH:mm:ss")
              )
            }else{
              return ('-')
            }
          } 
        },
      ],
    },
    {
      title: "TOTAL DURATION",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      sorter: true,
      width: 150,
      render: (status, data) => {
        let from = data.created_at;
        let to = ''
        let total = 0
        if(data.appdate_dept){
          to = data.appdate_dept
        }
        if(data.appdate_div){
          to = data.appdate_div
        }
        if(data.appdate_dir1){
          to = data.appdate_dir1
        }
        if(data.appdate_dir2){
          to = data.appdate_dir2
        }
        if(data.appdate_fin){
          to = data.appdate_fin
        }
        if(data.appdate_acc){
          to = data.appdate_acc
        }
        if(to==''){
          return ('-')
        }else{
          return calculateTotalTime(from, to);
        }
      }
    },
    {
      title: "DURATION(MINUTES)",
      children: [
        {
          title: " DEPT HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalUser",
          width: 110,
          render: (status, data) => {
            if(data.appdate_dept){
              return calculateTimes(data.created_at, data.appdate_dept);
            }else{
              return ('-')
            }
          }
        },
        {
          title: " DIV HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalDeptHead",
          width: 110,
          render: (status, data) => {
            if(data.appdate_div && data.appdate_dept){
              return calculateTimes(data.appdate_dept, data.appdate_div);
            }else{
              return('-')
            }
          }
        },
        {
          title: " DIRECTOR 1",
          dataIndex: "status",
          align: "center",
          key: "approvalDivHead",
          width: 110,
          render: (status, data) => {
            if(data.appdate_div && data.appdate_dir1){
              return calculateTimes(data.appdate_div, data.appdate_dir1);
            }else{
              return('-')
            }
          }
        },
        {
          title: " DIRECTOR 2",
          dataIndex: "status",
          align: "center",
          key: "approvalDirektur",
          width: 110,
          render: (status, data) => {
            if(data.appdate_dir2 && data.appdate_dir1){
              return calculateTimes(data.appdate_dir1, data.appdate_dir2);
            }else{
              return('-')
            }
          }
        },
        {
          title: " ACCOUNTING",
          dataIndex: "status",
          align: "center",
          key: "approvalDirektur",
          width: 110,
          render: (status, data) => {
            if(data.appdate_dir2 && data.appdate_acc){
              return calculateTimes(data.appdate_dir2, data.appdate_acc);
            }else{
              return('-')
            }
          }
        },
        {
          title: " FINANCE",
          dataIndex: "status",
          align: "center",
          key: "approvalDirektur",
          width: 110,
          render: (status, data) => {
            if(data.appdate_acc && data.appdate_fin){
              return calculateTimes(data.appdate_acc, data.appdate_fin);
            }else{
              return('-')
            }
          }
        },
      ],
    },
   
  ].filter((filter) => {
    if (sessionStorage.getItem("role_name") === "Department Head") {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Division Head") {
      return (
        filter.key !== "approvalUser" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDivHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Direktur") {
      return (
        filter.key !== "approvalUser" &&
        // filter.key !== "approvalDirektur" &&
        filter.key !== "approvalDeptHead"
      );
    } else if (sessionStorage.getItem("role_name") === "Admin") {
      return filter.key !== "approvalAdmin";
    } else if (
      sessionStorage.getItem("role_name") !== "Department Head" &&
      sessionStorage.getItem("role_name") !== "Division Head" &&
      sessionStorage.getItem("role_name") !== "Direktur"
    ) {
      return (
        filter.key !== "approvalDivHead" &&
        filter.key !== "approvalDirektur" &&
        filter.key !== "approvalStatus" &&
        filter.key !== "approvalDeptHead"
      );
    }
    return filter;
  });

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            //className="card-a"
            type="inner"
            title="LEAD TIME REPORT "
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={6} lg={6}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "leadtime-report/export") && (
                        <Button
                          loading={loading}
                          type="primary"
                          onClick={exportLead}
                        >
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                    

                    {/* <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button> */}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={12} className="align-right">
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      onChange={handleChangeDate}
                      value={[date.from, date.to]}
                      format="DD MMM YYYY"
                      picker="date"
                    />
                  </Space>
                </Col>
                <Col xs={24} md={8} lg={6} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                    {/* <RangePicker
                      onChange={handleChangeDate}
                      value={[data.start_date, data.end_date]}
                      format="DD MMMM YYYY"
                    /> */}
                  </Space>
                  {/* <Search
                                        placeholder="Search ..."
                                        onSearch={(value) => handleSearch(value)}
                                    /> */}
                </Col>
                <Col span={24} style={{backgroundColor: '#daffb9', padding: 10}}>
                  <span style={{fontSize: 16, fontWeight: 'bold'}}>
                    TOTAL DOCUMENT : {leadTimeReport.total}
                  </span>
                </Col>
                <Col span={24} style={{backgroundColor: '#daffb9', padding: 10}}>
                  <span style={{fontSize: 16, fontWeight: 'bold'}}>
                    AVERAGE THIS MONTH (HOURS) : {leadTimeReport.average}
                  </span>
                </Col>
                <Col xs={24}>
                  <Table
                    bordered
                    scroll={{ x: 1500 }}
                    columns={columns}
                    dataSource={leadTimeReport.data}
                    rowKey={(data) => data._id}
                   
                    // pagination={{
                    //   pageSize: 10,
                    //   current: page,
                    //   total: leadTimeReport.total,
                    //   showSizeChanger: true,
                    // }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>      
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default LeadTime;
