import React, { Fragment, useState, useEffect } from "react";
import {
    Layout,
    Row,
    Col,
    Card,
    Breadcrumb,
    Typography,
    List,
    message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
const { Title } = Typography;

const { Content } = Layout;

function ViewInternalOrder(props) {
    const [data, setData] = useState({
        department: '',
        pic: '',
        internal_order_number: '',
        description: '',
        chart_of_account: '',
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Axios.get(`${url}/internal-order/${props.match.params.id}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                setData({
                    ...data,
                    department: res.data.data.department,
                    pic: res.data.data.pic,
                    internal_order_number: res.data.data.internal_order_number,
                    description: res.data.data.description,
                    chart_of_account: res.data.data.chart_of_account,
                });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        sessionStorage.removeItem("token");
                    }
                }
            });
    };

    const dataview = [
        {
            title: "Department",
            description: data && data.username,
        },
        {
            title: "PIC",
            description: data && data.pic,
        },
        {
            title: "IO Number",
            description: data && data.internal_order_number,
        },
    ];
    const dataview2 = [
        {
            title: "Description",
            description: data && data.description,
        },
        {
            title: "Chart Of Account",
            description: data && data.chart_of_account,
        },
    ];

    if (!sessionStorage.getItem("token")) {
        return <Redirect to="/login" />;
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>View User </Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/user">User </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>View User </Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={40} className="pr-25 pl-25 mb-25">
                                <Col sm={24} md={12}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataview}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<strong>{item.title}</strong>}
                                                    description={
                                                        item.description ? item.description : "-"
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                                <Col sm={24} md={12}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataview2}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<strong>{item.title}</strong>}
                                                    description={
                                                        item.description ? item.description : "-"
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}

export default ViewInternalOrder;