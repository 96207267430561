import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Select,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { CaretRightOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import AntNumberFormat from "../../components/AntNumberFormat";
import PenyelesaianUangMukaPerjalananDinas from "./PenyelesaianUangMukaPerjalananDinas";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function CreatePenyelesaianUangMukaPerjalananDinas(props) {
  const [data, setData] = useState({
    pengajuan_uang_muka_perjalanan_dinas_code: "",
    pengajuan_uang_muka_perjalanan_dinas_id: "",
    nama_karyawan: "",
    npk: "",
    divisi: "",
    tanggal_berangkat: "",
    tanggal_pulang: "",
    jenis_perjalanan: "",
    currency: "",
    kota_tujuan: "",
    kendaraan: "",
    nominal_kurs: "",
    code_name: "",
    description_head: ""
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [codes, setCodes] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [budgetArea, setBudgetArea] = useState(null);
  const [currency, setCurrency] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [penyelesaianUangMukaDetail, setPenyelesaianUangMukaDetail] = useState(
    []
  );
  const [listBudget, setListBudget] = useState([]);

  useEffect(() => {
    getSetBudget();
  }, []);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const onSaveData = () => {
    setLoading(true);

    if(listBudget.length != 0){
      Axios.post(
        `${url}/penyelesaian-uang-muka-perjalanan-dinas`,
        {
          perjalanan_dinas_id: data.perjalanan_dinas_id,
          perjalanan_dinas_code: data.deklarasi_umb_code,
          tanggal_berangkat: data.tanggal_berangkat,
          tanggal_pulang: data.tanggal_pulang,
          jenis_perjalanan: data.jenis_perjalanan,
          currency: data.currency,
          kota_tujuan: data.kota_tujuan,
          kendaraan: data.kendaraan,
          nominal_kurs: data.nominal_kurs,
          details: penyelesaianUangMukaDetail,
          list_budget_details: listBudget,
          pengajuan_um: data.pengajuan_um,
          pengajuan_umb_code: data.pengajuan_umb_code,
          types_reimbursment: "Penyelesaian Perjalanan Dinas",
          mata_uang: data.mata_uang,
          grand_total: data.grand_total,
          deklarasi_id: data.perjalanan_dinas_id,
          deklarasi_code: data.deklarasi_umb_code,
          deklarasi_label: data.perjalanan_dinas_code,
          total_umb: data.pengajuan_umb_total,
          calSum: data.kurang_lebih,
          transfer_to: data.transfer_to,
          nama_bank: data.nama_bank,
          no_rekening: data.no_rekening,
          vendor_code: data.vendor_code,
          vendor_name: data.vendor_name,
          description: data.description_head,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/penyelesaian-uang-muka-perjalanan-dinas");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    } else {
      if(listBudget.length == 0){
        message.error("Please complete your transaction list budget Internal Order & Cost Center");
      }
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      code_name: "",
      nama_karyawan: "",
      npk: "",
      divisi: "",
      tanggal_berangkat: "",
      tanggal_pulang: "",
      jenis_perjalanan: "",
      kota_tujuan: "",
      kendaraan: "",
      nominal_kurs: "",
      details: ""
    });

    setPenyelesaianUangMukaDetail([]);
    setListBudget([]);
    
  };

  const getDetails = (id) => {
    setLoading(true);
    Axios.get(`${url}/perjalanan-dinas/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if(res.data.data.list_budget_details){
          setPenyelesaianUangMukaDetail(res.data.data.details);
          setListBudget(res.data.data.list_budget_details);
        } else {
          message.error("Please complete your transaction list budget Internal Order & Cost Center");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSelectCode = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          nama_karyawan: findControlItem.nama_karyawan,
          npk: findControlItem.npk,
          divisi: findControlItem.divisi,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          jenis_perjalanan: findControlItem.jenis_perjalanan,
          currency: findControlItem.currency,
          tanggal_pulang: moment(findControlItem.tanggal_pulang),
          tanggal_berangkat: moment(findControlItem.tanggal_berangkat),
          kota_tujuan: findControlItem.tujuan,
          kendaraan: findControlItem.kendaraan,
          nominal_kurs: findControlItem.nominal_kurs,
          mata_uang: findControlItem.mata_uang,
          grand_total: findControlItem.grand_total,
          dirupiahkan: findControlItem.dirupiahkan,
          pengajuan_um: findControlItem.pengajuan_um,
          pengajuan_umb_code: findControlItem.pengajuan_umb_code,
          pengajuan_umb_total: findControlItem.pengajuan_umb_total,
          deklarasi_umb_code: findControlItem.deklarasi_perdin_code,
          vendor_code: findControlItem.vendor_code,
          vendor_name: findControlItem.vendor_name,
          vendor_label: `${findControlItem.vendor_code} - ${findControlItem.vendor_name} `,
          description_head: findControlItem.description,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const getCode = async () => {
    await Axios.get(`${url}/perjalanan-dinas`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const columns = [
    {
      align: "center",
      title: "CODE",
      dataIndex: "gl_code",
    },
    {
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      align: "left",
    },
    {
      title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
      dataIndex: "cost_center_number",
      align: "center",
      render: (cost_center_number) => {
        return cost_center_number ? 
          cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
          : '';
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
      align: "center",
      render: (internal_order_number) => {
        return internal_order_number ? 
          internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
          : '';
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" />;
      },
    },
    {
      align: "center",
      title: "Qty",
      dataIndex: "qty",
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>IDR</div>,
      dataIndex: "rupiah",
      render: (rupiah) => {
        return <AntNumberFormat value={rupiah} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>USD</div>,
      dataIndex: "usd",
      render: (usd) => {
        return <AntNumberFormat value={usd} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Total</div>,
      dataIndex: "total",
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/penyelesaian-uang-muka-perjalanan-dinas">
                    Back
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Advance settlement Create</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.perjalanan_dinas_code
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.perjalanan_dinas_code
                                  ? error.perjalanan_dinas_code[0]
                                  : false
                              }
                              label="Ref Doc Number"
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectCode(
                                    "perjalanan_dinas",
                                    value
                                  )
                                }
                                onFocus={() => getCode("")}
                                showSearch
                                onSearch={(value) => getCode(value)}
                                filterOption={false}
                                value={{
                                  value: data.perjalanan_dinas_id,
                                  label: data.perjalanan_dinas_code,
                                }}
                              >
                                {codes &&
                                  codes.map((code) => {
                                    // return code.grand_total < budgetArea &&
                                    //   code.status === 4 &&
                                    //   code.status_proses !== "penyelesaian" ? (
                                    //   <Option value={code._id} key={code._id}>
                                    //     {`${code.deklarasi_perdin_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                    //   </Option>
                                    // ) : code.grand_total >= budgetArea &&
                                    //   code.status === 5 &&
                                    //   code.status_proses !== "penyelesaian" ? (
                                    //   <Option value={code._id} key={code._id}>
                                    //     {`${code.deklarasi_perdin_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                    //   </Option>
                                    // ) : (
                                    //   ""
                                    // );

                                    return code.status === 5 &&
                                      code.status_proses !== "penyelesaian" ? (
                                      <Option value={code._id} key={code._id}>
                                        {`${code.deklarasi_perdin_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                      </Option>
                                    ) : (
                                      ""
                                    );

                                    // <Option value={code._id} key={code._id}>
                                    //   {`${code.deklarasi_perdin_code} - ${code.internal_order_description}`}
                                    // </Option>
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Employee Name"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("fullname")}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("npk")}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Division"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("divisi")}
                                onChange={(e) => {
                                  handleChange("divisi", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item {...layout} label="Departure ">
                              <DatePicker
                                disabled
                                style={{ width: 250 }}
                                onChange={(value) =>
                                  handleChange("tanggal_berangkat", value)
                                }
                                value={data.tanggal_berangkat}
                                format="DD MMMM YYYY"
                                className={`form-control text-right ant-input ${
                                  error &&
                                  error.data.errors.tanggal_berangkat &&
                                  "is-invalid"
                                }`}
                              />
                            </Form.Item>
                            <Form.Item {...layout} label="Return ">
                              <Space>
                                <DatePicker
                                  disabled
                                  style={{ width: 250 }}
                                  onChange={(value) =>
                                    handleChange("tanggal_pulang", value)
                                  }
                                  value={data.tanggal_pulang}
                                  format="DD MMMM YYYY"
                                />
                              </Space>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.description_head ? "error" : false
                              }
                              help={error && error.description_head ? error.description_head[0] : false}
                              label="Description"
                            >
                              <TextArea
                                disabled
                                value={data.description_head}
                                onChange={(e) => handleChange("description_head", e.target.value)}
                              ></TextArea>
                            </Form.Item>

                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.jenis_perjalanan
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.jenis_perjalanan
                                  ? error.jenis_perjalanan[0]
                                  : false
                              }
                              label="Type of Travel"
                            >
                              <Select
                                placeholder="Jenis Perjalanan"
                                disabled
                                onChange={(value) =>
                                  handleChange("jenis_perjalanan", value)
                                }
                                value={data.jenis_perjalanan}
                              >
                                <Option value="Domestic">Domestic</Option>
                                <Option value="Overseas">Overseas</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.currency ? "error" : false
                              }
                              help={
                                error && error.currency
                                  ? error.currency[0]
                                  : false
                              }
                              label="Currency"
                            >
                              <Input
                                disabled
                                value={data.currency}
                                onChange={(e) => {
                                  handleChange("currency", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.kota_tujuan ? "error" : false
                              }
                              help={
                                error && error.kota_tujuan
                                  ? error.kota_tujuan[0]
                                  : false
                              }
                              label="Destination"
                            >
                              <Input
                                disabled
                                value={data.kota_tujuan}
                                onChange={(e) => {
                                  handleChange("kota_tujuan", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.kendaraan ? "error" : false
                              }
                              help={
                                error && error.kendaraan
                                  ? error.kendaraan[0]
                                  : false
                              }
                              label="Vehicle"
                            >
                              <Input
                                disabled
                                value={data.kendaraan}
                                onChange={(e) => {
                                  handleChange("kendaraan", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.nominal_kurs ? "error" : false
                              }
                              help={
                                error && error.nominal_kurs
                                  ? error.nominal_kurs[0]
                                  : false
                              }
                              label=" Currency Reference"
                            >
                              <NumberFormat
                                disabled={
                                  data.jenis_perjalanan !== "Overseas" ||
                                  data.pengajuan_um !== "Belum"
                                }
                                decimalSeparator="."
                                thousandSeparator=","
                                type="text"
                                id="nominal_kurs"
                                className={`form-control text-right ant-input ${
                                  error &&
                                  error.data.errors.nominal_kurs &&
                                  "is-invalid"
                                }`}
                                value={
                                  data.jenis_perjalanan === "Overseas"
                                    ? data.nominal_kurs
                                    : 0
                                }
                                onValueChange={(value) =>
                                  handleChange("nominal_kurs", value.floatValue)
                                }
                                placeholder="0 "
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Input
                                disabled
                                maxLength={16}
                                value={data.transfer_to}
                                onChange={(e) => {
                                  handleChange("transfer_to", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama_bank ? "error" : false
                              }
                              help={
                                error && error.nama_bank
                                  ? error.nama_bank[0]
                                  : false
                              }
                              label="Bank Name"
                            >
                              <Input
                                disabled={data.pengajuan_um !== "Belum"}
                                value={data.nama_bank}
                                onChange={(e) => {
                                  handleChange("nama_bank", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.no_rekening ? "error" : false
                              }
                              help={
                                error && error.no_rekening
                                  ? error.no_rekening[0]
                                  : false
                              }
                              label="Account Number"
                            >
                              <Input
                                disabled={data.pengajuan_um !== "Belum"}
                                value={data.no_rekening}
                                onChange={(e) => {
                                  handleChange("no_rekening", e.target.value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Table
                      bordered
                      columns={columns}
                      size="small"
                      dataSource={penyelesaianUangMukaDetail}
                      rowKey={(data) => data._id}
                      onChange={handleTableChange}
                      // pagination={{
                      //   pageSize: 10,
                      //   current: page,
                      //   total: biayaRupas.total,
                      //   showSizeChanger: false,
                      // }}
                      loading={loading}
                    />
                    <Form
                      style={{ marginTop: "30px" }}
                      labelAlign="left"
                      {...layout}
                      name="control-hooks"
                    >
                      <Row>
                        <Col xs={24} md={12} lg={12}>
                          <Form.Item label="Total">
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              prefix={
                                data.jenis_perjalanan === "Overseas"
                                  ? data.currency === "USD"
                                    ? "$"
                                    : data.currency === "JPY"
                                    ? "¥"
                                    : data.currency === "SGD"
                                    ? "S$"
                                    : data.currency === "EUR"
                                    ? "€"
                                    : ""
                                  : ""
                              }
                              type="text"
                              id="grand_total"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.grand_total &&
                                "is-invalid"
                              }`}
                              // value={data.grand_total}
                              value={penyelesaianUangMukaDetail.reduce(
                                (prevValue, currentValue) => {
                                  const total =
                                    currentValue.category === "Overseas"
                                      ? currentValue.total
                                      : currentValue.total;
                                  const grandTotal = total;

                                  return grandTotal + prevValue;
                                },
                                0
                              )}
                              onValueChange={(value) =>
                                handleChange("jumlah", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.uang_muka ? "error" : false
                            }
                            help={
                              error && error.uang_muka
                                ? error.uang_muka[0]
                                : false
                            }
                            label="IDR"
                          >
                            <NumberFormat
                              //defaultValue="0"
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="uang_muka"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.uang_muka &&
                                "is-invalid"
                              }`}
                              // value={data.uang_muka}
                              value={penyelesaianUangMukaDetail.reduce(
                                (prevValue, currentValue) => {
                                  const total =
                                    currentValue.category === "Overseas"
                                      ? currentValue.rupiah
                                      : 0;
                                  const grandTotal = total;

                                  return grandTotal + prevValue;
                                },
                                0
                              )}
                              onValueChange={(value) =>
                                handleChange("uang_muka", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.uang_muka ? "error" : false
                            }
                            help={
                              error && error.uang_muka
                                ? error.uang_muka[0]
                                : false
                            }
                            label="Down Payment Filing"
                          >
                            <NumberFormat
                              //defaultValue="0"
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="uang_muka"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.uang_muka &&
                                "is-invalid"
                              }`}
                              value={
                                data.pengajuan_um === "Sudah"
                                  ? data.pengajuan_umb_total
                                  : 0
                              }
                              onValueChange={(value) =>
                                handleChange("uang_muka", value.floatValue)
                              }
                              placeholder="0"
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.kurang_lebih ? "error" : false
                            }
                            help={
                              error && error.kurang_lebih
                                ? error.kurang_lebih[0]
                                : false
                            }
                            label="+/-"
                          >
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="kurang_lebih"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.kurang_lebih &&
                                "is-invalid"
                              }`}
                              value={
                                data.pengajuan_um === "Sudah"
                                  ? data.pengajuan_umb_total - data.grand_total
                                  : 0
                              }
                              onValueChange={(value) =>
                                handleChange("kurang_lebih", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                          <Form.Item label="Grand Total">
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="amount"
                              className={`form-control text-right ant-input ${
                                error &&
                                error.data.errors.amount &&
                                "is-invalid"
                              }`}
                              value={penyelesaianUangMukaDetail.reduce(
                                (prevValue, currentValue) => {
                                  const total =
                                    currentValue.category === "Overseas"
                                      ? currentValue.rupiah
                                      : currentValue.total;
                                  const grandTotal = total;

                                  return Math.ceil(grandTotal + prevValue);
                                },
                                0
                              )}
                              onValueChange={(value) =>
                                handleChange("grand_total", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreatePenyelesaianUangMukaPerjalananDinas;
