import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Typography,
  Select,
  Modal,
  DatePicker,
  Upload, Radio,
  Divider,
  Spin
} from "antd";
import CreatableSelect from 'react-select/creatable';
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import NumberFormat from "react-number-format";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateBiayaPerjalananDinas({ history, match }) {
  const [data, setData] = useState({
    ref_doc_pengajuan: "",
    name: "",
    npk: "",
    divisi: "",
    tanggal_berangkat: "",
    tanggal_pulang: "",
    jenis_perjalanan: "",
    currency: "",
    kota_tujuan: "",
    tujuan: "",
    kendaraan: "",
    nominal_kurs: 0,
    doc_name: "",
    keterangan_1: "",
    keterangan_2: "",
    grand_total: 0,
    jumlah: 0,
    quantity: 0,
    rupiah: 0,
    usd: 0,
    total: 0,
    gl_code: "",
    description: "",
    available: 0,
    internal_order_id: "",
    internal_order_number: "",
    cost_center_id: "",
    cost_center_number: "",
    nama_bank: "",
    transfer_to: "",
    bukti: [],
    pengajuan_um: "",
    description_head: "",
    countStatusBudgetX: "",

  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleOpsi, setIsModalVisibleOpsi] = useState(false);
  const [error, setError] = useState(null);
  const [listBudget, setListBudget] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [bankName, setBankName] = useState([]);
  const [accountNumber, setAccountNumber] = useState([]);
  const [codes_account, setCodesAccount] = useState([]);
  const [codes, setCodes] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [loadBudgetRevised, setLoadBudgetRevised] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [perdinDetail, setPerdinDetail] = useState([]);
  const [waktuBerangkat, setWaktuBerangkat] = useState(null);
  const [waktuPulang, setWaktuPulang] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [budgetArea, setBudgetArea] = useState(null);
  const [loadGlCode, setLoadGlCode] = useState(false);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [currency, setCurrency] = useState([]);
  const onChangeWaktuBerangkat = (time) => {
    setWaktuBerangkat(time);
  };
  const arrayTes = ["delete", "edit"];
  const [selectedId, setSelectedId] = useState("");
  const refAccountName = useRef(null);
  const refBtnUpload = useRef(null);
  /* START - createby "abdoelhariez-kelola" 04-09-2022 */
  const refInputDeparture = useRef(null)
  const refInputReturn = useRef(null)
  const refInputType = useRef(null)
  const refInputDestination = useRef(null)
  const refInputVehicle = useRef(null)
  const refInputDescription = useRef(null)
  const refActCostCenter = useRef(null)
  const refActInternalOrder = useRef(null)
  const refActGlCode = useRef(null)
  const refActQty = useRef(null)
  /* END - createby "abdoelhariez-kelola" 04-09-2022 */

  useEffect(() => {
    getSetBudget();
  }, []);

  const handleChange = (name, value) => {
    setError(null);
    if (name === "rupiah") {
      setData({
        ...data,
        [`rupiah`]: value,
      });
    } else if (name === "dirupiahkan") {
      setData({
        ...data,
        [name]: value,
      });
    } else if (name === "currency") {
      setData({
        ...data,
        [`${name}`]: value.label,
      });
    } else if (name === "jenis_perjalanan") {
      if (value === "Domestic") {
        setData({
          ...data,
          [`${name}`]: value,
          ["currency"]: "",
        });
      } else {
        setData({
          ...data,
          [`${name}`]: value,
        });
      }
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleChangeDoc = (name, value) => {
    if (value === "Belum" || value === "Sudah") {
      handleResetPengajuan(name, value);
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setPerdinDetail([]);
    setData({
      ref_doc_pengajuan: "",
      name: "",
      npk: "",
      divisi: "",
      tanggal_berangkat: "",
      tanggal_pulang: "",
      jenis_perjalanan: "",
      kota_tujuan: "",
      kendaraan: "",
      nominal_kurs: 0,
      jumlah: 0,
      quantity: 0,
      rupiah: 0,
      usd: 0,
      total: 0,
      gl_code: "",
      description: "",
      grandTotal: "",
      available: "",
      bukti: [],
    });
  };

  const handleModalSelect = async () => {
    if (data.status) {
      const total_amount = Math.floor(
        perdinDetail.reduce((prevValue, currentValue) => {
          const sumTotal = currentValue.total;
          const grandTotal = sumTotal;
          return grandTotal + prevValue;
        }, 0) ||
        perdinDetail.reduce((prevValue, currentValue) => {
          const sumTotal = currentValue.total;
          const grandTotal = sumTotal;
          return grandTotal + prevValue;
        }, 0)
      );


      setLoading(true);
      setLoadBudgetRevised(true);
      const totals = data.available;
      const datapengajuan = data.pengajuan_um === "Belum";

      let fd = new FormData();

      fd.set("status_proses", data.status);
      fd.set("status_pengajuan", data.pengajuan_um);
      fd.set("types_reimbursment", "Biaya Perjalanan Dinas");
      fd.set("details", JSON.stringify(perdinDetail));
      fd.set("list_budget_details", JSON.stringify(listBudget));
      fd.set("description", data.description_head);
      fd.set(
        "pengajuan_uang_muka_perjalanan_dinas_code",
        datapengajuan ? "-" : data.pengajuan_uang_muka_perjalanan_dinas_code
      );
      fd.set(
        "pengajuan_uang_muka_perjalanan_dinas_id",
        datapengajuan ? "-" : data.pengajuan_uang_muka_perjalanan_dinas_id
      );
      fd.set("vendor_code", data.vendor_code);
      fd.set("vendor_name", data.vendor_name);
      fd.set("transfer_to", data.transfer_to);
      fd.set("nama_bank", data.nama_bank);
      fd.set("no_rekening", data.no_rekening);
      fd.set("tanggal_berangkat", data.tanggal_berangkat);
      fd.set("tanggal_pulang", data.tanggal_pulang);
      fd.set("jenis_perjalanan", data.jenis_perjalanan);
      fd.set("currency", data.currency);
      fd.set("tujuan", data.kota_tujuan);
      fd.set("kendaraan", data.kendaraan);
      fd.set("nominal_kurs", data.nominal_kurs);
      fd.set(
        "mata_uang",
        data.jenis_perjalanan === "Domestic" ? "IDR" : data.currency
      );
      fd.set("grand_total", data.grand_total);
      fd.set("total_amount", total_amount);
      fd.set(
        "pengajuan_umb_code",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_code
      );
      fd.set(
        "pengajuan_umb_id",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_id
      );
      fd.set(
        "pengajuan_umb_total",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_total
      );

      if (data.bukti) {
        if (data.bukti.length > 0) {
          data.bukti.length > 0 && data.bukti.map((bukti, i) => {
            if (bukti.originFileObj) {
              fd.append(`bukti_scanner[${i}]`, bukti.originFileObj);
            } else {
              fd.set(`bukti_scanner[${i}]`, bukti.name);
            }
          });
        } else {
          fd.append(`bukti_scanner`, "-");
        }
      } else {
        fd.append(`bukti_scanner`, "-");
      }

      if (fd.get('bukti_scanner') !== '-' && fd.get('transfer_to') && perdinDetail.length != 0 && listBudget.length != 0) {
        await Axios.post(`${url}/perjalanan-dinas`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
          .then(async () => {
            await Axios.post(
              data.status === "penambahan"
                ? `${url}/penambahan-budget`
                : `${url}/pemindahan-budget`,
              {
                status_proses: data.status,
                types_reimbursment: "Biaya Perjalanan Dinas",
                pengajuan_uang_muka_perjalanan_dinas_code:
                  data.pengajuan_um === "Belum"
                    ? ""
                    : data.pengajuan_uang_muka_perjalanan_dinas_code,
                pengajuan_uang_muka_perjalanan_dinas_id:
                  data.pengajuan_um === "Belum"
                    ? ""
                    : data.pengajuan_uang_muka_perjalanan_dinas_id,
                details: perdinDetail,
                list_budget_details: listBudget,
                vendor_code: data.vendor_code,
                vendor_name: data.vendor_name,
                transfer_to: data.transfer_to,
                nama_bank: data.nama_bank,
                no_rekening: data.no_rekening,
                tanggal_berangkat: data.tanggal_berangkat,
                tanggal_pulang: data.tanggal_pulang,
                jenis_perjalanan: data.jenis_perjalanan,
                tujuan: data.kota_tujuan,
                kendaraan: data.kendaraan,
                nominal_kurs: data.nominal_kurs,
                currency: data.currency,
                mata_uang: data.jenis_perjalanan === "Domestic" ? "IDR" : data.currency,
                // available: totals,
                total_amount: total_amount,
                grand_total: data.grand_total,
              },
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              }
            )
              .then((res) => {
                message.success(res.data.message);
                if (data.status === "penambahan") {
                  history.push("/penambahan-budget");
                } else {
                  history.push("/pemindahan-budget");
                }
                closeModal();
              })
              .catch((err) => {
                console.log(err.response);
                if (err.response) {
                  message.error(err.response.data.message);
                  if (err.response.status === 401) {
                    sessionStorage.removeItem("token");
                  }
                }
              })
              .finally(() => {
                setLoading(false);
                setLoadBudgetRevised(false);
              });
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data.message);
              if (err.response.status === 422) {
                setError(err.response.data.errors);
              }
              if (err.response.status === 401) {
                sessionStorage.removeItem("token");
              }
            } else {
              message.error("Backend not yet Started");
            }
            setLoading(false);
          });
      } else {
        if (fd.get('transfer_to') === '') {
          message.error("Please input your Account Name, cannot be empty");
          refAccountName.current.focus()
        } else if (fd.get('bukti_scanner') === '-') {
          message.error("Please upload file or image, cannot be empty");
          refBtnUpload.current.focus()
        } else if (perdinDetail.length == 0) {
          message.error("Please input activity details transaction, cannot be empty");
        } else if (listBudget.length == 0) {
          message.error("Please complete your transaction list budget Internal Order & Cost Center");
        }
        setLoading(false);
      }
    } else {
      message.error("Please select radio button before click 'OK' on your 'Insufficient Budget' modal!");
    }
  };

  const handleBeforeUpload = (files) => {
    return false;
  };

  const handleChangeFile = (files) => {
    if (files.fileList.length > 10000) {
      message.error("Maximum files !");
    } else {
      let fileList = [...files.fileList];
      fileList = fileList.map((file) => {
        if (file.originFileObj) {
          file.url = URL.createObjectURL(file.originFileObj);
        }
        return file;
      });
      setData({
        ...data,
        bukti: fileList,
        // bukti: files,
      });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      gl_code: "",
      description: "",
      gl_label: "",
      cost_center_id: "",
      cost_center_label: "",
      cost_center_number: "",
      internal_order_id: "",
      internal_order_number: "",
      internal_order_label: "",
      available: "",
      quantity: 0,
      jumlah: 0,
      rupiah: 0,
      usd: 0,
      total: 0,
    });
    setSelectedId("");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisibleOpsi(false);
  };

  const handleChangeSelectPengajuan = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          tanggal_pulang: moment(findControlItem.tanggal_pulang),
          tanggal_berangkat: moment(findControlItem.tanggal_berangkat),
          kota_tujuan: findControlItem.tujuan,
          jenis_perjalanan: findControlItem.jenis_perjalanan,
          description_head: findControlItem.description,
          vendor_code: findControlItem.vendor_code,
          vendor_name: findControlItem.vendor_name,
          vendor_label: `${findControlItem.vendor_code} - ${findControlItem.vendor_name}`,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          kendaraan: findControlItem.kendaraan,
          nominal_kurs: findControlItem.nominal_kurs,
          currency: findControlItem.currency,
          mata_uang: findControlItem.mata_uang,
          grand_total: findControlItem.grand_total,
          total_usd: findControlItem.total_usd,
          pengajuan_uang_muka_before_code: findControlItem.pengajuan_perdin_code,
          pengajuan_uang_muka_before_id: findControlItem._id,
          bukti: findControlItem.bukti,
          pengajuan_uang_muka_before_total: findControlItem.grand_total,
          countStatusBudgetX: findControlItem.list_budget_details ? findControlItem.list_budget_details.filter((x) => x.statusBudget === 'X') : ""
        });

        if (findControlItem.list_budget_details) {
          setListBudget(
            findControlItem.list_budget_details.map((detail) => {
              return {
                id: detail._id,
                ...detail,
              };
            })
          );
        }

        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
        internal_order_id: "",
        internal_order_number: "",
        internal_order_label: "",
        vendor_code: "",
        vendor_name: "",
        vendor_label: "",
      });
    }
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getInternalOrder = async (keyword) => {
    setLoadingBudget(true);
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        department: JSON.parse(sessionStorage.getItem("io_department")),
        keyword,
        columns: ["internal_order_number", "description"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          available: 0,
        });
        setBudgets(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectIo = (name, value) => {
    setLoadingBudget(true);
    setData({
      ...data,
      available: 0,
    });

    if (name === "internal_order") {
      const findControlItem = budgets.find((x) => x.aufnr === value.value);
      if (findControlItem) {
        setData({
          ...data,
          available: findControlItem.available,
          [`${name}_number`]: value.value,
          [`${name}_label`]: value.label,
        });
      } else {
        setData({
          ...data,
          [`${name}_number`]: value.value,
          [`${name}_label`]: value.label,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_number`]: "",
        [`${name}_label`]: "",
      });
    }
  };

  const getDetails = (id) => {
    setLoading(true);
    Axios.get(`${url}/pengajuan-uang-muka-perjalanan-dinas/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPerdinDetail(res.data.data.details);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSaveTemporaryData = () => {
    /* START - createby "abdoelhariez-kelola" 04-09-2022 */
    if (data.gl_code !== '' && data.cost_center_number !== '' && data.internal_order_number !== '' && data.quantity !== 0) {
      if (selectedId) {
        const modifyPerdinDetail = [...perdinDetail];
        let findPerdinDetail = modifyPerdinDetail.find(
          (x) => x.idActvCode === selectedId
        );

        if (findPerdinDetail === undefined) {
          findPerdinDetail = modifyPerdinDetail.find(
            (x) => x._id === selectedId
          );
        }

        const budgetIO = data.available;
        const amountBudget = data.jenis_perjalanan === "Domestic"
          ? data.quantity * data.rupiah
          : data.usd * data.quantity * data.nominal_kurs;
        const beforeAmount = findPerdinDetail.total;
        const modifyListBudget = [...listBudget];
        const listIoDuplicate = modifyListBudget.find(
          (x) => x.internal_order_number === findPerdinDetail.internal_order_number
        );

        // Setting Budget on details items
        findPerdinDetail.cost_center_id = data.cost_center_id;
        findPerdinDetail.cost_center_number = data.cost_center_number;
        findPerdinDetail.cost_center_label = data.cost_center_label;
        // findPerdinDetail.internal_order_id = data.internal_order_id;
        findPerdinDetail.internal_order_number = data.internal_order_number;
        findPerdinDetail.internal_order_label = data.internal_order_label;
        findPerdinDetail.available_budget = budgetIO;
        // end setting budget


        findPerdinDetail.gl_code = data.gl_code;
        findPerdinDetail.description = data.description;
        findPerdinDetail.qty = data.quantity;
        findPerdinDetail.rupiah =
          data.jenis_perjalanan === "Overseas"
            ? data.usd * data.quantity * data.nominal_kurs
            : data.rupiah;
        findPerdinDetail.usd = data.jenis_perjalanan === "Overseas" ? data.usd : data.usd;
        findPerdinDetail.category = data.jenis_perjalanan;
        findPerdinDetail.nominal_kurs =
          data.jenis_perjalanan === "Overseas" ? data.nominal_kurs : 0;
        findPerdinDetail.total =
          data.jenis_perjalanan === "Domestic"
            ? data.quantity * data.rupiah
            : data.quantity * data.usd;

        setPerdinDetail(modifyPerdinDetail);

        if (listIoDuplicate) {
          const totalAmountBefore = listIoDuplicate.total_amount - beforeAmount + amountBudget;
          console.log('totalAmountBefore', listIoDuplicate.total_amount, beforeAmount, amountBudget)
          listIoDuplicate.cost_center_id = data.cost_center_id;
          listIoDuplicate.cost_center_number = data.cost_center_number;
          listIoDuplicate.cost_center_label = data.cost_center_label;
          listIoDuplicate.internal_order_number = data.internal_order_number;
          listIoDuplicate.internal_order_label = data.internal_order_label;
          listIoDuplicate.available_budget = parseFloat(budgetIO);
          listIoDuplicate.remaining_budget = parseFloat(budgetIO - totalAmountBefore);
          listIoDuplicate.total_amount = listIoDuplicate.total_amount - beforeAmount + amountBudget;
          listIoDuplicate.statusBudget = parseFloat(budgetIO) >= totalAmountBefore ? 'V' : 'X'

          setListBudget(modifyListBudget);
        } else {
          setListBudget([
            ...listBudget,
            {
              id: Math.random(),
              cost_center_id: data.cost_center_id,
              cost_center_number: data.cost_center_number,
              cost_center_label: data.cost_center_label,
              internal_order_number: data.internal_order_number,
              internal_order_label: data.internal_order_label,
              available_budget: parseFloat(budgetIO),
              remaining_budget: parseFloat(budgetIO - amountBudget),
              total_amount: amountBudget,
              statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
            }
          ]);
        }

      } else {
        const budgetIO = data.available;
        const amountBudget = data.jenis_perjalanan === "Domestic"
          ? data.quantity * data.rupiah
          : data.usd * data.quantity * data.nominal_kurs;

        setPerdinDetail([
          ...perdinDetail,
          {
            idActvCode: Math.random(),
            _id: data._id,
            gl_code: data.gl_code,
            cost_center_id: data.cost_center_id,
            cost_center_number: data.cost_center_number,
            cost_center_label: data.cost_center_label,
            internal_order_number: data.internal_order_number,
            internal_order_label: data.internal_order_label,
            available_budget: budgetIO,
            description: data.description,
            category: data.jenis_perjalanan,
            qty: data.quantity,
            rupiah:
              data.jenis_perjalanan === "Overseas"
                ? data.usd * data.quantity * data.nominal_kurs
                : data.rupiah,
            usd: data.jenis_perjalanan === "Domestic" ? 0 : data.usd,
            nominal_kurs:
              data.jenis_perjalanan === "Overseas" ? data.nominal_kurs : 0,
            total:
              data.jenis_perjalanan === "Domestic"
                ? data.quantity * data.rupiah
                : data.quantity * data.usd,
          },
        ]);

        if (listBudget.length >= 1) {
          const modifyListBudget = [...listBudget];
          const listIoDuplicate = modifyListBudget.find(
            (x) => x.internal_order_number === data.internal_order_number
          );

          if (listIoDuplicate) {
            const totalAmountBefore = listIoDuplicate.total_amount + amountBudget;
            listIoDuplicate.cost_center_id = data.cost_center_id;
            listIoDuplicate.cost_center_number = data.cost_center_number;
            listIoDuplicate.cost_center_label = data.cost_center_label;
            listIoDuplicate.internal_order_number = data.internal_order_number;
            listIoDuplicate.internal_order_label = data.internal_order_label;
            listIoDuplicate.available_budget = parseFloat(budgetIO);
            listIoDuplicate.remaining_budget = parseFloat(budgetIO - totalAmountBefore);
            listIoDuplicate.total_amount = listIoDuplicate.total_amount + amountBudget;
            listIoDuplicate.statusBudget = parseFloat(budgetIO) >= totalAmountBefore ? 'V' : 'X'

            setListBudget(modifyListBudget);
          } else {
            setListBudget([
              ...listBudget,
              {
                id: Math.random(),
                cost_center_id: data.cost_center_id,
                cost_center_number: data.cost_center_number,
                cost_center_label: data.cost_center_label,
                internal_order_number: data.internal_order_number,
                internal_order_label: data.internal_order_label,
                available_budget: parseFloat(budgetIO),
                remaining_budget: parseFloat(budgetIO - amountBudget),
                total_amount: amountBudget,
                statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
              }
            ]);
          }
        } else {
          setListBudget([
            ...listBudget,
            {
              id: Math.random(),
              cost_center_id: data.cost_center_id,
              cost_center_number: data.cost_center_number,
              cost_center_label: data.cost_center_label,
              internal_order_number: data.internal_order_number,
              internal_order_label: data.internal_order_label,
              available_budget: parseFloat(budgetIO),
              remaining_budget: parseFloat(budgetIO - amountBudget),
              total_amount: amountBudget,
              statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
            }
          ]);
        }
      }

      setSelectedId("");

      setData({
        ...data,
        _id: "",
        gl_code: "",
        description: "",
        gl_label: "",
        cost_center_id: "",
        cost_center_label: "",
        cost_center_number: "",
        internal_order_id: "",
        internal_order_number: "",
        internal_order_label: "",
        available: "",
        quantity: 0,
        jumlah: 0,
        rupiah: 0,
        usd: 0,
        total: 0,
      });

      setIsModalVisible(false);
    } else if (data.cost_center_number === '') {
      message.error("Please input your Cost Center Number, cannot be empty");
      refActCostCenter.current.focus()
    } else if (data.internal_order_number === '') {
      message.error("Please input your Internal Order Number, cannot be empty");
      refActInternalOrder.current.focus()
    } else if (data.gl_code === '') {
      message.error("Please input your Activity Code, cannot be empty");
      refActGlCode.current.focus()
    } else if (data.quantity === 0) {
      message.error("Please input your Qty, cannot be empty");
      refActQty.current.focus()
    }
    /* END - createby "abdoelhariez-kelola" 04-09-2022 */
  };

  const handleEditDetails = (id, detailSelect) => {
    const idEdit = id === undefined ? detailSelect._id : id;
    let details = [];

    details = perdinDetail.find(
      (x) => x.idActvCode === idEdit
    );

    if (details === undefined) {
      details = perdinDetail.find(
        (x) => x._id === idEdit
      );
    }

    setSelectedId(idEdit);

    setData({
      ...data,
      cost_center_id: details.cost_center_id,
      cost_center_label: details.cost_center_label,
      cost_center_number: details.cost_center_number,
      internal_order_number: details.internal_order_number,
      internal_order_label: details.internal_order_label,
      available: details.available_budget,
      gl_code: details.gl_code,
      description: details.description,
      quantity: details.qty,
      rupiah: details.rupiah,
      usd: details.usd,
      nominal_kurs: data.nominal_kurs,
      // grand_total: details.total,
    });

    setIsModalVisible(true);
  };

  const handleRemoveDetails = (id, detailSelect) => {
    const idEdit = id === undefined ? detailSelect._id : id;
    let cekDetailIO = [];
    let details = [];

    cekDetailIO = perdinDetail.find(
      (x) => x.idActvCode === idEdit
    );

    if (cekDetailIO === undefined) {
      cekDetailIO = perdinDetail.find(
        (x) => x._id === idEdit
      );
    }

    if (id === undefined) {
      details = perdinDetail.filter(
        (x) => x._id !== idEdit
      );
    } else {
      details = perdinDetail.filter(
        (x) => x.idActvCode !== idEdit
      );
    }


    const modifyListBudget = [...listBudget];
    const listBudgetDetails = modifyListBudget.find(
      (x) => x.internal_order_number === cekDetailIO.internal_order_number
    );

    const sumAmount = listBudgetDetails.total_amount - cekDetailIO.total;

    if (sumAmount <= 0) {
      const listBudgetDetails = listBudget.filter(
        (x) => x.internal_order_number !== cekDetailIO.internal_order_number
      );

      setListBudget(listBudgetDetails);
    } else {
      listBudgetDetails.total_amount = sumAmount;
      listBudgetDetails.remaining_budget = listBudgetDetails.available_budget - sumAmount;
      listBudgetDetails.statusBudget = listBudgetDetails.available_budget >= sumAmount ? 'V' : 'X'
      setListBudget(modifyListBudget);
    }

    setPerdinDetail(details);
  };

  const handleResetPengajuan = (name, value) => {
    setData({
      gl_code: "",
      gl_label: "",
      description: "",
      quantity: 0,
      harga_satuan: 0,
      tanggal_berangkat: "",
      tanggal_pulang: "",
      jenis_perjalanan: "",
      mata_uang: "",
      nominal_kurs: "",
      tujuan: "",
      kota_tujuan: "",
      kendaraan: "",
      transfer_to: "",
      nama_bank: "",
      no_rekening: "",
      bukti: [],
      pengajuan_um: "",
      pengajuan_uang_muka_before_id: "",
      pengajuan_uang_muka_before_code: "",
      pengajuan_uang_muka_before_total: "",
      internal_order_id: "",
      internal_order_number: "",
      internal_order_label: "",
      cost_center_id: "",
      cost_center_number: "",
      internal_orders: [],
      cost_centers: [],
      jumlah: 0,
      total: 0,
      available: 0,
      [name]: value,
    });

    setListBudget([]);
    setPerdinDetail([]);
  };

  const onSaveData = () => {
    const total_amount = Math.floor(
      perdinDetail.reduce(
        (prevValue, currentValue) => {
          const sumTotal = currentValue.total;
          const grandTotal = sumTotal;
          return grandTotal + prevValue;
        },
        0
      ) ||
      perdinDetail.reduce(
        (prevValue, currentValue) => {
          const sumTotal = currentValue.total;
          const grandTotal = sumTotal;

          return grandTotal + prevValue;
        },
        0
      )
    );

    // setLoading(true);
    const totals = data.available;
    const datapengajuan = data.pengajuan_um === "Belum";

    const countDataBudgetX = listBudget.filter((x) => x.statusBudget === 'X');
    setData({
      ...data,
      countStatusBudgetX: countDataBudgetX,
    })

    if (countDataBudgetX.length == 0) {
      setLoading(true);
      let fd = new FormData();

      fd.set("status_proses", "budget_ok");
      fd.set("status_pengajuan", data.pengajuan_um);
      fd.set("types_reimbursment", "Biaya Perjalanan Dinas");
      fd.set("details", JSON.stringify(perdinDetail));
      fd.set("list_budget_details", JSON.stringify(listBudget));
      fd.set(
        "pengajuan_uang_muka_perjalanan_dinas_code",
        datapengajuan ? "-" : data.pengajuan_uang_muka_perjalanan_dinas_code
      );
      fd.set(
        "pengajuan_uang_muka_perjalanan_dinas_id",
        datapengajuan ? "-" : data.pengajuan_uang_muka_perjalanan_dinas_id
      );
      fd.set("vendor_code", data.vendor_code);
      fd.set("vendor_name", data.vendor_name);
      fd.set("transfer_to", data.transfer_to);
      fd.set("nama_bank", data.nama_bank);
      fd.set("no_rekening", data.no_rekening);
      fd.set("tanggal_berangkat", data.tanggal_berangkat);
      fd.set("tanggal_pulang", data.tanggal_pulang);
      fd.set("jenis_perjalanan", data.jenis_perjalanan);
      fd.set("currency", data.currency);
      fd.set("tujuan", data.kota_tujuan);
      fd.set("kendaraan", data.kendaraan);
      fd.set("nominal_kurs", data.nominal_kurs);
      fd.set("description", data.description_head);
      fd.set(
        "mata_uang",
        data.jenis_perjalanan === "Domestic" ? "IDR" : data.currency
      );
      fd.set("available", totals);
      fd.set("total_amount", total_amount);
      fd.set("grand_total", data.grand_total);
      fd.set(
        "pengajuan_umb_code",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_code
      );
      fd.set(
        "pengajuan_umb_id",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_id
      );
      fd.set(
        "pengajuan_umb_total",
        datapengajuan ? "-" : data.pengajuan_uang_muka_before_total
      );


      if (data.bukti) {
        if (data.bukti.length > 0) {
          data.bukti.length > 0 && data.bukti.map((bukti, i) => {
            if (bukti.originFileObj) {
              fd.append(`bukti_scanner[${i}]`, bukti.originFileObj);
            } else {
              fd.set(`bukti_scanner[${i}]`, bukti.name);
            }
          });
        } else {
          fd.append(`bukti_scanner`, "-");
        }
      } else {
        fd.append(`bukti_scanner`, "-");
      }

      if (
        fd.get('bukti_scanner') !== '-' &&
        fd.get('jenis_perjalanan') !== '' &&
        fd.get('tujuan') !== '' &&
        fd.get('kendaraan') !== '' &&
        fd.get('description') !== '' &&
        fd.get('transfer_to') &&
        perdinDetail.length != 0 &&
        listBudget.length != 0) {
        Axios.post(`${url}/perjalanan-dinas`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            message.success(res.data.message);
            history.push("/perjalanan-dinas");
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data.message);
              if (err.response.status === 422) {
                setError(err.response.data.errors);
              }
              if (err.response.status === 401) {
                localStorage.removeItem("token");
              }
            } else {
              message.error("Backend not yet Started");
            }
            setLoading(false);
          });
      } else {
        if (fd.get('jenis_perjalanan') === '') {
          message.error("Please input your type of travel, cannot be empty");
          refInputType.current.focus()
        } else if (fd.get('tujuan') === '') {
          message.error("Please input your destination, cannot be empty");
          refInputDestination.current.focus()
        } else if (fd.get('kendaraan') === '') {
          message.error("Please input your vehicle, cannot be empty");
          refInputVehicle.current.focus()
        } else if (fd.get('transfer_to') === '') {
          message.error("Please input your Account Name, cannot be empty");
          refAccountName.current.focus()
        } else if (fd.get('description') === '') {
          message.error("Please input your description, cannot be empty");
          refInputDescription.current.focus()
        } else if (fd.get('bukti_scanner') === '-') {
          message.error("Please upload file or image, cannot be empty");
          refBtnUpload.current.focus()
        } else if (perdinDetail.length == 0) {
          message.error("Please input activity details transaction, cannot be empty");
        } else if (listBudget.length == 0) {
          message.error("Please complete your transaction list budget Internal Order & Cost Center");
        }
        setLoading(false);
      }
    } else {
      showModalOpsi();
    }
  };

  console.log(data)

  const getSetCurrency = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "currency",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCurrency(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleGetBudget = (name, value) => {
    setLoadingBudget(true);
    setData({
      ...data,
      [`${name}_number`]: value.value,
      [`${name}_label`]: value.label,
      available: 0,
      availLoad: 0
    });

    Axios.get(`${url}/internal-order/sap/${value.value}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.data.length <= 0) {
          message.error("Budget not available");
          setData({
            ...data,
            availLoad: 1
          });
        } else {
          setData({
            ...data,
            [`${name}_number`]: value.value,
            [`${name}_label`]: value.label,
            available:
              res.data.data.length > 0
                ? res.data.data[0].available
                  ? res.data.data[0].available.replaceAll(".", "")
                  : 0
                : 0,
            availLoad: 1
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoadingBudget(false);
      });
  };

  const handleChangeStatus = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const handleRemove = (files) => {
    if (files.fileid) {
      deleteFileList(files.fileid);
    }
    setData({
      ...data,
      bukti: null,
    });
  };

  const deleteFileList = (id) => {
    Axios.delete(`${url}/file-perdin-image/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSelectCodeAccount = (name, value) => {
    if (value) {
      const findControlItem = codes_account.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_label`]: value.label,
          [`${name}_code`]: findControlItem.code,
          [`_id`]: value.value,
          description: findControlItem.description,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`_id`]: null,
      });
    }
  };

  const getVendor = async (keyword) => {
    setLoadingVendor(true);
    await Axios.get(`${url}/vendor/list`, {
      params: {
        keyword,
        columns: ["vendor_name", "vendor_code"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setVendor(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoadingVendor(false);
  };

  const handleCreate = (value) => {
    console.log('create new', value, bankName.length);
    let arrBank = [];
    let key = bankName.length;
    arrBank.push({
      key: key,
      label: value,
      value: value
    })
    setBankName(bankName.concat(arrBank));
    let name = "nama_bank";
    setData({
      ...data,
      [`${name}`]: value,
      [`${name}_id`]: key,
      no_rekening: accountNumber[key],
    });
  }

  const handleChangeSelectVendor = (name, value) => {
    if (name === "vendor") {
      const findControlItem = vendor.find((x) => x.vendor_code === value.value);
      if (findControlItem) {
        if (findControlItem.bank_name1) {
          const arrBank = [];
          const arrAccBank = [];
          const elBN = findControlItem.bank_name1;
          const elAB = findControlItem.account_number1;

          arrBank.push({
            key: 0,
            label: elBN,
            value: elBN
          })

          arrAccBank.push(elAB);

          if (findControlItem.bank_name2) {
            const elBN = findControlItem.bank_name2;
            const elAB = findControlItem.account_number2;

            arrBank.push({
              key: 1,
              label: elBN,
              value: elBN
            })

            arrAccBank.push(elAB);
          } else {
            message.success("Bank Name & Account Number available 1 data");
          }

          setBankName(arrBank);
          setAccountNumber(arrAccBank);

          setData({
            ...data,
            [`${name}_code`]: value.value,
            [`${name}_name`]: findControlItem.vendor_name,
            [`${name}_label`]: value.label,
            transfer_to: findControlItem.vendor_name,
          });
        } else {
          message.error("Bank Name or Account Number not found");
          setData({
            ...data,
            [`${name}_code`]: value.value,
            [`${name}_name`]: findControlItem.vendor_name,
            [`${name}_label`]: value.label,
            transfer_to: findControlItem.vendor_name,
            nama_bank: "",
            no_rekening: "",
          });
        }
      }
    } else if (name === "nama_bank") {
      setData({
        ...data,
        [`${name}`]: value.label,
        [`${name}_id`]: value.key,
        no_rekening: accountNumber[value.key],
      });
    } else {
      setData({
        ...data,
        [`${name}_code`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const showModalOpsi = () => {
    setIsModalVisibleOpsi(true);
  };

  const getPengajuan = async () => {
    await Axios.get(`${url}/pengajuan-uang-muka-perjalanan-dinas`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const response = res.data.data.map((item) => {
          return {
            ...item,
            bukti:
              item.bukti_scan_details.length > 0
                ? item.bukti_scan_details.map((x, i) => {
                  return {
                    uid: i,
                    name: x.bukti_scan,
                    status: "done",
                    url: x.bukti_scan_url,
                    fileid: x._id,
                  };
                })
                : [],
          };
        });

        setCodes(response);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
        department: JSON.parse(sessionStorage.getItem("io_department")),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    if (name === "cost_center") {
      const findControlItem = data.cost_centers.find(
        (x) => x._id === value.value
      );
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: findControlItem.cost_center_number,
        [`${name}_label`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: value.label,
      });
    }
  };

  const getCodeAccount = async (keyword) => {
    await Axios.get(`${url}/gl-account`, {
      params: {
        keyword,
        columns: ["code", "description"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodesAccount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const columnsListBudget = [
    {
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_label",
      align: "left",
    },
    {
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      align: "right",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>REMAINING BUDGET</div>,
      dataIndex: "remaining_budget",
      align: "right",
      render: (remaining_budget) => {
        return <AntNumberFormat value={remaining_budget} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
  ];

  const columns = [
    {
      align: "center",
      title: "CODE",
      dataIndex: "gl_code",
      width: 120,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>Description</div>,
      dataIndex: "description",
      width: 350,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
      dataIndex: "cost_center_number",
      width: 120,
    },
    {
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
      align: "center",
      width: 120,
      render: (internal_order_number) => {
        return internal_order_number ?
          internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
          : '';
      },
    },
    {
      align: "center",
      title: "QTY",
      dataIndex: "qty",
      width: 60,
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>IDR</div>,
      dataIndex: "rupiah",
      render: (rupiah) => {
        return <AntNumberFormat value={rupiah} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>USD</div>,
      dataIndex: "usd",
      render: (usd) => {
        return <AntNumberFormat value={usd} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Total</div>,
      dataIndex: "total",
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "idActvCode",
      width: 200,
      render: (id, data) =>
        arrayTes &&
        arrayTes.map((x) => {
          return x == "delete" ? (
            <Button
              onClick={() => handleRemoveDetails(id, data)}
              size="small"
              type="danger"
            >
              <DeleteOutlined />
              Delete
            </Button>
          ) : (
            <Button
              style={{ marginLeft: 5 }}
              onClick={() => handleEditDetails(id, data)}
              size="small"
              type="primary"
            >
              <EditOutlined />
              Edit
            </Button>
          );
        }),
    },
  ];

  const columnModalListBudget = [
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>TOTAL AMOUNT</div>,
      dataIndex: "total_amount",
      render: (total_amount) => {
        return <AntNumberFormat value={total_amount} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
  ];

  return (
    <Fragment>
      <Row>
        <Modal
          title="Insufficient Budget"
          visible={isModalVisibleOpsi}
          onOk={handleModalSelect}
          onCancel={handleCancel}
          width={650}
          confirmLoading={loadBudgetRevised}
        >
          <Table
            style={{ marginTop: "15px", marginBottom: "15px" }}
            columns={columnModalListBudget}
            dataSource={listBudget.filter((x) => x.statusBudget === 'X')}
            // rowKey={(data) => data._id}
            // onChange={handleTableChange}
            // scroll={{ x: 700 }}
            pagination={false}
            loading={loading}
          />
          <Radio.Group
            style={{ marginBottom: 20, width: '100%', textAlign: "center" }}
            onChange={(e) => handleChangeStatus("status", e.target.value)}
            value={data.status}
          >
            {data.countStatusBudgetX && data.countStatusBudgetX.length > 1 ?
              (
                <Radio value="pemindahan">TRANSFER BUDGET</Radio>
              ) : (
                <>
                  <Radio value="penambahan">ADD BUDGET</Radio>
                  <Radio value="pemindahan">TRANSFER BUDGET</Radio>
                </>
              )
            }
          </Radio.Group>
        </Modal>

        {/* <Modal
          title="Select Optional"
          visible={isModalVisibleOpsi}
          onOk={handleModalSelect}
          onCancel={handleCancel}
          confirmLoading={loadBudgetRevised}
        >
          <Table
            style={{ marginTop: "15px", marginBottom: "15px" }}
            columns={columnModalListBudget}
            dataSource={listBudget.filter((x) => x.statusBudget === 'X')}
            // rowKey={(data) => data._id}
            // onChange={handleTableChange}
            // scroll={{ x: 700 }}
            pagination={false}
            loading={loading}
          />

          <Radio.Group
            style={{ marginBottom: 20 }}
            onChange={(e) => handleChangeStatus("status", e.target.value)}
            value={data.status}
          >
            { data.countStatusBudgetX.length > 1 ? 
              (
                <Radio value="pemindahan">TRANSFER BUDGET</Radio>
              ) : (
                <>
                  <Radio value="penambahan">ADD BUDGET</Radio>
                  <Radio value="pemindahan">TRANSFER BUDGET</Radio>
                </>
              )
            }
          </Radio.Group>
        </Modal> */}
        <Col xs={24}>
          <Card
            type="inner"
            title="Travel Expense    "
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/perjalanan-dinas">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Travel Expense Create</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={10} lg={15}>
                            <Form.Item
                              validateStatus={
                                error && error.pengajuan_um ? "error" : false
                              }
                              help={
                                error && error.pengajuan_um
                                  ? error.pengajuan_um[0]
                                  : false
                              }
                              label="Have Submited Down Payment? "
                            >
                              <Radio.Group
                                onChange={(e) =>
                                  handleChangeDoc(
                                    "pengajuan_um",
                                    e.target.value
                                  )
                                }
                                value={data.pengajuan_um}
                              >
                                <Radio value="Sudah">Yes</Radio>
                                <Radio value="Belum">No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Ref Doc Number">
                              <Select
                                disabled={data.pengajuan_um !== "Sudah"}
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelectPengajuan(
                                    "pengajuan_uang_muka_perjalanan_dinas",
                                    value
                                  )
                                }
                                onFocus={() => getPengajuan("")}
                                showSearch
                                onSearch={(value) => getPengajuan(value)}
                                filterOption={false}
                                value={{
                                  value:
                                    data.pengajuan_uang_muka_perjalanan_dinas_id,
                                  label:
                                    data.pengajuan_uang_muka_perjalanan_dinas_code,
                                }}
                              >
                                {codes &&
                                  codes.map((code) => {
                                    // return code.grand_total < budgetArea &&
                                    //   code.status === 4 &&
                                    //   code.status_proses !== "deklarasi" &&
                                    //   code.status_received === 1 ? (
                                    //   <Option value={code._id} key={code._id}>
                                    //     {`${code.pengajuan_perdin_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                    //   </Option>
                                    // ) : code.grand_total >= budgetArea &&
                                    //   code.status === 5 &&
                                    //   code.status_proses !== "deklarasi" &&
                                    //   code.status_received === 1 ? (
                                    //   <Option value={code._id} key={code._id}>
                                    //     {`${code.pengajuan_perdin_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                    //   </Option>
                                    // ) : (
                                    //   ""
                                    // );

                                    return code.status_proses !== "deklarasi" && code.status_received === 1 ? (
                                      <Option value={code._id} key={code._id}>
                                        {`${code.pengajuan_perdin_code} - Rp. ${code.grand_total.toLocaleString()}`}
                                      </Option>
                                    ) : (
                                      ""
                                    );
                                  })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Employee Nama"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("fullname")}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("npk")}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Division"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("divisi")}
                                onChange={(e) => {
                                  handleChange("divisi", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item {...layout} label="Departure ">
                              <DatePicker
                                disabled={data.pengajuan_um !== "Belum"}
                                style={{ width: 250 }}
                                onChange={(value) =>
                                  handleChange("tanggal_berangkat", value)
                                }
                                value={data.tanggal_berangkat}
                                format="DD MMMM YYYY"
                                className={`form-control text-right ant-input ${error &&
                                  error.data.errors.tanggal_berangkat &&
                                  "is-invalid"
                                  }`}
                              />
                            </Form.Item>

                            <Form.Item {...layout} label="Return ">
                              <Space>
                                <DatePicker
                                  disabled={data.pengajuan_um !== "Belum"}
                                  style={{ width: 250 }}
                                  onChange={(value) =>
                                    handleChange("tanggal_pulang", value)
                                  }
                                  value={data.tanggal_pulang}
                                  format="DD MMMM YYYY"
                                />
                              </Space>
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.jenis_perjalanan
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.jenis_perjalanan
                                  ? error.jenis_perjalanan[0]
                                  : false
                              }
                              label="Type of Travel"
                            >
                              <Select
                                placeholder="Jenis Perjalanan"
                                disabled={data.pengajuan_um !== "Belum"}
                                ref={refInputType}
                                onChange={(value) =>
                                  handleChange("jenis_perjalanan", value)
                                }
                                value={data.jenis_perjalanan}
                              >
                                <Option value="Domestic">Domestic</Option>
                                <Option value="Overseas">Overseas</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.currency ? "error" : false
                              }
                              help={
                                error && error.currency
                                  ? error.currency[0]
                                  : false
                              }
                              label="Currency"
                            >
                              <Select
                                disabled={
                                  data.jenis_perjalanan !== "Overseas" ||
                                  data.pengajuan_um !== "Belum"
                                }
                                // loading={loadingCostCenter}
                                labelInValue
                                onChange={(value) =>
                                  handleChange("currency", value)
                                }
                                showSearch
                                onFocus={() => getSetCurrency()}
                                onSearch={(value) => getSetCurrency(value)}
                                filterOption={false}
                                value={{
                                  key: data.currency_id,
                                  label: data.currency,
                                }}
                              >
                                {currency &&
                                  currency.map((curFormat) => {
                                    return (
                                      <Option
                                        value={curFormat.name}
                                        key={curFormat.id}
                                      >
                                        {curFormat.name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.nominal_kurs ? "error" : false
                              }
                              help={
                                error && error.nominal_kurs
                                  ? error.nominal_kurs[0]
                                  : false
                              }
                              label="Currency Reference"
                            >
                              <NumberFormat
                                disabled={
                                  data.jenis_perjalanan !== "Overseas"
                                }
                                decimalSeparator="."
                                thousandSeparator=","
                                type="text"
                                id="nominal_kurs"
                                className={`form-control text-right ant-input ${error &&
                                  error.data.errors.nominal_kurs &&
                                  "is-invalid"
                                  }`}
                                value={
                                  data.jenis_perjalanan === "Overseas"
                                    ? data.nominal_kurs
                                    : 0
                                }
                                onValueChange={(value) =>
                                  handleChange("nominal_kurs", value.floatValue)
                                }
                                placeholder="0 "
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.kota_tujuan ? "error" : false
                              }
                              help={
                                error && error.kota_tujuan
                                  ? error.kota_tujuan[0]
                                  : false
                              }
                              label="Destination"
                            >
                              <Input
                                // disabled={data.pengajuan_um !== "Belum"}
                                value={data.kota_tujuan}
                                ref={refInputDestination}
                                onChange={(e) => {
                                  handleChange("kota_tujuan", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.kendaraan ? "error" : false
                              }
                              help={
                                error && error.kendaraan
                                  ? error.kendaraan[0]
                                  : false
                              }
                              label="Vehicle"
                            >
                              <Input
                                // disabled={data.pengajuan_um !== "Belum"}
                                value={data.kendaraan}
                                ref={refInputVehicle}
                                onChange={(e) => {
                                  handleChange("kendaraan", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Select
                                labelInValue
                                loading={loadingVendor}
                                onChange={(value) =>
                                  handleChangeSelectVendor("vendor", value)
                                }
                                onFocus={() => getVendor("")}
                                showSearch
                                ref={refAccountName}
                                onSearch={(value) => getVendor(value)}
                                filterOption={false}
                                value={{
                                  key: data.vendor_code,
                                  label: data.vendor_label,
                                }}
                              >
                                {vendor &&
                                  vendor.map((account) => {
                                    return (
                                      <Option
                                        value={account.vendor_code}
                                        key={account.vendor_code}
                                      >
                                        {`${account.vendor_code} - ${account.vendor_name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              {/* <Input
                                disabled={data.pengajuan_um !== "Belum"}
                                placeholder="e.g Nama Pemilik Rekening"
                                maxLength={16}
                                value={data.transfer_to}
                                onChange={(e) => {
                                  handleChange("transfer_to", e.target.value);
                                }}
                              /> */}
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.nama_bank ? "error" : false
                              }
                              help={
                                error && error.nama_bank
                                  ? error.nama_bank[0]
                                  : false
                              }
                              label=" Bank Name"
                            >
                              {/* <Select
                                labelInValue
                                loading={loadingVendor}
                                onChange={(value) =>
                                  handleChangeSelectVendor("nama_bank", value)
                                }
                                showSearch
                                filterOption={false}
                                value={{
                                  key: data.nama_bank_id,
                                  label: data.nama_bank,
                                }}
                              >
                                {bankName &&
                                  bankName.map((value, key) => {
                                    return (
                                      <Option value={key} key={key}>
                                        {`${value}`}
                                      </Option>
                                    );
                                  })}
                              </Select> */}
                              <CreatableSelect
                                theme={(theme) => ({
                                  ...theme,
                                  borderWidth: 0.1,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#f5f5f5',
                                    primary: '#089244',
                                  },
                                })}
                                // isClearable
                                onCreateOption={(value) => handleCreate(value)}
                                onChange={(value) =>
                                  handleChangeSelectVendor("nama_bank", value)
                                }
                                options={bankName}
                                value={{
                                  key: data.nama_bank_id,
                                  label: data.nama_bank,
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.no_rekening ? "error" : false
                              }
                              help={
                                error && error.no_rekening
                                  ? error.no_rekening[0]
                                  : false
                              }
                              label="Account Number"
                            >
                              <Input
                                disabled={data.pengajuan_um !== "Belum"}
                                placeholder="e.g 012345xxx"
                                value={data.no_rekening}
                                onChange={(e) => {
                                  handleChange("no_rekening", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.description_head ? "error" : false
                              }
                              help={error && error.description_head ? error.description_head[0] : false}
                              label="Description"
                            >
                              <TextArea
                                value={data.description_head}
                                ref={refInputDescription}
                                onChange={(e) => handleChange("description_head", e.target.value)}
                              ></TextArea>
                            </Form.Item>

                            <Form.Item
                              name="bukti"
                              label="Upload File"
                              // required
                              validateStatus={
                                error && error.bukti ? "error" : false
                              }
                              help={
                                error && error.bukti ? error.bukti[0] : false
                              }
                            >
                              <Fragment>
                                <Upload
                                  // listType="picture-card"

                                  multiple={true}
                                  onRemove={handleRemove}
                                  onChange={handleChangeFile}
                                  beforeUpload={handleBeforeUpload}
                                  // onPreview={handlePreview}
                                  // defaultfileList={[data.bukti]}
                                  action={data.bukti}
                                  fileList={data.bukti}
                                >
                                  <Button ref={refBtnUpload}>
                                    Upload
                                    <UploadOutlined />
                                  </Button>
                                </Upload>
                              </Fragment>
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space direction="vertical">
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add
                        </Button>

                        <Modal
                          title={selectedId ? "Edit Details" : "Add Details"}
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1200}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  labelAlign="left"
                                  {...layout}
                                  onFinish={onSaveData}
                                  name="control-hooks"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        {/* COST CENTER */}
                                        <Form.Item
                                          validateStatus={
                                            error && error.cost_center ? "error" : false
                                          }
                                          help={
                                            error && error.cost_center
                                              ? error.cost_center[0]
                                              : false
                                          }
                                          label="Cost Center"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelect("cost_center", value)
                                            }
                                            onFocus={() => getCostCenter("")}
                                            showSearch
                                            ref={refActCostCenter}
                                            onSearch={(value) => getCostCenter(value)}
                                            filterOption={false}
                                            value={{
                                              key: data.cost_center_id,
                                              label: data.cost_center_label,
                                            }}
                                          >
                                            {data.cost_centers &&
                                              data.cost_centers.map((cost_center) => {
                                                return (
                                                  <Option
                                                    value={cost_center._id}
                                                    key={cost_center._id}
                                                  >
                                                    {`${cost_center.cost_center_number} - ${cost_center.description}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        {/* END COST CENTER */}

                                        {/* INTERNAL ORDER */}
                                        <Form.Item label="Internal Order">
                                          <Select
                                            labelInValue
                                            loading={
                                              data.available === 0 ? loadingBudget : ""
                                            }
                                            onChange={(value) =>
                                              handleChangeSelectIo("internal_order", value)
                                            }
                                            onFocus={() => getInternalOrder("")}
                                            ref={refActInternalOrder}
                                            showSearch
                                            onSearch={(value) => getInternalOrder(value)}
                                            onSelect={(value) =>
                                              handleGetBudget("internal_order", value)
                                            }
                                            filterOption={false}
                                            value={{
                                              key: data.internal_order_number,
                                              label: data.internal_order_label,
                                            }}
                                          >
                                            {budgets &&
                                              budgets.map((internal_order_budget) => {
                                                return (
                                                  <Option
                                                    value={
                                                      internal_order_budget.internal_order_number
                                                    }
                                                    key={internal_order_budget._id}
                                                  >
                                                    {`${internal_order_budget.internal_order_number} - ${internal_order_budget.description}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        {/* END INTERNAL ORDER */}

                                        <Form.Item
                                          label={
                                            data.available !== "" ? "Budget Tersedia" : ""
                                          }
                                        >
                                          {/* START - createby "abdoelhariez-kelola" 04-09-2022 */}
                                          {data.availLoad === 0 ?
                                            (
                                              <Spin tip="Loading get data budget . . ."></Spin>
                                            ) : (
                                              <NumberFormat
                                                disabled
                                                type="input"
                                                thousandSeparator={true}
                                                prefix={"Rp. "}
                                                style={{ fontWeight: 600, fontSize: 20 }}
                                                value={data.available}
                                                displayType={"text"}
                                                onChange={(value) => {
                                                  handleChange("available", value);
                                                }}
                                              />
                                            )
                                          }
                                        </Form.Item>

                                        <Divider />
                                        <Form.Item
                                          validateStatus={
                                            error && error.gl_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.gl_code
                                              ? error.gl_code[0]
                                              : false
                                          }
                                          label="Activity Code"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectCodeAccount(
                                                "gl",
                                                value
                                              )
                                            }
                                            onFocus={() => getCodeAccount("")}
                                            ref={refActGlCode}
                                            showSearch
                                            onSearch={(value) =>
                                              getCodeAccount(value)
                                            }
                                            filterOption={false}
                                            value={{
                                              value: data._id,
                                              label: data.gl_code,
                                            }}
                                          >
                                            {codes_account &&
                                              codes_account.map(
                                                (code_account) => {
                                                  return (
                                                    <Option
                                                      value={code_account._id}
                                                      key={code_account._id}
                                                    >
                                                      {`${code_account.code} - ${code_account.description}`}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.description
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.description
                                              ? error.description[0]
                                              : false
                                          }
                                          label="Description"
                                        >
                                          <Input
                                            disabled
                                            value={data.description}
                                            onChange={(e) => {
                                              handleChange(
                                                "description",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.quantity
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.quantity
                                              ? error.quantity[0]
                                              : false
                                          }
                                          label="Qty"
                                        >
                                          <AntNumberFormat
                                            value={data.quantity}
                                            getInputRef={refActQty}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("quantity", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.rupiah
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.rupiah
                                              ? error.rupiah[0]
                                              : false
                                          }
                                          label="IDR"
                                        >
                                          <AntNumberFormat
                                            placeholder="0"
                                            disabled={
                                              data.jenis_perjalanan !==
                                              "Domestic"
                                            }
                                            value={
                                              data.jenis_perjalanan ===
                                                "Overseas"
                                                ? data.usd *
                                                data.quantity *
                                                data.nominal_kurs
                                                : data.rupiah
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("rupiah", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.usd ? "error" : false
                                          }
                                          help={
                                            error && error.usd
                                              ? error.usd[0]
                                              : false
                                          }
                                          label=" USD"
                                        >
                                          <AntNumberFormat
                                            placeholder="0"
                                            disabled={
                                              data.jenis_perjalanan !==
                                              "Overseas"
                                            }
                                            value={
                                              data.jenis_perjalanan ===
                                                "Domestic"
                                                ? 0
                                                : data.usd
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("usd", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.total
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.total
                                              ? error.total[0]
                                              : false
                                          }
                                          label="Total"
                                        >
                                          <AntNumberFormat
                                            placeholder="0"
                                            disabled
                                            value={
                                              data.jenis_perjalanan ===
                                                "Overseas"
                                                ? data.usd * data.quantity
                                                : data.quantity * data.rupiah
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("total", e);
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={handleReset}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>
                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        size="small"
                        columns={columnsListBudget}
                        dataSource={listBudget}
                        rowKey={(data) => data.id}
                        onChange={handleTableChange}
                        // scroll={{ x: 2000 }}
                        loading={loading}
                      />
                    </Col>
                    <Col xs={24}>
                      <Table
                        bordered
                        style={{ marginTop: "15px" }}
                        size="small"
                        columns={columns}
                        dataSource={perdinDetail}
                        rowKey="idActvCode"
                        // rowKey={(data) => data._id}
                        scroll={{ x: 1300 }}
                        onChange={handleTableChange}
                        loading={loading}
                      />
                    </Col>
                    <Form
                      style={{ marginTop: "30px" }}
                      labelAlign="left"
                      {...layout}
                      name="control-hooks"
                    >
                      <Row>
                        <Col xs={24} md={12} lg={12}>
                          <Form.Item label="Total">
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              prefix={
                                data.jenis_perjalanan === "Overseas"
                                  ? data.currency === "USD"
                                    ? "$"
                                    : data.currency === "JPY"
                                      ? "¥"
                                      : data.currency === "SGD"
                                        ? "S$"
                                        : data.currency === "EUR"
                                          ? "€"
                                          : ""
                                  : ""
                              }
                              type="text"
                              className={`form-control text-right ant-input ${error &&
                                error.data.errors.grand_total &&
                                "is-invalid"
                                }`}
                              value={
                                data.pengajuan_um === "Sudah"
                                  ? perdinDetail.reduce(
                                    (prevValue, currentValue) => {
                                      const total =
                                        currentValue.category !== "Domestic"
                                          ? currentValue.total
                                          : currentValue.total;
                                      const grandTotal = total;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  )
                                  : perdinDetail.reduce(
                                    (prevValue, currentValue) => {
                                      const total =
                                        currentValue.category ===
                                          "Overseas"
                                          ? currentValue.usd *
                                          currentValue.qty
                                          : currentValue.total;
                                      const grandTotal = total;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  )
                              }
                              onValueChange={(value) =>
                                handleChange("jumlah", value.floatValue)
                              }
                              placeholder="0"
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.dirupiahkan ? "error" : false
                            }
                            help={
                              error && error.dirupiahkan
                                ? error.dirupiahkan[0]
                                : false
                            }
                            label="IDR"
                          >
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              className={`form-control text-right ant-input ${error &&
                                error.data.errors.dirupiahkan &&
                                "is-invalid"
                                }`}
                              value={
                                data.pengajuan_um !== "Sudah"
                                  ? perdinDetail.reduce(
                                    (prevValue, currentValue, data) => {
                                      const total =
                                        currentValue.category ===
                                          "Overseas"
                                          ? currentValue.rupiah
                                          : 0;
                                      const grandTotal = total;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  )
                                  : perdinDetail.reduce(
                                    (prevValue, currentValue, data) => {
                                      const total =
                                        currentValue.category ===
                                          "Overseas"
                                          ? currentValue.rupiah
                                          : 0;
                                      const grandTotal = total;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  )
                              }
                              onValueChange={(value) =>
                                handleChange("dirupiahkan", value.floatValue)
                              }
                              placeholder="0"
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.uang_muka ? "error" : false
                            }
                            help={
                              error && error.uang_muka
                                ? error.uang_muka[0]
                                : false
                            }
                            label="Down Payment Filing"
                          >
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              className={`form-control text-right ant-input ${error &&
                                error.data.errors.uang_muka &&
                                "is-invalid"
                                }`}
                              value={
                                data.pengajuan_um === "Sudah"
                                  ? data.pengajuan_uang_muka_before_total
                                  : 0
                              }
                              onValueChange={(value) =>
                                handleChange("uang_muka", value.floatValue)
                              }
                              placeholder="0"
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              error && error.kurang_lebih ? "error" : false
                            }
                            help={
                              error && error.kurang_lebih
                                ? error.kurang_lebih[0]
                                : false
                            }
                            label="+/-"
                          >
                            <NumberFormat
                              //defaultValue="0"
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              className={`form-control text-right ant-input ${error &&
                                error.data.errors.kurang_lebih &&
                                "is-invalid"
                                }`}
                              value={
                                data.pengajuan_um === "Sudah" ?
                                  data.jenis_perjalanan === "Overseas"
                                    ? data.pengajuan_uang_muka_before_total -
                                    data.grand_total
                                    : data.pengajuan_uang_muka_before_total -
                                    data.grand_total
                                  : 0
                              }
                              onValueChange={(value) =>
                                handleChange("kurang_lebih", value.floatValue)
                              }
                              placeholder="0"
                            />
                          </Form.Item>

                          <Form.Item label="Grand Total">
                            <NumberFormat
                              disabled
                              decimalSeparator="."
                              thousandSeparator=","
                              type="text"
                              id="amount"
                              className={`form-control text-right ant-input ${error &&
                                error.data.errors.amount &&
                                "is-invalid"
                                }`}
                              value={perdinDetail.reduce(
                                (prevValue, currentValue) => {
                                  const total =
                                    currentValue.category === "Overseas"
                                      ? currentValue.rupiah
                                      : currentValue.total;
                                  const grandTotal = total;

                                  return Math.ceil(grandTotal + prevValue);
                                },
                                0
                              )}
                              onValueChange={(value) =>
                                handleChange("grand_total", value.floatValue)
                              }
                              placeholder="0 "
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    <Row justify="start">
                      <Col xs={24}>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateBiayaPerjalananDinas;
