import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Select,
  TimePicker,
  DatePicker,
  InputNumber, Modal, Divider
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { CaretRightOutlined, EditOutlined  } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Option } = Select;
const { Content } = Layout;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

const layoutItems = {
  labelCol: {
    xs: 6,
    md: 10,
    lg: 8,
  },
  
  wrapperCol: {
    xs: 18,
    md: 14,
    lg: 16,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreatePemindahanBudget(props) {
  const [data, setData] = useState({
    total_transfer_budget: 0,
    sisa_budget: 0,
  });
  const [codes, setCodes] = useState([]);
  const [penyelesaianUangMukaDetails, setPenyelesaianUangMukaDetails] =
    useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [pemindahanBudgetDetail, setPemindahanBudgetDetail] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [listBudget, setListBudget] = useState([]);
  const [newListBudget, setNewListBudget] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/pemindahan-budget/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          name: res.data.data.name,
          npk: res.data.data.npk,
          divisi: res.data.data.divisi,
          ref_code: res.data.data.ref_code,
          // internal_order_id: res.data.data.internal_order_id,
          // internal_order: res.data.data.old_internal_order,
          // cost_center_id: res.data.data.cost_center_id,
          // cost_center_number: res.data.data.cost_center,
          transfer_to: res.data.data.transfer_to,
          types_reimbursment: res.data.data.types_reimbursment,
          nama_bank: res.data.data.nama_bank,
          no_rekening: res.data.data.no_rekening,
          text: res.data.data.text,
          // available: res.data.data.old_available,
          total_amount: res.data.data.total_amount,
          grand_total: res.data.data.grand_total,
        });

        setPemindahanBudgetDetail(
          res.data.data.details_entertaiment.map((detail) => {
            return {
              id: detail._id,
              ...detail,
            };
          })
        );

        setListBudget(
          res.data.data.list_budget_details.map((x) => {
            return {
              id: x._id,
              ...x,
            };
          })
        );

        setNewListBudget(res.data.data.list_budget_details.filter((x) => x.statusBudget === 'X'));

      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setError(null);

    if (name === "periode") {
      setData({
        ...data,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const getInternalOrder = async (keyword) => {
    setLoadingBudget(true);
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        department: JSON.parse(sessionStorage.getItem("io_department")),
        keyword,
        columns: ["internal_order_number", "description"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          new_available: 0,
        });
        setBudgets(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectIo = (name, value) => {
    if (value) {
      const findControlItem = budgets.find(
        (x) => x.internal_order_number === value.value
      );
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_number`]: value.value,
          [`${name}_label`]: value.label,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_number`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const handleGetBudget = (name, value) => {
    setLoadingBudget(true);
    setData({
      ...data,
      [`${name}_number`]: value.value,
      [`${name}_label`]: value.label,
      new_available: 0,
    });

    Axios.get(`${url}/internal-order/sap/${value.value}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          [`${name}_number`]: value.value,
          [`${name}_label`]: value.label,
          // new_available:
          //   res.data.data.length > 0
          //     ? res.data.data[0].available
          //       ? res.data.data[0].available.replaceAll(".", "")
          //       : 0
          //     : 0,
          available_budget_get:
            res.data.data.length > 0
              ? res.data.data[0].available
                ? res.data.data[0].available.replaceAll(".", "")
                : 0
              : 0,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoadingBudget(false);
  };

  const getDetails = (id) => {
    setLoadingDetail(true);
    
    Axios.get(`${url}/pemindahan-budget/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPemindahanBudgetDetail(res.data.data.details_entertaiment);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const onSaveData = () => {
    setLoading(true);
    const totalTransferBudget = newListBudget.reduce((total, budget) => budget.total_transfer + total, 0);

    Axios.post(
      `${url}/pemindahan-budget/${props.match.params.id}`,
      {
        details: pemindahanBudgetDetail,
        list_budget_details: listBudget,
        new_list_budget_details: newListBudget,
        nama: data.nama,
        npk: data.npk,
        divisi: data.divisi,
        pengajuan_um: data.pengajuan_um,
        ref_code: data.ref_code,
        periode: moment(data.periode).format("YYYY-MM-DD"),
        types_reimbursment: data.types_reimbursment,
        // internal_order_id: data.internal_order_id,
        // old_internal_order: data.internal_order,
        // new_internal_order: data.internal_order_number,
        // new_internal_order_label: data.internal_order_label,
        // cost_center_id: data.cost_center_id,
        // cost_center_number: data.cost_center_number,
        transfer_to: data.transfer_to,
        // old_available: data.available,
        // new_available: data.new_available,
        total_transfer_budget: totalTransferBudget,
        total_amount: data.total_amount,
        grand_total: data.grand_total,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/pemindahan-budget");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const getCode = async () => {
    // Axios.get(match.params.id
    //     ? `${url}/penyelesaian-uang-muka-perjalanan-dinas`
    //     :  `${url}/penyelesaian-uang-muka-perjalanan-dinas/${match.params.id} `, {
    await Axios.get(
      `${url}/entertainment`,
      `${url}/pengajuan-uang-muka-entertainment`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectCode = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          nama: findControlItem.nama,
          npk: findControlItem.npk,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          internal_order: findControlItem.internal_order,
          cost_center: findControlItem.cost_center,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      vendor_code: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifyListBudget = [...newListBudget];
      const findData = modifyListBudget.find(
        (x) => x.id === selectedId
      );

      // Setting Budget on details items
      findData.internal_order_number_get = data.internal_order_number;
      findData.available_budget_get = data.available_budget_get;
      findData.total_transfer = data.total_amount_io - data.available_budget;
      // end setting budget

      setNewListBudget(modifyListBudget);
      
    } 

    setSelectedId("");

    setData({
      ...data,
      internal_order_number: "",
      available_budget_get: "",
      total_transfer: "",
      internal_order_label: "",
      internal_order_number: "",
    });

    setIsModalVisible(false);
  };

  const handleEditDetails = (id) => {
    const details = newListBudget.find(
      (x) => x.id === id
    );

    setSelectedId(id);

    setData({
      ...data,
      before_internal_order_number: details.internal_order_number,
      before_internal_order_label: details.internal_order_label,
      available_budget: details.available_budget,
      total_amount_io: details.total_amount,
    });

    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      internal_order_id: '',
      internal_order_number: '',
      internal_order_label: ''
    });

    setSelectedId("");
    setIsModalVisible(false);
  };

  const resetModal = () => {
    setData({
      ...data,
      internal_order_id: '',
      internal_order_number: '',
      internal_order_label: ''
    });
  };

  const columnsListBudget = [
    {
      align: "center",
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
      render: (internal_order_number) => {
        return internal_order_number ? 
          internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
          : '';
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>TOTAL AMOUNT</div>,
      dataIndex: "total_amount",
      render: (total_amount) => {
        return <AntNumberFormat value={total_amount} type="text" />;
      },
    },
    {
      align: "center",
      title: <div style={{ textAlign: "center" }}>GET FROM INTERNAL ORDER</div>,
      dataIndex: "internal_order_number_get",
      render: (internal_order_number_get) => {
        return internal_order_number_get ? 
          internal_order_number_get.length == 12 ? internal_order_number_get.substring(2) : internal_order_number_get
          : '';
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET FROM AVAILABLE</div>,
      dataIndex: "available_budget_get",
      render: (available_budget_get) => {
        return <AntNumberFormat value={available_budget_get ? available_budget_get : ''} type="text" style={{fontWeight: 800, fontSize: 16, letterSpacing: 1}} />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>TOTAL TRANSFER BUDGET</div>,
      dataIndex: "total_transfer",
      render: (total_transfer) => {
        return <AntNumberFormat value={total_transfer ? total_transfer : ''} type="text" style={{fontWeight: 800, fontSize: 16, letterSpacing: 1}} />;
      },
    },
    {
      title: "ACTION",
      align: "center",
      dataIndex: "id",
      width: 200,
      render: (id) => {
          return (
            <Button
              style={{ marginLeft: 5 }}
              onClick={() => handleEditDetails(id, data)}
              size="small"
              type="primary"
            >
              <EditOutlined />
              Edit
            </Button>
          );
        },
    },
  ];

  const columns = [
    {
      title: "Purchase of Entertainment And The Like",
      // title: "Entertainment and the like",
      children: [
        {
          title: "DATE",
          dataIndex: "created_at",
          render: (date) => moment(date).format("DD-MM-YYYY"),
        },
        {
          title: "LOCATION",
          dataIndex: "tempat",
        },
        {
          title: "ADDRESS",
          dataIndex: "alamat",
        },
        {
          title: "TYPE *",
          dataIndex: "jenis",
        },
        {
          title: "AMOUNT (Rp)",
          dataIndex: "jumlah",
          render: (jumlah) => {
            return <AntNumberFormat value={jumlah} type="text" />;
          },
        },
      ],
    },
    {
      title: "Relase Business Provided Entertainment And The Like",
      // title: "Business relationships provided by entertainment and the like",
      children: [
        {
          title: "NAME",
          dataIndex: "nama",
        },
        {
          title: "POSITION",
          dataIndex: "jabatan",
        },
        {
          title: "COMPANY NAME",
          dataIndex: "nama_perusahaan",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Transfer Budget"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/pemindahan-budget">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Transfer Budget</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Budget"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.ref_doc_no ? "error" : false
                              }
                              help={
                                error && error.ref_doc_no
                                  ? error.ref_doc_no[0]
                                  : false
                              }
                              label="Ref Doc Number"
                            >
                              <Select
                                labelInValue
                                disabled
                                onChange={(value) =>
                                  handleChangeSelectCode("ref", value)
                                }
                                onFocus={() => getCode("")}
                                showSearch
                                onSearch={(value) => getCode(value)}
                                filterOption={false}
                                value={{
                                  value: data.ref_id,
                                  label: data.ref_code,
                                }}
                              >
                                {codes &&
                                  codes.map((code) => {
                                    return (
                                      <Option value={code._id} key={code._id}>
                                        {code.entertainment_code ||
                                          code.pengajuan_uang_muka_entertainment_code}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Name"
                            >
                              <Input
                                //maxLength={16}
                                disabled
                                value={sessionStorage.getItem("fullname")}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                //maxLength={16}
                                disabled
                                value={sessionStorage.getItem("npk")}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Department"
                            >
                              <Input
                                //maxLength={16}
                                disabled
                                value={sessionStorage.getItem("divisi")}
                                onChange={(e) => {
                                  handleChange("divisi", e.target.value);
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Input
                                disabled
                                //maxLength={16}
                                value={data.transfer_to}
                                // onKeyPress={(e) => {
                                //   checkReceipt(e.target.value);
                                // }}
                                onChange={(e) => {
                                  handleChange("transfer_to", e.target.value);
                                  //checkReceipt(e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.pengajuan ? "error" : false
                              }
                              help={
                                error && error.pengajuan
                                  ? error.pengajuan[0]
                                  : false
                              }
                              label="Pengajuan"
                            >
                              {/* <Input
                                disabled
                                amount
                                value={data.grand_total}
                                onChange={(e) => {
                                  handleChange("pengajuan", e.target.value);
                                }}
                              /> */}
                              <InputNumber
                                disabled
                                style={{ width: 150 }}
                                value={data.grand_total}
                                formatter={(value) =>
                                  `Rp. ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                              />
                            </Form.Item>
                            
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    {/* <Form layout="inline" style={{ marginBottom: 50}}> */}
                    <Row>
                      <Col span={8}>
                        <Form layout="vertical">
                          <Form.Item label="Jenis Reimbursment" required>
                            <Input
                              disabled
                              style={{ width: "90%" }}
                              value={data.types_reimbursment}
                              onChange={(e) => {
                                handleChange(
                                  "types_reimbursment",
                                  e.target.value
                                );
                              }}
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                      
                      <Col span={8}>
                        <Form layout="vertical">
                          <Form.Item
                            label="Periode"
                            required
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              onChange={(e) => {
                                handleChange("periode", e);
                              }}
                              style={{ width: "90%" }}
                            />
                           
                          </Form.Item>
                        </Form>
                      </Col>

                      <Col xs={24}>
                        <Space>
                          <Modal
                            title={selectedId ? "Edit Details" : "Add Details"}
                            style={{ top: 20 }}
                            visible={isModalVisible}
                            width={1000}
                            footer={null}
                            onCancel={closeModal}
                          >
                            <Card className="body-data">
                              <div name="control-hooks">
                                <div name="file" label="File">
                                  <Form
                                    {...layoutItems}
                                    name="control-hooks"
                                    labelAlign="left"
                                  >
                                    <Row>
                                      <Fragment>
                                        <Col xs={24}>
                                        
                                          {/* INTERNAL ORDER */}
                                          <Form.Item label="Before Internal Order">
                                            <Select
                                              labelInValue
                                              filterOption={false}
                                              value={{
                                                key: data.before_internal_order_number,
                                                label: data.before_internal_order_label,
                                              }}
                                              disabled
                                            >
                                              <Option
                                                value={
                                                  data.internal_order_number
                                                }
                                              >
                                              </Option>
                                            </Select>
                                          </Form.Item>
                                          {/* END INTERNAL ORDER */}
                                              
                                          <Form.Item
                                            label={
                                              data.available !== "" ? "Available Budget Before" : ""
                                            }
                                          >
                                            <NumberFormat
                                              disabled
                                              type="input"
                                              thousandSeparator={true}
                                              prefix={"Rp. "}
                                              style={{ fontWeight: 600, fontSize: 20 }}
                                              value={data.available_budget}
                                              displayType={"text"}
                                            />
                                          </Form.Item>

                                          <Divider />
                                          
                                          {/* INTERNAL ORDER */}
                                          <Form.Item label="Get From Internal Order">
                                            <Select
                                              labelInValue
                                              loading={
                                                data.available === 0 ? loadingBudget : ""
                                              }
                                              onChange={(value) =>
                                                handleChangeSelectIo("internal_order", value)
                                              }
                                              onFocus={() => getInternalOrder("")}
                                              showSearch
                                              onSearch={(value) => getInternalOrder(value)}
                                              onSelect={(value) =>
                                                handleGetBudget("internal_order", value)
                                              }
                                              filterOption={false}
                                              value={{
                                                key: data.internal_order_number,
                                                label: data.internal_order_label,
                                              }}
                                            >
                                              {budgets &&
                                                budgets.map((internal_order_budget) => {
                                                  return (
                                                    <Option
                                                      value={
                                                        internal_order_budget.internal_order_number
                                                      }
                                                      key={internal_order_budget._id}
                                                    >
                                                      {`${internal_order_budget.internal_order_number} - ${internal_order_budget.description}`}
                                                    </Option>
                                                  );
                                                })}
                                            </Select>
                                          </Form.Item>
                                          {/* END INTERNAL ORDER */}
                                              
                                          <Form.Item
                                            label={
                                              data.available !== "" ? "Budget Available" : ""
                                            }
                                          >
                                            <NumberFormat
                                              disabled
                                              type="input"
                                              thousandSeparator={true}
                                              prefix={"Rp. "}
                                              style={{ fontWeight: 600, fontSize: 20 }}
                                              value={data.available_budget_get}
                                              displayType={"text"}
                                              onChange={(value) => {
                                                handleChange("available_budget_get", value);
                                              }}
                                            />
                                          </Form.Item>

                                          <Form.Item
                                            validateStatus={
                                              error && error.total
                                                ? "error"
                                                : false
                                            }
                                            help={
                                              error && error.total
                                                ? error.total[0]
                                                : false
                                            }
                                            label="Total Transfer Budget"
                                          >
                                            <AntNumberFormat
                                              //defaultValue="0"
                                              disabled
                                              value={Math.floor(
                                                data.total_amount_io - data.available_budget
                                              )}
                                              type="input"
                                              onChange={(e) => {
                                                handleChange("total_transfer", e);
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Fragment>
                                    </Row>
                                  </Form>
                                </div>
                                <div>
                                  <Button
                                    loading={loading}
                                    onClick={onSaveTemporaryData}
                                    type="primary"
                                    htmlType="submit"
                                    className="mr-button"
                                    style={{
                                      marginRight: 10,
                                    }}
                                  >
                                    Submit
                                  </Button>
                                  <Button htmlType="reset" 
                                    onClick={resetModal}
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          </Modal>
                        </Space>
                      </Col>

                      <Col xs={24}>
                        <Table
                          style={{ marginTop: "15px", marginBottom: "25px" }}
                          size="small"
                          columns={columnsListBudget}
                          dataSource={newListBudget}
                          rowKey={(data) => data.id}
                          onChange={handleTableChange}
                          // scroll={{ x: 2000 }}
                          loading={loading}
                        />
                      </Col>
                    </Row>
                    {/* </Form> */}
                    <Col xs={24}>
                      <Table
                        columns={columns}
                        dataSource={pemindahanBudgetDetail}
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        // scroll={{ x: 1500 }}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        loading={loading}
                      />
                      <Form
                        style={{ marginTop: "30px" }}
                        labelAlign="left"
                        {...layout}
                        name="control-hooks"
                      >
                        <Row>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Total Amount">
                              <NumberFormat
                                disabled
                                type="input"
                                thousandSeparator={true}
                                prefix={"Rp. "}
                                value={data.total_amount}
                                className="form-control text-right ant-input"

                              />
                            </Form.Item>
                            
                            <Form.Item label="Total Transfer Budget">
                              <NumberFormat
                                disabled
                                type="input"
                                thousandSeparator={true}
                                prefix={"Rp. "}
                                value={
                                  newListBudget.length > 1 ?
                                    newListBudget.reduce((total, budget) => budget.total_transfer + total, 0) ? 
                                      newListBudget.reduce((total, budget) => budget.total_transfer + total, 0) : 0 
                                    : data.total_amount - data.available_budget
                                }
                                className="form-control text-right ant-input"
                                onChange={(e) => {
                                  handleChange("total_transfer_budget", e);
                                }}

                              />
                              {/* <NumberFormat
                                disabled
                                type="input"
                                thousandSeparator={true}
                                prefix={"Rp. "}
                                value={data.total_amount - data.available}
                                className="form-control text-right ant-input"

                              /> */}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Row justify="start">
                      <Col style={{ marginTop: 10 }}>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreatePemindahanBudget;
