import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  Checkbox,
  Space,
  Upload,
  Select,
  Divider,
  Spin
} from "antd";
import CreatableSelect from 'react-select/creatable';
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import NumberFormat from "react-number-format";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    xs: 6,
    md: 10,
    lg: 8,
  },

  wrapperCol: {
    xs: 14,
    md: 14,
    lg: 14,
  },
};

const layoutItems = {
  labelCol: {
    xs: 6,
    md: 10,
    lg: 8,
  },

  wrapperCol: {
    xs: 18,
    md: 14,
    lg: 16,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreatePengajuanBiayaRupaRupa({ history, match }) {
  const [data, setData] = useState({
    gl_code: "",
    gl_label: "",
    description: "",
    quantity: 0,
    harga_satuan: 0,
    cost_center: "",
    transfer_to: "",
    nama_bank: "",
    no_rekening: "",
    jumlah: 0,
    ppn: 0,
    total: "",
    total_amount: "",
    grand_total: "",
    pph21: 0,
    pph23: 0,
    pph4: 0,
    pengajuan_uang_muka_biaya_rupa_id: "",
    pengajuan_uang_muka_biaya_rupa_code: "",
    internal_order_id: "",
    internal_order_number: "",
    cost_center_id: "",
    cost_center_number: "",
    internal_orders: [],
    cost_centers: [],
    available: "",
    bukti: "",
    available_sap: "",
    bukti: [],
    description_head: "",
    listbudget: []
  });

  const [selectedId, setSelectedId] = useState("");
  const [loadGlCode, setLoadGlCode] = useState(false);
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [loadingVendor, setLoadingVendor] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [bankName, setBankName] = useState([]);
  const [accountNumber, setAccountNumber] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [codes, setCodes] = useState([]);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleOpsi, setIsModalVisibleOpsi] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [biayaRupaDetail, setBiayaRupaDetail] = useState([]);
  const [listBudget, setListBudget] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [codes_account, setCodesAccount] = useState([]);
  const [calPpn, setCalPpn] = useState(0);
  const [ppnArea, setPpnArea] = useState(null);
  const [valCheckbox, setValCheckbox] = useState(false);

  const arrayTes = ["delete", "edit"];
  const refAccountName = useRef(null)
  const refBtnUpload = useRef(null)

  /* START - createby "abdoelhariez-kelola" 04-09-2022 */
  const refInputDescription = useRef(null)
  const refActCostCenter = useRef(null)
  const refActInternalOrder = useRef(null)
  const refActGlCode = useRef(null)
  const refActQty = useRef(null)
  const refActPrice = useRef(null)
  /* END - createby "abdoelhariez-kelola" 04-09-2022 */


  useEffect(() => {
    getSetPpn();
  }, []);

  const getSetPpn = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "ppn",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPpnArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const activePPN = (e) => {
    const calculatePpn = data.quantity * data.harga_satuan * (ppnArea / 100);
    setValCheckbox(e.target.checked);
    if (e.target.checked) {
      setData({
        ...data,
        ppn: calculatePpn,
      });
    } else {
      setData({
        ...data,
        ppn: 0,
      });
    }
  };

  const handleChange = (name, value) => {
    if (value === "Belum") {
      handleResetPengajuan(name, value);
    } else {
      if (name === "grand_total") {
        setData({
          ...data,
          [name]: Math.floor(value),
        });
      } if (name === "quantity") {
        setValCheckbox(false);
        setData({
          ...data,
          [name]: Math.floor(value),
          ppn: ""
        });
      } else if (name === "harga_satuan") {
        setValCheckbox(false);
        setData({
          ...data,
          [name]: Math.floor(value),
          ppn: ""
        });
      } else {
        setData({
          ...data,
          [name]: value,
        });
      }
    }
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    if (name === "cost_center") {
      const findControlItem = data.cost_centers.find(
        (x) => x._id === value.value
      );
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: findControlItem.cost_center_number,
        [`${name}_label`]: value.label,
        // [`${name}_number`]: findControlItem.cost_center_number,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: value.label,
      });
    }
  };

  const handleChangeFile = (files) => {
    if (files.fileList.length > 10000) {
      message.error("Maximum files !");
    } else {
      let fileList = [...files.fileList];
      fileList = fileList.map((file) => {
        file.url = URL.createObjectURL(file.originFileObj);
        return file;
      });
      setData({
        ...data,
        bukti: fileList,
        // bukti: files,
      });
    }
  };

  const handleChangeSelectCodeAccount = (name, value) => {
    if (value) {
      const findControlItem = codes_account.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_label`]: value.label,
          [`${name}_code`]: findControlItem.code,
          [`_id`]: value.value,
          description: findControlItem.description,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`_id`]: null,
      });
    }
  };

  const handleGetBudget = (name, value) => {
    setLoadingBudget(true);

    setData({
      ...data,
      [`${name}_number`]: value.value,
      [`${name}_label`]: value.label,
      available: 0,
      availLoad: 0
    });

    Axios.get(`${url}/internal-order/sap/${value.value}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.data.length <= 0) {
          message.error("Budget not available");
          setData({
            ...data,
            availLoad: 1
          });
        } else {
          setData({
            ...data,
            [`${name}_number`]: value.value,
            [`${name}_label`]: value.label,
            available:
              res.data.data.length > 0
                ? res.data.data[0].available
                  ? res.data.data[0].available.replaceAll(".", "")
                  : 0
                : 0,
            availLoad: 1
          });
        }

        setLoadingBudget(false);

      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoadingBudget(false);
      });
  };

  const getInternalOrder = async (keyword) => {
    setLoadingBudget(true);
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        department: JSON.parse(sessionStorage.getItem("io_department")),
        keyword,
        columns: ["internal_order_number", "description"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          available: 0,
        });
        setBudgets(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectIo = (name, value) => {
    setLoadingBudget(true);

    setData({
      ...data,
      available: 0,
    });

    if (value) {
      const findControlItem = budgets.find((x) => x.aufnr === value.value);
      if (findControlItem) {
        setData({
          ...data,
          available: findControlItem.available,
          [`${name}_number`]: value.value,
          [`${name}_label`]: value.label,
        });
      }
    } else {
      setData({
        ...data,
        [`${name}_number`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const getVendor = async (keyword) => {
    setLoadingVendor(true);
    await Axios.get(`${url}/vendor/list`, {
      params: {
        keyword,
        columns: ["vendor_name", "vendor_code"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setVendor(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoadingVendor(false);
  };

  const handleCreate = (value) => {
    let arrBank = [];
    let key = bankName.length;
    arrBank.push({
      key: key,
      label: value,
      value: value
    })
    setBankName(bankName.concat(arrBank));
    let name = "nama_bank";
    setData({
      ...data,
      [`${name}`]: value,
      [`${name}_id`]: key,
      no_rekening: accountNumber[key],
    });
  }

  const handleChangeSelectVendor = (name, value) => {
    if (name === "vendor") {
      const findControlItem = vendor.find((x) => x.vendor_code === value.value);
      if (findControlItem) {
        if (findControlItem.bank_name1) {
          const arrBank = [];
          const arrAccBank = [];
          const elBN = findControlItem.bank_name1;
          const elAB = findControlItem.account_number1;

          // arrBank.push(elBN);
          arrBank.push({
            key: 0,
            label: elBN,
            value: elBN
          })
          // arrAccBank.push({
          //   label: elBN,
          //   value: elAB
          // })
          arrAccBank.push(elAB);

          if (findControlItem.bank_name2) {
            const elBN = findControlItem.bank_name2;
            const elAB = findControlItem.account_number2;
            // arrBank.push(elBN);
            arrBank.push({
              key: 1,
              label: elBN,
              value: elBN
            })
            // arrAccBank.push({
            //   label: elBN,
            //   value: elAB
            // })
            arrAccBank.push(elAB);
          } else {
            message.success("Bank Name & Account Number available 1 data");
          }

          setBankName(arrBank);
          setAccountNumber(arrAccBank);

          setData({
            ...data,
            [`${name}_code`]: value.value,
            [`${name}_name`]: findControlItem.vendor_name,
            [`${name}_label`]: value.label,
            transfer_to: findControlItem.vendor_name,
          });
        } else {
          message.error("Bank Name or Account Number not found");
          setData({
            ...data,
            [`${name}_code`]: value.value,
            [`${name}_name`]: findControlItem.vendor_name,
            [`${name}_label`]: value.label,
            transfer_to: findControlItem.vendor_name,
            nama_bank: "",
            no_rekening: "",
          });
        }
      }
    } else if (name === "nama_bank") {
      setData({
        ...data,
        [`${name}`]: value.label,
        [`${name}_id`]: value.key,
        no_rekening: accountNumber[value.key],
      });
    } else {
      setData({
        ...data,
        [`${name}_code`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
        department: JSON.parse(sessionStorage.getItem("io_department")),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getDetails = (id) => {
    setLoadingDetail(true);
    Axios.get(`${url}/pengajuan-uang-muka-biaya-rupa/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBiayaRupaDetail(res.data.data.details);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const onSaveTemporaryData = () => {

    /* START - createby "abdoelhariez-kelola" 04-09-2022 */
    if (data.gl_code !== '' && data.cost_center_number !== '' && data.internal_order_number !== '' && data.quantity !== 0 && data.harga_satuan !== 0) {
      if (selectedId) {
        const modifyBiayaRupaDetail = [...biayaRupaDetail];
        const findBiayaRupaDetail = modifyBiayaRupaDetail.find(
          (x) => x.idActvCode === selectedId
        );

        const budgetIO = data.available;
        const amountBudget = data.quantity * data.harga_satuan;
        const beforeAmount = findBiayaRupaDetail.jumlah;
        const modifyListBudget = [...listBudget];
        const listIoDuplicate = modifyListBudget.find(
          (x) => x.internal_order_number === findBiayaRupaDetail.internal_order_number
        );

        // Setting Budget on details items
        findBiayaRupaDetail.cost_center_id = data.cost_center_id;
        findBiayaRupaDetail.cost_center_number = data.cost_center_number;
        findBiayaRupaDetail.cost_center_label = data.cost_center_label;
        // findBiayaRupaDetail.internal_order_id = data.internal_order_id;
        findBiayaRupaDetail.internal_order_number = data.internal_order_number;
        findBiayaRupaDetail.internal_order_label = data.internal_order_label;
        findBiayaRupaDetail.available_budget = budgetIO;
        // end setting budget

        findBiayaRupaDetail.gl_code = data.gl_code;
        findBiayaRupaDetail.gl_label = data.gl_label;

        findBiayaRupaDetail.description = data.description;
        findBiayaRupaDetail.quantity = data.quantity;
        findBiayaRupaDetail.harga_satuan = data.harga_satuan;
        findBiayaRupaDetail.jumlah = data.quantity * data.harga_satuan;
        // findBiayaRupaDetail.ppn_persen = data.ppn_persen

        findBiayaRupaDetail.ppn = Math.floor(data.ppn);
        findBiayaRupaDetail.pph21 = data.pph21 ? data.pph21 : 0;
        findBiayaRupaDetail.pph23 = data.pph23 ? data.pph23 : 0;
        findBiayaRupaDetail.pph4 = data.pph4 ? data.pph4 : 0;
        findBiayaRupaDetail.total =
          data.quantity * data.harga_satuan +
          data.ppn -
          data.pph21 -
          data.pph23 -
          data.pph4;

        setBiayaRupaDetail(modifyBiayaRupaDetail);

        if (listIoDuplicate) {
          const totalAmountBefore = listIoDuplicate.total_amount - beforeAmount + amountBudget;

          listIoDuplicate.cost_center_id = data.cost_center_id;
          listIoDuplicate.cost_center_number = data.cost_center_number;
          listIoDuplicate.cost_center_label = data.cost_center_label;
          listIoDuplicate.internal_order_number = data.internal_order_number;
          listIoDuplicate.internal_order_label = data.internal_order_label;
          listIoDuplicate.available_budget = parseFloat(budgetIO);
          listIoDuplicate.remaining_budget = parseFloat(budgetIO - totalAmountBefore);
          listIoDuplicate.total_amount = listIoDuplicate.total_amount - beforeAmount + amountBudget;
          listIoDuplicate.statusBudget = parseFloat(budgetIO) >= totalAmountBefore ? 'V' : 'X'

          setListBudget(modifyListBudget);
        }

      } else {
        const budgetIO = data.available;
        const amountBudget = data.quantity * data.harga_satuan;
        setBiayaRupaDetail([
          ...biayaRupaDetail,
          {
            idActvCode: Math.random(),
            _id: data._id,
            cost_center_id: data.cost_center_id,
            cost_center_number: data.cost_center_number,
            cost_center_label: data.cost_center_label,
            internal_order_number: data.internal_order_number,
            internal_order_label: data.internal_order_label,
            available_budget: budgetIO,
            gl_code: data.gl_code,
            gl_label: data.gl_label,
            description: data.description,
            quantity: data.quantity,
            harga_satuan: data.harga_satuan,
            jumlah: data.quantity * data.harga_satuan,
            ppn: Math.floor(data.ppn),
            pph21: data.pph21 ? data.pph21 : 0,
            pph23: data.pph23 ? data.pph23 : 0,
            pph4: data.pph4 ? data.pph4 : 0,
            total: Math.floor(
              data.quantity * data.harga_satuan +
              data.ppn -
              data.pph21 -
              data.pph23 -
              data.pph4
            ),
          },
        ]);

        if (listBudget.length >= 1) {
          const modifyListBudget = [...listBudget];
          const listIoDuplicate = modifyListBudget.find(
            (x) => x.internal_order_number === data.internal_order_number
          );

          if (listIoDuplicate) {
            const totalAmountBefore = listIoDuplicate.total_amount + amountBudget;
            listIoDuplicate.cost_center_id = data.cost_center_id;
            listIoDuplicate.cost_center_number = data.cost_center_number;
            listIoDuplicate.cost_center_label = data.cost_center_label;
            listIoDuplicate.internal_order_number = data.internal_order_number;
            listIoDuplicate.internal_order_label = data.internal_order_label;
            listIoDuplicate.available_budget = parseFloat(budgetIO);
            listIoDuplicate.remaining_budget = parseFloat(budgetIO - totalAmountBefore);
            listIoDuplicate.total_amount = listIoDuplicate.total_amount + amountBudget;
            listIoDuplicate.statusBudget = parseFloat(budgetIO) >= totalAmountBefore ? 'V' : 'X'

            setListBudget(modifyListBudget);
          } else {
            setListBudget([
              ...listBudget,
              {
                id: Math.random(),
                cost_center_id: data.cost_center_id,
                cost_center_number: data.cost_center_number,
                cost_center_label: data.cost_center_label,
                internal_order_number: data.internal_order_number,
                internal_order_label: data.internal_order_label,
                available_budget: parseFloat(budgetIO),
                remaining_budget: parseFloat(budgetIO - amountBudget),
                total_amount: amountBudget,
                statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
              }
            ]);
          }
        } else {
          setListBudget([
            ...listBudget,
            {
              id: Math.random(),
              cost_center_id: data.cost_center_id,
              cost_center_number: data.cost_center_number,
              cost_center_label: data.cost_center_label,
              internal_order_number: data.internal_order_number,
              internal_order_label: data.internal_order_label,
              available_budget: parseFloat(budgetIO),
              remaining_budget: parseFloat(budgetIO - amountBudget),
              total_amount: amountBudget,
              statusBudget: parseFloat(budgetIO) >= amountBudget ? 'V' : 'X'
            }
          ]);
        }
      }

      setSelectedId("");

      setData({
        ...data,
        cost_center_id: "",
        cost_center_label: "",
        cost_center_number: "",
        internal_order_id: "",
        internal_order_number: "",
        internal_order_label: "",
        available: "",
        gl_code: null,
        gl_label: null,
        _id: null,
        description: "",
        quantity: 0,
        harga_satuan: 0,
        jumlah: 0,
        ppn: 0,
        pph21: 0,
        pph23: 0,
        pph4: 0,
        total: 0,
      });

      setCalPpn(0);
      setValCheckbox(false);
      setIsModalVisible(false);


    } else if (data.cost_center_number === '') {
      message.error("Please input your Cost Center Number, cannot be empty");
      refActCostCenter.current.focus()
    } else if (data.internal_order_number === '') {
      message.error("Please input your Internal Order Number, cannot be empty");
      refActInternalOrder.current.focus()
    } else if (data.gl_code === '') {
      message.error("Please input your Activity Code, cannot be empty");
      refActGlCode.current.focus()
    } else if (data.quantity === 0) {
      message.error("Please input your Qty Activity Code, cannot be empty");
      refActQty.current.focus()
    } else if (data.harga_satuan === 0) {
      message.error("Please input your Price, cannot be empty");
      refActPrice.current.focus()
    }
    /* END - createby "abdoelhariez-kelola" 04-09-2022 */


  };

  const handleRemoveDetails = (id) => {
    const cekDetailIO = biayaRupaDetail.find(
      (x) => x.idActvCode === id
    );

    const details = biayaRupaDetail.filter(
      (x) => x.idActvCode !== id
    );

    const modifyListBudget = [...listBudget];
    const listBudgetDetails = modifyListBudget.find(
      (x) => x.internal_order_number === cekDetailIO.internal_order_number
    );

    const sumAmount = listBudgetDetails.total_amount - cekDetailIO.jumlah;

    if (sumAmount <= 0) {
      const listBudgetDetails = listBudget.filter(
        (x) => x.internal_order_number !== cekDetailIO.internal_order_number
      );

      setListBudget(listBudgetDetails);
    } else {
      listBudgetDetails.total_amount = sumAmount;
      listBudgetDetails.remaining_budget = listBudgetDetails.available_budget - sumAmount;
      listBudgetDetails.statusBudget = listBudgetDetails.available_budget >= sumAmount ? 'V' : 'X'
      setListBudget(modifyListBudget);
    }

    setBiayaRupaDetail(details);
  };

  const handleEditDetails = (id) => {
    const details = biayaRupaDetail.find(
      (x) => x.idActvCode === id
    );

    setSelectedId(id);

    setData({
      ...data,
      cost_center_id: details.cost_center_id,
      cost_center_label: details.cost_center_label,
      cost_center_number: details.cost_center_number,
      // internal_order_id: details.internal_order_id,
      internal_order_number: details.internal_order_number,
      internal_order_label: details.internal_order_label,
      available: details.available_budget,
      gl_code: details.gl_code,
      gl_label: `${details.gl_code} - ${details.description}`,
      code: details.gl_code,
      description: details.description,
      quantity: details.quantity,
      harga_satuan: details.harga_satuan,
      jumlah: details.jumlah,
      ppn: details.ppn,
      pph21: details.pph21,
      pph23: details.pph23,
      pph4: details.pph4,
      total: details.total,
    });

    setIsModalVisible(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      code: "",
      description: "",
      quantity: 0,
      harga_satuan: 0,
      jumlah: 0,
      ppn: 0,
      total: 0,
      pph21: 0,
      pph23: 0,
      pph4: 0,
      internal_order_id: "",
      internal_order_number: "",
      available: "",
      cost_center_id: "",
      cost_center_number: "",
      vendor_code: "",
    });

    setBiayaRupaDetail([]);
  };

  const handleResetPengajuan = (name, value) => {
    setData({
      code: "",
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: "",
      ppn: 0,
      total: "",
      pph21: 0,
      pph23: 0,
      pph4: 0,
      internal_order_id: "",
      internal_order_number: "",
      cost_center_id: "",
      cost_center_number: "",
      vendor_code: "",
      [name]: value,
    });

    setBiayaRupaDetail([]);
    setListBudget([]);
  };

  const onSaveData = () => {
    const total_amount = Math.floor(
      biayaRupaDetail.reduce(
        (prevValue, currentValue) => {
          const sumTotal = currentValue.quantity * currentValue.harga_satuan;
          const grandTotal = sumTotal;
          return grandTotal + prevValue;
        },
        0
      ) ||
      biayaRupaDetail.reduce(
        (prevValue, currentValue) => {
          const sumTotal = currentValue.quantity * currentValue.harga_satuan;
          const grandTotal = sumTotal;

          return grandTotal + prevValue;
        },
        0
      )
    );


    setLoading(true);
    let fd = new FormData();

    fd.set("details", JSON.stringify(biayaRupaDetail));
    fd.set("list_budget_details", JSON.stringify(listBudget));
    fd.set("types_reimbursment", "Pengajuan UM Biaya Rupa-Rupa");
    fd.set("vendor_code", data.vendor_code);
    fd.set("vendor_name", data.vendor_name);
    fd.set("transfer_to", data.transfer_to);
    fd.set("nama_bank", data.nama_bank);
    fd.set("no_rekening", data.no_rekening);
    fd.set("status_proses", "ok budget");
    fd.set("grand_total", data.grand_total);
    fd.set("total_amount", total_amount);
    fd.set("grand_total", data.grand_total);
    fd.set("description", data.description_head);

    if (data.bukti) {
      if (data.bukti.length > 0) {
        data.bukti.length > 0 && data.bukti.map((bukti, i) => {
          if (bukti.originFileObj) {
            fd.append(`bukti_scanner[${i}]`, bukti.originFileObj);
          } else {
            fd.set(`bukti_scanner[${i}]`, bukti.name);
          }
        });
      } else {
        fd.append(`bukti_scanner`, "-");
      }
    } else {
      fd.append(`bukti_scanner`, "-");
    }

    if (fd.get('bukti_scanner') !== '-' && fd.get('description') !== '' && fd.get('transfer_to') && biayaRupaDetail.length != 0 && listBudget.length != 0) {
      Axios.post(
        match.params.id
          ? `${url}/pengajuan-uang-muka-biaya-rupa/${match.params.id}`
          : `${url}/pengajuan-uang-muka-biaya-rupa`,
        fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          if (res.data.type === "Success") {
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
          setLoading(false);
          history.push("/pengajuan-uang-muka-biaya-rupa");
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              const errors = err.response.data.errors
              if (Object.keys(errors).some(key => key.startsWith('bukti_scanner'))) {
                for (const key in errors) {
                  errors[key].map((err) => message.error(err))
                }
              } else {
                setError(errors);
              }
            } else if (err.response.status === 401) {
              sessionStorage.removeItem("token");
            } else {
              message.error(err.response.data.message);
            }
          } else {
            message.error("Backend not yet Started");
          }
          setLoading(false);
        });

    } else {
      if (fd.get('transfer_to') === '') {
        message.error("Please input your Account Name, cannot be empty");
        refAccountName.current.focus()
      } else if (fd.get('bukti_scanner') === '-') {
        message.error("Please upload file or image, cannot be empty");
        refBtnUpload.current.focus()
      } else if (fd.get('description') === '') {
        message.error("Please input your description, cannot be empty");
        refInputDescription.current.focus()
      } else if (biayaRupaDetail.length == 0) {
        message.error("Please input activity details transaction, cannot be empty");
      } else if (listBudget.length == 0) {
        message.error("Please complete your transaction list budget Internal Order & Cost Center");
      }

      setLoading(false);
    }
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const resetModal = () => {
    setData({
      ...data,
      code: null,
      _id: null,
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: 0,
      ppn: 0,
      pph21: 0,
      pph23: 0,
      pph4: 0,
      total: 0,
    });
  };

  const closeModal = () => {
    setData({
      ...data,
      code: null,
      _id: null,
      internal_order_number: "",
      internal_order_label: "",
      cost_center_id: "",
      cost_center_number: "",
      cost_center_label: "",
      gl_label: "",
      description: "",
      quantity: "",
      harga_satuan: "",
      jumlah: 0,
      ppn: 0,
      pph21: 0,
      pph23: 0,
      pph4: 0,
      total: 0,
    });

    setSelectedId("");
    setIsModalVisible(false);
    setValCheckbox(false);
    setCalPpn(0);
  };

  const getCodeAccount = async (keyword) => {
    setLoadGlCode(true);
    await Axios.get(`${url}/gl-account`, {
      params: {
        keyword,
        columns: ["code", "description"],
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodesAccount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
    setLoadGlCode(false);
  };

  const columnsListBudget = [
    {
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_label",
      align: "left",
    },
    {
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      align: "right",
      style: { fontWeight: 800, fontSize: 16 },
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>REMAINING BUDGET</div>,
      dataIndex: "remaining_budget",
      align: "right",
      render: (remaining_budget) => {
        return <AntNumberFormat value={remaining_budget} type="text" style={{ fontWeight: 800, fontSize: 16, letterSpacing: 1 }} />;
      },
    },
  ];

  const columns = [
    {
      align: "center",
      title: "CODE",
      dataIndex: "gl_code",
      width: 120,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      width: 350,
    },
    {
      align: "center",
      title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
      dataIndex: "cost_center_number",
      width: 120,
    },
    {
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
      align: "center",
      width: 120,
      render: (internal_order_number) => {
        return internal_order_number ?
          internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
          : '';
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      width: 150,
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" />;
      },

    },
    {
      align: "center",
      title: "QTY",
      dataIndex: "quantity",
      width: 60,
      render: (quantity) => {
        return <AntNumberFormat value={quantity} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PRICE</div>,
      dataIndex: "harga_satuan",
      width: 100,
      render: (harga_satuan) => {
        return <AntNumberFormat value={harga_satuan} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "jumlah",
      width: 100,
      render: (jumlah) => {
        return <AntNumberFormat value={jumlah} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPN</div>,
      dataIndex: "ppn",
      width: 100,
      render: (ppn) => {
        return <AntNumberFormat value={ppn} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH21</div>,
      dataIndex: "pph21",
      width: 100,
      render: (pph21) => {
        return <AntNumberFormat value={pph21} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH23</div>,
      dataIndex: "pph23",
      width: 100,
      render: (pph23) => {
        return <AntNumberFormat value={pph23} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH4(2)</div>,
      dataIndex: "pph4",
      width: 100,
      render: (pph4) => {
        return <AntNumberFormat value={pph4} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>GRAND TOTAL</div>,
      dataIndex: "total",
      width: 120,
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
    {
      title: "ACTION",
      align: "center",
      dataIndex: "idActvCode",
      width: 200,
      render: (id) =>
        arrayTes &&
        arrayTes.map((x, data) => {
          return x == "delete" ? (
            <Button
              onClick={() => handleRemoveDetails(id, data)}
              size="small"
              type="danger"
            >
              <DeleteOutlined />
              Delete
            </Button>
          ) : (
            <Button
              style={{ marginLeft: 5 }}
              onClick={() => handleEditDetails(id, data)}
              size="small"
              type="primary"
            >
              <EditOutlined />
              Edit
            </Button>
          );
        }),
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance Payment Submission"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/pengajuan-uang-muka-biaya-rupa">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  Advance Payment Submission Create
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Information"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Employee Name"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("fullname")}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("npk")}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Division"
                            >
                              <Input
                                disabled
                                value={sessionStorage.getItem("divisi")}
                                onChange={(e) => {
                                  handleChange("divisi", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              validateStatus={
                                error && error.description_head ? "error" : false
                              }
                              help={error && error.description_head ? error.description_head[0] : false}
                              label="Description *"
                            >
                              <TextArea
                                value={data.description_head}
                                ref={refInputDescription}
                                onChange={(e) => handleChange("description_head", e.target.value)}
                              ></TextArea>
                            </Form.Item>

                          </Col>
                          <Col xs={24} md={12} lg={12}>

                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name *"
                            >
                              <Select
                                labelInValue
                                loading={loadingVendor}
                                onChange={(value) =>
                                  handleChangeSelectVendor("vendor", value)
                                }
                                onFocus={() => getVendor("")}
                                showSearch
                                ref={refAccountName}
                                onSearch={(value) => getVendor(value)}
                                filterOption={false}
                                value={{
                                  key: data.vendor_code,
                                  label: data.vendor_label,
                                }}
                              >
                                {vendor &&
                                  vendor.map((account) => {
                                    return (
                                      <Option
                                        value={account.vendor_code}
                                        key={account.vendor_code}
                                      >
                                        {`${account.vendor_code} - ${account.vendor_name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama_bank ? "error" : false
                              }
                              help={
                                error && error.nama_bank
                                  ? error.nama_bank[0]
                                  : false
                              }
                              label="Bank Name *"
                            >
                              <CreatableSelect
                                theme={(theme) => ({
                                  ...theme,
                                  borderWidth: 0.1,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#f5f5f5',
                                    primary: '#089244',
                                  },
                                })}
                                // isClearable
                                onCreateOption={(value) => handleCreate(value)}
                                onChange={(value) =>
                                  handleChangeSelectVendor("nama_bank", value)
                                }
                                options={bankName}
                                value={{
                                  key: data.nama_bank_id,
                                  label: data.nama_bank,
                                }}
                              />

                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.no_rekening ? "error" : false
                              }
                              help={
                                error && error.no_rekening
                                  ? error.no_rekening[0]
                                  : false
                              }
                              label="Account Number *"
                            >
                              <Input
                                placeholder="Account Number"
                                value={data.no_rekening}
                                onChange={(e) => {
                                  handleChange("no_rekening", e.target.value);
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              name="bukti"
                              label="Upload File *"
                              // required
                              validateStatus={
                                error && error.bukti ? "error" : false
                              }
                              help={
                                error && error.bukti ? error.bukti[0] : false
                              }
                            >
                              <Fragment>
                                <Upload
                                  multiple={true}
                                  onRemove={handleRemove}
                                  onChange={handleChangeFile}
                                  beforeUpload={handleBeforeUpload}
                                  action={data.bukti}
                                  fileList={data.bukti}
                                >
                                  <Button ref={refBtnUpload}>
                                    Upload
                                    <UploadOutlined />
                                  </Button>
                                </Upload>
                              </Fragment>
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space>
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add
                        </Button>

                        <Modal
                          title={selectedId ? "Edit Details" : "Add Details"}
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1000}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  {...layoutItems}
                                  name="control-hooks"
                                  labelAlign="left"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        {/* COST CENTER */}
                                        <Form.Item
                                          validateStatus={
                                            error && error.cost_center ? "error" : false
                                          }
                                          help={
                                            error && error.cost_center
                                              ? error.cost_center[0]
                                              : false
                                          }
                                          label="Cost Center"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelect("cost_center", value)
                                            }
                                            onFocus={() => getCostCenter("")}
                                            showSearch
                                            ref={refActCostCenter}
                                            onSearch={(value) => getCostCenter(value)}
                                            filterOption={false}
                                            value={{
                                              key: data.cost_center_id,
                                              label: data.cost_center_label,
                                            }}
                                          >
                                            {data.cost_centers &&
                                              data.cost_centers.map((cost_center) => {
                                                return (
                                                  <Option
                                                    value={cost_center._id}
                                                    key={cost_center._id}
                                                  >
                                                    {`${cost_center.cost_center_number} - ${cost_center.description}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        {/* END COST CENTER */}

                                        {/* INTERNAL ORDER */}
                                        <Form.Item label="Internal Order">
                                          <Select
                                            labelInValue
                                            loading={
                                              data.available === 0 ? loadingBudget : ""
                                            }
                                            onChange={(value) =>
                                              handleChangeSelectIo("internal_order", value)
                                            }
                                            ref={refActInternalOrder}
                                            onFocus={() => getInternalOrder("")}
                                            showSearch
                                            onSearch={(value) => getInternalOrder(value)}
                                            onSelect={(value) =>
                                              handleGetBudget("internal_order", value)
                                            }
                                            filterOption={false}
                                            value={{
                                              key: data.internal_order_number,
                                              label: data.internal_order_label,
                                            }}
                                          >
                                            {budgets &&
                                              budgets.map((internal_order_budget) => {
                                                return (
                                                  <Option
                                                    value={
                                                      internal_order_budget.internal_order_number
                                                    }
                                                    key={internal_order_budget._id}
                                                  >
                                                    {`${internal_order_budget.internal_order_number} - ${internal_order_budget.description}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        {/* END INTERNAL ORDER */}

                                        <Form.Item
                                          label="Budget Tersedia"
                                        >
                                          {/* START - createby "abdoelhariez-kelola" 04-09-2022 */}
                                          {data.availLoad === 0 ?
                                            (
                                              <Spin tip="Loading get data budget . . ."></Spin>
                                            ) : (
                                              <NumberFormat
                                                disabled
                                                type="input"
                                                thousandSeparator={true}
                                                prefix={"Rp. "}
                                                style={{ fontWeight: 600, fontSize: 20 }}
                                                value={data.available}
                                                displayType={"text"}
                                                onChange={(value) => {
                                                  handleChange("available", value);
                                                }}
                                              />
                                            )
                                          }
                                          {/* END - createby "abdoelhariez-kelola" 04-09-2022 */}

                                        </Form.Item>

                                        <Divider />

                                        <Form.Item
                                          validateStatus={
                                            error && error.gl_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.gl_code
                                              ? error.gl_code[0]
                                              : false
                                          }
                                          label="Activity Code"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectCodeAccount(
                                                "gl",
                                                value
                                              )
                                            }
                                            onFocus={() => getCodeAccount("")}
                                            showSearch
                                            ref={refActGlCode}
                                            loading={loadGlCode}
                                            onSearch={(value) =>
                                              getCodeAccount(value)
                                            }
                                            filterOption={false}
                                            value={{
                                              value: data._id,
                                              label: data.gl_label,
                                            }}
                                          >
                                            {codes_account &&
                                              codes_account.map(
                                                (code_account) => {
                                                  return (
                                                    <Option
                                                      value={code_account._id}
                                                      key={code_account._id}
                                                    >
                                                      {`${code_account.code} - ${code_account.description}`}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.description
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.description
                                              ? error.description[0]
                                              : false
                                          }
                                          label="Description"
                                        >
                                          <Input
                                            disabled
                                            value={data.description}
                                            onChange={(e) => {
                                              handleChange(
                                                "description",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.quantity
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.quantity
                                              ? error.quantity[0]
                                              : false
                                          }
                                          label="Quantity"
                                        >
                                          <AntNumberFormat
                                            getInputRef={refActQty}
                                            value={data.quantity}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("quantity", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.harga_satuan
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.harga_satuan
                                              ? error.harga_satuan[0]
                                              : false
                                          }
                                          label="Unit Price"
                                        >
                                          <AntNumberFormat
                                            getInputRef={refActPrice}
                                            value={data.harga_satuan}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("harga_satuan", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.jumlah
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.jumlah
                                              ? error.jumlah[0]
                                              : false
                                          }
                                          label="Total"
                                        >
                                          <AntNumberFormat
                                            disabled
                                            value={
                                              data.quantity * data.harga_satuan
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("jumlah", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item label="PPN">
                                          <Row gutter={8}>
                                            <Col span={4}>
                                              <Checkbox
                                                checked={
                                                  data.ppn
                                                    ? data.ppn
                                                    : valCheckbox
                                                }
                                                onChange={(e) => {
                                                  activePPN(e);
                                                }}
                                              >
                                                PPN
                                              </Checkbox>
                                            </Col>
                                            <Col span={20}>
                                              <Form.Item
                                                validateStatus={
                                                  error && error.ppn
                                                    ? "error"
                                                    : false
                                                }
                                                help={
                                                  error && error.ppn
                                                    ? error.ppn[0]
                                                    : false
                                                }
                                              >
                                                <AntNumberFormat
                                                  // disabled
                                                  value={Math.floor(data.ppn)}
                                                  type="input"
                                                  onChange={(e) => {
                                                    handleChange("ppn", e);
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.pph21
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.pph21
                                              ? error.pph21[0]
                                              : false
                                          }
                                          label="PPH21"
                                        >
                                          <AntNumberFormat
                                            value={data.pph21 ? data.pph21 : 0}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("pph21", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.pph23
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.pph23
                                              ? error.pph23[0]
                                              : false
                                          }
                                          label="PPH23"
                                        >
                                          <AntNumberFormat
                                            value={data.pph23 ? data.pph23 : 0}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("pph23", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.pph4
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.pph4
                                              ? error.pph4[0]
                                              : false
                                          }
                                          label="PPH4(2)"
                                        >
                                          <AntNumberFormat
                                            value={data.pph4 ? data.pph4 : 0}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("pph4", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.total
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.total
                                              ? error.total[0]
                                              : false
                                          }
                                          label="Grand Total"
                                        >
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            disabled
                                            value={Math.floor(
                                              data.quantity *
                                              data.harga_satuan +
                                              data.ppn -
                                              data.pph21 -
                                              data.pph23 -
                                              data.pph4
                                            )}
                                            type="input"
                                            onChange={(e) => {
                                              handleChange("total", e);
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={resetModal}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>

                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        size="small"
                        columns={columnsListBudget}
                        dataSource={listBudget}
                        rowKey={(data) => data.id}
                        onChange={handleTableChange}
                        // scroll={{ x: 2000 }}
                        loading={loading}
                      />
                    </Col>

                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        size="small"
                        columns={columns}
                        dataSource={biayaRupaDetail}
                        rowKey="idActvCode"
                        // rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        scroll={{ x: 1800 }}
                        loading={loading}
                      />
                      <Form
                        style={{ marginTop: "30px" }}
                        labelAlign="left"
                        {...layout}
                        name="control-hooks"
                      >
                        <Row>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item label="Total Amount">
                              <AntNumberFormat
                                disabled
                                value={Math.floor(
                                  biayaRupaDetail.reduce(
                                    (prevValue, currentValue) => {
                                      const sumTotal =
                                        currentValue.quantity *
                                        currentValue.harga_satuan;
                                      const grandTotal = sumTotal;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  ) ||
                                  biayaRupaDetail.reduce(
                                    (prevValue, currentValue) => {
                                      const sumTotal =
                                        currentValue.quantity *
                                        currentValue.harga_satuan;
                                      const grandTotal = sumTotal;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  )
                                )}
                                type="input"
                              />
                            </Form.Item>

                            <Form.Item label="Total">
                              <AntNumberFormat
                                disabled
                                value={Math.floor(
                                  data.totals +
                                  biayaRupaDetail.reduce(
                                    (prevValue, currentValue) => {
                                      const sumTotal =
                                        currentValue.quantity *
                                        currentValue.harga_satuan;
                                      const sumPpn = currentValue.ppn;
                                      const grandTotal =
                                        sumTotal +
                                        sumPpn -
                                        currentValue.pph21 -
                                        currentValue.pph23 -
                                        currentValue.pph4;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  ) ||
                                  biayaRupaDetail.reduce(
                                    (prevValue, currentValue) => {
                                      const sumTotal =
                                        currentValue.quantity *
                                        currentValue.harga_satuan;
                                      const sumPpn = currentValue.ppn;
                                      const grandTotal =
                                        sumTotal +
                                        sumPpn -
                                        currentValue.pph21 -
                                        currentValue.pph23 -
                                        currentValue.pph4;

                                      return grandTotal + prevValue;
                                    },
                                    0
                                  )
                                )}
                                type="input"
                                onChange={(e) => {
                                  handleChange("grand_total", e);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>

                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreatePengajuanBiayaRupaRupa;
