let url;
let url_sso;
// const socketUrl = 'http://127.0.0.1:5555'
// const socketUrl = "http://192.168.1.5:5555";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // url = "https://api.evoucher.kelola.biz/api";
  url = "http://127.0.0.1:8000/api"; //Local Dev
  // url = "http://192.168.18.15:8000/api";
  // url = "http://192.168.1.7:8000/api";
  //url = "http://api.evoucher.dharmap.com/api";
  url_sso = "https://api.sso.kelola.biz/api";
} else {
  // url = "http://127.0.0.1:8000/api";
  // url = "https://api.evoucher.kelola.biz/api";
  url_sso = "https://api.sso.kelola.biz/api";
  url = "http://api.evoucher.dharmap.com/api";
  // url = "http://192.168.100.3:8002/api";
}

export { url, url_sso };

//config
