import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Input,
  Table,
  DatePicker,
  Space,
  message,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./ApprovalPenyelesaianUangMuka.less";
import Search from "antd/lib/input/Search";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const types = [
  {
    filter: "all",
    title: "Total Document",
  },
  {
    filter: "inProgress",
    title: "Total In Progress",
  },
  {
    filter: "receive",
    title: "Total Receive",
  },
  {
    filter: "unverified",
    title: "Total Unverified",
  },
];

function ApprovalPenyelesaianUangMuka() {
  const [type, setType] = useState("all");
  const [data, setData] = useState({
    //start_date: moment().startOf("month"),
    //end_date: moment().endOf("month"),
    start_date: undefined,
    end_date: undefined,
  });
  const [keyword, setKeyword] = useState("");
  const [approvalPenyelesaianUangMukas, setApprovalPenyelesaianUangMukas] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({
    sort: "create_at",
    order: "descend",
  });
  const [count, setCount] = useState({
    all: "",
    in_progress: "",
    canceled: "",
    received: "",
    unverified: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChangeType = (type) => {
    setType(type);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value[0],
        end_date: value[1],
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
        end_date: undefined,
      });
    }
  };

  useEffect(() => {
    getData();
    getApprovalPenyelesaianUangMukaCount();
  }, [keyword, page, sort, type, data.start_date, data.end_date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/penyelesaian-uang-muka`, {
      params: {
        keyword,
        //columns: ["gr_number", "vendor_name", "po_number"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setApprovalPenyelesaianUangMukas(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApprovalPenyelesaianUangMukaCount = () => {
    setLoading(true);
    Axios.get(`${url}/approval-penyelesaian-uang-muka/count`, {
      params: {
        // keyword,
        // columns: ["gr_number", "vendor_name", "po_number"],
        // perpage: pageSize,
        // page,
        // sort: sort.sort,
        // order: sort.order,
        type,
        //start_date: moment(data.start_date).format("YYYY-MM-DD"),
        //end_date: moment(data.end_date).format("YYYY-MM-DD"),
        start_date: data.start_date
          ? data.start_date.format("YYYY-MM-DD")
          : null,
        end_date: data.end_date ? data.end_date.format("YYYY-MM-DD") : null,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "nama",
      key: "nama",
      align: "center",
      sorter: true,
    },
    {
      title: "NPK",
      dataIndex: "npk",
      key: "npk",
      align: "center",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "keterangan",
      key: "keterangan",
      align: "center",
    },
    {
      title: "Internal Order",
      dataIndex: "internal_order",
      key: "internal_order",
      align: "center",
    },
    {
      title: "Cost Center",
      dataIndex: "cost_center",
      key: "cost_center",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: "Total",
      children: [
        {
          title: "Down Payment",
          dataIndex: "uang_muka",
          key: "uang_muka",
        },
        {
          title: "Sisa",
          dataIndex: "sisa",
          key: "sisa",
        },
      ],
    },
    {
      title: "Approval Status",
      dataIndex: "approval_status",
      key: "approval_status",
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card type="inner" title="Approval Penyelesaian Uang Muka"></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={8}>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-in-progress pointer"
              title="Pending"
              bordered={false}
            >
              <div className="value-font-size ">5</div>
            </Card>
          </Col>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-received pointer"
              title="Approved"
              bordered={false}
            >
              <div className="value-font-size ">3</div>
            </Card>
          </Col>
          <Col xs={24} md={8} span={8}>
            <Card
              className="card-body-all pointer"
              title="Total Document"
              bordered={false}
            >
              <div className="value-font-size ">8</div>
            </Card>
          </Col>
          <Col xs={24}>
            <Card className="body-data-a">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={10} lg={10}>
                  {types.find((x) => x.filter === type).title}
                </Col>
                <Col xs={24} md={14} lg={14} className="align-right">
                  <Space>
                    <Search
                      placeholder="Search..."
                      onSearch={(value) => handleSearch(value)}
                    />
                  </Space>
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    scroll={{ x: "calc(400px)" }}
                    columns={columns}
                    dataSource={approvalPenyelesaianUangMukas.data}
                    //rowKey={(data) => data.purchase_order}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: approvalPenyelesaianUangMukas.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ApprovalPenyelesaianUangMuka;
