import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Tag,
  Avatar,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  ExportOutlined,
  ImportOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import "./User.less";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function User() {
  const [keyword, setKeyword] = useState("");
  const [npk, setNpk] = useState("");
  const [user_type, setUserType] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [data, setData] = useState({
    file: null,
  });
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "desc",
  });

  const handleSearch = (value) => {
    setKeyword(value);
    setNpk(value);
    setUserType(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, npk, user_type, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
      // if (findPermission && findPermission.children) {
      //   const findButtonAccess = findPermission.children.filter(
      //     (x) => x.permission_type === "button"
      //   );
      //   if (findButtonAccess) {
      //     setButtonAccess(findButtonAccess);
      //   }
      // }
    }
  }, []);

  const exportUser = () => {
    setLoading(true);
    Axios.get(`${url}/user/download`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export User" + ".xlsx");
      setLoading(false);
    });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/user`, {
      params: {
        keyword,
        columns: ["username", "email", "npk", "user_type", "full_name"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/user/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Photo",
      dataIndex: "photo_url",
      sorter: false,
      align: "center",
      render: (photo_url) =>
        photo_url ? (
          <Avatar src={photo_url} />
        ) : (
          <Avatar icon={<UserOutlined />} />
        ),
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "center",
      sorter: true,
    },
    {
      title: "NPK",
      dataIndex: "npk",
      align: "center",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>Full Name</div>,
      dataIndex: "full_name",
      align: "left",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>Email</div>,
      dataIndex: "email",
      align: "left",
      sorter: false,
    },
    {
      align: "center",
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      sorter: false,
      render: (id, data, no) => {
        no = no + 1;
        return {
          children: (
            <Tag
              color={
                data.user_type === "Internal"
                  ? "success"
                  : data.user_type === "Eksternal"
                  ? "processing"
                  : ""
              }
            >
              {" "}
              {data.user_type}
            </Tag>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      // fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {buttonAccess &&
                    buttonAccess.find((x) => x.url === "user/view") && (
                      <Menu.Item key="1">
                        <Link to={`/user/view/${id}`}>
                          <InfoCircleOutlined /> View
                        </Link>
                      </Menu.Item>
                    )}
                  {buttonAccess &&
                    buttonAccess.find((x) => x.url === "user/edit") && (
                      <Menu.Item key="2">
                        <Link to={`/user/edit/${id}`}>
                          <EditOutlined /> Edit
                        </Link>
                      </Menu.Item>
                    )}
                  {buttonAccess &&
                    buttonAccess.find((x) => x.url === "user/delete") && (
                      <Menu.Item key="3">
                        <span onClick={() => handleDelete(id)}>
                          <DeleteOutlined />
                          Delete
                        </span>
                      </Menu.Item>
                    )}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="User"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "user/create") && (
                        <Link to="/user/create">
                          <Button type="primary">
                            <PlusOutlined />
                            Create
                          </Button>
                        </Link>
                      )}
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "user/import") && (
                        <Button type="primary" onClick={showModal}>
                          <ImportOutlined />
                          Import
                        </Button>
                      )}
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "user/export") && (
                        <Button
                          loading={loading}
                          type="primary"
                          onClick={exportUser}
                        >
                          <ExportOutlined />
                          Export
                        </Button>
                      )}

                    <Modal
                      title="Import User"
                      visible={isModalVisible}
                      footer={null}
                      onCancel={closeModal}
                      // onCancel={handleCancel}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Fragment>
                              <Upload
                                onRemove={handleRemove}
                                beforeUpload={handleBeforeUpload}
                                fileList={[]}
                              >
                                <Button style={{ marginBottom: 10 }}>
                                  Choose File <ExportOutlined />
                                </Button>
                              </Upload>
                              {data.file && (
                                <React.Fragment
                                  children={[
                                    <Button
                                      onClick={handleRemove}
                                      type="danger"
                                      ghost
                                    >
                                      <DeleteOutlined />
                                    </Button>,
                                  ]}
                                >
                                  {data.file.name ? data.file.name : data.file}
                                </React.Fragment>
                              )}
                            </Fragment>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSaveData}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                                backgroundColor: "#1b2086",
                                borderColor: "#1b2086",
                              }}
                            >
                              Upload
                            </Button>
                            <Button htmlType="reset" onClick={handleRemove}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>

                    {/* <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button> */}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={users.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: users.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    bordered
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default User;
