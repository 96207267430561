import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Checkbox,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";

const { Content } = Layout;
const { TextArea } = Input;

const layout = {
  labelCol: {
    lg: 9,
    md: 12,
  },
  wrapperCol: {
    lg: 12,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditGl(props) {
  const [data, setData] = useState({
    code: "",
    gl_account: "",
    journal: "",
    pst_key: "",
    description: "",
    text: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGL();
  }, []);

  const getGL = () => {
    Axios.get(`${url}/gl-account/${props.match.params.id}`, {
      params: {
        //user_type: "supplier",
        //status: "2",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          code: res.data.data.code,
          gl_account: res.data.data.gl_account,
          journal: res.data.data.journal,
          pst_key: res.data.data.pst_key,
          description: res.data.data.description,
          text: res.data.data.text,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const updateData = (code) => {
    setLoading(true);

    Axios.post(
      `${url}/gl-account/${code}`,
      {
        code: data.code,
        gl_account: data.gl_account,
        journal: data.journal,
        pst_key: data.pst_key,
        description: data.description,
        text: data.text,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/gl-code");
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      code: "",
      gl_account: "",
      journal: "",
      pst_key: "",
      description: "",
      text: "",
    });
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            size="small"
            //type="inner"
            //title="Edit Vendor"
            //extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/gl-code">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit GL Code</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={() => updateData(data.code)}>
                <Row>
                  <Fragment>
                    <Col xs={24}>
                      <Form.Item
                        required
                        label="Code"
                        validateStatus={error && error.code ? "error" : false}
                        help={error && error.code ? error.code[0] : false}
                      >
                        <Input
                          disabled
                          value={data.code}
                          onChange={(e) => handleChange("code", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        label="GL Account"
                        validateStatus={
                          error && error.gl_account ? "error" : false
                        }
                        help={
                          error && error.gl_account
                            ? error.gl_account[0]
                            : false
                        }
                      >
                        <Input
                          value={data.gl_account}
                          onChange={(e) =>
                            handleChange("gl_account", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Journal"
                        validateStatus={
                          error && error.journal ? "error" : false
                        }
                        help={error && error.journal ? error.journal[0] : false}
                      >
                        <Input
                          value={data.journal}
                          onChange={(e) =>
                            handleChange("journal", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="PST Key"
                        validateStatus={
                          error && error.pst_key ? "error" : false
                        }
                        help={error && error.pst_key ? error.pst_key[0] : false}
                      >
                        <Input
                          value={data.pst_key}
                          onChange={(e) =>
                            handleChange("pst_key", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Description"
                        validateStatus={
                          error && error.description ? "error" : false
                        }
                        help={
                          error && error.description
                            ? error.description[0]
                            : false
                        }
                      >
                        <Input
                          value={data.description}
                          onChange={(e) =>
                            handleChange("description", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Text"
                        validateStatus={error && error.text ? "error" : false}
                        help={error && error.text ? error.text[0] : false}
                      >
                        <Input
                          value={data.text}
                          onChange={(e) => handleChange("text", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item {...tailLayout}>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          className="mr-button"
                        >
                          Submit
                        </Button>
                        <Button htmlType="reset" onClick={handleReset}>
                          Reset
                        </Button>
                      </Form.Item>
                    </Col>
                    {/* <Row type="flex" gutter={[10, 20]} justify="space-around">
                      <Col xs={24}>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          className="mr-button"
                        >
                          Update
                        </Button>
                        <Button htmlType="reset" onClick={handleReset}>
                          Reset
                        </Button>
                      </Col>
                    </Row> */}
                  </Fragment>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditGl;
