import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  DatePicker,
  Radio,
  Space,
  Select,
  Spin,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreatePenyelesaianBiayaRupaRupa(props) {
  const [data, setData] = useState({
    code: "",
    gl_code: "",
    gl_label: "",
    description: "",
    quantity: "",
    harga_satuan: "",
    jumlah: "",
    ppn: 0,
    total: 0,
    pph21: 0,
    pph23: 0,
    pph4: 0,
    biaya_rupa_code: "",
    biaya_rupa_id: "",
    internal_order_id: "",
    internal_order_number: "",
    cost_center_id: "",
    cost_center_number: "",
    internal_orders: [],
    cost_centers: [],
    description_head: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [codes, setCodes] = useState([]);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [biayaRupaDetail, setBiayaRupaDetail] = useState([]);
  const [listBudget, setListBudget] = useState([]);
  const [codes_account, setCodesAccount] = useState([]);
  const [budgetArea, setBudgetArea] = useState(null);
  const [loadSubmit, setLoadSubmit] = useState(false);

  useEffect(() => {
    getSetBudget();
  }, []);

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectCodeAccount = (name, value) => {
    if (value) {
      const findControlItem = codes_account.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_label`]: value.label,
          [`${name}_code`]: findControlItem.code,
          [`_id`]: value.value,
          description: findControlItem.description,
        });
      }
    } else {
      setData({
        ...data,
        ["gl_code"]: null,
        [`_id`]: null,
      });
    }
  };

  const getDeklarasi = async () => {
    await Axios.get(`${url}/biaya-rupa-rupa`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getInternalOrder = async (keyword) => {
    await Axios.get(`${url}/internal-order/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          internal_orders: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectDeklarasi = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          deklarasi_code: findControlItem.deklarasi_biaya_rupa_code,
          nama: findControlItem.nama,
          npk: findControlItem.npk,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          total_pengajuan_before: findControlItem.pengajuan_umb_total,
          total_amount: findControlItem.total_amount,
          grand_total: findControlItem.grand_total,
          available: findControlItem.available_budget,
          calSum: findControlItem.pengajuan_umb_total === '-' ? 0 : findControlItem.pengajuan_umb_total - findControlItem.grand_total,
          pengajuan_umb_code: findControlItem.pengajuan_umb_code,
          pengajuan_umb_total: findControlItem.pengajuan_umb_total,
          deklarasi_umb_code: findControlItem.deklarasi_biaya_rupa_code,
          vendor_code: findControlItem.vendor_code,
          vendor_name: findControlItem.vendor_name,
          vendor_label: `${findControlItem.vendor_code} - ${findControlItem.vendor_name} `,
          description_head: findControlItem.description,
        });

        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  const getDetails = (id) => {
    setLoadSubmit(true);
    Axios.get(`${url}/biaya-rupa-rupa/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if(res.data.data.list_budget_details){
          setBiayaRupaDetail(res.data.data.details);
          setListBudget(res.data.data.list_budget_details);
        } else {
          message.error("Please complete your transaction list budget Internal Order & Cost Center");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadSubmit(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      gl_code: "",
      gl_label: "",
      description: "",
      quantity: "",
      harga_satuan: "",
      internal_order_number: "",
      cost_center_number: "",
      transfer_to: "",
      nama_bank: "",
      no_rekening: "",
      jumlah: "",
      ppn: 0,
      total: 0,
      pph21: 0,
      pph23: 0,
      pph4: 0,
    });

    setBiayaRupaDetail([]);
    setListBudget([]);
  };

  const onSaveData = () => {
    setLoadSubmit(true);

    if(listBudget.length != 0){
      Axios.post(
        `${url}/penyelesaian-uang-muka-biaya-rupa`,
        {
          pengajuan_umb_code: data.pengajuan_umb_code,
          deklarasi_id: data.biaya_rupa_id,
          deklarasi_code: data.deklarasi_code,
          deklarasi_label: data.biaya_rupa_code,
          pengajuan_um: data.pengajuan_um,
          types_reimbursment: "Penyelesaian Biaya Rupa",
          transfer_to: data.transfer_to,
          nama_bank: data.nama_bank,
          no_rekening: data.no_rekening,
          total_amount: data.total_amount,
          grand_total: data.grand_total,
          total_umb: data.total_pengajuan_before,
          details: biayaRupaDetail,
          list_budget_details: listBudget,
          available: data.available,
          calSum: data.calSum,
          vendor_code: data.vendor_code,
          vendor_name: data.vendor_name,
          description: data.description_head,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/penyelesaian-uang-muka-biaya-rupa-rupa");
  
        setLoadSubmit(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoadSubmit(false);
      });
    } else {
      if(listBudget.length == 0){
        message.error("Please complete your transaction list budget Internal Order & Cost Center");
      }
      setLoadSubmit(false);
    }

  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const columns = [
    {
      align: "center",
      title: "ACTIVITY CODE",
      dataIndex: "gl_code",
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
    },
    {
      title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
      dataIndex: "cost_center_number",
      align: "center",
      render: (cost_center_number) => {
        return cost_center_number ? 
          cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
          : '';
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
      align: "center",
      render: (internal_order_number) => {
        return internal_order_number ? 
          internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
          : '';
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" />;
      },
    },
    {
      align: "center",
      title: <div style={{ textAlign: "center" }}>QTY</div>,
      dataIndex: "quantity",
      render: (quantity) => {
        return <AntNumberFormat value={quantity} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>UNIT PRICE</div>,
      dataIndex: "harga_satuan",
      render: (harga_satuan) => {
        return <AntNumberFormat value={harga_satuan} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "jumlah",
      render: (jumlah) => {
        return <AntNumberFormat value={jumlah} type="text" />;
      },
    },
   
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPn</div>,
      dataIndex: "ppn",
      render: (ppn) => {
        return <AntNumberFormat value={ppn} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH21</div>,
      dataIndex: "pph21",
      render: (pph21) => {
        return <AntNumberFormat value={pph21} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH23</div>,
      dataIndex: "pph23",
      render: (pph23) => {
        return <AntNumberFormat value={pph23} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH4(2)</div>,
      dataIndex: "pph4",
      render: (pph4) => {
        return <AntNumberFormat value={pph4} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Grand Total</div>,
      dataIndex: "total",
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
  ];
  console.log(data);

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance settlement"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/penyelesaian-uang-muka-biaya-rupa-rupa">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Advance settlement Create</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Spin spinning={loadSubmit} style={{ top: 250 }}>
                <Card
                  className="body-data"
                  style={{ borderWidth: 2, borderRadius: 10 }}
                >
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1", "2"]}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                  >
                    <Panel
                      header="Information"
                      key="1"
                      className="site-collapse-custom-panel"
                    >
                      <Form labelAlign="left" {...layout} name="control-hooks">
                        <Row>
                          <Fragment>
                            <Col xs={24} md={12} lg={12}>
                              <Form.Item label="Ref Doc Number">
                                <Select
                                  labelInValue
                                  onChange={(value) =>
                                    handleChangeSelectDeklarasi(
                                      "biaya_rupa",
                                      value
                                    )
                                  }
                                  onFocus={() => getDeklarasi("")}
                                  showSearch
                                  onSearch={(value) => getDeklarasi(value)}
                                  filterOption={false}
                                  value={{
                                    value: data.biaya_rupa_id,
                                    label: data.biaya_rupa_code,
                                  }}
                                >
                                  {codes &&
                                    codes.map((code) => {
                                      return code.total_amount < budgetArea &&
                                        code.status === 4 &&
                                        code.status_proses !==
                                          "penyelesaian" ? (
                                        <Option value={code._id} key={code._id}>
                                          {`${code.deklarasi_biaya_rupa_code} - Rp. ${code.total_amount.toLocaleString()}`}
                                        </Option>
                                      ) : code.total_amount >= budgetArea &&
                                        code.status === 5 &&
                                        code.status_proses !==
                                          "penyelesaian" ? (
                                        <Option value={code._id} key={code._id}>
                                          {`${code.deklarasi_biaya_rupa_code} - Rp. ${code.total_amount.toLocaleString()}`}
                                        </Option>
                                      ) : (
                                        ""
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                validateStatus={
                                  error && error.nama ? "error" : false
                                }
                                help={
                                  error && error.nama ? error.nama[0] : false
                                }
                                label="Employee Name"
                              >
                                <Input
                                  disabled
                                  value={sessionStorage.getItem("fullname")}
                                  onChange={(e) => {
                                    handleChange("nama", e.target.value);
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                validateStatus={
                                  error && error.npk ? "error" : false
                                }
                                help={error && error.npk ? error.npk[0] : false}
                                label="NPK"
                              >
                                <Input
                                  disabled
                                  value={sessionStorage.getItem("npk")}
                                  onChange={(e) => {
                                    handleChange("npk", e.target.value);
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                validateStatus={
                                  error && error.transfer_to ? "error" : false
                                }
                                help={
                                  error && error.transfer_to
                                    ? error.transfer_to[0]
                                    : false
                                }
                                label="Account Name"
                              >
                                <Input
                                  disabled
                                  maxLength={16}
                                  value={data.transfer_to}
                                  onChange={(e) => {
                                    handleChange("transfer_to", e.target.value);
                                  }}
                                />
                              </Form.Item>
                              
                            </Col>
                            <Col xs={24} md={12} lg={12}>
                              <Form.Item
                                validateStatus={
                                  error && error.nama_bank ? "error" : false
                                }
                                help={
                                  error && error.nama_bank
                                    ? error.nama_bank[0]
                                    : false
                                }
                                label=" Bank Name"
                              >
                                <Input
                                  disabled={data.deklarasi !== "Belum"}
                                  value={data.nama_bank}
                                  onChange={(e) => {
                                    handleChange("nama_bank", e.target.value);
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                validateStatus={
                                  error && error.no_rekening ? "error" : false
                                }
                                help={
                                  error && error.no_rekening
                                    ? error.no_rekening[0]
                                    : false
                                }
                                label="Account Number"
                              >
                                <Input
                                  disabled={data.deklarasi !== "Belum"}
                                  value={data.no_rekening}
                                  onChange={(e) => {
                                    handleChange("no_rekening", e.target.value);
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                validateStatus={
                                  error && error.description_head ? "error" : false
                                }
                                help={error && error.description_head ? error.description_head[0] : false}
                                label="Description"
                              >
                                <TextArea
                                  disabled
                                  value={data.description_head}
                                  onChange={(e) => handleChange("description_head", e.target.value)}
                                ></TextArea>
                              </Form.Item>
                            </Col>
                          </Fragment>
                        </Row>
                      </Form>
                    </Panel>
                    <Panel
                      header="Detail"
                      key="2"
                      className="site-collapse-custom-panel"
                    >

                      <Col xs={24}>
                        <Table
                          bordered
                          style={{ marginTop: "15px", marginBottom: "25px" }}
                          size="small"
                          columns={columns}
                          dataSource={biayaRupaDetail}
                          rowKey={(data) => data._id}
                          onChange={handleTableChange}
                          scroll={{ x: 1000 }}
                          loading={loadSubmit}
                        />
                        <Form
                          style={{ marginTop: "30px" }}
                          labelAlign="left"
                          {...layout}
                          name="control-hooks"
                        >
                          <Row>
                            <Col xs={24} md={12} lg={12}>
                              <Form.Item label="Total">
                                <AntNumberFormat
                                  disabled
                                  value={data.grand_total}
                                  type="input"
                                  onChange={(e) => {
                                    handleChange("grand_total", e);
                                  }}
                                />
                              </Form.Item>
                              <Form.Item label="Down Payment Total ">
                                <AntNumberFormat
                                  disabled
                                  value={
                                    data.total_pengajuan_before
                                      ? data.total_pengajuan_before
                                      : 0
                                  }
                                  type="input"
                                  onChange={(e) => {
                                    handleChange("total_um", e);
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                validateStatus={
                                  error && error.kelebihan ? "error" : false
                                }
                                help={
                                  error && error.kelebihan
                                    ? error.kelebihan[0]
                                    : false
                                }
                                label="Remaining Down Payment"
                              >
                                <AntNumberFormat
                                  disabled
                                  value={
                                    Math.sign(data.calSum) === 1
                                      ? data.calSum
                                      : 0
                                  }
                                  type="input"
                                  onChange={(e) => {
                                    handleChange("kelebihan", e);
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                validateStatus={
                                  error && error.kekurangan ? "error" : false
                                }
                                help={
                                  error && error.kekurangan
                                    ? error.kekurangan[0]
                                    : false
                                }
                                label="Insufficient Payment"
                              >
                                <AntNumberFormat
                                  disabled
                                  value={
                                    Math.sign(data.calSum) === -1
                                      ? data.calSum
                                      : 0
                                  }
                                  type="input"
                                  onChange={(e) => {
                                    handleChange("kekurangan", e);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>

                      <Row justify="start">
                        <Col>
                          <Space>
                            <Button
                              type="primary"
                              onClick={onSaveData}
                              loading={loadSubmit}
                              htmlType="submit"
                              className="mr-button"
                              disabled={listBudget.length != 0 ? false : true}
                            >
                              Submit
                            </Button>

                            <Button
                              onClick={handleReset}
                              type="default"
                              htmlType="button"
                              className="mr-button"
                            >
                              Reset
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Card>
              </Spin>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreatePenyelesaianBiayaRupaRupa;
