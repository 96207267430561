import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  ExportOutlined,
  ImportOutlined,
  ReloadOutlined,
  PicLeftOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./GlCode.less";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function GlCode() {
  const [keyword, setKeyword] = useState("");
  const [code, setCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    file: null,
  });
  const [buttonAccess, setButtonAccess] = useState(null);
  const [sort, setSort] = useState({
    sort: "name",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/gl-account`, {
      params: {
        keyword,
        columns: [
          "code",
          "gl_account",
          "journal",
          "pst_key",
          "description",
          "text",
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
        //status: "2",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCode(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/gl-account/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadGl = () => {
    setLoading(true);
    Axios.get(`${url}/gl-account/download`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "GL Code" + ".xlsx");
      setLoading(false);
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  //   const exportCode = () => {
  //     Axios.get(`${url}/export/gl-code`, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //       },
  //       responseType: "blob",
  //     }).then((res) => {
  //       fileDownload(res.data, "Export GL Code" + ".xlsx");
  //     });
  //   };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/gl-account/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const columns = [
    {
      align: "center",
      title: "Code",
      dataIndex: "code",
      width: 130,
      sorter: true,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   sorter: true,
    // },
    {
      align: "center",
      title: "GL Account",
      dataIndex: "gl_account",
      width: 130,
      sorter: false,
    },
    {
      align: "center",
      title: "Journal",
      dataIndex: "journal",
      width: 130,
      sorter: false,
    },
    {
      align: "center",
      title: "PST Key",
      dataIndex: "pst_key",
      width: 130,
      sorter: false,
    },
    // {
    //   title: "NPWP",
    //   dataIndex: "tax_registration_number",
    //   sorter: false,
    // },
    {
      align: "center",
      title: "GL Info",
      dataIndex: "description",
      width: 130,
      sorter: false,
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>Description</div>,
      dataIndex: "text",
      width: 180,
      sorter: false,
    },
    // {
    //   title: "PPh23 (%)",
    //   dataIndex: "pph23",
    //   key: "pph23",
    //   align: "center",
    // },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      // fixed: "right",
      width: 50,
      render: (id, data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {buttonAccess &&
                    buttonAccess.find((x) => x.url === "gl-code/view") && (
                      <Menu.Item key="1">
                        <Link to={`/gl-code/view/${id}`}>
                          <InfoCircleOutlined /> View
                        </Link>
                      </Menu.Item>
                    )}

                  {buttonAccess &&
                    buttonAccess.find((x) => x.url === "gl-code/edit") && (
                      <Menu.Item key="2">
                        <Link to={`/gl-code/edit/${id}`}>
                          <EditOutlined /> Edit
                        </Link>
                      </Menu.Item>
                    )}

                  {buttonAccess &&
                    buttonAccess.find((x) => x.url === "gl-code/delete") && (
                      <Menu.Item key="3">
                        <span onClick={() => handleDelete(id)}>
                          <DeleteOutlined />
                          Delete
                        </span>
                      </Menu.Item>
                    )}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="GL Code"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "gl-code/create") && (
                        <Link to="/gl-code/create">
                          <Button type="primary">
                            <PlusOutlined />
                            Create
                          </Button>
                        </Link>
                      )}
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "gl-code/import") && (
                        <Button type="primary" onClick={showModal}>
                          <ImportOutlined />
                          Import
                        </Button>
                      )}
                    {buttonAccess &&
                      buttonAccess.find((x) => x.url === "gl-code/export") && (
                        <Button
                          loading={loading}
                          onClick={downloadGl}
                          type="primary"
                        >
                          <ExportOutlined />
                          Export
                        </Button>
                      )}

                    <Modal
                      title="Import GL Code"
                      visible={isModalVisible}
                      footer={null}
                      onCancel={closeModal}
                      onCancel={handleCancel}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Fragment>
                              <Upload
                                onRemove={handleRemove}
                                beforeUpload={handleBeforeUpload}
                                fileList={[]}
                              >
                                <Button style={{ marginBottom: 10 }}>
                                  Choose File <ExportOutlined />
                                </Button>
                              </Upload>
                              {data.file && (
                                <React.Fragment
                                  children={[
                                    <Button
                                      onClick={handleRemove}
                                      type="danger"
                                      ghost
                                    >
                                      <DeleteOutlined />
                                    </Button>,
                                  ]}
                                >
                                  {data.file.name ? data.file.name : data.file}
                                </React.Fragment>
                              )}
                            </Fragment>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSaveData}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                                backgroundColor: "#1b2086",
                                borderColor: "#1b2086",
                              }}
                            >
                              Upload
                            </Button>
                            <Button htmlType="reset" onClick={handleRemove}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={code.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: code.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default GlCode;
