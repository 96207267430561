import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  ExportOutlined,
  ImportOutlined,
  ReloadOutlined,
  PicLeftOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./InternalOrder.less";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function InternalOrder() {
  const [keyword, setKeyword] = useState("");
  const [code, setCode] = useState([]);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "name",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/internal-order`, {
      params: {
        keyword,
        columns: [
          "department",
          "pic",
          "internal_order_number",
          "description",
          "chart_account",
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
        //status: "2",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCode(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, []);

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/internal-order/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadInternal = () => {
    Axios.get(`${url}/internal-order/download`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      // fileDownload(res.data, "internal-order.xlsx");
    });
  };

  const exportInternalOrder = () => {
    setLoading(true);
    Axios.get(`${url}/internal-order/download`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Internal Order" + ".xlsx");
      setLoading(false);
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Department",
      dataIndex: "department",
      align: "center",
      width: 130,
      sorter: true,
    },
    {
      title: <div style={{ textAlign: "center" }}>PIC</div>,
      dataIndex: "pic",
      width: 130,
      align: "left",
      sorter: false,
    },
    {
      title: "IO Number",
      dataIndex: "internal_order_number",
      align: "center",
      width: 130,
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>Description</div>,
      dataIndex: "description",
      align: "left",
      width: 180,
      sorter: false,
    },
    {
      title: "Chart Of Account",
      dataIndex: "chart_of_account",
      align: "center",
      width: 130,
      sorter: false,
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      // fixed: "right",
      width: 50,
      render: (id, data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "internal-order/view"
                    ) && (
                      <Menu.Item key="1">
                        <Link to={`/internal-order/view/${id}`}>
                          <InfoCircleOutlined /> View
                        </Link>
                      </Menu.Item>
                    )}

                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "internal-order/edit"
                    ) && (
                      <Menu.Item key="2">
                        <Link to={`/internal-order/edit/${id}`}>
                          <EditOutlined /> Edit
                        </Link>
                      </Menu.Item>
                    )}

                  {buttonAccess &&
                    buttonAccess.find(
                      (x) => x.url === "internal-order/delete"
                    ) && (
                      <Menu.Item key="3">
                        <span onClick={() => handleDelete(id)}>
                          <DeleteOutlined />
                          Delete
                        </span>
                      </Menu.Item>
                    )}
                  {/* <Menu.Item disabled={data.status_send_email === "2"} key="4">
                    <span
                      onClick={
                        data.status_send_email === "2"
                          ? (e) => e.preventDefault()
                          : () => handleSendEmail(id)
                      }
                    >
                      <SendOutlined />
                      Email
                    </span>
                  </Menu.Item> */}
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Internal Order"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "internal-order/export"
                      ) && (
                        <Button
                          loading={loading}
                          type="primary"
                          onClick={exportInternalOrder}
                        >
                          <ExportOutlined />
                          Export
                        </Button>
                      )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={code.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: code.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default InternalOrder;
