import React, { useState, useEffect, Fragment } from "react";
import { Card, Menu, Dropdown, Layout, Row, Col, Button, Input, Table, Modal, message, Space, Tag, Tooltip, Badge, Form, Avatar, Comment } from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import "../SummaryDetail.less"
import TextArea from "antd/lib/input/TextArea";

const { Search } = Input;
const { Content } = Layout;

function UangMukaEntertainment() {
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [entertainments, setEntertainments] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [printing, setPrinting] = useState([]);
  const [sort, setSort] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [budgetArea, setBudgetArea] = useState(null);
  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [entertainmentDetails, setEntertainmentDetails] = useState([]);
  const [entertainmentDetailsTotal, setEntertainmentDetailsTotal] = useState(0);
  const [buttonAccess, setButtonAccess] = useState(null);
  /* Comment */
  const [visible, setVisible] = useState(false);
  const [commentTemp, setCommentTemp] = useState({
    tempDataComment: []
  });
  const [commentList, setCommentList] = useState({});
  /* End Comment */

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getBiayaEntertainmentDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      sessionStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
    getSetBudget();
  }, [keyword, page, sort]);

  useEffect(() => {
    if (selectedId) {
      getBiayaEntertainmentDetails();
    }

    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
      if (findPermission && findPermission.children) {
        const findButtonAccess = findPermission.children.filter(
          (x) => x.permission_type === "button"
        );
        if (findButtonAccess) {
          setButtonAccess(findButtonAccess);
        }
      }
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const getSetBudget = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Set Budget",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBudgetArea(res.data.data[0].name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/pengajuan-uang-muka-entertainment`, {
      params: {
        keyword,
        columns: [
          "internal_order_number",
          "nama",
          "cost_center_number",
          "grand_total",
          "status",
          "pengajuan_entertainment_code",
          "transfer_to",
          "department"
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setEntertainments(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getBiayaEntertainmentDetails(data2[0]);
    }
  };

  const getBiayaEntertainmentDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/pengajuan-uang-muka-entertainment/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: [
          "gl_code",
          "description",
          "tanggal",
          "tempat",
          "alamat",
          "jenis",
          "jumlah",
          "nama",
          "nama_perusahaan",
          "jabatan",
        ],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setEntertainmentDetails(res.data.data);
        setEntertainmentDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handlePrint = (id) => {
    setPrinting(true);
    setLoading(true);
    Axios.post(
      `${url}/pengajuan-uang-muka-entertainment-print`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        responseType: "blob",
      }
    )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet started");
        }
      })
      .finally(() => {
        setPrinting(false);
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/pengajuan-uang-muka-entertainment/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const getEntertainmentDetails = () => {
    const columns = [
      {
        align: "center",
        title: "NO",
        dataIndex: "no",
        sorter: false,
        fixed: "left",
        width: 40,
        render: (text, record, index) => index + page * 10 - 9,
      },
      {
        align: "center",
        title: "ACTIVITY CODE",
        dataIndex: "gl_code",
        sorter: false,
      },
      {
        align: "left",
        title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
        dataIndex: "description",
        sorter: false,
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_number",
        align: "center",
        render: (cost_center_number) => {
          return cost_center_number ? 
            cost_center_number.length == 12 ? cost_center_number.substring(2) : cost_center_number
            : '';
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_number",
        align: "center",
        render: (internal_order_number) => {
          return internal_order_number ? 
            internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
            : '';
        },
      },
      {
        title: "Purchase of entertainment and the like",
        children: [
          {
            align: "center",
            title: "DATE",
            dataIndex: "tanggal",
            render: (date) => moment(date).format("DD MMM YYYY"),
          },
          {
            align: "left",
            title: <div style={{ textAlign: "center" }}>LOCATION</div>,
            dataIndex: "tempat",
          },
          {
            align: "left",
            title: <div style={{ textAlign: "center" }}>ADDRESS</div>,
            dataIndex: "alamat",
          },

          {
            align: "center",
            title: "TYPE",
            dataIndex: "jenis",
          },
          {
            // title: "Amount",
            align: "right",
            title: <div style={{ textAlign: "center" }}>AMOUNT (Rp)</div>,
            dataIndex: "jumlah",
            key: "jumlah",
            render: (jumlah) => {
              return <AntNumberFormat value={jumlah} type="text" />;
            },
          },
        ],
      },
      {
        title: "Relase business provided entertainment and the like",
        children: [
          {
            align: "left",
            title: <div style={{ textAlign: "center" }}>NAME</div>,
            dataIndex: "nama",
          },
          {
            align: "center",
            title: "POSITION",
            dataIndex: "jabatan",
          },
          {
            align: "left",
            title: <div style={{ textAlign: "center" }}>COMPANY NAME</div>,
            dataIndex: "nama_perusahaan",
          },
        ],
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              // style={{ maxWidth: 1200 }}
              scroll={{ x: 1200 }}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={entertainmentDetails}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: entertainmentDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
              size="small"
              summary={pageData => {
                let totalAmount = 0;
                let totalGrandTotal = 0;
        
                pageData.forEach(({ jumlah, total }) => {
                  totalAmount += jumlah;
                  totalGrandTotal += total;
                });
        
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={9} className="table-summary">TOTAL</Table.Summary.Cell>
                      <Table.Summary.Cell className="table-summary">
                      <div className="summary">
                          <AntNumberFormat value={totalAmount} type="text" />
                        </div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={3} className="table-summary">
                       
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const handleClickFile = (x) => {
    console.log(x.bukti_scan_url, "click");
    window.open(x.bukti_scan_url);
  };

  const closeComment = () => {
    setVisible(false);
  };

  const handleChangeComment = (name, value) => {
    setCommentTemp({
        ...commentTemp,
        [name]: value,
        last_comment: sessionStorage.getItem("npk")
    })
  }

  const submitComment = () => {
    setLoading(true);

    Axios.post(
      `${url}/send-comment`,
      {
        doc_id: commentTemp.doc_id,
        doc_number: commentTemp.doc_number,
        doc_name: commentTemp.doc_name,
        doc_type: commentTemp.doc_type,
        comment_description: commentTemp.comment_description,
        last_comment: commentTemp.last_comment
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      setCommentTemp({
        ...commentTemp,
        comment_description: '',
        last_comment: ''
      })
      
      setCommentList(res.data.data);
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  /*  Open Data Comment Transaksi */
  const openDataComment = async (id, data) => {
    setCommentTemp({
      ...commentTemp,
      doc_number: data.pengajuan_id,
      doc_name: data.reminder_name,
      doc_type: data.reminder_status,      
      doc_id: id,
      DocNumber: data.pengajuan_id,
      CreatedDate: data.created_at
    })
    setLoading(true);

    await Axios.get(
      `${url}/show-comment`, {
        params: {
          doc_id: id,
          doc_number: data.pengajuan_id,
          doc_name: data.reminder_name,
          doc_type: data.reminder_status,
          comment_description: data.comment_description,
          last_comment: data.last_comment
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    })
    .then((res) => {
      setCommentList(res.data.data)
      getData();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 401) {
          sessionStorage.removeItem("token");
        }
      } else {
        message.error("Backend not yet Started");
      }
    })
    .finally(() => {
      setLoading(false);
      setVisible(true);

    });
  };

  const columns = [
    {
      align: "center",
      title: "DOCUMENT DATE",
      dataIndex: "created_at",
      sorter: true,
      width: 120,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: " DOCUMENT NUMBER",
      dataIndex: "pengajuan_entertainment_code",
      sorter: false,
      width: 120,
      align: "center",
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>CREATED BY</div>,
      dataIndex: "nama",
      sorter: false,
      width: 150,
    },
    {
      title: <div style={{ textAlign: "center" }}>DEPARTMENT</div>,
      dataIndex: "department",
      width: 150,
      sorter: false,
      render: (department) => {
        return (
          <span>{department[0]}</span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
      dataIndex: "description",
      sorter: false,
      width: 150,
      key: "description",
    },
    {
      title: <div style={{ textAlign: "center" }}>PAYMENT TO</div>,
      dataIndex: "transfer_to",
      sorter: false,
      width: 150,
      key: "transfer_to",
    },
    {
      title: <div style={{ textAlign: "center" }}>TOTAL</div>,
      dataIndex: "grand_total",
      sorter: false,
      align: "right",
      width: 95,
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total} type="text" />;
      },
    },
    {
      title: "FILE",
      dataIndex: "bukti_scan_details",
      sorter: false,
      align: "center",
      width: 200,
      render: (bukti_scan_url) =>
        bukti_scan_url &&
        bukti_scan_url.map((x, index) => {
          return x.bukti_scan.split(".")[1] === "jpg" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "png" ||
            x.bukti_scan.split(".")[1] === "jpeg" ? (
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconimage.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : x.bukti_scan.split(".")[1] === "pdf" ? (
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconpdf.png")}
              onClick={() => handleClickFile(x)}
            />
          ) : (
            <img
              style={{
                width: 30,
                height: 30,
                marginRight: 5,
                cursor: "pointer",
              }}
              src={require("../../assets/img/iconexcel.png")}
              onClick={() => handleClickFile(x)}
            />
          );
        }),
    },
    {
      title: "ACTION",
      dataIndex: "_id",
      sorter: false,
      align: "center",
      width:  sessionStorage.getItem('role_name') === "Staff" ? 150 :
              sessionStorage.getItem('role_name') === "Admin" ? 150 : 100,
      render: (id, data) => {
        return (
          <div>
            {
              buttonAccess &&
              buttonAccess.find(
                (x) => x.url === "pengajuan-uang-muka-entertainment/edit"
              ) && (
                <Tooltip placement="top" title="Edit">
                  <span style={{marginRight: 10, cursor: data.status !== 1 ? 'not-allowed' : 'pointer'}} >
                    <Link
                      disabled={data.status !== 1}
                      to={`/pengajuan-uang-muka-entertainment/edit/${id}`}
                    >
                      <img
                      alt="icon"
                      width="20"
                      src={
                        require("../../assets/img/writing.png")
                      }
                    />
                    </Link>
                  </span>
                </Tooltip>
                
                
              )
            }
            {
              buttonAccess &&
              buttonAccess.find(
                (x) => x.url === "pengajuan-uang-muka-entertainment/print"
              ) && (
                <Tooltip placement="top" title="Print">
                  <span style={{marginRight: 10}}
                    size="small"
                    type="default"
                    onClick={
                      data.status >= 3 ? () => handlePrint(id) : ""
                    }
                  >
                    <img
                      alt="icon"
                      width="20"
                      src={
                        require("../../assets/img/printer.png")
                      }
                      style={{
                        cursor: data.status >= 3 ? 'pointer' : 'not-allowed'
                      }}
                    />
                  </span>
                </Tooltip>
                
              )
            }

            {
              buttonAccess &&
              buttonAccess.find(
                (x) => x.url === "pengajuan-uang-muka-entertainment/delete"
              ) && (
                <Tooltip placement="top" title="Delete">
                  <span style={{marginRight: 10}}
                    size="small"
                    type="default"
                    onClick={
                      data.status == 1 ? () => handleDelete(id) : ""
                    }
                  >
                    <img
                      alt="icon"
                      width="20"
                      src={
                        require("../../assets/img/delete.png")
                      }
                      style={{
                        cursor: data.status == 1 ? 'pointer' : 'not-allowed'
                      }}
                    />
                  </span>
                </Tooltip>
              )
            }

            <Tooltip placement="top" title="Comment">
              <span 
                style={{cursor: 'pointer'}}
                size="small"
                type="default"
                datas={id}
                onClick={() => openDataComment(id, data)}
              >
                <Badge count={
                  sessionStorage.getItem('role_name') === "Staff" ?
                    data.sc_user >= 1 ? data.sc_user : 0 
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0 
                  : sessionStorage.getItem('role_name') === "Division Head" ?
                    data.sc_div >= 1 ? data.sc_div : 0
                  : sessionStorage.getItem('role_name') === "Direktur" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : sessionStorage.getItem('role_name') === "Department Head" ?
                    data.sc_dept >= 1 ? data.sc_dept : 0
                  : data.sc_random >= 1 ? data.sc_random : 0
                }>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      require("../../assets/img/chat.png")
                    }
                  />
                </Badge>
              </span>
            </Tooltip>

          </div>
        );
      }
    },
    {
      title: "STATUS RECEIVE",
      dataIndex: "status_received",
      align: "center",
      key: "status_received",
      width: 80,
      render: (status_received) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status_received === 1
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>REMARK</div>,
      dataIndex: "remark",
      align: "left",
      width: 150,
      key: "status_received"
    },
    {
      title: "PAYMENT DATE",
      dataIndex: "payment_date",
      align: "center",
      key: "payment_date",
      width: 180,
      render: (payment_date) => {
        return {
          children: payment_date
            ? moment(payment_date).format("DD MMMM YYYY ")
            : "",
        };
      },
    },
    {
      title: "APPROVAL",
      children: [
        {
          title: " DEPT HEAD",
          dataIndex: "status",
          align: "center",
          key: "approvalDeptHead",
          width: 110,
          render: (status, data) => {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={status !== 1 ? data.app_dept : ""}
                >
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 1
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                </Tooltip>
              </>
            );
          },
        },
        {
          title: "DIV HEAD",
          dataIndex: "status",
          align: "center",
          width: 110,
          key: "approvalDivHead",
          render: (status, data) => {
            return (
              <Tooltip placement="top" title={status !== 1 ? data.app_div : ""}>
                {data.app_div !== "-" ? (
                  <img
                    alt="icon"
                    width="20"
                    src={
                      status === 2
                        ? require("../../assets/img/sand-clock.png")
                        : status === 2 ||
                          status === 3 ||
                          status === 4 ||
                          status === 5
                        ? require("../../assets/img/checked.png")
                        : status === -2
                        ? require("../../assets/img/warning.png")
                        : status === -1
                        ? require("../../assets/img/danger.png")
                        : require("../../assets/img/sand-clock.png")
                    }
                  />
                ) : (
                  "-"
                )}
              </Tooltip>
            );
          },
        },
        {
          title: " DIRECTOR 1",
          dataIndex: "status",
          align: "center",
          width: 110,
          key: "approvalDirektur1",
          render: (status, data) => {
            return (
              <Tooltip
                placement="top"
                title={status !== 1 ? data.app_dir1 : ""}
              >
                <img
                  alt="icon"
                  width="20"
                  src={
                    status === 3
                      ? require("../../assets/img/sand-clock.png")
                      : status === 3 || status === 4 || status === 5
                      ? require("../../assets/img/checked.png")
                      : status === -2
                      ? require("../../assets/img/warning.png")
                      : status === -1
                      ? require("../../assets/img/danger.png")
                      : require("../../assets/img/sand-clock.png")
                  }
                />
              </Tooltip>
            );
          },
        },
        {
          title: " DIRECTOR 2",
          dataIndex: "status",
          align: "center",
          width: 110,
          key: "approvalDirektur2",
          render: (status, data) => {
            return (
              <>
                {data.grand_total >= budgetArea ? (
                  <Tooltip
                    placement="top"
                    title={status !== 1 ? data.app_dir2 : ""}
                  >
                    {data.grand_total >= budgetArea ? (
                      <img
                        alt="icon"
                        width="20"
                        src={
                          status === 4
                            ? require("../../assets/img/sand-clock.png")
                            : status === 4 || status === 5
                            ? require("../../assets/img/checked.png")
                            : status === -2
                            ? require("../../assets/img/warning.png")
                            : status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </Tooltip>
                ) : (
                  "-"
                )}
              </>
            );
          },
        },
      ],
    },
    
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   // fixed: "right",
    //   width: 50,
    //   render: (id, data) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               {buttonAccess &&
    //                 buttonAccess.find(
    //                   (x) => x.url === "pengajuan-uang-muka-entertainment/print"
    //                 ) && (
    //                   <Menu.Item key="1" disabled={data.status < 3}>
    //                     <span
    //                       size="small"
    //                       type="default"
    //                       onClick={
    //                         data.status >= 3 ? () => handlePrint(id) : ""
    //                       }
    //                     >
    //                       <PrinterOutlined />
    //                       Print
    //                     </span>
    //                   </Menu.Item>
    //                 )}
    //               {buttonAccess &&
    //                 buttonAccess.find(
    //                   (x) => x.url === "pengajuan-uang-muka-entertainment/edit"
    //                 ) && (
    //                   <Menu.Item key="2">
    //                     <span>
    //                       <Link
    //                         disabled={data.status !== 1}
    //                         to={`/pengajuan-uang-muka-entertainment/edit/${id}`}
    //                       >
    //                         <EditOutlined /> Edit
    //                       </Link>
    //                     </span>
    //                   </Menu.Item>
    //                 )}
    //               {/* {buttonAccess &&
    //                 buttonAccess.find(
    //                   (x) =>
    //                     x.url === "pengajuan-uang-muka-entertainment/delete"
    //                 ) && (
    //                   <Menu.Item key="3">
    //                     <span onClick={() => handleDelete(id)}>
    //                       <DeleteOutlined />
    //                       Delete
    //                     </span>
    //                   </Menu.Item>
    //                 )} */}
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Advance Payment Submission "
            extra={moment(new Date()).format("DD-MMMM-YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/pengajuan-uang-muka-entertainment/create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    size="small"
                    columns={columns}
                    scroll={{ x: 1500 }}
                    expandable={{
                      expandedRowRender: getEntertainmentDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    dataSource={entertainments.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: entertainments.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* MODAL COMMENT LIST */}
      <Modal
        className="modal-comment-list"
        visible={visible}
        title={`${commentTemp.DocNumber} - ${commentTemp.CreatedDate} `}
        onOk={submitComment}
        onCancel={closeComment}
        footer={[
          <Form.Item>
            <TextArea
              rows={4}
              value={commentTemp.comment_description}
              onChange={(e) => handleChangeComment("comment_description", e.target.value)}
            ></TextArea>
          </Form.Item>,
          <Button key="back" onClick={closeComment} >
            Close
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={submitComment} >
            Send
          </Button>
        ]}
      >
        { commentList ?
            commentList.comment_list && 
              commentList.comment_list.map((comment) => {
              return (
                <Comment
                  // actions={actions}
                  className={`${comment.username === sessionStorage.getItem('username') ? 'comment-right' : ''}`}
                  author={<span className="comment-title">{comment.full_name}</span>}
                  avatar={<Avatar className="comment-avatar" src={comment.avatar} alt={`${comment.full_name}`} />}
                  content={
                    <p>
                      {comment.comment_description}
                    </p>
                  }
                  datetime={
                    <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                      <span>{comment.comment_date}</span>
                    </Tooltip>
                  }
                />
              )
            })
          : <div id="comment-new-list">{commentTemp.tempDataComment}</div>}
      </Modal>
      {/* END COMMENT LIST */}
      
    </Fragment>
  );
}

export default UangMukaEntertainment;
