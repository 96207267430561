import Axios from "axios";
import { url } from "../../config/global";

import { Breadcrumb, Card, Col, Row, Layout, Form, Input, Button, message, Select, Upload, List } from 'antd';
import React, { Fragment } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";

const { Content } = Layout
const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CompanyAdd = (props) => {
  const [error, setError] = useState(null)
  const [company, setCompany] = useState({
    code: null,
    abbre_cc: null,
    name: null,
    email: null,
    npwp: null,
    address: null,
    city: null,
    phone_number: null
  })
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setCompany({
      ...company,
      [name]: value,
    });
  };
  
  const handleSelectChange = (value) => {
    setError(null);
    setCompany({
      ...company,
      ['type']: value,
    });
  };

  const handleReset = () => {
    setCompany({
      code: null,
      abbre_cc: null,
      name: null,
      email: null,
      npwp: null,
      address: null,
      city: null,
      phone_number: null
    })
  }

  const onSubmitOLD = () => {
    setLoading(true)
    
    Axios.post(
      `${url}/company`, company, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
      props.history.push("/company");
    })
    .catch((err) => {
      if (err.response) {
        message.error(err.response.data.message);
        if (err.response.status === 422) {
          setError(err.response.data.errors);
        }
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          props.history.push("/login");
        }
      }
      setLoading(false);
    });
  }

  const onSubmit = () => {
    setLoading(true)
    
    let params = new FormData();

    for (const key in company) {
      if (key === "logo") {
        params.append(key, company[key])
      } else {
        params.set(key, company[key])
      }
    }

    Axios.post(
      `${url}/company`, params, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/company");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
            props.history.push("/login");
          }
        }
        setLoading(false);
      });
  }

  const handleBeforeUpload = (files) => {
    setCompany({
      ...company,
      logo: files,
    });

    return false;
  };

  const handleRemove = () => {
    setCompany({
      ...company,
      logo: null,
    });
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card size="small">
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/company">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Company</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row >
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks"   labelAlign="left" onFinish={onSubmit}>
                <Row justify="left" >
                  <Fragment>
                    <Col xs={24} md={12} lg={12} className="left" >
                      <Form.Item
                        required
                        label="Code"
                        validateStatus={error && error.code ? "error" : false}
                        help={error && error.code ? error.code[0] : false}
                      >
                        <Input
                          value={company.code}
                          onChange={(e) => handleChange("code", e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Abree.CC"
                        validateStatus={error && error.abbre_cc ? "error" : false}
                        help={error && error.abbre_cc ? error.abbre_cc[0] : false}
                      >
                        <Input
                          value={company.abbre_cc}
                          onChange={(e) => handleChange("abbre_cc", e.target.value)}
                        />
                      </Form.Item>
                      
                      <Form.Item
                        required
                        label="Name"
                        validateStatus={error && error.name ? "error" : false}
                        help={error && error.name ? error.name[0] : false}
                      >
                        <Input
                          value={company.name}
                          onChange={(e) => handleChange("name", e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Email"
                        validateStatus={error && error.email ? "error" : false}
                        help={error && error.email ? error.email[0] : false}
                      >
                        <Input
                          value={company.email}
                          onChange={(e) => handleChange("email", e.target.value)}
                        />
                      </Form.Item>

                    </Col>
                    <Col xs={24} md={12} lg={12} className="left">
                        
                      <Form.Item
                        label="NPWP"
                        validateStatus={error && error.npwp ? "error" : false}
                        help={error && error.npwp ? error.npwp[0] : false}
                      >
                        <Input
                          value={company.npwp}
                          onChange={(e) => handleChange("npwp", e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        label="No. Telpon"
                        validateStatus={error && error.phone_number ? "error" : false}
                        help={error && error.phone_number ? error.phone_number[0] : false}
                      >
                        <Input
                          value={company.phone_number}
                          onChange={(e) => handleChange("phone_number", e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        label="City"
                        validateStatus={error && error.city ? "error" : false}
                        help={error && error.city ? error.city[0] : false}
                      >
                        <Input
                          value={company.city}
                          onChange={(e) => handleChange("city", e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Address"
                        validateStatus={error && error.address ? "error" : false}
                        help={error && error.address ? error.address[0] : false}
                      >
                        <Input.TextArea
                          value={company.address}
                          onChange={(e) => handleChange("address", e.target.value)}
                          rows={3}
                        />
                      </Form.Item>

                      <Form.Item name="logo" label="Logo">
                        <Fragment>
                          <Upload
                            onRemove={handleRemove}
                            beforeUpload={handleBeforeUpload}
                            fileList={[]}
                          >
                            <Button>
                              Upload <UploadOutlined />
                            </Button>
                          </Upload>
                          {company.logo && (
                            <List>
                              <List.Item
                                actions={[
                                  <Button onClick={handleRemove} type="danger" ghost>
                                    <DeleteOutlined />
                                  </Button>,
                                ]}
                              >
                                <List.Item.Meta description={company.logo.name} />
                              </List.Item>
                            </List>
                          )}
                        </Fragment>
                      </Form.Item>

                      <br />
                      <Form.Item {...tailLayout}>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          className="mr-button"
                        >
                          Submit
                        </Button>
                        <Button htmlType="reset" onClick={handleReset}>
                          Reset
                        </Button>
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  )
}

export default CompanyAdd;