import React, { Fragment, useState, useEffect } from "react";
import { Menu, Badge } from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import "./Sidebar.less";
import Icon, { MessageOutlined } from "@ant-design/icons";
import Axios from "axios";
import { url } from "../config/global";

const { SubMenu } = Menu;
var count = 0;
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1964787_1z33meo70x9.js",
});

function Sidebar(props) {
  const [openKeys, setOpenKeys] = useState("");
  const [limitCounting, setLimitCounting] = useState(0);
  const pathname = props.location.pathname.split("/");
  const [allCounting, setAllCounting] = useState([]);

  const company_logo = sessionStorage.getItem('company_logo') !== undefined && sessionStorage.getItem('company_logo') !== "null"
    ? sessionStorage.getItem('company_logo') 
    : require("../assets/img/Logo-Dharma2.png")
  
  console.log(company_logo)
  useEffect(() => {
   
    if(limitCounting == 0)
    {
      getDataAllCounting();
    } 
   
  });

  const handleOpenSub = (path) => {
    if (path !== openKeys) {
      setOpenKeys(path);
      sessionStorage.setItem("openKeys", path);
    } else {
      setOpenKeys("");
      sessionStorage.setItem("openKeys", "");
    }
  };

  const permissions = sessionStorage.getItem("permissions")
    ? JSON.parse(sessionStorage.getItem("permissions")).filter(
        (permission) => permission !== null
      )
    : [];
  const defaultSelectedKeys = sessionStorage.getItem("redirect");

  const getDataAllCounting = () => {
    // setLoading(true);
    setLimitCounting(limitCounting+1);
    Axios.get(`${url}/approval-home/all-counting`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
       
        
        setAllCounting(res.data.total);
      })
      .catch((err) => {
        // if (err.response) {
        //   message.error(err.response.data.message);
        //   if (err.response.status === 401) {
        //     sessionStorage.removeItem("token");
        //   }
        // } else {
        //   message.error("Backend not yet Started");
        // }
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  return (
    <Fragment>
      <div className="logo">
        <Link to={defaultSelectedKeys}>
          {props.collapsed ? (
            <img
              style={{ padding: 30 }}
              src={require("../assets/img/pm-sidebar-small.png")}
              alt="DEM Logo"
            />
          ) : (
            <img
              style={{ padding: 27 }}
              src={company_logo}
              // src={require("../assets/img/Desain tanpa judul (5).png")}
              alt="DEM Logo"
            />
          )}
        </Link>
      </div>
      <Menu
        mode="inline"
        theme="light"
        // defaultSelectedKeys={[sessionStorage.getItem('openKeys') ? sessionStorage.getItem('openKeys') : openKeys]}
        // defaultOpenKeys={pathname}
        className="sidebar bg-transparent"
        selectedKeys={pathname}
        openKeys={[
          sessionStorage.getItem("openKeys")
            ? sessionStorage.getItem("openKeys")
            : openKeys,
        ]}
      >
        {permissions &&
          permissions.length > 0 &&
          permissions.map((permission) => {
            return permission.children && permission.children.length > 0 ? (
              <SubMenu
                // className="gradasi-drma"
                key={permission.url}
                onTitleClick={() => handleOpenSub(permission.url)}
                title={
                  <span>
                    {/* <FontAwesomeIcon prefix="fab" icon="500px" /> */}
                    {/* <FeatherIcon icon="close" /> */}
                    <IconFont style={{ fontSize: 20 }} type={permission.icon} />
                    <span>{permission.name}</span>
                  </span>
                }
              >
                {permission.children &&
                  permission.children.map((child) => {
                    return (
                      child && (
                        <Menu.Item key={child.url}>
                          <Link to={`/${child.url}`}>{child.name}</Link>
                        </Menu.Item>
                      )
                    );
                  })}
              </SubMenu>
            ) : (
              
                <Menu.Item key={permission.url}>
                  <Badge 
                    count={
                      permission.url === "home-approval" ? allCounting >= 1 ? allCounting : 0 : 0
                    } 
                    className="badge-home-approval">
                    <Link to={`/${permission.url}`}>
                      {/* <Icon type="message" theme="outlined" /> */}
                      {/* <MessageOutlined />  */}
                      <IconFont style={{ fontSize: 20 }} type={permission.icon} />

                      <span>{permission.name}</span>
                    </Link>
                  </Badge>
                </Menu.Item>
            );
          })}
        {!props.collapsed && (
          <div className="spacing side-foot">
            <table border="1" cellpadding="10" width={245} height={155}>
              <tr>
                <div className="dharma"> PT. DHARMA POLIMETAL Tbk</div>
                <div className="address">Jl Angsana Raya Blok A9 No.8</div>
                <div className="detail-address">
                  {" "}
                  Delta Silicon 1 Cikarang 17550
                </div>
                <div className="info"> For Support/Information :</div>
                <div className="phone"> Phone : Ext 321</div>
                <div className="ext"> Ext 332</div>
              </tr>
            </table>
          </div>
        )}
      </Menu>
    </Fragment>
  );
}

export default Sidebar;
