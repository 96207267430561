import React, { useState } from "react";
import { Input, Tabs, Button, message, Form, Space } from "antd";
import "./ResetPassword.less";
import { useLocation } from "react-router-dom";
import {
  LockOutlined,
  MailOutlined,
  HourglassOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

import Axios from "axios";
import { url } from "../../config/global";

const { TabPane } = Tabs;

function ResetPassword(props) {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const email = query.get("email");
  const username = query.get("username");
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    email,
    username,
    token,
    password: "",
    password_confirmation: "",
  });

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/reset-password`,
      {
        email: data.email,
        username: data.username,
        token: data.token,
        password: data.password,
        password_confirmation: data.password_confirmation,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/login");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTab = (key) => {
    setTab(key);
  };

  return (
    <div className="main">
      <div>
        <img
          src={require("../../assets/img/logo-dharma-polimetal.png")}
          className="img-logo"
          alt="DEM"
        />
        <Tabs activeKey={tab} onChange={handleTab}>
          <TabPane tab="Reset Password" key="1">
            <Form onFinish={onSaveData}>
              <Form.Item
                validateStatus={error && error.email ? "error" : false}
                help={error && error.email ? error.email[0] : false}
              >
                <Input
                  disabled
                  className="login-input"
                  prefix={<MailOutlined />}
                  placeholder="Email Active"
                  onChange={(e) => handleChange("email", e.target.value)}
                  value={data.email}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.username ? "error" : false}
                help={error && error.username ? error.username[0] : false}
              >
                <Input
                  disabled
                  className="login-input"
                  prefix={<ProfileOutlined />}
                  placeholder="Username"
                  onChange={(e) => handleChange("username", e.target.value)}
                  value={data.username}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.token ? "error" : false}
                help={error && error.token ? error.token[0] : false}
              >
                <Input
                  disabled
                  className="login-input"
                  prefix={<HourglassOutlined />}
                  placeholder="Token"
                  onChange={(e) => handleChange("token", e.target.value)}
                  value={data.token}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.password ? "error" : false}
                help={error && error.password ? error.password[0] : false}
              >
                <Input.Password
                  className="login-input"
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  onChange={(e) => handleChange("password", e.target.value)}
                  value={data.password}
                />
              </Form.Item>
              <Form.Item
                validateStatus={
                  error && error.password_confirmation ? "error" : false
                }
                help={
                  error && error.password_confirmation
                    ? error.password_confirmation[0]
                    : false
                }
              >
                <Input.Password
                  className="login-input"
                  prefix={<LockOutlined />}
                  placeholder="Retype Password"
                  onChange={(e) =>
                    handleChange("password_confirmation", e.target.value)
                  }
                  value={data.password_confirmation}
                />
              </Form.Item>

              <div>
                <Space>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    // onClick={handleLogin}
                    type="primary"
                    className="button-login"
                  >
                    Reset Password
                  </Button>
                </Space>
              </div>
            </Form>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default ResetPassword;
