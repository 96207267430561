import React, { useState, useRef, Fragment } from "react";
import {
  Form,
  Card,
  Layout,
  Row,
  Col,
  message,
  Input,
  Table,
  Space,
  Button,
  Breadcrumb,
  Collapse,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import {
  CaretRightOutlined,
  DeleteOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import _ from "underscore";
import "./DocumentReceive.less";
import AntNumberFormat from "../../components/AntNumberFormat";
import SubMenu from "antd/lib/menu/SubMenu";

const { Content } = Layout;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 8,
    md: 12,
  },
  wrapperCol: {
    lg: 12,
    md: 14,
  },
};

const isAdmin = sessionStorage.getItem('role_name') === 'Admin';
const isAccounting = sessionStorage.getItem('role_name') === 'Accounting';
const isFinance = sessionStorage.getItem('role_name') === 'Finance';

function ScanDocumentReceive({ history, match }) {
  const [documentReceive, setDocumentReceive] = useState([]);
  const [documentReceiveDetails, setDocumentReceiveDetails] = useState([]);
  const [detailReceive, setDetailReceive] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [rowDetails, setRowDetails] = useState([]);

  // const total_document = documentReceive.length;

  const date = moment(new Date()).format("dddd, DD MMMM YYYY");
  const time = moment(new Date()).format("HH:mm:ss");
  const [data, setData] = useState({
    document_no: "",
    grand_total: "",
    currency: "",
    vendor_code: "",
    vendor_name: "",
    pic: "",
    email: "",
    date,
    time,
    // total_document,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    order: "ascend",
  });
  const [isAllowedSubmit, setIAllowedSubmit] = useState(false);

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  // const scanNumberRef = useRef(null);

  const scanData = () => {
    setIAllowedSubmit(false);
    if (data.document_no.slice(0, 2) === '40' && isFinance && !isAdmin) {
      setIAllowedSubmit(true);
    }

    if (data.document_no.slice(0, 2) === '50' && isAccounting && !isAdmin) {
      setIAllowedSubmit(true);
    }
    Axios.get(`${url}/document-receive/scan`, {
      params: {
        document_no: data.document_no,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
        setDocumentReceive(res.data.data);
        setDocumentReceiveDetails(res.data.data[0].details);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const handleRemoveDocument = (id) => {
    const filter = documentReceive.filter((row) => row.document_id !== id);
    setDocumentReceive(filter);
  };

  const handleReset = () => {
    setData({
      ...data,
      document_no: "",
    });

    setDocumentReceive([]);
  };

  const handleSubmit = () => {
    if (documentReceive.length > 0) {
      setLoading(true);

      Axios.post(
        `${url}/document-receive`,
        {
          doc_no: documentReceive.pengajuan_id,
          date: documentReceive.created_at,
          nama: documentReceive.nama,
          npk: documentReceive.npk,
          divisi: documentReceive.divisi,
          internal_order_number: documentReceive.internal_order_number,
          cost_center_number: documentReceive.cost_center_number,
          transfer_to: documentReceive.transfer_to,
          grand_total: documentReceive.grand_total,
          details: documentReceive.details,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          console.log(res);
          message.success(res.data.message);
          // props.history.push("/document-receive");
        })
        .catch((err) => {
          if (err.response) {
            message.error("Something Went Wrong");
          }
          setLoading(false);
        });

      setDocumentReceive([]);
    } else {
      message.error(`Please fill the data first!`);
    }
  };

  const onSaveData = () => {
    if (documentReceive.length > 0) {
      setLoading(true);
      Axios.post(
        `${url}/document-receive`,
        {
          doc_no: documentReceive[0].pengajuan_id
            ? documentReceive[0].pengajuan_id
            : documentReceive[0].penyelesaian_id,
          date: documentReceive[0].created_at,
          nama: documentReceive[0].nama,
          npk: documentReceive[0].npk,
          divisi: documentReceive[0].divisi,
          internal_order_number: documentReceive[0].internal_order_number,
          cost_center_number: documentReceive[0].cost_center_number,
          transfer_to: documentReceive[0].transfer_to,
          grand_total: documentReceive[0].grand_total,
          details: documentReceive[0].details,
          type: documentReceive[0].reminder_name,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          console.log(res.data.type);
          if (res.data.type === "Error") {
            message.error(res.data.message);
          } else {
            message.success(res.data.message);
            history.push("/document-receive");
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            message.error("Something Went Wrong");
          }
          setLoading(false);
        });

      setDocumentReceive([]);
    } else {
      message.error(`Please scan the data first!`);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "nama",
      key: "nama",
      align: "center",
      sorter: false,
    },
    {
      title: "NPK",
      dataIndex: "npk",
      key: "npk",
      align: "center",
      sorter: false,
    },
    {
      title: "Divisi",
      dataIndex: "divisi",
      key: "divisi",
      sorter: false,
      align: "center",
      render: (divisi) => {
        return _.pluck(divisi, "name").join(", ");
      },
    },
    // {
    //   title: "Internal Order",
    //   dataIndex: "internal_order_number",
    //   key: "internal_order_number",
    //   sorter: false,
    //   align: "center",
    //   render: (internal_order_number) => {
    //     return internal_order_number.substring(2);
    //   },
    // },
    // {
    //   title: "Cost Center",
    //   dataIndex: "cost_center_number",
    //   key: "cost_center_number",
    //   align: "center",
    //   sorter: false,
    // },
    {
      title: "Account Name",
      dataIndex: "transfer_to",
      key: "transfer_to",
      align: "center",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "center" }}>Total</div>,
      dataIndex: "grand_total",
      key: "grand_total",
      align: "right",
      sorter: false,
      render: (grand_total) => {
        return <AntNumberFormat value={grand_total} type="text" />;
      },
    },
  ];

  // if (!sessionStorage.getItem("token")) {
  //   return <Redirect to="/login" />;
  // }

  const handleChangeDetails = (data) => {
    console.log(data)
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      // getDataDetails(data2[0]);
    }
  };

  const getDetails = () => {
    const columns = [
      {
        title: <div style={{ textAlign: "center" }}>GL CODE</div>,
        dataIndex: "gl_code",
        align: "center",
      },
      {
        title: <div style={{ textAlign: "center" }}>DESCRIPTION</div>,
        dataIndex: "description",
        align: "center",
      },
      {
        title: <div style={{ textAlign: "center" }}>COST CENTER</div>,
        dataIndex: "cost_center_label",
        align: "center",
      },
      {
        title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
        dataIndex: "internal_order_label",
        align: "center",
      },
      {
        title: <div style={{ textAlign: "right" }}>TOTAL</div>,
        dataIndex: "total",
        align: "right",
        render: (total) => {
          return <AntNumberFormat value={total} type="text" />;
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>

          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              //scroll={{ x: 1000 }}
              bordered
              columns={columns}
              rowKey={(data, index) => index}
              // loading={loadingDetail}
              dataSource={documentReceiveDetails}
              size="small"
            // pagination={{
            //   pageSize: perPageDetail,
            //   current: pageDetail,
            //   total: pengajuanBiayaRupaDetailsTotal,
            //   showSizeChanger: true,
            //   style: {
            //     marginTop: "2rem",
            //     marginBottom: 0,
            //   },
            // }}
            // onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Scan Document Receive"
          //extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/document-receive">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Document Receive</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card size="small" className="body-data">
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
              >
                <Panel
                  header="Scan Document"
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <Row type="flex" gutter={[10, 20]} justify="space-around">
                    <Fragment>
                      <Col md={14}>
                        <Input
                          className="size-scan"
                          prefix={<QrcodeOutlined />}
                          autoFocus
                          placeholder="Scan QR Document Here"
                          onPressEnter={(e) => scanData(e, "document_no")}
                          onChange={(e) => {
                            handleChange("document_no", e.target.value)
                          }}
                          value={data.document_no}
                        />
                      </Col>
                    </Fragment>
                  </Row>

                  <Form labelAlign="left" {...layout} name="control-hooks">
                    <Row>
                      <Fragment>
                        <Col md={24}>
                          {/* <Row justify="end" gutter={[0, 20]}></Row> */}

                          <Table
                            style={{ marginTop: "15px", marginBottom: "25px" }}
                            size="small"
                            columns={columns}
                            dataSource={documentReceive}
                            expandable={{
                              expandedRowRender: getDetails,
                              onExpandedRowsChange: (data) => handleChangeDetails(data),
                              expandedRowKeys: rowDetails,
                            }}
                            rowKey={(data) => data._id}
                            // onChange={handleTableChange}
                            scroll={{ x: 1500 }}
                            loading={loading}
                          // pagination={{ total, showSizeChanger: true }}
                          />
                          <Row justify="first" style={{ marginTop: 25 }}>
                            <Col>
                              <Space>
                                {/* {isAllowedSubmit && ( */}
                                  <Button
                                    type="primary"
                                    onClick={onSaveData}
                                    loading={loading}
                                    htmlType="submit"
                                    className="mr-button"
                                  >
                                    Submit
                                  </Button>
                                {/* )} */}
                                <Button
                                  onClick={handleReset}
                                  type="default"
                                  htmlType="button"
                                  className="mr-button"
                                >
                                  Reset
                                </Button>
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                      </Fragment>
                    </Row>
                  </Form>
                </Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ScanDocumentReceive;
