import React, { Fragment, useState, useEffect } from "react";
import {
    Form,
    Input,
    Button,
    Layout,
    Row,
    Col,
    Card,
    Breadcrumb,
    message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import "./CostCenter";
const { Content } = Layout;

const layout = {
    labelCol: {
        lg: 4,
        md: 14,
    },
    wrapperCol: {
        lg: 12,
        md: 14,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

function EditCostCenter(props) {
    const [data, setData] = useState({
        cost_center: "",
        description: "",
        plant: "",
        structure_cost: "",
    });

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCostCenter();
      }, []);
    
      const getCostCenter = () => {
        Axios.get(`${url}/cost-center/${props.match.params.id}`, {
          params: {
            //user_type: "supplier",
            //status: "2",
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            setData({
              ...data,
              cost_center: res.data.data.cost_center,
              description: res.data.data.description,
              plant: res.data.data.plant,
              structure_cost: res.data.data.structure_cost,
            });
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data.message);
              if (err.response.status === 401) {
                sessionStorage.removeItem("token");
              }
            } else {
              message.error("Backend not yet Started");
            }
          });
      };

    const handleChange = (name, value) => {
        setError(null);
        setData({
            ...data,
            [name]: value,
        });
    };

    const onSaveData = () => {
        setLoading(true);

        Axios.post(
            `${url}/cost-center`,
            {
                data:[{
                    cost_center: data.cost_center,
                    plant: data.plant,
                    structure_cost: data.structure_cost,
                    description: data.description,
                }]
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            }
        )
            .then((res) => {
                message.success(res.data.message);
                props.history.push("/cost-center");
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 422) {
                        setError(err.response.data.errors);
                    }
                    if (err.response.status === 401) {
                        sessionStorage.removeItem("token");
                    }
                }
                setLoading(false);
            });
    };

    const handleReset = () => {
        setData({
            cost_center: "",
            description: "",
            plant: "",
            structure_cost: "",
        });
    };

    if (!sessionStorage.getItem("token")) {
        return <Redirect to="/login" />;
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card
                        type="inner"
                        title="Cost Center"
                        extra={moment(new Date()).format("DD MMMM YYYY")}
                    >
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/cost-center">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit Cost Center</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form {...layout} name="control-hooks" onFinish={onSaveData} labelAlign="left" style={{marginLeft: '25%'}}>
                                <Form.Item
                                    required
                                    label="Cost Center "
                                    validateStatus={error && error.cost_center ? "error" : false}
                                    help={
                                        error && error.cost_center ? error.cost_center[0] : false
                                    }
                                >
                                    <Input
                                        value={data.cost_center}
                                        onChange={(e) =>
                                            handleChange("cost_center", e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Description"
                                    validateStatus={error && error.description ? "error" : false}
                                    help={
                                        error && error.description ? error.description[0] : false
                                    }
                                >
                                    <Input
                                        value={data.description}
                                        onChange={(e) =>
                                            handleChange("description", e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Plant "
                                    validateStatus={error && error.plant ? "error" : false}
                                    help={error && error.plant ? error.plant[0] : false}
                                >
                                    <Input
                                        value={data.plant}
                                        onChange={(e) => handleChange("plant", e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Structure Cost"
                                    validateStatus={error && error.structure_cost ? "error" : false}
                                    help={
                                        error && error.structure_cost ? error.structure_cost[0] : false
                                    }
                                >
                                    <Input
                                        value={data.structure_cost}
                                        onChange={(e) =>
                                            handleChange("structure_cost", e.target.value)
                                        }
                                    />
                                </Form.Item>

                                <Form.Item {...tailLayout}>
                                    <Button
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                        className="mr-button"
                                    >
                                        Submit
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                        Reset
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}

export default EditCostCenter;