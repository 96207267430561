import React from "react";
import BasicLayout from "./layouts/BasicLayout";
import SelectCompanyLayout from "./layouts/SelectCompanyLayout";
import Login from "./views/Login/Login";
import { Redirect } from "react-router-dom";
import FullLayout from "./layouts/FullLayout";
import Dashboard from "./views/Dashboard/Dashboard";
import User from "./views/User/User";
import CreateUser from "./views/User/CreateUser";
import EditUser from "./views/User/EditUser";
import Permission from "./views/Permission/Permission";
import CreatePermission from "./views/Permission/CreatePermission";
import EditPermission from "./views/Permission/EditPermission";
import ViewPermission from "./views/Permission/ViewPermission";
import Role from "./views/Role/Role";
import CreateRole from "./views/Role/CreateRole";
import EditRole from "./views/Role/EditRole";
import Setting from "./views/Setting/Setting";
import CreateSetting from "./views/Setting/CreateSetting";
import EditSetting from "./views/Setting/EditSetting";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import Register from "./views/Register/Register";
import ViewUser from "./views/User/ViewUser";
import Vendor from "./views/Vendor/Vendor";
import CreateVendor from "./views/Vendor/CreateVendor";
import ViewVendor from "./views/Vendor/ViewVendor";
import { LayoutFilled } from "@ant-design/icons";
import MasterApproval from "./views/MasterApproval/MasterApproval";
import CreateMasterApproval from "./views/MasterApproval/CreateMasterApproval";
import EditMasterApproval from "./views/MasterApproval/EditMasterApproval";
import ViewMasterApproval from "./views/MasterApproval/ViewMasterApproval";
import BiayaRupaRupa from "./views/BiayaRupaRupa/BiayaRupaRupa";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import CreateBiayaRupaRupa from "./views/BiayaRupaRupa/CreateBiayaRupaRupa";
import EditVendor from "./views/Vendor/EditVendor";
import ApprovalBiayaRupaRupa from "./views/BiayaRupaRupa/ApprovalBiayaRupaRupa";
import BiayaPerjalananDinas from "./views/PerjalananDinas/BiayaPerjalananDinas";
import CreateBiayaPerjalananDinas from "./views/PerjalananDinas/CreateBiayaPerjalananDinas";
import ApprovalBiayaPerjalananDinas from "./views/PerjalananDinas/ApprovalBiayaPerjalananDinas";
import UangMukaEntertainment from "./views/Entertainment/UangMukaEntertainment";
import CreateUangMukaEntertainment from "./views/Entertainment/CreateUangMukaEntertainment";
import ApprovalBiayaEntertainment from "./views/Entertainment/ApprovalBiayaEntertainment";
import ApprovalPengajuanUangMuka from "./views/PengajuanUangMuka/ApprovalPengajuanUangMuka";
import PenyelesaianUangMukaPerjalananDinas from "./views/PenyelesaianUangMuka/PenyelesaianUangMukaPerjalananDinas";
import CreatePenyelesaianUangMukaPerjalananDinas from "./views/PenyelesaianUangMuka/CreatePenyelesaianUangMukaPerjalananDinas";
import ApprovalPenyelesaianUangMuka from "./views/PenyelesaianUangMuka/ApprovalPenyelesaianUangMuka";
import PenambahanBudget from "./views/PenambahanBudget/PenambahanBudget";
import CreatePenambahanBudget from "./views/PenambahanBudget/CreatePenambahanBudget";
import ApprovalPenambahanBudget from "./views/PenambahanBudget/ApprovalPenambahanBudget";
import PemindahanBudget from "./views/PemindahanBudget/PemindahanBudget";
// import CreatePemindahanBudget from "./views/PemindahanBudget/CreatePemindahanBudget";
import PemindahanBudgetBiayaRupaRupa from "./views/PemindahanBudget/CreatePemindahanBudgetBiayaRupa";
import PemindahanBudgetEntertainment from "./views/PemindahanBudget/CreatePemindahanBudgetEntertainment";
import PemindahanBudgetPerdin from "./views/PemindahanBudget/CreatePemindahanBudgetPerdin";
import ApprovalPemindahanBudget from "./views/PemindahanBudget/ApprovalPemindahanBudget";
import DocumentReceive from "./views/DocumentReceive/DocumentReceive";
import ScanDocumentReceive from "./views/DocumentReceive/ScanDocumentReceive";
import GlCode from "./views/GLCode/GlCode";
import CreateGl from "./views/GLCode/CreateGl";
import InternalOrder from "./views/InternaOrder/InternalOrder";
import VerifyDocumentReceive from "./views/DocumentReceive/VerifyDocumentReceive";
import Entertainment from "./views/Entertainment/Entertainment";
import CreateEntertainment from "./views/Entertainment/CreateEntertainment";
import ApprovalEntertainment from "./views/Entertainment/ApprovalBiayaEntertainment";
import CostCenter from "./views/CostCenter/CostCenter";
import EditGl from "./views/GLCode/EditGl";
import ViewGl from "./views/GLCode/ViewGl";
import PengajuanUangMukaPerjalananDinas from "./views/PengajuanUangMuka/PengajuanUangMukaPerjalananDinas";
import CreatePengajuanUangMukaPerjalanDinas from "./views/PengajuanUangMuka/CreatePengajuanUangMukaPerjalananDinas";
import CreatePengajuanBiayaRupaRupa from "./views/BiayaRupaRupa/CreatePengajuanBiayaRupaRupa";
import CreatePenyelesaianUangMukaEntertainment from "./views/PenyelesaianUangMuka/CreatePenyelesaianUangMukaEntertainment";
import PenyelesaianUangMukaEntertainment from "./views/PenyelesaianUangMuka/PenyelesaianUangMukaEntertainment";
import PengajuanBiayaRupaRupa from "./views/BiayaRupaRupa/PengajuanBiayaRupaRupa";
import PenyelesaianBiayaRupaRupa from "./views/BiayaRupaRupa/PenyelesaianBiayaRupaRupa";
import CreatePenyelesaianBiayaRupaRupa from "./views/BiayaRupaRupa/CreatePenyelesaianBiayaRupaRupa";
import EditBiayaRupaRupa from "./views/BiayaRupaRupa/EditBiayaRupaRupa";
import ViewInternalOrder from "./views/InternaOrder/ViewInternalOrder";
import EditPengajuanBiayaRupaRupa from "./views/BiayaRupaRupa/EditPengajuanBiayaRupaRupa";
import EditPenyelesaianBiayaRupaRupa from "./views/BiayaRupaRupa/EditPenyelesaianBiayaRupaRupa";
import EditPerjalananDinas from "./views/PerjalananDinas/EditBiayaPerjalananDinas";
import EditPenyelesaianUangMukaPerjalananDinas from "./views/PenyelesaianUangMuka/EditPenyelesaianUangMukaPerjalananDinas";
import EditUangMukaPerjalananDinas from "./views/PengajuanUangMuka/EditUangMukaPerjalananDinas";
import EditEntertainment from "./views/Entertainment/EditEntertainment";
import EditUangMukaEntertainment from "./views/Entertainment/EditUangMukaEntertainment";
import EditPenyelesaianUangMukaEntertainment from "./views/PenyelesaianUangMuka/EditPenyelesaianUangMukaEntertainment";
import ApprovalPengajuanBiayaRupaRupa from "./views/BiayaRupaRupa/ApprovalPengajuanBiayaRupaRupa";
import HomeApproval from "./views/Home/HomeApproval";
import LeadTimeReport from "./views/LeadTime/LeadTime";
import ApprovalPenyelesaianBiayaRupaRupa from "./views/BiayaRupaRupa/ApprovalPenyelesaianBiayaRupaRupa";
import ApprovalPengajuanEntertainment from "./views/Entertainment/ApprovalPengajuanEntertainment";
import ApprovalPenyelesaianEntertainment from "./views/Entertainment/ApprovalPenyelesaianEntertainment";
import ApprovalUMPerjalananDinas from "./views/PerjalananDinas/ApprovalUMPerjalananDinas";
import ApprovalPenyelesaianPerjalananDinas from "./views/PerjalananDinas/ApprovalPenyelesaianPerjalananDinas";
import Sso from "./views/Sso/Sso";
import EditCostCenter from "./views/CostCenter/EditCostCenter";
import EditInternalOrder from "./views/InternaOrder/EditInternalOrder";
import SelectCompany from "./views/SelectCompany/SelectCompany";
import CompanyIndex from "./views/Company/index";
import CompanyEdit from "./views/Company/edit";
import CompanyUpload from "./views/Company/upload";

import CompanyCreate from "./views/Company/add";
//import WarehouseReceiving from './views/WarehouseReceiving/WarehouseReceiving';

export default [
  {
    path: "/",
    layout: BasicLayout,
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    layout: BasicLayout,
    component: Login,
    exact: true,
  },
  {
    path: "/dashboard",
    layout: FullLayout,
    component: Dashboard,
    exact: true,
  },
  {
    path: "/user",
    layout: FullLayout,
    component: User,
    exact: true,
  },
  {
    path: "/user/create",
    layout: FullLayout,
    component: CreateUser,
    exact: true,
  },
  {
    path: "/user/edit/:id",
    layout: FullLayout,
    component: EditUser,
    exact: true,
  },
  {
    path: "/user/view/:id",
    layout: FullLayout,
    component: ViewUser,
    exact: true,
  },
  {
    path: "/permission",
    layout: FullLayout,
    component: Permission,
    exact: true,
  },
  {
    path: "/permission/create",
    layout: FullLayout,
    component: CreatePermission,
    exact: true,
  },
  {
    path: "/permission/edit/:id",
    layout: FullLayout,
    component: EditPermission,
    exact: true,
  },
  {
    path: "/permission/view/:id",
    layout: FullLayout,
    component: ViewPermission,
    exact: true,
  },
  {
    path: "/role",
    layout: FullLayout,
    component: Role,
    exact: true,
  },
  {
    path: "/role/create",
    layout: FullLayout,
    component: CreateRole,
    exact: true,
  },
  {
    path: "/role/edit/:id",
    layout: FullLayout,
    component: EditRole,
    exact: true,
  },
  {
    path: "/setting",
    layout: FullLayout,
    component: Setting,
    exact: true,
  },
  {
    path: "/setting/create",
    layout: FullLayout,
    component: CreateSetting,
    exact: true,
  },
  {
    path: "/setting/edit/:id",
    layout: FullLayout,
    component: EditSetting,
    exact: true,
  },
  {
    path: "/forgot-password",
    layout: BasicLayout,
    component: ForgotPassword,
    exact: true,
  },
  {
    path: "/reset-password",
    layout: BasicLayout,
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/register",
    layout: BasicLayout,
    component: Register,
    exact: true,
  },
  {
    path: "/vendor",
    layout: FullLayout,
    component: Vendor,
    exact: true,
  },
  {
    path: "/vendor/create",
    layout: FullLayout,
    component: CreateVendor,
    exact: true,
  },
  {
    path: "/vendor/edit/:vendor_code",
    layout: FullLayout,
    component: EditVendor,
    exact: true,
  },
  {
    path: "/vendor/view/:vendor_code",
    layout: FullLayout,
    component: ViewVendor,
    exact: true,
  },
  {
    path: "/master-approval",
    layout: FullLayout,
    component: MasterApproval,
    exact: true,
  },
  {
    path: "/master-approval/create",
    layout: FullLayout,
    component: CreateMasterApproval,
    exact: true,
  },
  {
    path: "/master-approval/edit/:id",
    layout: FullLayout,
    component: EditMasterApproval,
    exact: true,
  },
  {
    path: "/master-approval/view/:id",
    layout: FullLayout,
    component: ViewMasterApproval,
    exact: true,
  },
  {
    path: "/biaya-rupa-rupa",
    layout: FullLayout,
    component: BiayaRupaRupa,
    exact: true,
  },
  {
    path: "/biaya-rupa-rupa/create",
    layout: FullLayout,
    component: CreateBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/biaya-rupa-rupa/edit/:id",
    layout: FullLayout,
    component: EditBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/approval-biaya-rupa-rupa",
    layout: FullLayout,
    component: ApprovalBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-biaya-rupa",
    layout: FullLayout,
    component: PengajuanBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-biaya-rupa/create",
    layout: FullLayout,
    component: CreatePengajuanBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-biaya-rupa-rupa/edit/:id",
    layout: FullLayout,
    component: EditPengajuanBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/approval-pengajuan-uang-muka-biaya-rupa-rupa",
    layout: FullLayout,
    component: ApprovalPengajuanBiayaRupaRupa,
    exact: true,
  },
  // {
  //   path: "/approval-penyelesaian-uang-muka-biaya-rupa-rupa",
  //   layout: FullLayout,
  //   component: ApprovalPenyelesaianBiayaRupaRupa,
  //   exact: true,
  // },
  {
    path: "/approval-penyelesaian-uang-muka-biaya-rupa-rupa",
    layout: FullLayout,
    component: ApprovalPenyelesaianBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-biaya-rupa-rupa/edit/:id",
    layout: FullLayout,
    component: EditPenyelesaianBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/perjalanan-dinas",
    layout: FullLayout,
    component: BiayaPerjalananDinas,
    exact: true,
  },
  {
    path: "/perjalanan-dinas/create",
    layout: FullLayout,
    component: CreateBiayaPerjalananDinas,
    exact: true,
  },
  {
    path: "/perjalanan-dinas/edit/:id",
    layout: FullLayout,
    component: EditPerjalananDinas,
    exact: true,
  },
  {
    path: "/approval-perjalanan-dinas",
    layout: FullLayout,
    component: ApprovalBiayaPerjalananDinas,
    exact: true,
  },
  {
    path: "/approval-pengajuan-um-perjalanan-dinas",
    layout: FullLayout,
    component: ApprovalUMPerjalananDinas,
    exact: true,
  },
  {
    path: "/approval-penyelesaian-perjalanan-dinas",
    layout: FullLayout,
    component: ApprovalPenyelesaianPerjalananDinas,
    exact: true,
  },
  {
    path: "/entertainment",
    layout: FullLayout,
    component: Entertainment,
    exact: true,
  },
  {
    path: "/entertainment/create",
    layout: FullLayout,
    component: CreateEntertainment,
    exact: true,
  },
  {
    path: "/entertainment/edit/:id",
    layout: FullLayout,
    component: EditEntertainment,
    exact: true,
  },
  {
    path: "/approval-entertainment",
    layout: FullLayout,
    component: ApprovalEntertainment,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-entertainment",
    layout: FullLayout,
    component: UangMukaEntertainment,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-entertainment/create",
    layout: FullLayout,
    component: CreateUangMukaEntertainment,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-entertainment/edit/:id",
    layout: FullLayout,
    component: EditUangMukaEntertainment,
    exact: true,
  },
  {
    path: "/approval-pengajuan-uang-muka-entertainment",
    layout: FullLayout,
    component: ApprovalPengajuanEntertainment,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-perjalanan-dinas",
    layout: FullLayout,
    component: PengajuanUangMukaPerjalananDinas,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-perjalanan-dinas/create",
    layout: FullLayout,
    component: CreatePengajuanUangMukaPerjalanDinas,
    exact: true,
  },
  {
    path: "/pengajuan-uang-muka-perjalanan-dinas/edit/:id",
    layout: FullLayout,
    component: EditUangMukaPerjalananDinas,
    exact: true,
  },
  {
    path: "/approval-pengajuan-uang-muka",
    layout: FullLayout,
    component: ApprovalPengajuanUangMuka,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-biaya-entertainment",
    layout: FullLayout,
    component: PenyelesaianUangMukaEntertainment,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-biaya-rupa-rupa",
    layout: FullLayout,
    component: PenyelesaianBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-biaya-rupa-rupa/create",
    layout: FullLayout,
    component: CreatePenyelesaianBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-perjalanan-dinas",
    layout: FullLayout,
    component: PenyelesaianUangMukaPerjalananDinas,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-perjalanan-dinas/create",
    layout: FullLayout,
    component: CreatePenyelesaianUangMukaPerjalananDinas,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-perjalanan-dinas/edit/:id",
    layout: FullLayout,
    component: EditPenyelesaianUangMukaPerjalananDinas,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-entertainment",
    layout: FullLayout,
    component: PenyelesaianUangMukaEntertainment,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-entertainment/create",
    layout: FullLayout,
    component: CreatePenyelesaianUangMukaEntertainment,
    exact: true,
  },
  {
    path: "/penyelesaian-uang-muka-entertainment/edit/:id",
    layout: FullLayout,
    component: EditPenyelesaianUangMukaEntertainment,
    exact: true,
  },
  {
    path: "/approval-penyelesaian-uang-muka-entertainment",
    layout: FullLayout,
    component: ApprovalPenyelesaianEntertainment,
    exact: true,
  },
  {
    path: "/penambahan-budget",
    layout: FullLayout,
    component: PenambahanBudget,
    exact: true,
  },
  {
    path: "/penambahan-budget/:id",
    layout: FullLayout,
    component: CreatePenambahanBudget,
    exact: true,
  },
  {
    path: "/approval-penambahan-budget",
    layout: FullLayout,
    component: ApprovalPenambahanBudget,
    exact: true,
  },
  {
    path: "/pemindahan-budget",
    layout: FullLayout,
    component: PemindahanBudget,
    exact: true,
  },
  {
    path: "/pemindahan-budget/biaya-rupa-rupa/:id",
    layout: FullLayout,
    component: PemindahanBudgetBiayaRupaRupa,
    exact: true,
  },
  {
    path: "/pemindahan-budget/biaya-entertainment/:id",
    layout: FullLayout,
    component: PemindahanBudgetEntertainment,
    exact: true,
  },
  {
    path: "/pemindahan-budget/biaya-perjalanan-dinas/:id",
    layout: FullLayout,
    component: PemindahanBudgetPerdin,
    exact: true,
  },
  // {
  //   path: "/pemindahan-budget/:id",
  //   layout: FullLayout,
  //   component: CreatePemindahanBudget,
  //   exact: true,
  // },

  {
    path: "/approval-pemindahan-budget",
    layout: FullLayout,
    component: ApprovalPemindahanBudget,
    exact: true,
  },
  {
    path: "/document-receive",
    layout: FullLayout,
    component: DocumentReceive,
    exact: true,
  },
  {
    path: "/document-receive/scan",
    layout: FullLayout,
    component: ScanDocumentReceive,
    exact: true,
  },
  {
    path: "/gl-code",
    layout: FullLayout,
    component: GlCode,
    exact: true,
  },
  {
    path: "/gl-code/create",
    layout: FullLayout,
    component: CreateGl,
    exact: true,
  },
  {
    path: "/gl-code/edit/:id",
    layout: FullLayout,
    component: EditGl,
    exact: true,
  },
  {
    path: "/gl-code/view/:id",
    layout: FullLayout,
    component: ViewGl,
    exact: true,
  },
  {
    path: "/internal-order",
    layout: FullLayout,
    component: InternalOrder,
    exact: true,
  },
  {
    path: "/internal-order/edit/:id",
    layout: FullLayout,
    component: EditInternalOrder,
    exact: true,
  },
  {
    path: "/internal-order/view/:id",
    layout: FullLayout,
    component: ViewInternalOrder,
    exact: true,
  },
  {
    path: "/cost-center",
    layout: FullLayout,
    component: CostCenter,
    exact: true,
  },
  {
    path: "/cost-center/edit/:id",
    layout: FullLayout,
    component: EditCostCenter,
    exact: true,
  },
  {
    path: "/verify-document-receive",
    layout: FullLayout,
    component: VerifyDocumentReceive,
    exact: true,
  },
  {
    path: "/sso",
    layout: BasicLayout,
    component: Sso,
    exact: true,
  },
  {
    path: "/home-approval",
    layout: FullLayout,
    component: HomeApproval,
    exact: true,
  },
  {
    path: "/leadtime-report",
    layout: FullLayout,
    component: LeadTimeReport,
    exact: true,
  },
  
  {
    path: "/select-company",
    layout: SelectCompanyLayout,
    component: SelectCompany,
    exact: true,
  },
  {
    path: "/company",
    layout: FullLayout,
    component: CompanyIndex,
    exact: true,
  },
  
  {
    path: "/company/edit/:id",
    layout: FullLayout,
    component: CompanyEdit,
    exact: true,
  },
  {
    path: "/company/create",
    layout: FullLayout,
    component: CompanyCreate,
    exact: true,
  },
  {
    path: "/company/upload",
    layout: FullLayout,
    component: CompanyUpload,
    exact: true,
  },
];
