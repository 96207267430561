import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Tag,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  ScanOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const { Content } = Layout;

function DocumentReceive() {
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [documentReceives, setDocumentReceives] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [buttonAccess, setButtonAccess] = useState(null);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const currentUrl = pathname[1];
    const permissions =
      sessionStorage.getItem("permissions") &&
      JSON.parse(sessionStorage.getItem("permissions"));

    if (permissions) {
      const findPermission = permissions.find(
        (permission) => permission.url === currentUrl
      );

      const findChildPermission = permissions
        .map((child) => {
          return child.children.find(
            (grandChild) => grandChild.url === currentUrl
          );
        })
        .filter((grandChild) => grandChild)[0];

      if (findChildPermission && findChildPermission.children) {
        if (findChildPermission.children) {
          const findButtonAccessChildren = findChildPermission.children
            .filter((x) => x !== null)
            .filter((x) => x.permission_type === "button");
          if (findButtonAccessChildren) {
            setButtonAccess(findButtonAccessChildren.filter((x) => x !== null));
          }
        }
      }
      // if (findPermission && findPermission.children) {
      //   const findButtonAccess = findPermission.children.filter(
      //     (x) => x.permission_type === "button"
      //   );
      //   if (findButtonAccess) {
      //     setButtonAccess(findButtonAccess);
      //   }
      // }
    }
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/document-receive`, {
      params: {
        keyword,
        columns: [
          "date",
          "doc_number",
          "jenis_document",
          "penerima",
          "jam_diterima",
          "approval_status",
          "reveive_code",
          "document_no_pn"
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDocumentReceives(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/document-receive/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Document Date",
      dataIndex: "created_at",
      width: 150,
      align: "center",
      sorter: true,
      align: "center",
      render: (created_at) => moment(created_at).format("DD-MM-YYYY"),
      width: 150,
    },
    {
      title: " Document Received Number ",
      dataIndex: "receive_code",
      width: 150,
      align: "center",
      sorter: false,
    },
    {
      title: "Document Category",
      dataIndex: "doc_type_pn",
      width: 250,
      align: "center",
      sorter: false,
      render: (doc_type_pn, data) => {
        return (
          doc_type_pn 
          ? doc_type_pn 
            : data.document_type_pn === "pengajuan" ? "Advance Payment Submission" : "-"  
        )
      },

    },
    {
      title: "Filing Document Number",
      dataIndex: "document_no_pn",
      width: 150,
      align: "center",
      sorter: false,
    },
    {
      title: "Recipient",
      dataIndex: "document_received_name_pn",
      width: 150,
      align: "center",
      sorter: false,
    },
    {
      title: "Time Received",
      dataIndex: "date_received_pn",
      width: 200,
      align: "center",
      render: (date_received_pn) => 
      date_received_pn === "-" 
      ? "-"
      : moment(date_received_pn).format("DD MMMM YYYY HH:mm"),
      sorter: false,
    },
    {
      title: "Filing Pengajuan",
      dataIndex: "status_receive_pn",
      align: "center",
      key: "status_receive_pn",
      width: 150,
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 1
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: " Document Category",
      width: 200,
      align: "center",
      dataIndex: "doc_type_rn",
      sorter: false,
      render: (doc_type_rn, data) => {
        return (
          doc_type_rn 
          ? doc_type_rn 
            : data.document_type_rn === "penyelesaian" ? "Advance Settlement" : "-"  
        )
      },
    },
    {
      title: "Settlement Document Number",
      dataIndex: "document_no_rn",
      width: 150,
      align: "center",
      sorter: false,
    },
    {
      title: "Recipient",
      dataIndex: "document_received_name_rn",
      width: 150,
      align: "center",
      sorter: false,
    },
    {
      title: "Time Received",
      dataIndex: "date_received_rn",
      width: 200,
      align: "center",
      render: (date_received_rn) =>
      date_received_rn === "-" 
      ? "-"
      : moment(date_received_rn).format("DD MMMM YYYY HH:mm"),
      sorter: false,
    },

    {
      title: "Filing Status",
      dataIndex: "status_receive_rn",
      align: "center",
      key: "status_receive_rn",
      width: 150,
      render: (status) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status === 1
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: "Document Maker",
      dataIndex: "document_maker",
      width: 130,
      align: "center",
      sorter: false,
    },
    {
      title: "Type of Document",
      dataIndex: "doc_type_receive",
      width: 200,
      align: "center",
      sorter: false,
      render: (doc_type_receive, data) => {
        return (
          doc_type_receive 
          ? doc_type_receive 
            : data.type_receive === "rupa-rupa" ? "Miscellaneous Expense" 
            : data.type_receive === "entertainment" ? "Entertainment Expense" 
            : data.type_receive === "perdin" ? "Travel Expense" : "-"  
        )
      },
    },
    {
      title: "Recipient (Acc to Fin)",
      dataIndex: "document_received_last",
      width: 200,
      align: "center",
      sorter: false,
    },
    {
      title: "Time Received (Acc to Fin)",
      dataIndex: "date_received_last",
      width: 220,
      align: "center",
      sorter: false,
      render: (date_received_last) =>
        moment(date_received_last).format("DD MMMM YYYY HH:mm"),
    },

    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   fixed: "right",
    //   width: 80,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               <Menu.Item key="3">
    //                 <span onClick={() => handleDelete(id)}>
    //                   <DeleteOutlined />
    //                   Delete
    //                 </span>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Document Receive"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {buttonAccess &&
                      buttonAccess.find(
                        (x) => x.url === "document-receive/scan"
                      ) && (
                        <Link to="/document-receive/scan">
                          <Button type="primary">
                            <ScanOutlined />
                            Scan
                          </Button>
                        </Link>
                      )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    columns={columns}
                    scroll={{ x: 2000 }}
                    dataSource={documentReceives.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: documentReceives.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default DocumentReceive;
