import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Space,
  Select,
  TimePicker,
  DatePicker,
  AutoComplete,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { CaretRightOutlined, DeleteOutlined } from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreatePenambahanBudget(props) {
  const [data, setData] = useState({
    sisa_budget: 0,
    tambahan_budget: "",
    cost_center_id: "",
    cost_center_number: "",
    pengajuan_um: "",
    available: "",
    ref_code: "",
    ref_id: "",
    total_penambahan: "",
    types_reimbursment: "",
    cost_centers: [],
  });
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [codes, setCodes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [penambahanBudgetDetail, setPenambahanBudgetDetail] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [listBudget, setListBudget] = useState([]);
  const [newListBudget, setNewListBudget] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/penambahan-budget/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const lenDiv = res.data.data.divisi.length;
        const dataListBudget = res.data.data.list_budget_details.find((x) => x.statusBudget === 'X');
        let elDiv = [];
        for (let i = 0; i < lenDiv; i++) {
          elDiv.push(res.data.data.divisi[i].name);
        }

        setData({
          ...data,
          name: res.data.data.name,
          npk: res.data.data.npk,
          divisi: elDiv,
          ref_code: res.data.data.ref_code,
          // pengajuan_uang_muka_biaya_rupa_id: res.data.data.pengajuan_uang_muka_biaya_rupa_id,
          // internal_order_id: res.data.data.internal_order_id,
          // internal_order: res.data.data.internal_order,
          // cost_center_id: res.data.data.cost_center_id,
          // cost_center_number: res.data.data.cost_center,
          transfer_to: res.data.data.transfer_to,
          types_reimbursment: res.data.data.types_reimbursment,
          biaya_rupa_code: res.data.data.biaya_rupa_code,
          nama_bank: res.data.data.nama_bank,
          no_rekening: res.data.data.no_rekening,
          text: res.data.data.text,
          available_budget: dataListBudget.available_budget,
          total_add: dataListBudget.total_amount - dataListBudget.available_budget,
          total_amount: dataListBudget.total_amount,
          grand_total: res.data.data.grand_total,
        });

        if (res.data.data.types_reimbursment === "Biaya Rupa-Rupa") {
          setPenambahanBudgetDetail(
            res.data.data.details_rupa.map((detail) => {
              return {
                id: detail._id,
                ...detail,
              };
            })
          );
        } else if (res.data.data.types_reimbursment === "Biaya Entertainment") {
          setPenambahanBudgetDetail(
            res.data.data.details_entertainment.map((detail) => {
              return {
                id: detail._id,
                ...detail,
              };
            })
          );
        } else if (
          res.data.data.types_reimbursment === "Biaya Perjalanan Dinas"
        ) {
          setPenambahanBudgetDetail(
            res.data.data.details_perdin.map((detail) => {
              return {
                id: detail._id,
                ...detail,
              };
            })
          );
        }

        setListBudget(
          res.data.data.list_budget_details.map((x) => {
            return {
              id: x._id,
              ...x,
            };
          })
        );

        setNewListBudget(
          res.data.data.list_budget_details.filter((x) => 
            x.statusBudget === 'X').map((x) => {
              return {
                id: x._id,
                total_add: x.total_amount - x.available_budget,
                ...x,
              };
            }
          )
        );

      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            sessionStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_number`]: value.label,
    });
  };

  console.log(newListBudget)
  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/penambahan-budget/${props.match.params.id}`,
      {
        details: penambahanBudgetDetail,
        list_budget_details: listBudget,
        new_list_budget_details: newListBudget,
        nama: data.nama,
        npk: data.npk,
        divisi: data.divisi,
        pengajuan_um: data.pengajuan_um,
        ref_code: data.ref_code,
        ref_id: data.ref_id,
        types_reimbursment: data.types_reimbursment,
        // internal_order_id: data.internal_order_id,
        // internal_order: data.internal_order,
        // cost_center_id: data.cost_center_id,
        // cost_center_number: data.cost_center_number,
        transfer_to: data.transfer_to,
        nama_bank: data.nama_bank,
        no_rekening: data.no_rekening,
        total_add_budget: data.total_add,
        available_budget: data.available_budget,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/penambahan-budget");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      vendor_code: "",
    });
  };

  const getCodeEntertainment = async () => {
    await Axios.get(`${url}/entertainment`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCodes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getDetails = (id) => {
    setLoadingDetail(true);
    Axios.get(`${url}/penambahan-budget/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (data.types_reimbursment !== "Pengajuan UM Biaya Rupa-Rupa") {
          setPenambahanBudgetDetail(res.data.data.details_rupa);
        } else {
          setPenambahanBudgetDetail(res.data.data.details_um_rupa);
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const columnsDinas = [
    {
      align: "center",
      title: "Code",
      dataIndex: "gl_code",
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>Description</div>,
      dataIndex: "description",
    },
    {
      align: "center",
      title: "Qty",
      dataIndex: "qty",
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>IDR</div>,
      dataIndex: "rupiah",
      render: (rupiah) => {
        return <AntNumberFormat value={rupiah} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>USD</div>,
      dataIndex: "usd",
      render: (usd) => {
        return <AntNumberFormat value={usd} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Total</div>,
      dataIndex: "total",
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
  ];

  const columnsRupa = [
    {
      align: "center",
      title: "Code",
      dataIndex: "gl_code",
    },
    {
      align: "left",
      title: <div style={{ textAlign: "center" }}>Description</div>,
      dataIndex: "description",
    },
    {
      align: "center",
      title: "Quantity",
      dataIndex: "quantity",
      render: (quantity) => {
        return <AntNumberFormat value={quantity} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Unit Price</div>,
      dataIndex: "harga_satuan",
      render: (harga_satuan) => {
        return <AntNumberFormat value={harga_satuan} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Amount</div>,
      dataIndex: "jumlah",
      render: (jumlah) => {
        return <AntNumberFormat value={jumlah} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPn</div>,
      dataIndex: "ppn",
      render: (ppn) => {
        return <AntNumberFormat value={ppn} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH21</div>,
      dataIndex: "pph21",
      render: (pph21) => {
        return <AntNumberFormat value={pph21} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH23</div>,
      dataIndex: "pph23",
      render: (pph23) => {
        return <AntNumberFormat value={pph23} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>PPH4(2)</div>,
      dataIndex: "pph4",
      render: (pph4) => {
        return <AntNumberFormat value={pph4} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>Total</div>,
      dataIndex: "total",
      render: (total) => {
        return <AntNumberFormat value={total} type="text" />;
      },
    },
  ];

  const getCostCenter = async (keyword) => {
    await Axios.get(`${url}/cost-center/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          cost_centers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectCode = (name, value) => {
    if (value) {
      const findControlItem = codes.find((x) => x._id === value.value);
      if (findControlItem) {
        setData({
          ...data,
          [`${name}_code`]: value.label,
          [`${name}_id`]: value.value,
          nama: findControlItem.nama,
          npk: findControlItem.npk,
          divisi: findControlItem.divisi,
          transfer_to: findControlItem.transfer_to,
          nama_bank: findControlItem.nama_bank,
          no_rekening: findControlItem.no_rekening,
          internal_order: findControlItem.internal_order,
          cost_center_number: findControlItem.cost_center_number,
        });
        getDetails(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_id`]: null,
      });
    }
  };

  if (!sessionStorage.getItem("token")) {
    return <Redirect to="/login" />;
  } else if (sessionStorage.getItem("role_name") !== "Staff") {
    return <Redirect to="/login" />;
  }

  const columnsListBudget = [
    {
      align: "center",
      title: <div style={{ textAlign: "center" }}>INTERNAL ORDER</div>,
      dataIndex: "internal_order_number",
      render: (internal_order_number) => {
        return internal_order_number ? 
          internal_order_number.length == 12 ? internal_order_number.substring(2) : internal_order_number
          : '';
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>BUDGET AVAILABLE</div>,
      dataIndex: "available_budget",
      render: (available_budget) => {
        return <AntNumberFormat value={available_budget} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>TOTAL AMOUNT</div>,
      dataIndex: "total_amount",
      render: (total_amount) => {
        return <AntNumberFormat value={total_amount} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: "center" }}>TOTAL ADD BUDGET</div>,
      dataIndex: "total_add",
      render: (total_add) => {
        return <AntNumberFormat value={total_add ? total_add : ''} type="text" style={{fontWeight: 800, fontSize: 16, letterSpacing: 1}} />;
      },
    }
  ];
  
  const columns = [
    {
      title: "Purchase of entertainment and the like",
      children: [
        {
          title: "Tanggal",
          dataIndex: "created_at",
          render: (date) => moment(date).format("DD-MM-YYYY"),
        },
        {
          title: "Lokasi",
          dataIndex: "tempat",
        },
        {
          title: "Alamat",
          dataIndex: "alamat",
        },
        {
          title: "Jenis *",
          dataIndex: "jenis",
        },
        {
          title: "Jumlah (Rp)",
          dataIndex: "jumlah",
          render: (jumlah) => {
            return <AntNumberFormat value={jumlah} type="text" />;
          },
        },
      ],
    },
    {
      title: "Relase business provided entertainment and the like",
      children: [
        {
          title: "Nama",
          dataIndex: "nama",
        },
        {
          title: "Jabatan",
          dataIndex: "jabatan",
        },
        {
          title: "Nama Perusahaan",
          dataIndex: "nama_perusahaan",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Add Budget"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/penambahan-budget">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item> Add Budget Create</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            

            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Budget"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.ref_doc_no ? "error" : false
                              }
                              help={
                                error && error.ref_doc_no
                                  ? error.ref_doc_no[0]
                                  : false
                              }
                              label="Ref Doc Number"
                            >
                              <Input disabled value={data.ref_code} />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.nama ? "error" : false
                              }
                              help={error && error.nama ? error.nama[0] : false}
                              label="Name"
                            >
                              <Input
                                disabled
                                value={data.name}
                                onChange={(e) => {
                                  handleChange("nama", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.npk ? "error" : false
                              }
                              help={error && error.npk ? error.npk[0] : false}
                              label="NPK"
                            >
                              <Input
                                disabled
                                value={data.npk}
                                onChange={(e) => {
                                  handleChange("npk", e.target.value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            
                            <Form.Item
                              validateStatus={
                                error && error.divisi ? "error" : false
                              }
                              help={
                                error && error.divisi ? error.divisi[0] : false
                              }
                              label="Division"
                            >
                              <Input disabled value={data.divisi} />
                            </Form.Item>
                            {/* <Form.Item label="Cost Center">
                              <Select
                                disabled
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("cost_center", value)
                                }
                                onFocus={() => getCostCenter("")}
                                showSearch
                                onSearch={(value) => getCostCenter(value)}
                                filterOption={false}
                                value={{
                                  key: data.cost_center_id,
                                  label: data.cost_center_number,
                                }}
                              >
                                {data.cost_centers &&
                                  data.cost_centers.map((cost_center) => {
                                    return (
                                      <Option
                                        value={cost_center._id}
                                        key={cost_center._id}
                                      >
                                        {`${cost_center.cost_center_number}-${cost_center.description}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item> */}
                            <Form.Item
                              validateStatus={
                                error && error.transfer_to ? "error" : false
                              }
                              help={
                                error && error.transfer_to
                                  ? error.transfer_to[0]
                                  : false
                              }
                              label="Account Name"
                            >
                              <Input
                                disabled
                                value={data.transfer_to}
                                onChange={(e) => {
                                  handleChange("transfer_to", e.target.value);
                                }}
                              />
                            </Form.Item>
                            {/* <Form.Item
                              validateStatus={
                                error && error.pengajuan ? "error" : false
                              }
                              help={
                                error && error.pengajuan
                                  ? error.pengajuan[0]
                                  : false
                              }
                              label="Budget"
                            >
                              <AntNumberFormat
                                disabled
                                type="input"
                                value={
                                  data.grand_total -
                                  (data.grand_total - data.available)
                                }
                                onChange={(e) => {
                                  handleChange("pengajuan", e);
                                }}
                              />
                            </Form.Item> */}
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>

                  <Panel
                    header="Detail"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Form
                      layout="inline"
                      style={{ marginBottom: 50 }}
                    >
                      <Form layout="vertical">
                        <Form.Item label="Jenis Reimbursement" required>
                          <Input
                            disabled
                            style={{ width: 250, marginRight: 15 }}
                            value={data.types_reimbursment}
                            onChange={(e) => {
                              handleChange(
                                "types_reimbursment",
                                e.target.value
                              );
                            }}
                          />
                        </Form.Item>
                      </Form>
                      {/* <Form layout="vertical">
                        <Form.Item label="Internal Order" required>
                          <Input
                            disabled
                            style={{ width: 180, marginRight: 15 }}
                            value={data.internal_order}
                            onChange={(e) => {
                              handleChange("internal_order", e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Form>
                      <Form layout="vertical">
                        <Form.Item label="Submission Additional Budget" required>
                          <AntNumberFormat
                            disabled
                            style={{ width: 200 }}
                            type="input"
                            value={data.total_amount - data.available}
                            onChange={(e) => {
                              handleChange("total_penambahan", e);
                            }}
                          />
                        </Form.Item>
                      </Form> */}
                    </Form>
                    
                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "25px" }}
                        size="small"
                        columns={columnsListBudget}
                        dataSource={newListBudget}
                        rowKey={(data) => data.id}
                        onChange={handleTableChange}
                        // scroll={{ x: 2000 }}
                        loading={loading}
                      />
                    </Col>
                    
                    <Table
                      bordered
                      columns={
                        data.types_reimbursment === "Biaya Rupa-Rupa" ||
                        data.types_reimbursment === "Pengajuan UM Biaya Rupa-Rupa"
                          ? columnsRupa
                          : data.types_reimbursment === "Biaya Entertainment" ||
                            data.types_reimbursment === "Biaya UM Entertainment"
                          ? columns
                          : data.types_reimbursment === "Biaya Perjalanan Dinas" ||
                            data.types_reimbursment === "Biaya UM Perjalanan Dinas"
                          ? columnsDinas
                          : ""
                      }
                      dataSource={penambahanBudgetDetail}
                      rowKey={(data) => data._id}
                      onChange={handleTableChange}
                      loading={loading}
                    />
                    <Form
                      style={{ marginTop: "30px" }}
                      labelAlign="left"
                      {...layout}
                      name="control-hooks"
                    >
                      <Row>
                        <Col xs={24} md={12} lg={12}>
                          <Form.Item label="Total Amount">
                            <AntNumberFormat
                              disabled
                              value={data.total_amount}
                              type="input"
                            />
                          </Form.Item>

                          <Form.Item label="Budget Available">
                            <AntNumberFormat
                              disabled
                              value={data.available_budget}
                              type="input"
                            />
                            {/* <span>-</span> */}
                          </Form.Item>

                          <Form.Item label="Total Additional Budget">
                            <AntNumberFormat
                              disabled
                              value={data.total_add}
                              type="input"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    <Row justify="start">
                      <Col style={{ marginTop: 10 }}>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreatePenambahanBudget;
